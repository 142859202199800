import { documintApi } from '.'

export const airtableApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getDataFromProvider: build.query({
      query: ({ accessToken, connectionName, action, provider, ...rest }) => ({
        url: `/providers?${new URLSearchParams({ connectionName, provider, action, ...rest })}`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    }),
  }),
})

export const { useGetDataFromProviderQuery, useLazyGetDataFromProviderQuery } = airtableApi

export default airtableApi
