const config = {
  name: 'Background',
  open: true,
  buildProps: ['background-color', 'background-image', 'background-size', 'background-position', 'background-repeat'],
  properties: [
    {
      name: 'Color',
      property: 'background-color',
      type: 'color',
    },
    {
      name: 'Image',
      property: 'background-image',
      onChange: ({ property, value }) => {
        const editor = property.em.getEditor();
        const target = editor.getSelected();
        const sm = editor.Styles;
        const bgSize = sm.getProperty('background', 'background-size');
        const bgRepeat = sm.getProperty('background', 'background-repeat');
        const bgPosition = sm.getProperty('background', 'background-position');

        if (
          target &&
          value.startsWith('url')
        ) {
          bgSize.view.$el.show();
          bgRepeat.view.$el.show();
          bgPosition.view.$el.show();
        } else {
          bgSize.view.$el.hide();
          bgRepeat.view.$el.hide();
          bgPosition.view.$el.hide();
        }
      }
    },
    {
      name: 'Size',
      property: 'background-size',
      type: 'select',
      full: false,
      default: 'contain',
      options: [
        { value: 'contain', name: 'Fit' },
        { value: 'cover', name: 'Fill' },
        { value: '10%', name: '10%' },
        { value: '20%', name: '20%' },
        { value: '30%', name: '30%' },
        { value: '40%', name: '40%' },
        { value: '50%', name: '50%' },
        { value: '60%', name: '60%' },
        { value: '70%', name: '70%' },
        { value: '80%', name: '80%' },
        { value: '90%', name: '90%' },
        { value: '100%', name: '100%' },
      ],
    },
    {
      name: 'Repeat',
      property: 'background-repeat',
      type: 'select',
      full: false,
      default: 'no-repeat',
      list: [
        { value: 'no-repeat', name: 'None' },
        { value: 'repeat', name: 'All' },
        { value: 'repeat-x', name: 'Horizontally' },
        { value: 'repeat-y', name: 'Vertically' },
      ],
    },
    {
      name: 'Position',
      property: 'background-position',
      type: 'select',
      default: 'center center',
      full: false,
      list: [
        { value: 'top left', name: 'Top Left' },
        { value: 'top center', name: 'Top Center' },
        { value: 'top right%', name: 'Top Right' },
        { value: 'middle left', name: 'Middle Left' },
        { value: 'center center', name: 'Middle Center' },
        { value: 'center right', name: 'Middle Right' },
        { value: 'bottom left', name: 'Bottom Left' },
        { value: 'bottom center', name: 'Bottom Center' },
        { value: 'bottom right', name: 'Bottom Right' },
      ],
    },
  ],
}
export default config
