import React, { useEffect, useMemo } from 'react'
import { Layout, Typography, Tooltip, Segmented } from 'antd'
import PDFViewer from '@common/PDFViewer'
import { ManualPreviewControls, DataSourcePreviewControls } from './components'
import { MODES_LIST, MODES } from './consts'
import { useTemplatePreview } from './hooks'
import { FaIcon } from '@components/icons'
import DataSourcePreviewEmptyState from './components/DataSourcePreviewEmptyState'
import { useTemplate } from '@hooks/useTemplate'
import { DataSourceCards } from '@features/template-data-source'
import { useBreakpoint } from '@hooks/helperHooks'

const { Title, Text } = Typography

const siderBreakpointsProps = {
  xs: { width: 280 },
  sm: { width: 280 },
  md: { width: 280 },
  lg: { width: 280 },
  xl: { width: 300 },
  xxl: { width: 370 },
}

function TemplatePreview({ dataSource, ...props }) {
  const { template } = useTemplate()
  const { preview, mode, setMode, selectedRecord } = useTemplatePreview()
  const breakpoint = useBreakpoint()

  const siderBreakpointProps = siderBreakpointsProps[breakpoint]

  // Set initial mode based on dataSource connection
  useEffect(() => {
    if (!dataSource || mode) return
    if (dataSource?.isConnected) {
      setMode(MODES.dataSource)
    } else {
      setMode(MODES.manual)
    }
  }, [dataSource, setMode, mode])

  const modes = useMemo(() => {
    const result = [...MODES_LIST]
    if (dataSource?.isConnected && dataSource?.name) {
      const dataSourceMode = result.find((mode) => mode.value === MODES.dataSource.value)
      dataSourceMode.label = dataSource?.name
    }
    return result
  }, [dataSource])

  return (
    <Layout id='test-tab' hasSider {...props}>
      <Layout.Sider id='data' theme='light' className='sider dm-sidebar flex-column' {...siderBreakpointProps}>
        {dataSource?.id ? (
          <div className='d-flex flex-column' style={{ height: '100%' }}>
            <div
              className='d-flex flex-column px-3 pt-3 pb-2'
              style={{
                flex: '0 0 64px',
                backgroundColor: '#fff',
                borderBottom: 'solid 1px #eee',
                width: '100%',
                gap: '10px',
              }}
            >
              <div className='d-flex justify-content-center align-items-center py-1' style={{ gap: '10px' }}>
                <FaIcon icon='faFlask' size={18} />

                <Title
                  level={4}
                  className='mb-0 mr-2 text-center text--primary d-flex align-items-center'
                  style={{ gap: '10px' }}
                >
                  Test Data
                </Title>

                <Tooltip title='Test the variables in your template by entering sample data in the form below.'>
                  <FaIcon icon='faInfoCircle' size={16} />
                </Tooltip>
              </div>

              {dataSource?.isConnected && (
                <div className='text-center py-1' style={{ background: 'white' }}>
                  <Segmented options={modes} value={mode} onChange={(value) => setMode(value)} />
                </div>
              )}
            </div>

            <div className='d-flex flex-column' style={{ flex: '1 1 auto', overflowY: 'auto' }}>
              <div className='d-flex flex-column' style={{ flex: '1 1 auto', overflowY: 'auto' }}>
                {mode === MODES.manual.value ? (
                  <ManualPreviewControls />
                ) : (
                  <DataSourcePreviewControls dataSource={dataSource} />
                )}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div
              className='py-3 px-5 text-center'
              style={{
                flex: '0 0 64px',
                backgroundColor: '#fff',
                borderBottom: 'solid 1px #eee',
                width: '100%',
                gap: '10px',
              }}
            >
              <Title level={4} className='text--primary' style={{ gap: '10px' }}>
                Connect a data source
              </Title>
              <Text type='secondary'>Create a field schema or connect to a data source to preview your template</Text>
            </div>
            <div className='p-4'>
              <DataSourceCards />
            </div>
          </>
        )}
      </Layout.Sider>

      <Layout.Content className='content'>
        {mode === MODES.dataSource.value && !selectedRecord ? (
          <div className='d-flex mt-8 justify-content-center' style={{ height: '100%' }}>
            <DataSourcePreviewEmptyState />
          </div>
        ) : (
          <PDFViewer
            data={preview.data}
            isLoading={preview?.isLoading}
            error={preview?.error}
            controls={['download', ' ', 'scale']}
            loaderProps={template?.options || {}}
            height='100%'
          />
        )}
      </Layout.Content>
    </Layout>
  )
}

export default TemplatePreview
