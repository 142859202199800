import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectJwt, setJwt, selectProjectId, setProjectId } from '../slice'
import { useLazyGetJwtQuery, useLazyGetConnectionQuery } from '@documintApi/activepieces'
import { useGetProfile } from '@features/auth'

export const useActivePieces = () => {
  const profile = useGetProfile()
  const dispatch = useDispatch()
  const jwt = useSelector(selectJwt)
  const projectId = useSelector(selectProjectId)
  const [getConnection, { data: connection, isLoading: connectionIsLoading, error: connectionError }] =
    useLazyGetConnectionQuery()

  const [requestJwt, { isLoading: isLoadingJwt, error: jwtError, isFetching: isFetchingJwt }] = useLazyGetJwtQuery()

  const getJwt = useCallback(async () => {
    if (!profile) return
    const response = await requestJwt()
    if (response?.data?.projectId) dispatch(setProjectId(response.data.projectId))
    if (response?.data?.accessToken) dispatch(setJwt(response.data.accessToken))
  }, [profile, requestJwt, dispatch])

  useEffect(() => {
    if (profile && !jwt) getJwt()
  }, [profile, getJwt, jwt, dispatch, projectId])

  const openConnectionsModal = useCallback(async (pieceName) => {
    if (!pieceName || !window.activepieces) return

    const resPromise = window.activepieces.connect({ pieceName })
    const connectionsIframe = window.document.querySelector('#ApConnectionsIframe')

    if (connectionsIframe) {
      connectionsIframe.style.display = 'block'
      connectionsIframe.style.zIndex = 1001
    }
    return resPromise
  }, [])

  return {
    jwt,
    isLoadingJwt,
    jwtError,
    isFetchingJwt,
    getConnection,
    connection,
    connectionIsLoading,
    connectionError,
    projectId,
    openConnectionsModal,
  }
}

export default useActivePieces
