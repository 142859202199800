export const config = {
  id: 'default',
  name: 'Custom schema',
  icon: {
    type: 'icon',
    value: 'faListTree',
    style: { fontSize: '24px' },
  },
  label: 'Custom schema',
  description: 'Create documents from custom data sources.',
}

export default config
