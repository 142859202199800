import { toJsonSchema } from './parser'
import DataSource, { Entity, EntityConfigField } from '../DataSource'
import airtableConfig from './config'

const defaultConnection = { provider: 'airtable', pieceName: '@activepieces/piece-airtable' }

export class AirtableDataSource extends DataSource {
  id = airtableConfig.id
  name = airtableConfig.name
  icon = airtableConfig.icon
  description = airtableConfig.description
  schema = { bases: null, tables: null, fields: null }

  constructor(config = {}) {
    const { connection, schema, entity, primaryField, expandedFields } = config

    super({
      connection: { ...connection, ...defaultConnection },
      schema,
      primaryField,
      expandedFields,
    })

    this.entity = new Entity(entity, this)

    const dataSource = this

    this._entityConfigFields = [
      new EntityConfigField(
        {
          id: 'base',
          name: 'Base',
          action: 'getBases',
          entity: this,
          key: 'baseId',
          value: { id: entity?.baseId, name: entity?.baseName },
          handleResponse(res = {}, cb) {
            const bases = res?.data?.bases
            dataSource.schema.bases = bases

            const baseId = dataSource.entity?.get('baseId')

            const base = baseId && bases?.find((base) => base.id === baseId)
            if (base) {
              dataSource.entity?.set({ baseName: base?.name })
              dataSource.schema.base = base
            }

            if (cb) cb(bases)
            return bases
          },
          setValue({ id, name } = {}) {
            const baseChanged = dataSource.entity?.get('baseId') !== id
            this.value = { id, name }

            dataSource?.entity?.set({ baseId: id, baseName: name })

            if (!baseChanged) return

            dataSource.schema.base = dataSource.schema.bases?.find((base) => base.id === id)
            dataSource.schema.tables = null
            dataSource.schema.fields = null
          },
        },
        dataSource,
      ),
      new EntityConfigField(
        {
          id: 'table',
          name: 'Table',
          action: 'getBaseTables',
          entity: this,
          key: 'tableId',
          value: { id: entity?.tableId, name: entity?.tableName },
          handleResponse(res, cb) {
            const tables = res?.data?.tables
            dataSource.schema.tables = tables

            const tableId = dataSource.entity?.get('tableId')
            const table = tableId && tables?.find((table) => table.id === tableId)
            if (tableId) this.setValue({ id: tableId, name: table?.name })

            if (cb) cb(tables)
            return tables
          },
          getQuery() {
            return { baseId: dataSource.entity?.get('baseId') }
          },
          setValue({ id, name } = {}) {
            const tableChanged = dataSource.entity.get('tableId') !== id
            this.value = { id, name }

            dataSource.entity?.set({ tableId: id, tableName: name })

            const table = dataSource.schema?.tables?.find?.((table) => table.id === id)

            dataSource.schema.table = table

            if (tableChanged) dataSource.expandedFields = null

            const { fields, primaryFieldId } = table || {}

            dataSource.schema.fields = fields
            dataSource.primaryField = fields?.find?.((field) => field.id === primaryFieldId)
          },
        },
        dataSource,
      ),
    ]
  }

  getSearchQuery(searchValue = '') {
    const primaryField = this?.primaryField?.name
    return `REGEX_MATCH({${primaryField}}, '(?i)' & SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(SUBSTITUTE(LOWER('${searchValue}'), ' ', '\\s*'), ',', ''), '.', ''), '-', ''), "'", '') & '')`
  }

  getJsonSchema(editor, { expandedFields } = {}) {
    const { tables, fields } = this?.schema || {}

    if (!tables || !fields || !editor) return null

    const tableMap = tables.reduce((acc, table) => {
      acc[table.id] = table
      return acc
    }, {})

    return toJsonSchema(this.expandExpandedFields(fields, expandedFields, tableMap), { tables, editor })
  }

  expandExpandedFields(_fields, expandedFields, tableMap, parentKey = null) {
    const getFields = (fields, field, parentKey = null) => {
      let linkedTableId = field?.options?.linkedTableId

      // Get the linked table id for multipleLookupValues
      if (field.type === 'multipleLookupValues') {
        const linkedRecordField = fields.find((f) => f.id === field.options.recordLinkFieldId)
        const originalLinkedTableId = linkedRecordField?.options?.linkedTableId
        const originalLinkedTable = tableMap[originalLinkedTableId]
        const lookedUpField = originalLinkedTable?.fields.find((f) => f.id === field.options.fieldIdInLinkedTable)
        linkedTableId = lookedUpField?.options?.linkedTableId
      }

      const linkedTable = tableMap[linkedTableId]

      if (!linkedTable?.fields) return []

      return this.expandExpandedFields(linkedTable?.fields, expandedFields, tableMap, parentKey)
    }

    return [..._fields].map((field) => {
      const key = parentKey ? `${parentKey}-${field.id}` : field.id
      if (!getIsLinkedRecordField(field)) return field
      if (!(expandedFields ?? []).includes(key)) return field

      const fields = getFields(_fields, field, key)
      return {
        ...field,
        fields,
      }
    }, {})
  }

  getExpandableFields({ maxDepth = 4 } = {}) {
    const { tables } = this?.schema || {}
    const tableId = this.entity.get('tableId')

    if (!tables || !tableId) return null

    const tableMap = tables.reduce((acc, table) => {
      acc[table.id] = table
      return acc
    }, {})

    const primaryTable = tableMap[tableId]

    if (!primaryTable || !primaryTable.fields) return []

    function getNodes(fields, depth = 0, parent = null) {
      const result = []
      if (!fields || !Array.isArray(fields) || depth >= maxDepth) return []

      const linkedRecordFields = fields.filter(({ type, options }) => {
        if (!getIsLinkedRecordField({ type, options })) return false

        // Check if the field is not the inverse link field
        return options?.inverseLinkFieldId !== parent?.id
      })

      for (let field of linkedRecordFields) {
        field = { ...field, key: parent ? `${parent.key}-${field.id}` : field.id }
        field.options = { ...(field.options || {}) }

        let linkedTableId = field?.options?.linkedTableId

        // Get the linked table id for multipleLookupValues
        if (field.type === 'multipleLookupValues') {
          const linkedRecordField = fields.find((f) => f.id === field.options.recordLinkFieldId)
          const originalLinkedTableId = linkedRecordField?.options?.linkedTableId
          const originalLinkedTable = tableMap[originalLinkedTableId]
          const lookedUpField = originalLinkedTable?.fields.find((f) => f.id === field.options.fieldIdInLinkedTable)
          linkedTableId = lookedUpField?.options?.linkedTableId
        }

        const linkedTable = tableMap[linkedTableId]

        if (!linkedTable) continue

        field.options.linkedTableName = linkedTable.name
        field.expandableFields = getNodes(linkedTable.fields, depth + 1, field)
        field.fields = linkedTable.fields

        result.push(field)
      }

      return result
    }

    return getNodes(primaryTable.fields)
  }
}

function getIsLinkedRecordField(field) {
  if (field.type === 'multipleRecordLinks') return true
  return field.type === 'multipleLookupValues' && field?.options?.recordLinkFieldId
}

export const airtableDataSource = new AirtableDataSource()

export default AirtableDataSource
