import React, { useEffect, useState } from 'react'
import { Input } from 'antd'
import { useTemplateEdit } from '../../../hooks'
import clamp from 'lodash/clamp'
import { useDebouncedCallback } from 'use-debounce'

export default function TemplateName({ style, className }) {
  const { template, update } = useTemplateEdit()
  const updateNameDebounce = useDebouncedCallback(update, 500)
  const { name } = template || {}

  const [_name, _setName] = useState(template?.name || '')
  const [size, setSize] = useState(template?.name?.length || 20)

  useEffect(() => {
    if (!name) return
    _setName(name)
    setSize(name?.length || 20)
  }, [name, _setName, setSize])

  const handleChange = ({ target }) => {
    const newName = target.value
    const newSize = clamp(newName.length, 6, 70)
    setSize(newSize)
    _setName(newName)
    updateNameDebounce({ name: newName })
  }

  return (
    <div style={style} className={className}>
      <Input
        value={_name}
        placeholder='unnamed template'
        onChange={handleChange}
        className={'template-name editable-title'}
        htmlSize={size}
      />
    </div>
  )
}
