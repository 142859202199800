import React from 'react'
import Button from '@common/Button'
import { useTemplateEdit } from '../../../hooks'

export default function SaveButton() {
  const { isSaving, editor } = useTemplateEdit()

  const hasUnsavedChanges = false //editor.changeCount > 0

  return (
    <Button
      size='small'
      onClick={() => editor.store()}
      loading={isSaving}
      disabled={!hasUnsavedChanges}
      type={hasUnsavedChanges ? 'primary' : 'secondary'}
    >
      {isSaving ? 'Saving' : 'Save'}
    </Button>
  )
}
