import { useCallback } from 'react'
import { useResetPasswordMutation } from '@services/documintApi/auth'

/**
 * Use Reset Password
 */
export const useResetPassword = () => {
  const [_reset, { isLoading: isResetting }] = useResetPasswordMutation()
  /**
   * Reset Password
   * @param {*} password
   * @param {*} token
   * @returns
   */
  const reset = useCallback(
    async (password, token) => {
      const { data, error } = await _reset({ password, token })
      if (error) throw error
      return data
    },
    [_reset],
  )
  return [reset, { isResetting }]
}

export default useResetPassword
