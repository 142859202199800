import React, { useMemo } from 'react'
import { Button, Typography, Collapse } from 'antd'
import CodeBlock from '@common/CodeBlock'
import { createFormula } from '../utils'
import ConfigurationForm from './ConfigurationForm'
import { useInstructions } from '../../../hooks'
import { useTemplate } from '@hooks/useTemplate'

const { Title, Paragraph } = Typography

export default function GenerationLink({ data, onChange }) {
  const { template } = useTemplate()
  const { config } = useInstructions()

  const formula = useMemo(() => createFormula(template.id, config), [config, template.id])

  const handleChange = (options) => {
    onChange?.(options)
  }

  return (
    <>
      <div className='mb-3'>
        <Title level={4}>Overview</Title>

        <Paragraph>
          The Airtable Generation Link integration allows you to generate documents from Airtable using a formula field
          in your Airtable table to create a unique link for each record.
        </Paragraph>
      </div>

      <Collapse className='mb-3' bordered={false}>
        <Collapse.Panel header='Setup Instructions' key='1'>
          <ol>
            <li>
              Using the Formula Builder below, create a Generation Link by adding your table URL. <br />
              <b>Note</b>: If you would like to store the document in Airtable, expand the Options in the Formula
              Builder and add the name of the Attachment field where you would like to store it.
            </li>
            <li>Copy the generated formula.</li>
            <li>
              In Airtable, create a new formula field (in the same table as the URL) and add the generated formula.
            </li>
          </ol>
          <Button
            href='https://docs.documint.me/integrations/airtable/generation-link'
            htmlType='a'
            target='_blank'
            className='mt-2 mb-4'
            type='primary'
          >
            Complete Setup Guide
          </Button>
        </Collapse.Panel>
      </Collapse>

      <Title level={4}>Generation Link Formula</Title>
      <CodeBlock
        showLineNumbers={false}
        code={formula || '// Formula will be displayed here after you add your Table URL and Attachment field name'}
        wrapperStyle={{ marginBottom: '1em', border: 'none', background: '#f6f6f6' }}
        theme='light'
        wrapLines={true}
        style={{ overflow: 'hidden' }}
      />

      <Title level={5}>Options</Title>
      <ConfigurationForm onChange={handleChange} />
    </>
  )
}
