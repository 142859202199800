import { useState, useCallback } from 'react'
import { useDesigner } from '../../../hooks'

const useLogicEditor = (selectedComponent) => {
  const { template, isSaving } = useDesigner()
  const { fieldSchema } = template || {}

  const [dataSourceModalIsVisible, setDataSourceModalIsVisible] = useState(false)

  const handleOpenDataSourceModal = useCallback(() => {
    setDataSourceModalIsVisible(true)
  }, [])

  const handleCancelDataSourceModal = useCallback(() => {
    setDataSourceModalIsVisible(false)
  }, [])

  return {
    hasSchema: !!fieldSchema && Object.keys(fieldSchema).length,
    dataSourceModalIsVisible,
    handleOpenDataSourceModal,
    handleCancelDataSourceModal,
    isSaving,
  }
}

export default useLogicEditor
