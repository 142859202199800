import React from 'react'
import { Button, Dropdown, Grid } from 'antd'
import FaIcon from '@components/icons/FaIcon'
import { TemplateSettingsButton, useTemplateSettings } from '@features/template-settings'
import ActiveSwitch from './ActiveSwitch'
import { useToggle } from '@hooks/helperHooks'
import { useTemplate } from '@hooks/useTemplate'

const { useBreakpoint } = Grid

export default function TemplateControls() {
  const breakpoint = useBreakpoint()

  const { template } = useTemplate()
  const { toggleActive, toggleModal } = useTemplateSettings()
  const [showPopover, togglePopover] = useToggle(false)

  const handleResourceClick = () => {
    window.userGuiding.launchResourceCenter(2908)
    window.userGuiding.expandResourceCenter(2908)
  }

  const itemStyle = { padding: '10px 20px' }
  
  const items = [
    {
      key: '4',
      label: template?.isActive ? 'Active' : 'Inactive',
      content: <ActiveSwitch key='4' />,
      icon:
        template?.isActive === true ? (
          <FaIcon icon='faToggleOn' style={{ color: '#009BFF' }} />
        ) : (
          <FaIcon icon='faToggleOff' />
        ),
      onClick: () => {
        toggleActive()
        togglePopover(false)
      },
      style: itemStyle,
    },
    {
      key: '3',
      label: 'Settings',
      content: <TemplateSettingsButton key='3' />,
      icon: <FaIcon icon='faCog' />,
      onClick: () => {
        toggleModal()
        togglePopover(false)
      },
      style: itemStyle,
    },
    {
      key: '2',
      label: 'Tours',
      icon: <FaIcon icon='faPresentationScreen' id='guides-list' />,
      onClick: () => window.userGuiding.previewGuide(92579),
      style: itemStyle,
    },
    {
      key: '1',
      label: 'Help',
      size: 'large',
      icon: <FaIcon icon='faQuestionCircle' id='resource-center' />,
      onClick: () => {
        handleResourceClick()
        togglePopover(false)
      },
      style: itemStyle,
    },
  ]

  if (!breakpoint.xl) {
    return (
      <Dropdown
        id='template-controls__popover'
        trigger={['click']}
        open={showPopover}
        overlayStyle={{ marginLeft: '-30px' }}
        menu={{ items }}
      >
        <Button
          icon={showPopover ? <FaIcon icon='faX' /> : <FaIcon icon='faEllipsis' />}
          onClick={togglePopover}
          size='large'
          type={showPopover ? 'primary' : 'text'}
        />
      </Dropdown>
    )
  } else {
    return (
      <div className='d-flex flex-row' style={{ alignItems: 'center', gap: '10px' }}>
        <Button key={items[3].key} icon={items[3].icon} type='text' size='large' onClick={items[3].onClick} />
        <Button key={items[2].key} icon={items[2].icon} type='text' size='large' onClick={items[2].onClick} />
        {items[1].content}
        {items[0].content}
      </div>
    )
  }
}
