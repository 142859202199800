import React, { useRef, useCallback, useEffect, useMemo } from 'react'

import { debounce, mergeWith, add } from 'lodash'
import { notification } from 'antd'
import grapesjs from 'grapesjs'
import GjsEditor from '@grapesjs/react'

import config from './config'
import { validateTemplate } from '@utils'
import events, { setDevice, updateCanvasPosition, loadData, loopAllComponents, deselectComponents } from './utils'
import { useTemplate } from '@hooks/useTemplate'
import 'intersection-observer'
import style from './config/style'
import { TYPES } from './plugins/componentTypes/consts'
import { getSchemaCollectionItemsByTypes } from '../NewLogicModal/utils'
import { addItemsToCollection, deepClone } from '@utils'
import { useEditor, useDesigner } from '../../hooks'
import 'grapesjs/dist/css/grapes.min.css'
import { assetMarkup } from './plugins/assets'

export default function Editor({ onInit, onComponentToggle }) {
  const {
    editor,
    updateContent,
    setEditor,
    initialized,
    setInitialized,
    setSelectedComponent,
    zoomIn,
    zoomOut,
    setHasUndo,
    setHasRedo,
    setHasLegacy,
    run,
  } = useEditor()
  const { template, assets: globalAssets, updateAssets } = useDesigner()
  const { components, styles, options, fieldSchema } = template || {}
  const { isLoading } = useTemplate()

  // Local state variables
  const editorRef = useRef(null)
  // const isVisible = useIsVisible(editorRef)
  const error = useRef(null)
  const templateAssets = useRef({})
  const handle = useRef(null)
  const handleStore = useRef(null)

  useEffect(() => {
    if (!editor) return
    editor.getVariableOptions = (root = 'root', types = ['string']) =>
      getSchemaCollectionItemsByTypes(addItemsToCollection(deepClone(fieldSchema)), root, types)
    run('up-schema')
  }, [editor, fieldSchema, run])

  // if there isn't an editor set initialized to false
  // Used for reloading
  useEffect(() => {
    if (editor || initialized === false) return
    setInitialized(false)
  }, [editor, initialized, setInitialized])

  useEffect(() => {
    if (!globalAssets.isSuccess || !editor) return
    editor.Assets.load({ assets: globalAssets.data.data })
  }, [globalAssets, editor])

  handleStore.current = {
    // Handle changes to the template body here so that
    // we can control when changes are passed to the parent
    content: (data) => updateContent(data),
    assets() {
      const currentTemplateAssets = { ...templateAssets.current }
      const filteredAssets = Object.keys(currentTemplateAssets).filter((k) => currentTemplateAssets[k] > 0)

      if (!globalAssets?.data?.data?.length > 0) return
      // Get the IDs from global template assets & store if there's a change
      const assetIds = globalAssets.data.data.filter((a = {}) => filteredAssets.includes(a.src)).map((a = {}) => a.id)
      updateAssets(assetIds)
    },
  }

  const handleStoreAssetsDebounced = useMemo(() => debounce(handleStore.current.assets, 3000), [])

  const validateTextComponent = useCallback((model) => {
    if (model?.attributes?.type !== 'text') return
    const html = model.toHTML()
    error.current = validateTemplate(html)
    let action = error.current ? 'add' : 'remove'
    model.view?.el.classList[action]('error')
    notification.destroy()
    if (error.current) {
      notification.error({
        message: 'Syntax error',
        description:
          'This was probably caused by a malformed variable in the element that was modified last. To fix, try undoing the last change.',
        placement: 'bottomRight',
      })
    }
  }, [])

  const updateSelectedComponent = () => {
    if (!editor) return
    const selComp = editor.getSelected()
    setSelectedComponent(selComp)

    if (onComponentToggle) onComponentToggle(selComp)
  }

  const updateTemplateAssets = (model, changeType) => {
    // Verify is image type
    if (model?.attributes?.type !== 'dm-image') return

    const { attributes: img, _previousAttributes: prevImg } = model

    const changes = { [img.src]: 0, [prevImg.src]: 0 }

    if (changeType === 'add') changes[img.src]++
    if (changeType === 'remove') changes[img.src]--
    if (changeType === 'update') {
      changes[prevImg.src]--
      changes[img.src]++
    }
    const prevValues = { ...templateAssets.current }
    templateAssets.current = mergeWith(prevValues, changes, add)

    handleStoreAssetsDebounced()
  }

  const handleScrollZoom = useCallback(
    (event) => {
      if (event.ctrlKey === false) return
      event.preventDefault()
      const changeValue = event?.wheelDeltaY
      if (!changeValue || changeValue === 0) return
      if (changeValue > 0) {
        zoomIn()
      } else {
        zoomOut()
      }
    },
    [zoomIn, zoomOut],
  )

  useEffect(() => {
    return function () {
      if (editor) {
        const dirtyCount = editor?.getDirtyCount?.()
        if (dirtyCount > 0 && editor.storables) editor.store()
      }
    }
  }, [editor])

  function updateSelectedChildClasses(model, action = 'add', editor) {
    function updateParent(model) {
      const isWrapper = model?.is?.('wrapper')
      const parent = model?.parent?.()
      if (isWrapper || !parent) return
      if (parent?.view?.el?.classList) parent.view.el.classList[action]('child-selected')
      updateParent(parent)
    }
    editor?.getWrapper?.().view?.el?.classList[action]('canvas-focused')
    updateParent(model)
  }

  const doUpdateDebounce = useMemo(() => {
    return debounce(
      (editor) => {
        if (editor?.getDirtyCount?.() > 0 && editor.storables) editor?.store?.()
      },
      3000,
      { leading: false },
    )
  }, [])

  // This is a workaround for context issue with GrapesJS
  // const update
  handle.current = {
    initialComponentAdd(model) {
      updateTemplateAssets(model, 'add')
      validateTextComponent(model)
    },
    componentAdd(model) {
      updateTemplateAssets(model, 'add')
    },
    componentUpdate(model) {
      updateTemplateAssets(model, 'update')
    },
    componentStyleUpdate(component) {},
    componentRemove(model) {
      updateTemplateAssets(model, 'remove')
    },
    componentChange(change, editor) {},
    componentSelected(model, editor) {
      updateSelectedChildClasses(model, 'add', editor)
    },
    componentDeselected(model, editor) {
      updateSelectedChildClasses(model, 'remove', editor)
      validateTextComponent(model)
    },
    componentToggle() {
      updateSelectedComponent()
    },
    bodyScroll(event) {
      handleScrollZoom(event)
    },
  }

  // INITIALIZATION
  // ///////////////////////////////////
  const initializeEditor = useCallback(
    ({ editor }) => {
      if (!editor || initialized || isLoading) return
      setEditor(editor)

      // // STORAGE
      editor.Storage.add('custom', {
        store(data, options) {
          updateContent({
            components: data?.pages[0]?.frames?.[0].component,
            styles: data?.styles,
            html: editor.getHtml(),
            css: editor.getCss(),
          })
        },
      })
      if (!editor.SchemaIds) editor.SchemaIds = {}
      const initialAdd = (model) => handle.current.initialComponentAdd(model)

      editor.on('component:add', initialAdd)
      loadData(editor, components, styles, style)
      editor.off('component:add', initialAdd)

      editor.Storage.setCurrent('custom')

      editor.on('run:variable-error', () => {
        notification.error({
          message: 'Syntax error',
          description: "Don't include double curly brackets '{{...}}' in your variable name",
          placement: 'bottomRight',
        })
      })

      /**WORKAROUND FOR WRAPPER STYLES BEING SET TO body */
      const bodyRule = editor.Css.getRule('#wrapper')

      if (bodyRule) {
        const wrapperRule = editor.Css.getRule('.wrapper')
        if (!wrapperRule) editor.Css.setRule('.wrapper', bodyRule.config.style)
        editor.Css.remove('#wrapper')
        editor.refreshCanvas()
      }
      /** END WORKAROUND */

      editor.on('load', () => {
        // BIND EVENT LISTENERS
        // editor.on('change:changesCount', (change) => handle.current.componentChange(change, editor))
        // editor.on('change:changesCount', handleChangeDebounced)
        editor.on('component:add', (model) => {
          handle.current.componentAdd(model)
          doUpdateDebounce(editor)
        })
        editor.on('component:update', (model) => {
          setHasUndo(editor.UndoManager.hasUndo())
          setHasRedo(editor.UndoManager.hasRedo())
          handle.current.componentUpdate(model)
          doUpdateDebounce(editor)
        })
        editor.on('component:styleUpdate', (model) => {
          handle.current.componentStyleUpdate(model)
          doUpdateDebounce(editor)
        })
        editor.on('component:remove', (model) => {
          handle.current.componentRemove(model)
          doUpdateDebounce(editor)
        })
        editor.on('component:toggled', (model) => handle.current.componentToggle(model))
        editor.on('component:selected', (model) => handle.current.componentSelected(model, editor))
        editor.on('component:deselected', (model) => handle.current.componentDeselected(model, editor))

        editor.on('component:hover', (model) => {
          if (!model) return
          const hasLogic = Boolean(
            model.getLoop?.() || model.getCondition?.() || model.getAttributes()['data-value'] === 'variable',
          )
          const hlEl = editor.getContainer().querySelector('.gjs-highlighter')
          if (hasLogic && hlEl) hlEl.style.outline = '2px solid #f3d154'
          else if (hlEl) hlEl.style.outline = ''
        })

        const canvasEl = editor.Canvas.getElement()
        const bodyEl = editor.Canvas.getBody()
        const document = editor.Canvas.getDocument()
        const wrapper = editor.getWrapper()

        // Make dropping rows between rows easier
        editor.on('block:drag:start component:drag:start', (block) => {
          const component = block?.target ? block.target : block
          const type = block?.target ? component.get('type') : component.getId()
          if (component && type) {
            bodyEl.classList.remove('stop')
            bodyEl.setAttribute('data-drag', type)
            bodyEl.classList.add('gjs-blocks-dragging')
          }
        })
        editor.on('block:drag:stop component:drag:end', () => {
          bodyEl.classList.add('stop')
          bodyEl.removeAttribute('data-drag')
        })

        let varAssetEl
        const pfx = editor.getConfig('stylePrefix')
        // Reinit Assets on modal open
        editor.on('run:open-assets', () => {
          if (globalAssets.isSuccess) {
            editor.Assets.load({ assets: globalAssets?.data?.data ?? [] })
            editor.Assets.render()
          }

          const modal = editor.Modal
          const modalBody = modal.getContentEl()
          const assetsBody = modalBody.querySelector(`.${pfx}am-assets-cont .${pfx}am-assets`)

          // Instance button if not yet exists
          if (!varAssetEl) {
            varAssetEl = editor.Editor.$(assetMarkup)
            varAssetEl.on('dblclick', () => {
              const sel = editor.getSelected()
              const trait = sel.getTrait('data-value')
              trait && trait.view?.el?.querySelector('.ant-switch')?.classList?.add('ant-switch-checked')
              sel.addAttributes({
                'data-value': 'variable',
              })
              const varNameTrait = sel.getTrait('varName')
              sel.set({
                varName: varNameTrait && varNameTrait.view?.el?.querySelector('select')?.value,
              })
              modal.close()
            })
          }

          assetsBody.prepend(varAssetEl.get(0))
        })

        // CTRL + Scroll to zoom
        bodyEl.addEventListener('mousewheel', (e) => handle.current.bodyScroll(e), { passive: false })

        const canvasFrames = canvasEl.querySelector('.gjs-cv-canvas__frames')
        canvasFrames.addEventListener('scroll', () => {
          editor.refreshCanvas()
        })

        // Paste as plain text
        function onPaste(ev) {
          ev.preventDefault()
          const text = (ev.clipboardData || window.clipboardData).getData('text')
          // Replace \n with <br> in case of plain text
          const html = text.replace(/(?:\r\n|\r|\n)/g, '<br/>')
          document.execCommand('insertHTML', false, html)
        }

        editor.on('rte:enable', () => {
          events.on(document, 'paste', onPaste)

          const sel = editor.getSelected()

          if (!sel || !editor?.RichTextEditor?.getAll()?.find((o) => o.name === 'link')?.btn?.style) return

          if (sel.get('type') === 'dm-button')
            editor.RichTextEditor.getAll().find((o) => o.name === 'link').btn.style.display = 'none'
          else editor.RichTextEditor.getAll().find((o) => o.name === 'link').btn.style.display = 'flex'
        })

        editor.off('rte:disable', () => {
          events.off(document, 'paste', onPaste)

          if (!editor?.RichTextEditor?.getAll()?.find((o) => o.name === 'link')?.btn?.style) return

          editor.RichTextEditor.getAll().find((o) => o.name === 'link').btn.style.display = 'flex'
        })

        const documentWrapper = document.createElement('div')
        documentWrapper.classList.add('document')
        documentWrapper.appendChild(wrapper.view.el)
        bodyEl.appendChild(documentWrapper)

        const canvasDocument = editor.Canvas.getDocument()
        const canvasHTML = canvasDocument.querySelector('html')

        // Deselect components when canvas is clicked
        canvasHTML.addEventListener('click', ({ target }) => {
          if (target === canvasHTML || target === documentWrapper) deselectComponents(editor)
        })
        documentWrapper.addEventListener('click', ({ target }) => {
          if (target === documentWrapper) deselectComponents(editor)
        })

        // SET DEVICE & MARGIN
        setDevice(editor, options)
        updateCanvasPosition(editor)

        const ro = new ResizeObserver((entries) => {
          updateCanvasPosition(editor)
        })
        ro.observe(editorRef.current)

        // Enable guides
        editor.Commands.run('sw-visibility')

        const rootComponents = editor.Components?.getComponents?.()
        // Scan template for legacy components
        const ifTrue = (c) => c?.attributes?.type === TYPES.section || c?.attributes?.type === TYPES.row
        const clb = () => setHasLegacy(true)
        loopAllComponents(rootComponents, ifTrue, clb)

        const imageHasChildren = (c) => {
          const isImage = c.get?.('type') === 'dm-image'
          if (!isImage) return false
          const children = c.get?.('components')
          return children?.length > 0
        }
        const removeChildren = (c) => {
          c.empty()
        }
        loopAllComponents(rootComponents, imageHasChildren, removeChildren)

        if (onInit) onInit(editor)
        setInitialized(true)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setEditor, onInit, initialized, components, options, styles, isLoading, globalAssets, doUpdateDebounce],
  )

  // Handle Options Change
  // //////////////////////////////////
  useEffect(() => {
    if (!options || !editor) return
    setDevice(editor, options)
    // setEditorDimension(editor)
  }, [options, editor])

  useEffect(() => {
    const el = editorRef.current
    if (!el) return
    el.addEventListener('mousewheel', handleScrollZoom)
    return function () {
      el.removeEventListener('mousewheel', handleScrollZoom)
    }
  }, [handleScrollZoom])

  const handleDestroy = useCallback(() => {
    setEditor(null)
  }, [setEditor])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleDestroy, []);
  

  return (
    <div ref={editorRef} style={{ visibility: initialized ? 'visible' : 'hidden' }}>
      <GjsEditor grapesjs={grapesjs} id='template-designer-canvas' options={config} onEditor={initializeEditor} />
    </div>
  )
}
