import { isError } from 'lodash'
import React, { createContext, useRef, useState } from 'react'
import { MODES_LIST } from '../consts'
import hash from 'object-hash'

export const TemplatePreviewContext = createContext()

export function TemplatePreviewProvider({ children }) {
  const cacheHashRef = useRef(null)

  const [state, setState] = useState(() => ({
    preview: {
      data: null,
      isLoading: false,
      error: null,
      isError: false,
      isSuccess: false,
    },
    setPreview: (preview) => {
      setState(({ preview: prevPreview = {}, ...prev }) => {
        const newPreview = { ...prevPreview, isError: isError(preview.error), isSuccess: !isError(preview.error) }
        if (preview.error !== prevPreview?.error) newPreview.error = preview.error
        if (preview.data !== prevPreview?.data) newPreview.data = preview.data
        if (preview.isLoading !== prevPreview?.isLoading) newPreview.isLoading = preview.isLoading
        if (preview.hash) newPreview.hash = preview.hash
        return { ...prev, preview: newPreview }
      })
    },
    mode: null,
    setMode: (mode) => {
      const modeId = typeof mode === 'string' ? mode : mode?.value
      const newMode = MODES_LIST.find((m) => m.value === modeId)
      if (newMode) setState((prev) => ({ ...prev, mode: newMode.value }))
    },
    selectedRecord: null,
    setSelectedRecord: (record) => setState((prev) => ({ ...prev, selectedRecord: record })),
    setCacheHash: (value) => {
      if (typeof hash !== 'string') value = hash(value)
      cacheHashRef.current = value
    },
    checkIsCached: (data) => {
      const dataHash = hash(data)
      return !cacheHashRef.current ? false : cacheHashRef.current === dataHash
    },
  }))
  
  return <TemplatePreviewContext.Provider value={state}>{children}</TemplatePreviewContext.Provider>
}

const defaultExport = {
  TemplatePreviewerContext: TemplatePreviewContext,
  TemplatePreviewerProvider: TemplatePreviewProvider,
}

export default defaultExport
