import betterNumber from './numberType'
import switchType from './switchType'
import infoTextType from './infoTextType'
import errorTextType from './errorTextType'
import comboType from './comboType'

export const traitTypes = ({ editor, $ }) => {
    betterNumber(editor)
    switchType(editor, { $ })
    infoTextType(editor)
    errorTextType(editor)
    comboType(editor, { $ })
}

export default traitTypes
