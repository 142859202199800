export const useDownload = () => {
  const download = (data, { type = 'application/pdf', filename = 'download', onSuccess }) => {
    const blob = new Blob([data], { type })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    if (onSuccess) onSuccess()
  }
  return download
}

export default useDownload