import React, { useEffect, useRef } from 'react'
import { Alert } from 'antd'
import { useEditor } from '../../../hooks'

const CodeEditor = (props = { code: '', codeEditorOptions: {} }) => {
  const { editor } = useEditor()
  const { code, onChange } = props

  const codeViewer = editor.CodeManager.createViewer({
    theme: 'default',
    codeName: 'htmlmixed',
    readOnly: false,
    autoBeautify: true,
    autoCloseBrackets: true,
    autoCloseTags: true,
    styleActiveLine: true,
    smartIndent: true,
    ...props.codeEditorOptions,
  })

  const container = useRef(null)

  useEffect(() => {
    const cont = container.current

    code && codeViewer.setContent(code)

    cont.appendChild(codeViewer.getElement())

    codeViewer.refresh()
    setTimeout(() => codeViewer.focus(), 0)

    cont.querySelector('.CodeMirror').addEventListener('keyup', () => {
      onChange && onChange(codeViewer.getContent())
    })
  }, [])

  return (
    <>
      <Alert
        className='mb-3'
        message='Custom logic that acts as the condition for the "if" blocks e.g for {{#if (author) }} custom logic would be "author"'
      />
      <div className='query-builder-code-editor' ref={container}></div>
    </>
  )
}

export default CodeEditor
