import { useCallback } from 'react'
import { useTemplateEditContext } from './useTemplateEditContext'

export function useTemplateEdit() {
  const {
    template,
    mode,
    getMode,
    getModes,
    setMode,
    setEditor,
    editor,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUpdateError,
    isUpdating,
    isUpdateSuccess,
    update,
    updateError,
  } = useTemplateEditContext()

  const activate = useCallback(() => {
    return update({ isActive: true })
  }, [update])

  const deactivate = useCallback(() => {
    return update({ isActive: false })
  }, [update])

  return {
    template,
    activate,
    deactivate,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUpdateError,
    isUpdating,
    isUpdateSuccess,
    update,
    updateError,
    mode,
    getMode,
    getModes,
    setMode,
    editor,
    setEditor,
  }
}
