import React, { useEffect, useState, useCallback } from 'react'
import { Button, Typography, Dropdown, Input, Empty } from 'antd'
import { FaIcon } from '@components/icons'
import { useTemplatePreview } from '../hooks'
import isEmpty from 'lodash/isEmpty'
import { useLazyGetDataFromProviderQuery } from '@services/documintApi/providers'
import { useTemplate } from '@hooks/useTemplate'

const { Title } = Typography

export default function DataSourcePreviewControls({ dataSource }) {
  const { template } = useTemplate()
  const [fetchRecords, { data, isFetching, isError }] = useLazyGetDataFromProviderQuery()

  const { createDataSourcePreview, preview, selectedRecord, setSelectedRecord } = useTemplatePreview()

  const [recordOptions, setRecordOptions] = useState(undefined)

  const loadRecords = useCallback(
    async ({ query } = {}) => {
      if (!dataSource) return

      const config = {
        provider: dataSource.id,
        connectionName: dataSource.connectionName,
        action: 'listRecords',
        ...(dataSource?.entity?.value || {}),
      }

      const primaryFieldName = dataSource.primaryField?.name

      if (primaryFieldName) config.select = [primaryFieldName]

      if (!isEmpty(query) && dataSource?.getSearchQuery) config.query = dataSource.getSearchQuery?.(query)

      const { data } = await fetchRecords(config)
      const mappedRecords = data?.data?.map?.((record) => ({ label: record[primaryFieldName], value: record._id }))
      setRecordOptions(mappedRecords)
    },
    [dataSource, fetchRecords],
  )

  const doPreview = useCallback(
    (record, { ignoreCache } = {}) => {
      if (!record) return
      const { connectionName, provider, entity, expandedFields } = dataSource
      createDataSourcePreview(
        template,
        {
          recordId: record.value,
          connectionName,
          provider,
          entity: entity.value,
          expandedFields,
        },
        { ignoreCache },
      )
    },
    [dataSource, createDataSourcePreview, template],
  )

  // Initialize
  useEffect(() => {
    if (dataSource?.isConnected && !isFetching && !isError && !data) loadRecords()
  }, [isFetching, isError, data, dataSource, loadRecords])

  const handleSearch = (value) => {
    loadRecords({ query: value })
  }

  const handleSelect = useCallback(
    (record) => {
      setSelectedRecord(record)
      if (selectedRecord?.value !== record.value) doPreview(record)
    },
    [selectedRecord, setSelectedRecord, doPreview],
  )

  const handleGeneratePreview = useCallback(async () => {
    if (!selectedRecord) return
    doPreview(selectedRecord, { ignoreCache: true })
  }, [selectedRecord, doPreview])

  if (!dataSource) return null

  const items =
    recordOptions?.map?.((record) => ({
      key: record.value,
      label: <div>{record.label || record.value}</div>,
      onClick: () => handleSelect(record),
    })) ?? []

  return (
    <div className='px-4 py-3 d-flex' style={{ flexDirection: 'column', gap: '15px' }}>
      <Title level={5} className='mb-0 text-center'>
        Test with {dataSource?.name} data
      </Title>

      <div>
        <Dropdown
          menu={{ items, style: { boxShadow: 'none' } }}
          dropdownRender={(menu) => (
            <div
              style={{
                background: '#fff',
                borderRadius: '10px',
                boxShadow: '0 0 5px rgba(0,0,0,.1)',
                maxHeight: 'calc(100vh - 400px)',
                overflow: 'hidden',
              }}
            >
              <div className='px-2 py-2'>
                <Input.Search placeholder='Search' onSearch={handleSearch} allowClear />
              </div>
              <div className='dropdown-content' style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 450px)' }}>
                {items?.length > 0 ? (
                  menu
                ) : (
                  <Empty description='No records found' image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </div>
            </div>
          )}
          trigger={['click']}
          className='mb-4'
          disabled={isFetching}
        >
          <div
            className='d-flex align-items-center'
            style={{
              background: '#fff',
              border: 'solid 1px #ddd',
              borderRadius: '5px',
              padding: '10px 20px',
              minHeight: '70px',
              gap: '10px',
            }}
          >
            <div style={{ flex: '0 0 40px' }}>{dataSource?.getIcon?.({ style: { height: '15px' } })}</div>
            <div style={{ flex: '1 1 auto' }}>
              <div className='text--light' style={{ fontSize: '10px', marginTop: '-5px' }}>
                {dataSource?.entityFullName}
              </div>
              <div>{selectedRecord ? selectedRecord.label || selectedRecord.value : 'Select a record'}</div>
            </div>
            <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
              {isFetching ? <FaIcon icon='faSpinner' spin /> : <FaIcon icon='faChevronDown' />}
            </div>
          </div>
        </Dropdown>

        <Button
          size='large'
          type='primary'
          onClick={handleGeneratePreview}
          disabled={!selectedRecord}
          block
          loading={preview.isLoading}
        >
          Preview Document
        </Button>
      </div>
    </div>
  )
}
