import React, { useEffect } from 'react'
import { Layout, Tabs } from 'antd'

import { PageHeader, Error } from './components'
import { useTemplateEdit } from './hooks'
import { IntegrationsTab, PreviewTab, DesignTab } from './components'
import { TemplateSettingsModal } from '@features/template-settings'
import { useEditor } from '@features/template-designer'
import { DataSourceEditModal, useTemplateDataSource } from '@features/template-data-source'
import { useHistory } from 'react-router-dom'
import Loading from '@common/Loading'

import './styles.scss'

export default function Content(props) {
  const { template, savingError, isFetching, isSuccess, reload, mode, error } = useTemplateEdit()
  const { setEditor } = useTemplateDataSource()
  const { editor, clear } = useEditor()
  const history = useHistory()

  useEffect(() => {
    if (editor) setEditor(editor)
  }, [editor, setEditor])

  useEffect(()=>{
    if(template?.name || document.title !== template?.name) document.title = template?.name ? template.name : 'Edit Template'
  },[template?.name])

  const items = [
    {
      key: 'integrations',
      label: 'Integrations',
      children: <IntegrationsTab style={{ height: 'calc(100vh - 64px)', overflowY: 'auto' }} />,
    },
    {
      key: 'design',
      label: 'Designer',
      children: <DesignTab style={{ height: 'calc(100vh - 64px)' }} />,
      forceRender: true,
    },
    {
      key: 'preview',
      label: 'Preview',
      children: <PreviewTab style={{ height: 'calc(100vh - 64px)' }} />,
      forceRender: true,
    },
  ]

  useEffect(() => {
    if (error?.status === 404) history.push('/404')
  }, [error, history])

  if (isFetching && !isSuccess) return <Loading minHeight='100vh' backgroundColor='#f2f4fb' />
  return (
    <Layout id='page-template-edit' style={{ height: '100vh', overflow: 'hidden' }}>
      <PageHeader />
      <Layout.Content {...props} style={{ height: 'calc(100vh - 65px)' }}>
        <Tabs renderTabBar={() => <></>} defaultActiveKey={mode} activeKey={mode} items={items} />
        <Error error={savingError} onReload={reload} />
        <TemplateSettingsModal onEditorClear={clear} />
        <DataSourceEditModal editor={editor} />
      </Layout.Content>
    </Layout>
  )
}
