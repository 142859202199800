import React from 'react'
import { CopyBlock, github, dracula, monokai, androidstudio, codepen, a11yLight } from 'react-code-blocks'

export default function CodeBlock({
  code,
  language,
  theme,
  blockStyle,
  wrapperStyle,
  showLineNumbers,
  startingLineNumber,
  wrapLines,
  bordered,
  codeBlock,
  style,
}) {
  const selectedTheme = theme === 'dark' ? codepen : a11yLight
  const propsMap = {
    text: code,
    language: language,
    theme: selectedTheme,
    codeBlockStyle: blockStyle,
    showLineNumbers: showLineNumbers,
    startingLineNumber: startingLineNumber,
    wrapLines: wrapLines,
    codeBlock,
    style
  }

  let wrapperStyles = wrapperStyle || {}
  if (style) wrapperStyles = { ...wrapperStyles }
  if (bordered) wrapperStyles = { border: 'solid 1px #ddd', borderRadius: '5px', padding: '1em', ...wrapperStyles }

  return (
    <div className='code-block' style={wrapperStyles}>
      <CopyBlock {...propsMap}/>
    </div>
  )
}

CodeBlock.defaultProps = {
  code: '',
  language: 'javascript',
  theme: 'light',
  showLineNumbers: true,
  wrapLines: false,
  bordered: true,
  codeBlock: true,
  style: {},
}
