import React, { useCallback } from 'react'

import { ConnectionTitle, ConnectionSubTitle, FlexContainer, StyledConnectionCard } from './index.style'

import { useTemplateDataSource } from '../hooks'
import { Button, Dropdown, Spin } from 'antd'
import { DisconnectOutlined, EllipsisOutlined, LinkOutlined, DeleteOutlined } from '@ant-design/icons'
import { FaIcon } from '@components/icons'
import { DataSourceIcon } from '.'

export default function DataSourceCard({ onClick, dataSource = {}, readonly = false, showDescription = true }) {
  const {
    dataSource: currentDataSource,
    setSelectedDataSource,
    toggleIsModalOpen,
    syncDataSource,
    isSyncing,
    deleteDataSource,
  } = useTemplateDataSource()
  const isDisconnected = dataSource?.disconnected
  const handleClick = useCallback(() => {
    if (readonly === true) return
    toggleIsModalOpen(true)
    setSelectedDataSource(dataSource)
    if (onClick) onClick(dataSource)
  }, [dataSource, toggleIsModalOpen, onClick, setSelectedDataSource, readonly])

  const menuItems = [
    {
      icon: <FaIcon icon='faPen' />,
      onClick: handleClick,
      label: 'Edit',
      disabled: isSyncing,
      key: '0',
    },
    ...(dataSource?.isConnected
      ? [
          {
            icon: <FaIcon icon='faSync' />,
            onClick: ({ domEvent }) => {
              domEvent.stopPropagation()
              syncDataSource()
            },
            label: isSyncing ? 'Syncing...' : 'Sync',
            disabled: isSyncing || !dataSource?.connection,
            key: '1',
          },
        ]
      : []),
    ...(dataSource
      ? [
          {
            icon: dataSource?.isConnected ? <DisconnectOutlined /> : <LinkOutlined />,
            onClick: toggleIsModalOpen,
            label: dataSource?.isConnected ? 'Disconnect' : 'Connect',
            disabled: isSyncing,
            key: '2',
          },
        ]
      : []),
    {
      key: '3',
      type: 'divider',
    },
    {
      icon: <DeleteOutlined style={{ color: '#f40' }} />,
      onClick: ({ domEvent }) => {
        domEvent.stopPropagation()
        deleteDataSource()
      },
      label: 'Reset',
      disabled: isSyncing,
      key: '4',
    },
  ]

  const entityName = dataSource?.entityFullName
  const description = entityName ? entityName : dataSource?.description

  return (
    <StyledConnectionCard
      className='data-source-card'
      onClick={handleClick}
      styles={{ body: { padding: '12px 18px', width: '100%' } }}
      $readonly={readonly}
    >
      <FlexContainer $gap='15px' $width='100%' $alignItems='center'>
        <FlexContainer $flexDirection='column' $justifyContent='center' $basis='40px' $alignItems='center'>
          <DataSourceIcon
            icon={dataSource.icon}
            badge={isDisconnected && <DisconnectOutlined style={{ fontSize: '10px' }} />}
            badgeProps={{
              style: {
                backgroundColor: 'rgb(255 102 102)',
                color: 'white',
                borderRadius: '20px',
                padding: '3px',
                border: 'solid 2px white',
              },
              offset: [0, 25],
            }}
          />
        </FlexContainer>

        <FlexContainer
          className='data-source-card__text'
          $flexDirection='column'
          $basis='auto'
          $grow='1'
          $shrink='1'
          $gap='0px'
        >
          <ConnectionTitle level={5}>{dataSource.name}</ConnectionTitle>
          <ConnectionSubTitle>{showDescription && description}</ConnectionSubTitle>
        </FlexContainer>

        {currentDataSource && !readonly && (
          <FlexContainer
            $flexDirection='column'
            $basis='auto'
            $grow='0'
            $shrink='1'
            $gap='0px'
            $justifyContent='center'
            $alignItems='center'
          >
            <Dropdown menu={{ items: menuItems }} trigger={['click']}>
              {isSyncing ? (
                <Spin indicator={<FaIcon icon='faSync' spin={true} size={16} />} />
              ) : (
                <Button
                  icon={<EllipsisOutlined />}
                  onClick={(e) => e.stopPropagation()}
                  type='text'
                  style={{ alignSelf: 'flex-end' }}
                />
              )}
            </Dropdown>
          </FlexContainer>
        )}
      </FlexContainer>
    </StyledConnectionCard>
  )
}
