import React from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Layout, Avatar, Button, Space, Dropdown, Typography, Tooltip } from 'antd'
import { UserOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useLogout } from '@features/auth'
import { useSelector } from 'react-redux'
import { selectProfile } from '../../features/auth/authSlice'
import { useNewTemplate } from '@contexts/NewTemplateContext'
import { useBreakpoint } from '@hooks/helperHooks'
import { ChangePlanButton } from '@features/subscription'

const { Header } = Layout
const { Title } = Typography

export default function TopBar() {
  const [logout] = useLogout()
  const history = useHistory()
  const breakpoint = useBreakpoint()
  const profile = useSelector(selectProfile)
  const newTemplateModal = useNewTemplate()
  const setNewTemplateModalIsVisible = newTemplateModal[1]

  const handleLogout = async function () {
    await logout()
    history.push('/login')
  }

  const { email, name = {} } = profile || {}
  const fullName = `${name.givenName} ${name.familyName}`

  function goto(path) {
    history.push(path)
  }

  const buttons = [
    {
      label: 'New template',
      type: 'primary',
      icon: <PlusOutlined />,
      onClick: () => setNewTemplateModalIsVisible(true),
    },
  ]

  const avatarMenu = {
    items: [
      {
        key: 'settings',
        onClick: () => goto('/settings'),
        label: (
          <>
            {fullName}
            <br />
            <small>{email}</small>
          </>
        ),
      },
      {
        key: 'logout',
        label: 'Logout',
        onClick: handleLogout,
      },
    ],
  }

  return (
    <Header className='dm-topbar d-flex justify-content-between align-items-center pl-4'>
      <Title level={2} className='my-0'>
        <Link to='/' className='d-flex align-items-center'>
          <div
            className='logo'
            style={{
              backgroundImage: "url('/images/logo/standard.svg')",
            }}
          ></div>
        </Link>
      </Title>

      <div className='d-flex flex-row align-items-center'>
        <Space direction='horizontal' size='large'>
          {['md', 'lg', 'xl', 'xxl'].includes(breakpoint) && (
            <>
              <ChangePlanButton />
              {buttons
                .filter(({ show = true }) => show)
                .map(({ label, ...rest }, i) => (
                  <Button {...rest} key={i}>
                    {label}
                  </Button>
                ))}
            </>
          )}
          <Dropdown menu={avatarMenu} trigger={['click']} placement='bottom'>
            <Avatar size='large' icon={<UserOutlined />} />
          </Dropdown>
          <a
            href='http://support.documint.me'
            target='_blank'
            rel='noopener noreferrer'
            className='d-flex'
            style={{ fontSize: '20px' }}
          >
            <Tooltip title='Help Center'>
              <QuestionCircleOutlined />
            </Tooltip>
          </a>
        </Space>
      </div>
    </Header>
  )
}
