import React from 'react'
import dataSourceClasses, { getDataSource } from '../data-sources'
import { useTemplateDataSource } from '../hooks'
import { DataSourceCard } from '.'

const dataSources = dataSourceClasses.map((DataSource) => new DataSource())
const DefaultDataSource = getDataSource('default')
const defaultDataSource = new DefaultDataSource()

export default function DataSourceCards({ title }) {
  const { dataSource } = useTemplateDataSource()

  const sources = [...(dataSources.filter((dataSource) => dataSource?.connection) || []), defaultDataSource]

  return (
    <>
      {title}
      {dataSource ? (
        <DataSourceCard dataSource={dataSource} key={dataSource?.id} showDescription={!dataSource?.isDefault} />
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
              gridAutoRows: '1fr',
              gap: '10px',
            }}
          >
            {sources.map((dataSource) => (
              <DataSourceCard dataSource={dataSource} key={dataSource?.id} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}
