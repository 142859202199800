const config = {
  name: 'Text',
  open: true,
  buildProps: [
    'font-family',
    'color',
    'font-size',
    'font-weight',
    'line-height',
    'text-transform',
    'text-align',
    'font-style',
    'text-decoration',
    'letter-spacing',
    'word-break',
  ],
  properties: [
    {
      name: 'Font',
      property: 'font-family',
      options: [
        { name: '[same as parent]', value: 'inherit' },
        { name: 'Arial', value: 'Arial, Helvetica, sans-serif' },
        { name: 'Arial Black', value: '"Arial Black", Gadget, sans-serif' },
        { name: 'Brush Script MT', value: '"Brush Script MT", sans-serif' },
        { name: 'Comic Sans MS', value: '"Comic Sans MS", cursive, sans-serif' },
        { name: 'Courier New', value: '"Courier New", Courier, monospace' },
        { name: 'Courier Prime', value: '"Courier Prime", "Courier New", Courier, monospace' },
        { name: 'Dancing Script', value: '"Dancing Script", "Brush Script MT", sans-serif' },
        { name: 'Georgia', value: 'Georgia, serif' },
        { name: 'Helvetica', value: 'Helvetica, sans-serif' },
        { name: 'Impact', value: 'Impact, Charcoal, sans-serif' },
        { name: 'Inter', value: 'Inter, sans-serif' },
        { name: 'Lato', value: 'Lato, Arial, sans-serif' },
        { name: 'Lucida Sans Unicode', value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif' },
        { name: 'Nunito', value: 'Nunito, Arial, sans-serif' },
        { name: 'Montserrat', value: 'Montserrat, sans-serif' },
        { name: 'Open Sans', value: '"Open Sans", sans-serif' },
        { name: 'Playfair Display', value: '"Playfair Display", "Times New Roman", serif' },
        { name: 'PT Sans', value: '"PT Sans", Arial, sans-serif' },
        { name: 'PT Serif', value: '"PT Serif", "Times New Roman", Times, serif' },
        { name: 'Quicksand', value: 'Quicksand, sans-serif' },
        { name: 'Roboto', value: 'Roboto, sans-serif' },
        { name: 'Sans Serif', value: 'sans-serif' },
        { name: 'Source Sans Pro', value: '"Source Sans Pro", sans-serif' },
        { name: 'Tahoma', value: 'Tahoma, Geneva, sans-serif' },
        { name: 'Times New Roman', value: '"Times New Roman", Times, serif' },
        { name: 'Trebuchet MS', value: '"Trebuchet MS", Helvetica, sans-serif' },
        { name: 'Verdana', value: 'Verdana, Geneva, sans-serif' },
      ],
      full: true,
      default: 'inherit',
    },
    {
      property: 'font-size',
      name: 'Size',
      full: false,
      type: 'number',
      units: ['px', '%'],
      unit: 'px',
      min: 4,
    },

    {
      name: 'Weight',
      property: 'font-weight',
      options: [
        { value: 'inherit', label: 'inherit' },
        { value: '300', label: 'Light' },
        { value: '400', label: 'Normal' },
        { value: '500', label: 'Medium' },
        { value: '700', label: 'Bold' },
      ],
      default: 'inherit',
      full: false,
    },

    {
      property: 'color',
      name: 'Color',
      full: false,
      type: 'color',
      default: 'inherit',
    },

    {
      property: 'text-align',
      name: 'Align',
      label: 'Align',
      type: 'radio',
      full: false,
      default: 'inherit',
      options: [
        { value: 'inherit', className: 'fa fa-xmark', title: 'From parent' },
        { value: 'left', className: 'fa fa-align-left', title: 'Left-align text' },
        { value: 'center', className: 'fa fa-align-center', title: 'Center-align text' },
        { value: 'right', className: 'fa fa-align-right', title: 'Right-align text' },
        { value: 'justify', className: 'fa fa-align-justify', title: 'Justify text' },
      ],
      // onChange({ value, property }) {
      //   const editor = property.em.getEditor()
      //   const target = editor.getSelected()
      //   if (!target) return
      //   target.components().each(c => c.addStyle({ 'parent-align': value === 'justify' ? 'left' : value  }))
      // },
    },
    // {
    //   label: false,
    //   type: 'toggle',
    //   property: 'font-weight',
    //   on: 'bold',
    //   off: 'normal',
    //   default: 'normal',
    //   iconClass: 'fa fa-bold',
    // },

    {
      label: false,
      type: 'toggle',
      property: 'font-style',
      on: 'italic',
      off: 'normal',
      default: 'normal',
      iconClass: 'fa fa-italic',
    },
    {
      label: false,
      type: 'toggle',
      property: 'text-decoration',
      on: 'underline',
      off: 'none',
      default: 'normal',
      iconClass: 'fa fa-underline',
    },

    {
      property: 'line-height',
      name: 'Line Height',
      type: 'integer',
      units: [],
      unit: '',
      default: 'normal',
      min: 0,
      max: 20,
      full: false,
    },

    {
      property: 'letter-spacing',
      name: 'Letter Spacing',
      type: 'integer',
      units: ['px', 'pt'],
      unit: 'px',
      full: false,
    },

    {
      id: 'text-transform',
      property: 'text-transform',
      name: 'Casing',
      default: 'inherit',
      type: 'radio',
      full: false,
      className: 'text--bold',
      options: [
        { value: 'capitalize', name: 'Ag', title: 'Capitalize' },
        { value: 'uppercase', name: 'AG', title: 'Uppercase' },
        { value: 'lowercase', name: 'ag', title: 'Lowercase' },
      ],
    },
    {
      id: 'word-break',
      property: 'word-break',
      name: 'Break Word  <i class="fa-solid fa-square-info"></i>',
      default: 'normal',
      type: 'radio',
      info: 'Break a word that is larger than its containing element',
      full: false,
      options: [
        { value: 'normal', name: 'No' },
        { value: 'break-word', name: 'Yes' },
      ],
    },

    // {
    //   label: 'Break Word',
    //   type: 'toggle',
    //   property: 'word-break',
    //   on: 'break-word',
    //   off: 'normal',
    //   default: 'normal',
    //   iconClass: 'fas fa-kerning',
    // },
  ],
}
export default config
