import { useContext } from 'react'
import { InstructionContext } from '../contexts'

export function useInstructionContext() {
  return useContext(InstructionContext)
}

export default function useInstructions() {
  const { config, updateConfig, setConfig } = useInstructionContext()

  return {
    config,
    updateConfig,
    setConfig,
  }
}
