import React, { useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import { Typography, Row, Col, Divider } from 'antd'

import { RegisterForm } from '@features/auth'

import MainLayout from '@Layouts/MainLayout'
import config from '../../config'

import { useValidateSession } from '@features/auth'

// Nested Components
const { Title } = Typography

export default function Register() {
  const history = useHistory()

  const [validate] = useValidateSession()

  const location = useLocation()

  let { from } = location.state || { from: { pathname: '/' } }

  useEffect(() => {
    validate().then((isValid) => {
      if (isValid) history.replace(from)
    })
  }, [])

  const handleRegisterUser = async () => {
    history.replace(from)
  }

  return (
    <MainLayout>
      <Row align='stretch' className='vh-100'>
        <Col xs={24} md={12} lg={10} xl={8} xxl={6} className='bg--white'>
          <div className='d-flex align-items-center h-100 p-5'>
            <div id='register' className='w-100'>
              <a href='https://documint.me'>
                <img id='register__logo' src='/images/logo.svg' alt='Documint Logo' />
              </a>
              <Title level={3}>Create your account</Title>
              <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
                <RegisterForm onFinish={handleRegisterUser} />
              </GoogleReCaptchaProvider>
              <Divider />
              Already have an account? <Link to={{ pathname: '/login', state: { from: location } }}>Log In</Link>
            </div>
          </div>
        </Col>
        <Col xs={0} md={12} lg={14} xl={16} xxl={18} className='bg p-5'>
          <div
            className='h-100'
            style={{
              backgroundImage: 'url(/images/register-bg.svg)',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          ></div>
        </Col>
      </Row>
    </MainLayout>
  )
}
