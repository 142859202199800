const isObject = (value) => value !== null && typeof value === 'object'

export default function borderType(editor, opts = {}) {
  opts = {
    name: 'better-borders',
    selectedClassName: 'selected',
    defaultValues: {
      color: '#000000',
      width: 1,
      style: 'none',
    },
    ...opts,
  }

  const { name, selectedClassName, defaultValues } = opts
  const sm = editor.Styles

  sm.addType(name, {
    // Create the UI
    create({ change }) {
      const el = document.createElement('div')
      el.classList.add(name)
      el.innerHTML = `
        <div class="better-border-selectors" >
          <div class="better-border-row">
            <div class="better-border-selector better-border-selector-top" ><div></div></div>  
          </div>
          <div class="better-border-row">
            <div class="better-border-selector better-border-selector-left"><div></div></div>   
            <div class="better-border-selector better-border-selector-all"><div></div></div> 
            <div class="better-border-selector better-border-selector-right"><div></div></div>  
          </div>
          <div class="better-border-row">
            <div class="better-border-selector better-border-selector-bottom"><div></div></div>  
          </div>
        </div>
        <div class="better-border-styles" >
          <div class="gjs-sm-property gjs-sm-border-style gjs-sm-property__style">
            <div class="gjs-sm-label" data-sm-label="">
            <span class="gjs-sm-icon " title="">
              Style
            </span>
          </div>
          <div class="gjs-fields" data-sm-fields="">
            <div class="gjs-field gjs-sm-border-type gjs-field-border-type">
              <div class="gjs-field-border-type-none" data-style='none'></div>
              <div class="gjs-field-border-type-solid" data-style='solid'></div>
              <div class="gjs-field-border-type-dashed" data-style='dashed'></div>
              <div class="gjs-field-border-type-dotted" data-style='dotted'></div>
            </div>
          </div>
        </div>
      </div>
      `

      // BORDER STYLE PROPERTY
      const borderStyles = el.querySelectorAll('.gjs-field-border-type > div')
      borderStyles.forEach((style) => {
        style.addEventListener('click', () => {
          this.controls.setStyle(style.getAttribute('data-style'))
          change({ css: generateCSS() })
        })
      })

      // BORDER SIDES SELECTOR
      const sideSelectors = {
        top: el.querySelector('.better-border-selector-top > div'),
        right: el.querySelector('.better-border-selector-right > div'),
        left: el.querySelector('.better-border-selector-left > div'),
        bottom: el.querySelector('.better-border-selector-bottom > div'),
      }
      Object.keys(sideSelectors).forEach((key) => {
        const selector = sideSelectors[key]
        // Add click listener
        selector.addEventListener('click', (event) => {
          const activeSides = this.controls.getActiveSides()

          if (activeSides.all) {
            // Set all sides to false except select side
            Object.keys(activeSides).forEach((sideName) => (activeSides[sideName] = false))
            activeSides[key] = true
          } else {
            // Toggle selected side
            activeSides[key] = !activeSides[key]
          }

          this.controls.setActiveSides(activeSides)
          change({ css: generateCSS() })
        })
      })

      const selectorAll = el.querySelector('.better-border-selector-all > div')
      selectorAll.addEventListener('click', (event) => {
        const activeSides = this.controls.getActiveSides()
        this.controls.setActiveSides(!activeSides.all)
        change({ css: generateCSS() })
      })

      const generateCSS = () => {
        const activeSides = this.controls.getActiveSides()
        const style = this.controls.getStyle()

        const target = this.em.getSelected()

        if (!target || !target.getStyle) return

        const styles = target.getStyle()

        const borderWidth = this.getBorderWidth(styles)
        const borderColor = this.getBorderColor(styles)

        const borderWidthProp = borderWidth
          ? {
              'border-width': borderWidth,
            }
          : {
              'border-width': '1px',
            }

        const borderColorProp = borderColor
          ? {
              'border-color': borderColor,
            }
          : {
              'border-color': 'black',
            }

        return {
          'border-top-style': activeSides.top ? style : 'none',
          'border-right-style': activeSides.right ? style : 'none',
          'border-bottom-style': activeSides.bottom ? style : 'none',
          'border-left-style': activeSides.left ? style : 'none',
          ...borderColorProp,
          ...borderWidthProp,
        }
      }

      this.controls = {
        generateCSS,
        borderStyles,
        // Returns the border style picker value
        getStyle() {
          return this._style
        },
        // Sets the border style picker value
        setStyle(style) {
          this._style = style

          borderStyles.forEach((item) => {
            const isSelectedStyle = item.getAttribute('data-style') === style
            item.classList[isSelectedStyle ? 'add' : 'remove'](selectedClassName)
          })
        },
        getActiveSides() {
          const activeSides = this._activeSides || {}
          activeSides.all = Object.values(activeSides).every((v) => v)
          return activeSides
        },
        setActiveSides(value) {
          let activeSides = isObject(value) ? value : { top: value, right: value, bottom: value, left: value }

          delete activeSides.all

          const allTrue = Object.values(activeSides).every((v) => v === true)
          const allFalse = Object.values(activeSides).every((v) => v === false)

          // remove "selected" class from all sides
          selectorAll.classList.remove(selectedClassName)
          Object.values(sideSelectors).forEach((selector) => selector.classList.remove(selectedClassName))

          if (allTrue) {
            activeSides = { top: true, right: true, bottom: true, left: true }
            selectorAll.classList.add(selectedClassName)
          } else {
            // Add "selected" class to each active side
            Object.keys(sideSelectors).forEach((key) => {
              if (activeSides[key]) sideSelectors[key].classList.add(selectedClassName)
            })
          }
          const style = this.getStyle()
          if (!allFalse && style === 'none') this.setStyle('solid')
          if (allFalse && style !== 'none') this.setStyle('none')

          this._activeSides = activeSides
        },
        hasActiveSide() {
          const activeSides = this.getActiveSides()
          const allSidesAreInactive = Object.values(activeSides).every((v) => v === false)
          return !allSidesAreInactive
        },
      }

      this.em.getEditor().on('component:selected', () => this.updateUI())

      return el
    },

    // Propagate UI changes to target
    emit({ updateStyle }, { css, partial }) {
      updateStyle(css, { partial })
    },

    // Update UI when target is changed
    updateUI() {
      const target = this.em.getEditor().getSelected()

      if (!target || !target.getStyle) return

      const styles = target.getStyle()

      const activeSides = this.getActiveSides(styles)
      this.controls.setActiveSides(activeSides)
      const style = this.getBorderStyle(styles)
      this.controls.setStyle(style)
    },

    /**
     * HELPER FUNCTIONS
     */
    getElementProp(name, styles, test) {
      const sides = ['top', 'right', 'bottom', 'left']
      return sides.reduce((acc, side) => {
        const style = styles[`border-${side}-${name}`]
        if (!style) return acc
        if (!test) {
          acc = style
        } else {
          if (test(style)) acc = style
        }
        return acc
      }, null)
    },

    // Returns the border type from the selected element styles
    getBorderStyle(styles) {
      const test = (value) => value !== 'none'
      const style = this.getElementProp('style', styles, test)
      return style || defaultValues.style
    },

    getActiveSides(styles) {
      // const styles = element.getStyle()
      const sides = ['top', 'right', 'bottom', 'left']
      return sides.reduce((acc, side) => {
        const value = styles[`border-${side}-style`]
        acc[side] = value && value !== 'none'
        return acc
      }, {})
    },

    getBorderColor(styles) {
      return styles['border-color']
    },

    getBorderWidth(styles) {
      return styles['border-width']
    },

    // Clean memory if necessary
    destroy() {},
  })
}