import { createContext, useCallback, useState } from 'react'

export const InstructionContext = createContext()

export const InstructionProvider = ({ children }) => {
  const [config, setConfig] = useState({})

  const updateConfig = useCallback(
    (newConfig) => {
      setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }))
    },
    [setConfig],
  )

  return (
    <InstructionContext.Provider value={{ config, updateConfig, setConfig }}>{children}</InstructionContext.Provider>
  )
}
