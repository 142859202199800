import { TYPES } from "../../../plugins/componentTypes/consts";

export const custom = "custom";
export const hidden = "hidden";
export const none = "none";

export const allEqual = (arr) => arr.every((val) => val === arr[0]);

export const isZero = (target, values, toCheck) => {
  const isCol = target.get("type") === TYPES.column_2;
  const notValues = values.includes("0");
  return isCol && notValues && toCheck[0] === "15px";
};

export const noUpdate = (t, f, values, allEq) => {
  if (!t || !f) return true;
  const to = `${t.value}`;
  const from = `${f.value}`;
  const stillZero = from === "" && to === "0";
  const sameValue = from.split("px")[0] === to.split("px")[0];
  const valuesUnchanged =
    allEq && `${values[0]}`.split("px")[0] === to.split("px")[0];
  return stillZero || to === from || sameValue || valuesUnchanged;
};

export function resetVisible(property, keys, opts = {}) {
  const editor = property.em.getEditor();
  const padding = editor.Styles.getProperty("size_&_spacing", "padding");
  const radius = editor.Styles.getProperty(
    "borders_&_corners",
    "border-radius"
  );
  const composite = keys[0].includes("padding") ? padding : radius;
  const props = composite
    .getProperties()
    ?.filter((p) => keys.includes(p.get("property")));
  const isVisible = !props.every(
    (prop) => prop.view?.getClearEl()?.style.display === none
  );

  const compositeResetEl = composite.view?.getClearEl();

  if (!compositeResetEl?.classList) return;

  isVisible && compositeResetEl.classList.add(hidden); // TODO: Fix then enable reset all
  !isVisible && compositeResetEl.classList.add(hidden);
}

export function initValues(editor, customName, keys) {
  const target = editor.getSelected();
  const el = target.getEl();
  if (!el?.style) return;

  // Set to custom or all
  const sm = editor.Styles;
  const prop = keys[0].includes("padding")
    ? sm.getProperty("size_&_spacing", "padding")
    : sm.getProperty("borders_&_corners", "border-radius");
  const col = prop.getProperties()[0].collection;
  const computedStyle = getComputedStyle(el);
  const targetStyle = target.getStyle();
  const type = `--${customName.split("-")[2]}-type`;
  const value = targetStyle[type];
  const customValue = targetStyle[type];

  const values = [];
  const values2 = [];
  keys.forEach((element) => {
    values2.push(`${col.get(element)?.getValue()}`);
    values.push(computedStyle[element]);
  });
  const toCheck = values2.includes("0") ? values : values2;
  const allEq = allEqual(toCheck);

  if (customValue !== custom && !allEq) {
    target.addStyle({ [type]: custom });
  }

  if (!value && allEq)
    target.addStyle({
      [customName]: isZero(target, values2, toCheck) ? "0px" : toCheck[0],
    });
}

export function changeAll(property, value, _customName, keys, opts = {}) {
  const editor = property.em.getEditor()
  const target = editor.getSelected()
  if (!target) return
  const el = target.getEl()
  if (!el?.style) return

  const col = property.collection
  const computedStyle = getComputedStyle(el)

  const values = []
  const values2 = []
  keys.forEach((element) => {
    values2.push(`${col.get(element)?.getValue()}`)
    values.push(computedStyle[element])
  })
  const toCheck = values2.includes('0') ? values : values2
  const allEq = allEqual(toCheck)

  // Update all styles
  const styles = {}
  keys.forEach((element) => {
    styles[element] = value || toCheck[0]
  })

  const { to, from } = opts
  if (value && allEq && !noUpdate(to, from, toCheck, allEq)) value !== toCheck && target.addStyle(styles)
  else if (value && !noUpdate(to, from, toCheck, allEq)) target.addStyle(styles)

  allEq && property?.view?.$el?.removeClass(custom)
  !allEq && property?.view?.$el?.addClass(custom)
  resetVisible(property, keys)
}

export function updateVisibility(
  property,
  value,
  _customName,
  keys,
  opts = {}
) {
  const col = property.collection;
  if (value === custom) {
    keys.forEach((element) => {
      col.get(element)?.view?.$el?.removeClass(hidden);
    });
  } else {
    keys.forEach((element) => {
      col.get(element)?.view?.$el?.addClass(hidden);
    });
  }
  resetVisible(property, keys);
}

export function setCustom(property, value, customName, keys, opts = {}) {
  const editor = property.em.getEditor();
  const target = editor.getSelected();
  if (!target) return;
  const el = target.getEl();
  if (!el?.style) return;

  const name = property.getName();
  const col = property.collection;
  const values = [];
  const values2 = [];
  const styles = getComputedStyle(el);
  const targetStyle = target.getStyle();
  keys.forEach((element) => {
    values2.push(`${col.get(element)?.getValue()}`);
    values.push(
      name === element
        ? !value || value === "0"
          ? "0px"
          : value
        : styles[element]
    );
  });
  const toCheck = values2.includes("0") ? values : values2;
  const allEq = allEqual(toCheck);
  if (allEq) {
    targetStyle[customName] !== toCheck[0] &&
      target.addStyle({
        [customName]: toCheck[0],
      });
    col.get(customName)?.view?.$el?.removeClass(custom);
  } else {
    col.get(customName)?.view?.$el?.addClass(custom);
  }
  isZero(target, values2, toCheck) &&
    col.get(customName)?.view?.$el?.removeClass(custom);
  resetVisible(property, keys);
}
