import React, { useEffect } from 'react'
import { Result, Card, Button, Typography } from 'antd'
import { useHistory } from 'react-router-dom'
import { useCheckoutContext } from '@features/subscription'
import CheckoutHeader from './CheckoutHeader'
import Loading from '@common/Loading'
import { formatDate } from '@utils/data'
import * as Sentry from '@sentry/react'

const { Title } = Typography

export default function CheckoutStepResult() {
  const history = useHistory()
  const { checkout, selectedPlan, subscription, currentPlan, isCheckingOut, checkoutError, setStepIndex } =
    useCheckoutContext()

  const { schedule } = subscription

  useEffect(() => {
    checkout()
      .then(({ error }) => {
        if (error) throw new Error(error.data.message)
      })
      .catch((error) => {
        Sentry.captureException(error)
      })
  }, [checkout, selectedPlan])

  const isDowngrade = selectedPlan.price < currentPlan.price

  const results = {
    success: {
      status: 'success',
      icon: (
        <span style={{ fontSize: '225px' }} role='img' aria-label='Success'>
          🎉
        </span>
      ),
      title: <Title level={2}>Congratulations!</Title>,
      subTitle: currentPlan.isPaid ? (
        <>
          Your subscription will be {isDowngrade ? 'downgraded' : 'upgraded'} to the <b>{selectedPlan.name} plan</b> on{' '}
          <b>{formatDate(schedule?.nextPhase?.startDate, 'MM/dd/yyyy')}</b>.
        </>
      ) : (
        <>
          Your subscription has successfully been updated to the <b>${selectedPlan.name} plan</b>.
        </>
      ),
      extra: [
        <Button key='home' size='large' type='primary' style={{ marginTop: '1em' }} onClick={() => history.push('/')}>
          Go back home
        </Button>,
        <Button
          key='manage'
          size='large'
          type='secondary'
          style={{ marginTop: '1em' }}
          onClick={() => history.push('/settings/subscription')}
        >
          Manage Subscription
        </Button>,
      ],
    },
    error: {
      status: '500',
      title: "We've hit a bit of a snag",
      subTitle: (
        <>
          Not to worry though, our developers are on it!
          <br />
          In the meantime, feel free to
        </>
      ),
      extra: [
        <Button key='back' size='large' style={{ marginTop: '1em' }} onClick={() => history.push('/')}>
          Return home
        </Button>,
        <span key='2' style={{ padding: '0 1em' }}>
          or
        </span>,
        <Button key='retry' size='large' style={{ marginTop: '1em' }} onClick={() => setStepIndex(0)}>
          Try again
        </Button>,
      ],
    },
  }

  const result = checkoutError ? results.error : results.success

  return (
    <Card style={{ borderRadius: '1em' }}>
      <CheckoutHeader showBack={false} />
      {isCheckingOut ? <Loading style={{ fontSize: '100px' }} /> : <Result {...result} style={{ paddingTop: 0 }} />}
    </Card>
  )
}
