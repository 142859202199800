import React, { useEffect, useState, useCallback } from 'react'

import { useLocation, useHistory } from 'react-router-dom'
import { useTemplate } from '../../../hooks/templateHooks'
import LoadingDocument from '../../common/LoadingDocument'
import Display from './../../common/Display'
import FixedHeaderLayout from './../../../layouts/FixedHeaderLayout'

export default function Airtable() {
  const template = useTemplate()
  const { mergeAirtable } = template
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const location = useLocation()
  const history = useHistory()

  const createDocument = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(location.search)
      const { template_id, ...params } = getParamsObject(searchParams)
      const [error, response] = await mergeAirtable(template_id, params)

      if (error) throw error
      
      const { id } = response?.data || {}
      if (!id) throw new Error('Uncaught error')

      history.push(`/share/documents/${id}`)
    } catch (error) {
      if (error.status === 403) error.message = 'This feature is not available with your current plan.'
      setError(error)
    } finally {
      setIsLoading(false)
    }
  }, [history, mergeAirtable, location])

  useEffect(() => {
    createDocument()
  }, [createDocument])

  return (
    <FixedHeaderLayout showHeader={false}>
      <Display
        loader={<LoadingDocument className='pt-4' />}
        isLoading={isLoading}
        error={error}
        showErrorDetails={true}
      ></Display>
    </FixedHeaderLayout>
  )
}

/**
 * get parameters as object from URLSearchParams
 * @param {URLSearchParam} searchParams
 */
function getParamsObject(searchParams) {
  const entries = searchParams.entries()
  return Array.from(entries).reduce((acc, pair) => {
    let [key, value] = pair
    acc[key] = value
    return acc
  }, {})
}
