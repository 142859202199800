import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setProfile, setAccount } from '../authSlice'
import { useRegisterMutation } from '@services/documintApi/auth'

/**
 * Registration Hook
 */
export const useRegister = () => {
  const dispatch = useDispatch()
  const [_register, { isLoading: isRegistering, error }] = useRegisterMutation()

  const register = useCallback(
    async (user, captcha) => {
      const { data, error } = await _register(user, captcha)

      if (error) throw error

      dispatch(setProfile(data.profile))
      dispatch(setAccount(data.account))
      return data
    },
    [_register, dispatch],
  )

  return [register, { isRegistering, error }]
}

export default useRegister