import React from 'react'
import { Tooltip, Typography } from 'antd'
import { StarTwoTone } from '@ant-design/icons'

import { useSubscriptionContext, ChangePlanButton } from '@features/subscription'

const { Text, Title } = Typography

export default function PremiumFeatureFlag({ tooltip, children, label, iconPosition }) {
  const { subscription } = useSubscriptionContext()

  if (label) children = label

  if (subscription?.currentPlan?.isPaid) return <></>

  return (
    <Tooltip
      {...tooltip}
      title={
        <div className="px-2 py-1 text-center">
          <Title level={5}>Premium feature</Title>
          <Text style={{marginBottom:'20px'}}>Available with paid plans.</Text>
          <div className='text-center mt-3'>
            <ChangePlanButton />
          </div>
        </div>
      }
    >
      {iconPosition === 'left' && <StarTwoTone twoToneColor='#FFCF19' className='mx-2' />}
      {children}
      {iconPosition === 'right' && <StarTwoTone twoToneColor='#FFCF19' className='mx-2' />}
    </Tooltip>
  )
}

PremiumFeatureFlag.defaultProps = {
  iconPosition: 'right',
}
