const documintTheme = {
  token: {
    colorPrimary: '#009BFF',
    fontSize: 15,
    colorTextBase: '#374151',
    borderRadius: 7,
    colorBgSpotlight:'#fff'
  },
  components: {
    Menu: {
      iconSize: 16,
    },
  },
}

export default documintTheme