import React, { useCallback } from 'react'
import { Button, Form, Select } from 'antd'
import { useConnections, useActivePieces } from '@features/activePieces'

export default function ConnectionField({ onChange, dataSource, value }) {
  const { connections, isLoading: isConnectionsLoading, fetchConnections } = useConnections()
  const { openConnectionsModal } = useActivePieces()
  const pieceName = dataSource?.connection?.pieceName

  // Filter connections by selected connector
  const connectionOptions = connections
    ?.filter((c) => c.pieceName === pieceName)
    ?.map((c) => ({ label: c.name, value: c.id }))

  const handleSelect = useCallback(
    (value, option) => {
      const connection = connections?.find((c) => c.id === value)
      if (onChange) onChange(connection, value, option)
    },
    [onChange, connections],
  )

  const handleCreateConnection = useCallback(async () => {
    const { connection: newConnection } = await openConnectionsModal(pieceName)
    if(!newConnection) return
    const { data } = await fetchConnections()
    const connection = data?.data?.find((c) => c?.id === newConnection.id)
    if (onChange) onChange(connection, connection.id)
  }, [openConnectionsModal, pieceName, fetchConnections, onChange])

  return (
    <Form.Item label='Connection'>
      {connectionOptions?.length > 0 ? (
        <Select
          value={value}
          options={connectionOptions}
          loading={isConnectionsLoading}
          style={{ width: '100%' }}
          placeholder='Select connection'
          onChange={handleSelect}
        />
      ) : (
        <Button type='primary' onClick={handleCreateConnection}>
          Create connection
        </Button>
      )}
    </Form.Item>
  )
}
