import { useEffect, useState } from 'react'
import { Grid } from 'antd'
const { useBreakpoint: useBp } = Grid

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState('lg')
  const screens = useBp()

  useEffect(() => {
    const bpOrder = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
    const currentBps = Object.entries(screens)
      .filter(([k, v]) => !!v)
      .map(([k]) => k)
    const currentBp = bpOrder.reverse().find((bp) => currentBps.includes(bp))
    if (currentBp && currentBp !== breakpoint) setBreakpoint(currentBp)
  }, [screens, breakpoint])

  return breakpoint
}

export default useBreakpoint
