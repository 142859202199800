import { TYPES } from '../consts'
import { resizerNone } from '../sharedConfig'
import { getAndMergeModels } from '../../utils'
import { loopAllComponents } from '../../../utils'
import merge from 'lodash/merge'
import { mapAlignment, removeDisplayBefore, removeDisplayAfter } from './Section'
import { MAX_GRID } from '../consts'

export const type = TYPES.column

export const protectedCss = `
  /* COLUMN COMPONENT */
  *[data-gjs-type="${type}"] {
    flex: 100;
    display: flex;
    overflow: hidden;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    box-sizing: border-box;
    word-break: break-all;
    color: inherit;
  }
`

export default function columnType(dc) {
  const sizeClassStylesMap = {}
  for (let i = 0; i < MAX_GRID; i++) {
    sizeClassStylesMap[i + 1] = `${(100 / MAX_GRID) * (i + 1)}%`
  }
  const models = getAndMergeModels(dc, [TYPES.template])
  const model = {
    extend: TYPES.template,
    defaults: {
      tagName: 'div',
      name: 'Column (Legacy)',
      icon: '<i class="gjs-badge__icon fa-kit fa-column"></i>',
      traits: [],
      attributes: {
        'data-dm-category': 'layout',
      },
      resizable: { ...resizerNone, cr: true, cl: true, keyWidth: 'flex', currentUnit: 1, minDim: 1, step: 1 },
      draggable: `[data-gjs-type=${TYPES.row}], [data-gjs-type=${TYPES.section}]`, // IT CAN BE DRAGGED INTO these components
      droppable: `[data-gjs-type=${TYPES.row}], [data-gjs-type=${TYPES.dynamic_row}], [data-dm-category="content"]`, // these components CAN BE DROPPED INTO IT
      stylable: [
        'flex',
        'justify-content',
        'page-break-inside',
        'overflow',
        'font-family',
        'font-size',
        'font-weight',
        'color',
        'text-align',

        'better-borders',
        'border-width',
        'border-color',

        'background-color',
        'background-image',
        'background-size',
        'background-position',
        'background-repeat',

        'padding',
        'padding-top',
        'padding-right',
        'padding-left',
        'padding-bottom',

        'border-radius',
        'border-top-left-radius',
        'border-top-right-radius',
        'border-bottom-left-radius',
        'border-bottom-right-radius',
        'align-content-vertical',
      ],
    },
    init() {
      this.addClass('pl-10 pr-10')
    },
    mapAlignment,
    removeDisplayBefore,
    removeDisplayAfter,
    // This replaces it with the new column
    bump() {
      this.mapAlignment()
      const columns = parseInt(this.get('tempColumns'))
      this.set({
        type: TYPES.column_2,
        columns,
      })
      this.addAttributes({ 'data-gs-type': 'column', 'data-gs-columns': columns })
      this.addStyle({ width: sizeClassStylesMap[columns] })
      const ifTrue = (c) => c.bump
      const clb = (c) => c.bump()
      loopAllComponents(this.components(), ifTrue, clb)
    },
    getSpan() {},
    setSizeClass() {},
    getColumns() {},
    removeColumns() {},
    resetHandles() {},
    correctWidth() {},
  }

  dc.addType(type, { model: merge({}, models, model) })
}
