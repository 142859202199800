import React from 'react'
import { Segmented } from 'antd'
import { useTemplateEdit } from '../../../hooks'

export default function ModeSwitch() {
  const { mode, getModes, setMode } = useTemplateEdit()

  const modes = getModes()

  return (
    <Segmented className='template-edit__mode-switch primary' options={modes} value={mode} onChange={setMode} size='normal' />
  )
}
