const deviceManagerConfig = {
  devices: [
    {
      id: 'letter-portrait',
      width: '8.5in',
      height: '11in',
      name: 'Letter Portrait',
      widthMedia: false,
    },
    {
      id: 'letter-landscape',
      width: '11in',
      height: '8.5in',
      name: 'Letter Landscape',
      widthMedia: false,
    },
    {
      id: 'legal-portrait',
      width: '8.5in',
      height: '14in',
      name: 'Legal Portrait',
      widthMedia: false,
    },
    {
      id: 'legal-landscape',
      width: '14in',
      height: '8.5in',
      name: 'Legal Landscape',
      widthMedia: false,
    },
    {
      id: 'tabloid-portrait',
      width: '11in',
      height: '17in',
      name: 'Tabloid Portrait',
      widthMedia: false,
    },
    {
      id: 'tabloid-landscape',
      width: '17in',
      height: '11in',
      name: 'Tabloid Landscape',
      widthMedia: false,
    },
    {
      id: 'a3-portrait',
      width: '297mm',
      height: '420mm',
      name: 'A3 Portrait',
      widthMedia: false,
    },
    {
      id: 'a3-landscape',
      width: '420mm',
      height: '297mm',
      name: 'A3 Landscape',
      widthMedia: false,
    },
    {
      id: 'a4-portrait',
      width: '210mm',
      height: '297mm',
      name: 'A4 Portrait',
      widthMedia: false,
    },
    {
      id: 'a4-landscape',
      width: '297mm',
      height: '210mm',
      name: 'A4 Landscape',
      widthMedia: false,
    },
    {
      id: 'a5-portrait',
      width: '148mm',
      height: '210mm',
      name: 'A5 Portrait',
      widthMedia: false,
    },
    {
      id: 'a5-landscape',
      width: '210mm',
      height: '148mm',
      name: 'A5 Landscape',
      widthMedia: false,
    },
    {
      id: 'a6-portrait',
      width: '105mm',
      height: '148mm',
      name: 'A6 Portrait',
      widthMedia: false,
    },
    {
      id: 'a6-landscape',
      width: '148mm',
      height: '105mm',
      name: 'A6 Landscape',
      widthMedia: false,
    },
  ],
}

export const devices = {
  Letter: { width: '8.5in', height: '11in' },
  Legal: { width: '8.5in', height: '14in' },
  Tabloid: { width: '11in', height: '17in' },
  A3: { width: '297mm', height: '420mm' },
  A4: { width: '210mm', height: '297mm' },
  A5: { width: '148mm', height: '210mm' },
  A6: { width: '105mm', height: '148mm' },
}

export default deviceManagerConfig
