import { useCallback, useEffect, useState, useMemo } from 'react'
import { Utils, AntdConfig } from '@react-awesome-query-builder/antd'
import '@react-awesome-query-builder/ui/css/styles.css'
import { useDesigner, useEditor } from '../../../hooks'
import renderBuilder from '../components/renderBuilder'
import { formatQueryTree, generateLocalDataFields, toJsonLogic, toJson } from '../utils'
import { convertTypes, deepClone } from '@utils'

const InitialConfig = AntdConfig
const queryBuilderConfig = {
  ...InitialConfig,
  settings: {
    ...InitialConfig.settings,
    maxNesting: 1,
    renderSize: 'normal',
  },
}
const emptyTree = { id: Utils.uuid(), type: 'group' }

const useConditionEditor = ({ condition, setCondition }) => {
  const { template: { fieldSchema } } = useDesigner()
  const { retrieveArrayTempParent } = useEditor()

  const { parsedData, metaData } = useMemo(
    () => {
      const schemaKey = retrieveArrayTempParent()
      return generateLocalDataFields(convertTypes(deepClone(fieldSchema), false), schemaKey)
    },
    [fieldSchema, retrieveArrayTempParent]
  )
  const parsedCondition = typeof condition === 'string' ? toJson(condition) : condition
  const queryValue = condition ? parsedCondition : emptyTree

  const [code, setCode] = useState(
    parsedCondition && parsedCondition['custom-condition'] ? parsedCondition['custom-condition'] : '',
  )

  const getTree = useCallback(
    (queryValue, config) => {
      let tree = Utils.loadTree(emptyTree)
      if (queryValue?.operator) {
        if (queryValue.operator === 'matches') {
          !code && setCode(`$matches ${queryValue.operand1} ${queryValue.operand2}`)
        } else {
          tree = Utils.loadFromJsonLogic(
            toJsonLogic(queryValue.operand1, queryValue.operator, queryValue.operand2),
            config,
          )
        }
      } else if (queryValue?.type) {
        tree = Utils.loadTree(queryValue)
      } else if (queryValue?.logic && !queryValue?.type) {
        const logicTree = toJson(queryValue.logic)
        tree = Utils.loadFromJsonLogic(logicTree, config)
      }
      return Utils.checkTree(tree, config)
    },
    [code],
  )

  const config = useMemo(
    () => ({
      ...queryBuilderConfig,
      fields: {
        ...parsedData,
      },
    }),
    [parsedData],
  )
  const [state, setState] = useState({
    tree: getTree(queryValue, config),
    config,
  })

  const [stateMetadata, setMetadata] = useState(metaData)

  const builder = useCallback((props) => renderBuilder(props), [])

  const handleSave = useCallback(
    (immutableTree) => {
      const rawTree = Utils.getTree(immutableTree)
      setCondition &&
        setCondition(
          JSON.stringify({
            ...rawTree,
            children1: formatQueryTree(rawTree.children1, stateMetadata),
            logic: Utils.jsonLogicFormat(immutableTree, config).logic,
          }),
        )
    },
    [config, setCondition, stateMetadata],
  )

  const handleChange = useCallback(
    (immutableTree, config) => {
      setState((prevState) => ({ ...prevState, tree: immutableTree, config }))
      handleSave(immutableTree)
    },
    [handleSave],
  )

  const handleChangeCustom = useCallback(
    (custom) => {
      setCode(custom)
      setCondition && setCondition(JSON.stringify({ 'custom-condition': custom }))
    },
    [setCondition],
  )

  const getLocalVariables = useCallback(() => {
    const schemaKey = retrieveArrayTempParent()

    if (!schemaKey) return

    const { parsedData, metaData } = generateLocalDataFields(convertTypes(deepClone(fieldSchema), false), schemaKey)

    setState((prevState) => ({
      ...prevState,
      config: {
        ...queryBuilderConfig,
        fields: {
          ...parsedData,
        },
      },
    }))

    setMetadata(metaData)
  }, [fieldSchema, retrieveArrayTempParent])

  useEffect(() => {
    getLocalVariables()
  }, [fieldSchema, getLocalVariables])

  return {
    state,
    code,
    builder,
    handleChange,
    handleChangeCustom,
    handleSave,
  }
}

export default useConditionEditor
