import React from 'react'
import { FaIcon } from '@components/icons'
import { Button } from 'antd'

export default function SiderToggle({ isCollapsed, onClick, reverseIcons = false, width = '40px', height = '40px' }) {
  const collapsedIcon = reverseIcons ? 'faChevronsLeft' : 'faChevronsRight'
  const expandedIcon = reverseIcons ? 'faChevronsRight' : 'faChevronsLeft'

  const icon = isCollapsed ? collapsedIcon : expandedIcon

  return (
    <div style={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Button
        type='text'
        size='sm'
        icon={<FaIcon icon={icon} size='sm' raw />}
        onClick={onClick}
        title={isCollapsed ? 'Open' : 'Collapse'}
      />
    </div>
  )
}
