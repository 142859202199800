import { TYPES } from '../consts'
import { getAttrString, filterObject } from '../../utils'
import { toJson } from '../../../../NewLogicModal/utils'
import { move, parent, tokenSettings, clone, remove } from '../../../config/toolbarItems'
import { uniqueCollection } from '@utils'
// import handlebars from 'handlebars';

import isNil from 'lodash/isNil'

function isBlank(value) {
  return isNil(value) || value === ''
}

function isJsonString(string) {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

function removeItemOnce(arr, value) {
  var index = arr.indexOf(value)
  let result = [].concat(arr)
  if (index > -1) {
    result.splice(index, 1)
  }
  return result
}

function equalArrays(arr1, arr2) {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export const type = TYPES.token

export const getParentLoop = (component, key, last = 'root', contexts = []) => {
  if (component && key) {
    const loop = component.getLoop && component.getLoop()
    const parsedLoop = toJson(loop)
    const loopValue = Object.keys(parsedLoop)[0]
    const value = loopValue && loopValue !== 'undefined' ? loopValue : !parsedLoop && loop ? loop : null
    value && contexts.push(value)
    return key.includes(value)
      ? JSON.stringify(contexts)
      : getParentLoop(component.parent(), key, value || last, contexts)
  }
  last && contexts.push(last)
  return JSON.stringify(contexts)
}

export const formatName = (name) => {
  if (!name) return ''
  if (Array.isArray(name)) return name.map((n) => `[${n}]`).join('.')
  if (name.includes('[')) return name
  return name
    .split('.')
    .map((n) => `[${n}]`)
    .join('.')
}

export const addContext = (placeholder, context) => {
  if (context.includes('[')) return placeholder.split(`${context}.`).pop().replaceAll('[', '').replaceAll(']', '')
  return placeholder.split(`[${context}].`).pop().replaceAll('[', '').replaceAll(']', '')
}

export const up = '../'

export default function tokenType(dc, { editor }) {
  const model = {
    defaults: {
      name: 'Token',
      icon: '<i class="fa-regular fa-brackets-curly"></i>',
      tagName: 'span',
      textable: true,
      hasLogic: true,
      toolbar: [move, parent, tokenSettings, clone, remove],
      draggable: `[data-gjs-type=wrapper], [data-gjs-type=gs-columns], [data-gjs-type=${TYPES.text}], [data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.gridItem}], [data-gjs-type=${TYPES.column_2}]`,
      attributes: {
        'data-dm-category': 'content',
        'data-placeholder': 'token',
        'data-type': 'string',
        'data-parent-type': '',
        'data-parent-key': '',
        'data-context': '',
        //  "data-token-preview": ""
      },
      traits: [],
      stylable: [
        'background-color',
        'background-image',
        'background-size',
        'background-position',
        'background-repeat',
        'page-break-inside',
        'color',
        'text-align',
        'font-size',
        'font-family',
        'font-style',
        'text-decoration',
        'font-weight',
        'line-height',
        'word-break',
        'word-wrap',
        'text-transform',
        'letter-spacing',
        'border-width',
        'border-color',
        'border-style',
        'padding',
        'padding-top',
        'padding-right',
        'padding-bottom',
        'padding-left',
        'better-borders',

        'border-radius',
        'border-top-left-radius',
        'border-top-right-radius',
        'border-bottom-right-radius',
        'border-bottom-left-radius',
      ],
      // traits: [{ name: 'placeholder', changeProp: true }],
    },
    init() {
      this.on('change:attributes:data-placeholder change:attributes:data-context', this.handlePlaceholderChange)
      // this.setPreview();
    },
    // setPreview() {
    //   try {
    //     const preview = handlebars.compile(this.toHTML())()
    //     console.log({ preview })
    //     this.addAttributes({ "data-token-preview": preview })
    //   } catch (error) {
    //     return error.message
    //   }
    // },
    getInnerHTML() {
      return this.getAttrToHTML()['data-placeholder']
    },
    toHTML(opts = {}) {
      const model = this
      const { type: gjsType } = model.attributes

      // HTML TAG
      const customTag = opts.tag
      delete opts.tag
      const tag = customTag || model.get('tagName')

      // INNER HTML
      let HTML = ''

      // ATTRIBUTES
      const attributes = model.getAttrToHTML()

      const attributeStr = getAttrString({
        id: attributes.id,
        ...(attributes.class ? { class: attributes.class } : {}),
        'data-gjs-type': gjsType,
        'data-dm-category': attributes['data-dm-category'],
      })

      let name = attributes['data-placeholder']
      const formattedName = attributes['data-formatted-placeholder']
      const splitName = toJson(attributes['data-split-path'])
      const parentName = attributes['data-parent-key']
      const title = attributes['data-title']
      const type = attributes['data-type']
      const parentType = attributes['data-parent-type']
      const arrayNesting = attributes['data-array-nesting']
      const parentNesting = attributes['data-parent-nesting']

      const splitParentName = Array.isArray(splitName) && splitName?.length ? removeItemOnce(splitName, title) : null
      const splitPath = splitParentName ? splitName.filter((element) => !splitParentName.includes(element)) : null

      const arrayType = arrayNesting || parentType === 'array' ? attributes['data-array-type'] : 'string'
      const contextList = toJson(attributes['data-context'])
      const context = contextList ? uniqueCollection(contextList) : ['root']
      const lastContext = context[context.length - 1]

      const { data, attrs } = this.getMetaAttrsByType(
        type,
        formatName(splitParentName).includes(lastContext) ? null : parentType, // prev parentName === context
        arrayType,
      )

      const hasMatchingContext = lastContext !== 'root' && formattedName?.includes(lastContext)
      const hasParentArray = parentName && parentType === 'array' // Everything inside a collection
      const formatter = data.format
        ? type === 'date' || arrayType === 'date'
          ? 'formatter="date"'
          : 'formatter="number"'
        : ''

      const isThis = !splitPath?.length
      if (hasMatchingContext && isThis) {
        name = 'this'
      } else if (hasMatchingContext) {
        name = formatName(splitPath)
      } else {
        name = formatName(splitName)
      }

      name =
        Array.from(Array(hasMatchingContext ? context.length - 1 : context.length))
          .map(() => up)
          .join('') + name

      // Everything inside a collection by context
      if (
        hasParentArray &&
        lastContext !== formatName(splitParentName) &&
        `[${lastContext}]` !== formatName(splitParentName) &&
        splitParentName?.length &&
        !equalArrays(splitParentName, splitPath)
      ) {
        const allData = this.getAllTokenMetaData()
        const returnType = attrs ? attrs : 'returnType="first"'
        if (this.hasNestedCollections(formatName([...splitParentName, ...splitPath]))) {
          const localPath = splitPath.pop()
          const localParentName = [...splitParentName, ...splitPath]
          const formattedName = this.applyRoots(editor, allData, formatName(localParentName), localParentName[0])
          const splitFormattedName = formattedName.split(`.[${localPath}]`)
          HTML = `{{$token ${splitFormattedName[0]} dataType="${parentType}" path="[${localPath}]${
            splitFormattedName[1] || ''
          }" ${returnType} ${formatter}}}`
        } else {
          HTML = `{{$token ${formatName(splitParentName ?? parentName)} dataType="${parentType}" path="${formatName(
            splitPath,
          )}" ${returnType} ${formatter}}}`
        }
        // Primitive types and lists of primitive types and plain objects and collection or list roots by context
      } else if (attrs) {
        switch (type) {
          case 'object':
            HTML = `{{$token ${name} dataType="${type}" ${attrs} logic='{"var": "${data.filter}"}'}}`
            break
          case 'array':
            const allData = this.getAllTokenMetaData()
            let indices = ''
            for (let i = 0; i < arrayNesting + parentNesting; i++) {
              indices += `.[${allData[`returnType${i}`] === 'first' ? '0' : allData[`index${i}`] || '0'}]`
            }
            // Use type based on context
            const useArrayType = lastContext === formatName(splitParentName) || `[${lastContext}]` === formatName(splitParentName)
            HTML = `{{$token ${name + indices} dataType="${useArrayType ? arrayType : type}" ${attrs} ${formatter}}}`
            break
          case 'number':
          case 'date':
          default:
            HTML = `{{$token ${name} dataType="${type}" ${attrs}}}`
            break
        }
      } else {
        HTML = `{{${name}}}`
      }

      HTML = `<${tag} ${attributeStr}>${HTML}</${tag}>`

      // CONDITION
      if (!isBlank(data.condition) && isJsonString(data.condition)) {
        const tree = toJson(data.condition)
        if (tree['custom-condition']) HTML = `{{#if (${tree['custom-condition']}) }} ${HTML} {{/if}} `
        else if (tree.logic) HTML = `{{#compare '${JSON.stringify(tree.logic)}' }} ${HTML} {{/compare}} `
      }

      return HTML.replace(new RegExp('&quot;', 'g'), '"')
        .replace(new RegExp('&#039;', 'g'), "'")
        .replace(new RegExp(`<font(.*?)>`, 'g'), '')
        .replace(new RegExp('</font>', 'g'), '')
    },
    // getSchemaMetaData() {
    //   const data = this.getAttrToHTML()['data-schema-meta-data'];
    //   if (isJsonString(data)) return toJson(data);
    //   return {};
    // },
    applyRoots(editor, allData, tokenKey, rootKey) {
      if (!tokenKey) return ''
      let varName = tokenKey
      const ref = editor.SchemaKeys
      if (!ref || !Object.keys(ref).length) return varName
      const id = Object.keys(ref).find((k) => ref[k].key === rootKey) || ''
      const field = editor.SchemaKeys[id]
      if (!field) return varName
      const rootNesting = field.schemaMetaData.nestingObj
      const roots = Object.keys(rootNesting).filter((key) => tokenKey.includes(key))

      roots.forEach((root, i) => {
        let indices = ''
        for (let j = 0; j < rootNesting[root].dimensions; j++) {
          indices += `.[${allData[`returnType${root + j}`] === 'first' ? '0' : allData[`index${root + j}`] || '0'}]`
        }
        if (i < roots.length - 1) {
          varName = varName.replace(
            `[${root}]`,
            `[${root}]${
              indices ? indices : `.[${allData[`returnType${root}`] === 'first' ? '0' : allData[`index${root}`] || '0'}]`
            }`,
          )
        } else if (indices) {
          varName = varName.replace(`[${root}]`, `[${root}]${indices}`)
        }
      })
      return varName
    },
    hasNestedCollections(tokenKey) {
      const ref = editor.SchemaKeys
      if (!ref || !Object.keys(ref).length) return false
      const id = Object.keys(ref).find((k) => ref[k].formattedKey === tokenKey.split('.')[0]) || ''
      const field = editor.SchemaKeys[id]
      if (!field) return false
      const rootNesting = field.schemaMetaData.nestingObj
      const roots = Object.keys(rootNesting).filter((key) => tokenKey.includes(key))
      return roots.some((i) => roots.length > 1 || rootNesting[i].dimensions >= 1)
    },
    getNumberFormat(decimalPlaces, thousandsSeparator, decimalSeparator, abbreviate) {
      const zeros = new Array(parseInt(decimalPlaces)).fill(0)
      return `0${thousandsSeparator}0${zeros.length ? decimalSeparator + zeros.join('') : ''}${abbreviate ? 'a' : ''}`
    },
    getAllTokenMetaData() {
      const attributes = this.getAttrToHTML()
      const tokenMetaData = attributes['data-token-meta-data']
      if (!tokenMetaData) return {}
      return toJson(tokenMetaData) || {}
    },
    getMetaAttrsByType(type, parentType, arrayType) {
      const attributes = this.getAttrToHTML()
      const tokenMetaData = attributes['data-token-meta-data']
      if (!tokenMetaData) return { data: {}, attrs: '' }
      const types = {
        string: ['defaultValue', 'textType', 'condition'],
        bool: ['defaultValue', 'condition'],
        number: ['defaultValue', 'condition', 'format', 'locale', 'th', 'mi', 'bi'],
        date: ['defaultValue', 'condition', 'format', 'pattern', 'timezone'],
        array: ['condition', 'returnType', 'index', 'separator', 'final'],
        join: [
          'condition',
          'defaultValue',
          'textType',
          'pattern',
          'format',
          'locale',
          'th',
          'mi',
          'bi',
          'returnType',
          'separator',
          'final',
        ],
        custom: [
          'condition',
          'defaultValue',
          'textType',
          'pattern',
          'format',
          'locale',
          'th',
          'mi',
          'bi',
          'returnType',
          'index',
        ],
        other: ['condition', 'defaultValue', 'textType', 'pattern', 'format', 'locale', 'th', 'mi', 'bi', 'returnType'],
        object: ['condition', 'filter'],
      }
      const parsedData = toJson(tokenMetaData)
      const cleanedData = filterObject(parsedData, (key, val) => !!val || val === 0)
      if ((type === 'number' || arrayType === 'number') && cleanedData.format === 'custom')
        cleanedData.format = this.getNumberFormat(
          cleanedData.decimalPlaces,
          cleanedData.thousandsSeparator,
          cleanedData.decimalSeparator,
          cleanedData.abbreviate,
        )
      if (cleanedData.numberType === 'currency' && cleanedData.locale) {
        const plainCurrencySymbol = cleanedData.currencySymbol
        const right = plainCurrencySymbol && plainCurrencySymbol.startsWith('-')
        const currencySymbol = plainCurrencySymbol?.split('-').pop()
        cleanedData.format = `${!right ? (currencySymbol ? currencySymbol : '$') : ''}${cleanedData.format}${
          right ? (currencySymbol ? currencySymbol : '$') : ''
        }`
      } else {
        delete cleanedData.locale
        delete cleanedData.th
        delete cleanedData.mi
        delete cleanedData.bi
      }
      if (cleanedData.locale !== 'custom') {
        delete cleanedData.th
        delete cleanedData.mi
        delete cleanedData.bi
      }
      if (cleanedData.locale === '[default]' || cleanedData.locale === 'en-us') delete cleanedData.locale
      if (cleanedData.numberType === 'percent')
        cleanedData.format = `${cleanedData.format}${cleanedData.percent || '%'}`
      if ([type, arrayType].includes('date') && cleanedData.format === 'custom')
        cleanedData.format = cleanedData.pattern
      const filteredData = filterObject(
        cleanedData,
        (key) =>
          types[type].includes(key) ||
          (types[arrayType] && types[arrayType].includes(key)) ||
          (parentType === 'array' && types['array'].includes(key)),
      )

      let name = attributes['data-placeholder']
      const contextList = toJson(attributes['data-context'])
      const context = contextList ? contextList : ['root']
      const lastContext = context[context.length - 1]
      const hasContext = lastContext !== 'root' && name.includes(lastContext)
      if ((type === 'array' || parentType === 'array') && !(hasContext && name === lastContext)) {
        const filteredArrayData = filterObject(filteredData, (key) => {
          if (filteredData.returnType === 'join') return types['join'].includes(key)
          else if (filteredData.returnType === 'custom') return types['custom'].includes(key)
          else return types['other'].includes(key)
        })
        const { condition, ...attrs } = filteredArrayData
        return { data: filteredArrayData, attrs: getAttrString(attrs) }
      }
      const { condition, ...attrs } = filteredData
      return { data: filteredData, attrs: getAttrString(attrs) }
    },
    handlePlaceholderChange() {
      this.view.render()
    },
    setContext() {
      this.addAttributes({ 'data-context': getParentLoop(this, this.getAttrToHTML()['data-placeholder']) })
    },
  }

  const view = {
    events: {
      change: 'updatePlaceholder',
      dblclick: 'onActive',
    },
    init({ model }) {
      model.setContext()
    },
    updatePlaceholder(ev) {
      this.model.addAttributes({ 'data-placeholder': ev.target.value })
    },
    onAttrUpdate() {
      // const viewStyle = 'padding: 0px 5px 1px; border-radius: 3px; border: 1px solid #b300fd'
      // this.el.setAttribute('style', viewStyle)
    },
    onRender() {
      const { model, el } = this
      const attributes = model.getAttrToHTML()

      const splitName = toJson(attributes['data-split-path'])
      const title = attributes['data-title']

      const splitParentName = Array.isArray(splitName) && splitName?.length ? removeItemOnce(splitName, title) : null
      const splitPath = splitParentName ? splitName.filter((element) => !splitParentName.includes(element)) : null

      const placeholder = attributes['data-formatted-placeholder']
      const contextList = toJson(attributes['data-context'])
      const context = contextList ? contextList : ['root']
      const lastContext = context[context.length - 1]
      const hasContext = lastContext !== 'root' && placeholder?.includes(lastContext)

      const isThis = !splitPath?.length
      // const button = document.createElement('button')
      // const options = ['VARIABLE-1', 'VARIABLE-2', 'VARIABLE-3']
      if (hasContext && isThis) {
        el.innerHTML = 'this'
      } else if (hasContext) {
        el.innerHTML = addContext(placeholder, lastContext)
      } else {
        el.innerHTML = placeholder?.replaceAll('[', '').replaceAll(']', '')
      }
      // el.innerHTML = ''
      // el.appendChild(button)
      // button.setAttribute('style', 'padding: 0; border: none; appearance: none;')
    },
    onActive(ev) {
      ev && ev.stopPropagation()
      const { em } = this
      const editor = em.get('Editor')
      // const key = model.get("placeholder");
      // const type = model.get("dataType");
      editor.runCommand('edit-token') //, { key, type });
    },
  }

  dc.addType(type, { model, view })
}
