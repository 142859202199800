import React, { useState } from 'react'
import { Alert, Form, Input, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useLogin } from '../hooks'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

export default function LoginForm({ onFinish, onFinishFailed }) {
  const [login, { isLoggingIn }] = useLogin()
  const [errors, setErrors] = useState([])
  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleSubmit = async (values) => {
    try {
      if (executeRecaptcha) values.captcha = await executeRecaptcha()
      await login(values)
      if (onFinish) onFinish(values)
    } catch (error) {
      console.log(error)
      const message =
        error?.status === 401 ? 'Invalid Credentials' : error?.data?.message ? error?.data?.message : 'Unable to Log In'
      setErrors([message])
    }
  }

  return (
    <Form
      name='login'
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      validateTrigger={['onSubmit']}
    >
      {errors.map((error, i) => (
        <Alert type='error' key={i} message={error || 'Invalid credentials'} className='mb-3' />
      ))}

      <Form.Item
        name='email'
        rules={[{ required: true, message: 'Please enter a valid email address', type: 'email' }]}
      >
        <Input prefix={<UserOutlined />} placeholder='Email Address' size='large' type='email' />
      </Form.Item>

      <Form.Item name='password' rules={[{ required: true, message: 'Please input your password!' }]}>
        <Input.Password prefix={<LockOutlined />} placeholder='Password' size='large' />
      </Form.Item>

      <Form.Item label='amex' style={{ display: 'none' }}>
        <Input type='hidden' name='amex' />
      </Form.Item>

      <Form.Item className='text-center'>
        <Button block className='btn btn--primary' size='large' type='primary' htmlType='submit' loading={isLoggingIn}>
          Log In
        </Button>
      </Form.Item>
    </Form>
  )
}
