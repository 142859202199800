import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setProfile, setAccount } from '../authSlice'
import { useLoginMutation } from '@services/documintApi/auth'

/**
 * Login Hook
 */
export const useLogin = () => {
  const dispatch = useDispatch()
  const [_login, { isLoading }] = useLoginMutation()
  const login = useCallback(
    async (values) => {
      const { data, error } = await _login(values)
      if (error) throw error
      dispatch(setProfile(data.profile))
      dispatch(setAccount(data.account))

      return data
    },
    [_login, dispatch],
  )

  return [login, { isLoggingIn: isLoading }]
}

export default useLogin
