import { createContext, useCallback, useState } from 'react'

import { getMode, getModes, MODES } from '../modes'

import { useTemplate } from '@hooks/useTemplate'

export const TemplateEditContext = createContext(null)

export function TemplateEditProvider({ children }) {
  const {
    template,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUpdateError,
    isUpdating,
    isUpdateSuccess,
    update,
    updateError,
  } = useTemplate()
  const [_mode, _setMode] = useState(MODES.design.value)
  const [_editor, _setEditor] = useState()

  const setMode = useCallback((modeValue) => {
    if (typeof modeValue !== 'string') modeValue = modeValue?.value
    const mode = MODES[modeValue]
    if (mode) _setMode(mode.value)
  }, [])

  const setEditor = useCallback(
    (editor) => {
      if (_editor) return
      _setEditor(editor)
    },
    [_editor],
  )

  const providerValue = {
    template,
    mode: _mode,
    getMode,
    getModes,
    setMode,
    editor: _editor,
    setEditor,
    error,
    isError,
    isFetching,
    isLoading,
    isSuccess,
    isUpdateError,
    isUpdating,
    isUpdateSuccess,
    update,
    updateError,
  }

  return <TemplateEditContext.Provider value={providerValue}>{children}</TemplateEditContext.Provider>
}
