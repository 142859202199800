import React, { useCallback } from 'react'
import TemplatePreviewForm from './TemplatePreviewForm'
import config from '@config'
import { useTemplatePreview } from '../hooks'
import { isEmpty } from 'lodash'
import { Typography } from 'antd'

import { pickWithJsonSchema, addItemsToCollection, deepClone } from '@utils'
import { useTemplate } from '@hooks/useTemplate'
const { Title, Link } = Typography

export default function ManualPreviewControls() {
  const { template, update } = useTemplate()
  const { createPreview } = useTemplatePreview()

  const isSchemaEmpty = isEmpty(template?.fieldSchema)

  const updateTestData = useCallback(
    (testData) => {
      const cleanedFormData = pickWithJsonSchema(addItemsToCollection(deepClone(template?.fieldSchema || {})), testData)
      return update({ testData: cleanedFormData })
    },
    [update, template.fieldSchema],
  )

  const doPreview = useCallback(
    (formData) => createPreview(template, formData || template.testData),
    [createPreview, template],
  )

  const handleSubmit = useCallback(
    async (formData) => {
      updateTestData(formData)
      doPreview(formData)
    },
    [updateTestData, doPreview],
  )

  return isSchemaEmpty ? (
    <div className='text-center pt-3'>
      <Title level={5}>We didn't find any Variables in your template.</Title>
      <Link href={`${config.documentationUrl}/templates/variables`} target='_blank'>
        Learn how to add Variables to your template
      </Link>
      <div className='px-4 pt-4'>
        <img src='/images/empty-states/generic-empty-state.svg' alt='' width='100%' />
      </div>
    </div>
  ) : (
    <TemplatePreviewForm template={template} onSubmit={handleSubmit} />
  )
}
