import { isNumber, toNumber } from 'lodash'

export const isValid = (value) => (value && value !== '' && value !== 'null' && value !== null ? true : false)

export const stringifyUnitValueObj = ({ value, unit } = {}) => `${value}${unit}`

export const parseUnitValue = (stringValue = '') => {
  let value = stringValue.replace(/(in|px|mm|cm|)/g, '')
  let unit = stringValue.replace(value, '')

  value = isValid(value) ? toNumber(value) : 0
  return { value, unit }
}

export const isValidUnitValue = (test) => {
  const unitRegex = /(in|px|mm|cm|)/g
  if (!test || typeof test !== 'string') return false
  const value = test.replace(unitRegex, '')
  const unit = test.match(unitRegex)
  return isNumber(value) && unit && unit !== ''
}

const utils = { isValidUnitValue, stringifyUnitValueObj, parseUnitValue, isValid }

export default utils
