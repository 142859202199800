import React, { useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import Loading from './Loading'
import { isUnchanged } from './../../utils/index'

import { useCurrentAccount, useGetProfile, useValidateSession } from '@features/auth'

function PrivateRoute({ component, layout: Layout, children, path, location, ...rest }) {
  const profile = useGetProfile()
  const account = useCurrentAccount()
  const [validate, { isValidating }] = useValidateSession()
  const history = useHistory()

  useEffect(() => {
    if (!profile && !isValidating) {
      validate().then((isValid) => {
        if (isValid === false) history.push({ pathname: '/login', state: { from: location } })
      })
    }
  }, [history, isValidating, validate, profile, location])

  if (account?.betaOptIn === true && window.location.hostname === 'app.documint.me') {
    window.location = 'https://beta.app.documint.me' + location.pathname
  } else if (account?.betaOptIn === false && window.location.hostname === 'beta.app.documint.me') {
    window.location = 'https://app.documint.me' + location.pathname
  }

  if (!profile) return <Loading />

  if (children) return <Route children={children} />

  return <Route {...rest} component={component} />
}
export default React.memo(PrivateRoute, (prev, curr) => isUnchanged(prev, curr, ['path']))
