import globalConfig from '@config'

export const config = {
  id: 'zapier',
  name: 'Zapier',
  description: 'Connect to thousands of applications without code.',
  icon: { type: 'image', value: '/images/delivery-methods/zapier-logo.svg' },

  overview: 'Zapier allows you to connect your application to Documint with clicks, not code.',

  documentationUrl: `${globalConfig.documentationUrl}/integrations/zapier`,
}

export default config
