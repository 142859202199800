import React from 'react'
import Button from '@common/Button'
import {useTemplateSettings } from '../hooks/useTemplateSettings'
import { FaIcon } from '@components/icons'

export default function TemplateSettingsButton(props) {
  const { toggleModal } = useTemplateSettings()

  return (
    <Button
      icon={<FaIcon icon="faGear" />}
      type='text'
      size='large'
      onClick={toggleModal}
      tooltip={{ title: 'Settings' }}
      {...props}
    />
  )
}
