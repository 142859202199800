import { Divider, Popconfirm } from 'antd'
import Button from '@common/Button'
import { MODES } from './consts'
import { FaIcon } from '@components/icons'
import { useTemplateDataSource } from '../../hooks'
import { useCallback } from 'react'
import { LinkOutlined } from '@ant-design/icons'

export default function Actions({ fieldSchema, onDownload, onSave, onCancel, onClear, mode, isSchemaValid, ...rest }) {
  const { selectedDataSource, reconnectDataSource } = useTemplateDataSource()

  const handleReconnect = useCallback(() => {
    reconnectDataSource(selectedDataSource)
  }, [reconnectDataSource, selectedDataSource])

  const reconnectMessage = (
    <>
      Are you sure you want to reconnect?
      <br /> Any fields created since disconnecting will be lost.
    </>
  )

  const isDisconnected = selectedDataSource?.disconnected

  return (
    <div {...rest}>
      {fieldSchema && (
        <>
          <Popconfirm
            title='Are you sure you want to clear the schema?'
            okText='Yes'
            cancelText='No'
            onConfirm={onClear}
            key='clear'
          >
            <Button type='ghost' icon={<FaIcon icon='faTrash' />}>
              Clear Schema
            </Button>
          </Popconfirm>

          <Button key='download' onClick={onDownload} icon={<FaIcon icon='faDownload' />}>
            Download
          </Button>

          <Divider key='divider' type='vertical' />
        </>
      )}

      {isDisconnected && (
        <>
          <Popconfirm okText='Yes, reconnect' title={reconnectMessage} onConfirm={handleReconnect}>
            <Button icon={<LinkOutlined />}>Reconnect</Button>
          </Popconfirm>
          <Divider type='vertical' />
        </>
      )}

      {/* Cancel Button */}
      {fieldSchema ? (
        <Button key='close' onClick={onCancel}>
          Close
        </Button>
      ) : (
        <Button key='cancel' onClick={onCancel}>
          Cancel
        </Button>
      )}

      {/* Save Button */}
      {(fieldSchema || mode === MODES.paste || mode === MODES.build) && (
        <Button key='save' type='primary' onClick={onSave} disabled={mode === MODES.paste ? !isSchemaValid : false}>
          Save
        </Button>
      )}
    </div>
  )
}
