export default function numberTraitType(editor, opts = {}) {
  const options = {
    name: 'better-number',
  }
  const { name } = options
  const tm = editor.Traits
  const num = tm.getType('number')

  tm.types[name] = num.extend({
    eventCapture: ['click .gjs-sm-clear'],

    init() {
      this.listenTo(this.model, 'change:value', this.updateResetEl)
      this.listenTo(
        this.model.target,
        'change:status',
        (_model, status) => status === 'selected' && this.updateResetEl()
      )
    },

    updateResetEl() {
      const v = this.model.get('value')
      const el = this.el?.querySelector('.gjs-label-wrp-2 .gjs-sm-clear')
      if (!el) return
      this.setVisible(el, v)
    },

    onEvent({ trait }) {
      this.setInputValue('auto')
      trait.set('value', 'auto')
    },

    getVisibility(value) {
      if (!value || value === 'auto') {
        return 'none'
      }
      return 'block'
    },

    setVisible(el, value) {
      el.style.display = this.getVisibility(value)
    },

    createLabel({ label }) {
      return `<div class="gjs-label-wrp-2">
        ${label}
        <div class="gjs-sm-clear" style="display: ${this.getVisibility(
          this.model.get('value')
        )}" data-clear-style=""></div>
      </div>`
    },
  })
}
