import { DatabaseOutlined } from '@ant-design/icons'

export const toJsonSchema = (schema) => schema

export const DefaultParser = { toJsonSchema }

export class DataSource {
  constructor() {
    this.id = 'default'
    this.name = 'Custom schema'
    this.label = 'Custom schema'
    this.icon = <DatabaseOutlined style={{ fontSize: 18 }} />
    this.fields = {}
    this.parser = DefaultParser
  }

  getLabel() {
    return 'Custom schema'
  }
}
