/**
 * @typedef Connection
 * @property {string} id
 * @property {string} name
 * @property {string} pieceName
 * @property {string} provider
 */
export class Connection {
  id = null
  name = null
  provider = null
  pieceName = null
  disconnected = false
  _dataSource = null

  constructor({ id, name, provider, disconnected, pieceName } = {}, dataSource) {
    this.id = id
    this.name = name
    this.provider = provider
    this.pieceName = pieceName
    this.disconnected = disconnected ?? false
    this._dataSource = dataSource
  }

  set values({ id, name, provider, pieceName } = {}) {
    this.id = id
    this.name = name
    this.provider = provider
    this.pieceName = pieceName
  }

  get isConnected() {
    return !this.disconnected
  }

  disconnect() {
    this.disconnected = true
  }

  reconnect() {
    this.disconnected = false
  }

  connect() {
    this.disconnected = false
  }

  toObject() {
    return {
      id: this.id,
      name: this.name,
      provider: this.provider,
      pieceName: this.pieceName,
      disconnected: this.disconnected,
    }
  }
}

export default Connection
