import React, { useEffect, useCallback, useState } from 'react'
import { Layout, Alert } from 'antd'

import { useDesigner, useEditor } from './hooks'

import {
  Editor,
  Toolbar,
  LeftSider,
  RightSider,
  HeaderFooterEditor,
  LogicModal,
  LegacyConvertModal,
  TokenModal,
  NewLogicModal,
} from './components'

import { deepClone, addItemsToCollection, parseTreeSchema } from '@utils/index'
import { useToggle, useBreakpoint } from '@hooks/helperHooks'
import { useTemplate } from '@hooks/useTemplate'
import { retrieveTempParent } from './utils'

import {
  getComponentsWithLogic,
  getTokens,
  refreshLogicComponents,
  refreshTokenComponents,
  refreshTokenMap,
  setContexts,
} from '@features/template-data-source/utils'
import Loading from '@components/common/Loading'

const { Content } = Layout

export default function Designer(props) {
  const { showHeaderFooter, toggleHeaderFooter } = useDesigner()
  const { editor, selectedComponent } = useEditor()
  const { template } = useTemplate()
  const [legacyEditLogic, legacyEditLogicToggle] = useToggle(false)
  const [editLogic, editLogicToggle] = useToggle(false)
  const [legacyConvert, legacyConvertToggle] = useToggle(false)
  const [editToken, editTokenToggle] = useToggle(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const breakpoint = useBreakpoint()
  const siderResProps = {
    xs: { width: 170 },
    sm: { width: 280 },
    md: { width: 280 },
    lg: { width: 300 },
    xl: { width: 370 },
    xxl: { width: 370 },
  }[breakpoint]

  const siderBaseProps = {
    theme: 'light',
    style: { height: '100%' },
    className: 'sider',
  }
  const siderProps = Object.assign(siderResProps, siderBaseProps)

  if (showHeaderFooter) siderProps.style.display = 'none'

  // Realigns hover outlines in GJS editor
  useEffect(() => {
    if (editor) editor.refreshCanvas()
  }, [breakpoint, editor])

  const handleEditorInit = useCallback(
    (editor) => {
      if (!editor) return
      editor.on('run:edit-logic', () => {
        const sel = editor.getSelected()
        if (!sel) return
        editLogicToggle(true)
      })
      editor.on('run:convert-legacy', () => {
        legacyConvertToggle(true)
      })
      editor.on('run:edit-token', (ed, opts = { key: 'key', type: 'string' }) => {
        editTokenToggle(true)
      })

      let targetElementId = 'rte-controls'
      let targetElement = document.getElementById(targetElementId)
      let toolbarDiv = editor.RichTextEditor.getToolbarEl()

      // Append the toolbar to the target element
      targetElement.appendChild(toolbarDiv)

      // Set the toolbar's position to absolute and adjust its location
      toolbarDiv.style.position = 'absolute'
      toolbarDiv.style.left = '0px'
      toolbarDiv.style.top = '0px'

      const editorComponents = editor?.Components?.getComponents?.()
      setContexts(editorComponents)

      setIsLoaded(true)
    },
    [editLogicToggle, editTokenToggle, legacyConvertToggle],
  )
  
  useEffect(() => {
    if (editor) {
      const um = editor.UndoManager
      const editorComponents = editor?.Components?.getComponents?.()
      const tokens = parseTreeSchema(addItemsToCollection(deepClone(template?.fieldSchema)))
      const logicComps = getComponentsWithLogic(editorComponents)
      const tokenComps = getTokens(editorComponents)
      um?.stop?.()
      editor.SchemaKeysRef = deepClone(editor.SchemaKeys || {})
      editor.SchemaKeys = {}
      editor.SchemaTypes = {}
      editor.SchemaIds = {}
      refreshTokenMap(tokens, editor)
      refreshLogicComponents(logicComps, template?.fieldSchema, retrieveTempParent, editor)
      refreshTokenComponents(tokenComps, template?.fieldSchema, retrieveTempParent, editor)
      setContexts(editorComponents)
      um?.start?.()
    }
  }, [template?.fieldSchema, editor])

  return (
    <Alert.ErrorBoundary message='Something went wrong.'>
      {!isLoaded && <Loading style={{height:'calc(100vh - 65px)'}} />}

      <Layout id='template-designer' {...props} style={{visibility: isLoaded ? 'visible': 'hidden'}}>
        <LeftSider {...siderProps} />

        <Content style={{ height: '100%' }}>
          <Toolbar className={showHeaderFooter ? 'd-none' : ''} />
          <Editor onInit={handleEditorInit} />
        </Content>
        
        <RightSider {...siderProps} />

        <HeaderFooterEditor open={showHeaderFooter} onClose={() => toggleHeaderFooter(false)} />

        {/* <RightPanel {...siderProps} /> */}

        <LogicModal
          selectedComponent={selectedComponent}
          visible={legacyEditLogic}
          onOk={() => legacyEditLogicToggle(false)}
          onCancel={() => legacyEditLogicToggle(false)}
        />

        <NewLogicModal
          selectedComponent={selectedComponent}
          visible={editLogic}
          onOk={() => editLogicToggle(false)}
          onCancel={() => editLogicToggle(false)}
        />

        <LegacyConvertModal
          selectedComponent={selectedComponent}
          visible={legacyConvert}
          onOk={() => legacyConvertToggle(false)}
          onCancel={() => legacyConvertToggle(false)}
        />

        <TokenModal visible={editToken} onOk={() => editTokenToggle(false)} onCancel={() => editTokenToggle(false)} />
      </Layout>
    </Alert.ErrorBoundary>
  )
}
