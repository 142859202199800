import { createContext, useEffect, useState } from 'react'
import { DefaultDataSource, getDataSource } from '../data-sources'
import { useTemplate } from '@hooks/useTemplate'

export const TemplateDataSourceContext = createContext()

export const TemplateDataSourceProvider = ({ children }) => {
  const { template = {} } = useTemplate()
  const [state, setState] = useState(() => ({
    editor: null,
    setEditor: (editor) => {
      if (editor !== state.editor) setState((state) => ({ ...state, editor }))
    },
    dataSource: null,
    setDataSource: (dataSource) => setState((state) => ({ ...state, dataSource })),
    selectedDataSource: null,
    setSelectedDataSource: (selectedDataSource) => setState((state) => ({ ...state, selectedDataSource })),
    fieldSchema: template?.fieldSchema,
    setFieldSchema: (fieldSchema) => setState((state) => ({ ...state, fieldSchema })),
    isModalOpen: false,
    toggleIsModalOpen: (value) => {
      if (value === undefined) return setState((state) => ({ ...state, isModalOpen: !state.isModalOpen }))
      if (typeof value === 'boolean') return setState((state) => ({ ...state, isModalOpen: value }))
    },
    isSyncing: false,
    setIsSyncing: (isSyncing) => setState((state) => ({ ...state, isSyncing })),
    defaultExpandedLinkedRecords: false,
    setDefaultExpandedLinkedRecords: (defaultExpandedLinkedRecords) => {
      setState((state) => ({ ...state, defaultExpandedLinkedRecords }))
    },
  }))

  useEffect(() => {
    if (template?.fieldSchema && !template?.dataSource && !state.dataSource) {
      // Convert existing schema to data source
      const dataSource = new DefaultDataSource()
      state.setDataSource(dataSource)
    } else if (template?.dataSource?.id && !state.dataSource) {
      const DataSource = getDataSource(template.dataSource.id)
      const dataSource = new DataSource(template.dataSource)
      state.setDataSource(dataSource)
    }
  }, [template, state])

  // Set fieldSchema from template if not already set
  useEffect(() => {
    if (!state.fieldSchema && template?.fieldSchema) state.setFieldSchema(template?.fieldSchema)
  }, [template, state])

  return <TemplateDataSourceContext.Provider value={state}>{children}</TemplateDataSourceContext.Provider>
}

export default TemplateDataSourceContext
