import dmBlocks from './blocks'
import dmCommands from './commands'
import dmStyleTypes from './styleTypes'
import dmComponentTypes from './componentTypes'
import dmTraitTypes from './traitTypes'

// import 'grapesjs-blocks-basic'
// import 'grapick/dist/grapick.min.css'
// import 'grapesjs/dist/css/grapes.min.css'

import gridSystem from '@documint/grapesjs-grid-system'
import propertyToggle from '@documint/grapesjs-property-toggle'
import checkbox from '@documint/grapesjs-component-checkbox'
import barcode from '@documint/grapesjs-component-barcode'
import qrcode from '@documint/grapesjs-component-qrcode'

import { MAX_GRID } from './componentTypes/consts'

const blocks = [
  (e) => gridSystem(e, { blocks: null, maxGrid: MAX_GRID }),
  (e) => propertyToggle(e),
  (e) => checkbox(e, { blocks: null }),
  (e) => barcode(e, { blocks: null, api: 'https://documint-code-generator-elkqclkaca-uk.a.run.app/api/v1/barcodes' }),
  (e) => qrcode(e, { blocks: null, api: 'https://documint-code-generator-elkqclkaca-uk.a.run.app/api/v1/qrcodes' }),
  (e) => dmStyleTypes(e),
  (e) => dmTraitTypes(e),
  (e) => dmComponentTypes(e),
  (e) => dmBlocks(e),
  (e) => dmCommands(e)
]

export default blocks

export const options = {}
