import React, { useState } from 'react'
import { Typography, Row, Col, Card, Form, Button, Divider } from 'antd'

import { useCheckoutContext, PaymentMethodForm, usePaymentMethodsContext } from '@features/subscription'
import CheckoutLineItems from './CheckoutLineItems'

import { formatNumber, formatDate } from '@utils'
import CheckoutHeader from './CheckoutHeader'

const { Title } = Typography

export default function CheckoutStepPayment() {
  const [form] = Form.useForm()
  const { subscription, selectedPlan, currentPlan, nextStep } = useCheckoutContext()
  const { hasPaymentMethods, isCreating } = usePaymentMethodsContext()
  const [paymentMethodFormIsComplete, setPaymentMethodFormIsComplete] = useState(false)
  const { name, documentMerges, price } = selectedPlan
  const items = [{ name: `${name} Plan`, description: `${documentMerges} merges/mo.`, price }]

  const formatPrice = (price) => `${formatNumber(price, '$0,0.00')} USD`

  const isDowngrade = selectedPlan.price < currentPlan.price

  const handleCompleteCheckout = () => {
    if (hasPaymentMethods) return nextStep()
    form.submit()
  }

  const handlePaymentMethodFormChange = (values) => {
    const { complete } = values
    setPaymentMethodFormIsComplete(complete)
  }

  const handleSuccess = (data) => {
    nextStep()
  }

  return (
    <Card className='mb-4' styles={{ body: { padding: '2em 2em 4em' } }} style={{ borderRadius: '1em' }}>
      <CheckoutHeader title={`Subscribe to ${name}`} style={{ marginBottom: '2em' }} />

      <Row justify='center'>
        {!hasPaymentMethods && (
          <Col lg={14} xs={24} style={{ padding: '0 2.5em' }}>
            <Title level={4} style={{ fontWeight: '500', marginBottom: '.5em' }}>
              Add your payment information
            </Title>

            <PaymentMethodForm
              onSuccess={handleSuccess}
              onChange={handlePaymentMethodFormChange}
              form={form}
              submitButton={false}
            />
          </Col>
        )}

        <Col
          lg={10}
          xs={24}
          style={{ padding: '0 2.5em', display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}
        >
          <div>
            <Title level={4} style={{ fontWeight: '500' }}>
              Review your subscription
            </Title>

            <Divider />

            <CheckoutLineItems items={items} />

            <Divider />
          </div>

          <div>
            {/* TODO: UPDATE FOR PAID TO PAID */}
            <Title
              level={4}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: '1em',
                fontSize: '1.6em',
                fontWeight: 500,
              }}
            >
              <span>To pay now</span>
              <span>{formatPrice(currentPlan.price > 0 ? 0 : selectedPlan.price)}</span>
            </Title>

            {currentPlan.price > 0 && (
              <p style={{ marginBottom: '1em', textAlign: 'center' }}>
                This change will take effect on {formatDate(subscription.currentPeriodEnd, 'MM/dd/yyyy')}
              </p>
            )}

            <Button
              type='primary'
              onClick={handleCompleteCheckout}
              block
              size='large'
              style={{ padding: '.75em', height: 'auto' }}
              disabled={!hasPaymentMethods && !paymentMethodFormIsComplete}
              loading={!hasPaymentMethods && isCreating}
            >
              {isDowngrade ? 'Downgrade' : 'Upgrade'} to {selectedPlan.name} Plan
            </Button>

            <div style={{ fontSize: '10px', color: '#999', textAlign: 'center', lineHeight: '1.4', padding: '1.5em' }}>
              By continuing, you agree to Documint's{' '}
              <a href='https://documint.me/terms-of-service' target='_blank' rel='noreferrer noopener'>
                terms
              </a>{' '}
              and{' '}
              <a href='https://documint.me/privacy-policy' target='_blank' rel='noreferrer noopener'>
                privacy policy
              </a>
              . You'll be charged {formatPrice(selectedPlan.price)} every month until you cancel your subscription.
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

CheckoutStepPayment.defaultProps = {
  style: {},
}
