import Wrapper, { protectedCss as wrapperComponentCss } from './Wrapper'
import layoutComponents, { protectedCss as layoutComponentsCss } from './layout'
import contentComponents, { protectedCss as contentComponentsCss } from './content'
import deprecatedComponents, { protectedCss as deprecatedComponentsCss } from './_deprecated'
import Template from './Template'
import dynamicComponents from './dynamic'
import postProcessing from './postProcessing'

export const protectedCss = `
  /* 
   * GLOBAL COMPONENTS
   **************************/
  *[data-gjs-type^=dm-]{
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    padding-top:0;
    padding-right:0;
    padding-bottom:0;
    padding-left:0;
  }
  ${wrapperComponentCss}
  ${layoutComponentsCss}
  ${contentComponentsCss}
  ${deprecatedComponentsCss}
  `

export const componentTypes = ({ editor }) => {
  const { Components } = editor

  const opts = {
    defaultModel: Components.getType('default').model,
    editor,
    api: 'https://documint-code-generator-elkqclkaca-uk.a.run.app/api/v1',
  }
  const allComponents = [
    Wrapper,
    Template,
    ...dynamicComponents,
    ...deprecatedComponents,
    ...layoutComponents,
    ...contentComponents,
  ]

  allComponents.forEach((c) => c(Components, opts))

  postProcessing(editor)
}

export default componentTypes
