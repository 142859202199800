import React, { useState } from 'react'
import { ConnectionListContainer, ConnectorCard } from './index.style'
import { Card } from 'antd'
import { useToggle } from '@hooks/helperHooks'
import DataSourceIcon from './DataSourceIcon'
import { DataSourceInstructionsModal } from '.'

export default function DataSourceInstructionList({ dataSources }) {
  const [showModal, toggleModal] = useToggle(false)
  const [selectedDataSource, setSelectedDataSource] = useState(null)

  return (
    <>
      <ConnectionListContainer>
        {dataSources.map((dataSource) => (
          <ConnectorCard
            hoverable
            styles={{
              body: {
                justifyContent: 'space-between',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              },
            }}
            key={dataSource?.id}
            id={dataSource?.id}
            onClick={() => {
              setSelectedDataSource(dataSource)
              toggleModal(true)
            }}
          >
            <div
              style={{
                flex: '1 1 auto',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                padding: '0 1em',
              }}
            >
              <DataSourceIcon icon={dataSource.icon} />
            </div>
            <Card.Meta title={dataSource.name} style={{ flex: '0 0 auto', marginTop: '25px', textAlign: 'center' }} />
          </ConnectorCard>
        ))}
      </ConnectionListContainer>

      <DataSourceInstructionsModal
        dataSource={selectedDataSource}
        open={showModal}
        onCancel={() => {
          setSelectedDataSource(null)
          toggleModal(false)
        }}
      />
    </>
  )
}
