import Text, { protectedCss as textComponentCss } from './Text'
import Image, { protectedCss as imageComponentCss } from './Image'
import VerticalSpacer, { protectedCss as verticalSpacerComponentCss } from './VerticalSpacer'
import PageBreak, { protectedCss as pageBreakComponentCss } from './PageBreak'
import Checkbox, { protectedCss as checkboxCss } from './Checkbox'
import Button, { protectedCss as buttonComponentCss } from './Button'
import QR, { protectedCss as qrComponentCss } from './QR'
import Barcode, { protectedCss as barcodeComponentCss } from './Barcode'
import Link, { protectedCss as linkComponentCss } from './Link'
import Token from './Token'

export const protectedCss = `
  /* 
   * CONTENT COMPONENTS 
   **************************/
  ${textComponentCss}
  ${imageComponentCss}
  ${verticalSpacerComponentCss}
  ${pageBreakComponentCss}
  ${checkboxCss}
  ${buttonComponentCss}
  ${qrComponentCss}
  ${barcodeComponentCss}
  ${linkComponentCss}
`

const allContentTypes = [Text, Image, VerticalSpacer, PageBreak, Link, Checkbox, Button, QR, Barcode, Token]

export default allContentTypes
