import { DisconnectOutlined } from '@ant-design/icons'
import Button from '@components/common/Button'
import { FaIcon } from '@components/icons'
import { Divider, Popconfirm } from 'antd'
import React from 'react'

export default function Actions({
  dataSource,
  onDisconnect,
  onSync,
  onCancel,
  onSubmit,
  isSyncing,
  entityConfigFieldsAreComplete,
}) {
  const disconnectMessage = (
    <div style={{ maxWidth: '250px' }}>
      Are you sure you want to disconnect from {dataSource?.name}?<br /><br/>
      This will allow you to edit the schema however field changes in {dataSource?.name} will no longer be synced.
    </div>
  )
  return (
    <>
      {dataSource?.isConnected && dataSource?.entity?.value && (
        <>
          <Popconfirm
            key='disconnect'
            title={disconnectMessage}
            okText='Yes, disconnect'
            onConfirm={onDisconnect}
            
          >
            <Button icon={<DisconnectOutlined />} disabled={isSyncing}>
              Disconnect
            </Button>
          </Popconfirm>

          <Button key='sync' icon={<FaIcon icon='faSync' />} onClick={onSync} loading={isSyncing} tooltip={{title:`Sync schema with ${dataSource?.name || 'data source'}`}}>
            Sync
          </Button>

          <Divider key='div' type='vertical' />
        </>
      )}

      <Button key='cancel' onClick={onCancel} disabled={isSyncing}>
        Cancel
      </Button>

      <Button key='submit' type='primary' onClick={onSubmit} disabled={!entityConfigFieldsAreComplete}>
        {dataSource?.isConnected ? 'Save' : 'Connect'}
      </Button>
    </>
  )
}
