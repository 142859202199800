export const keywords = {
  additionalItems: true,
  items: true,
  contains: true,
  additionalProperties: true,
  propertyNames: true,
  not: true,
  if: true,
  then: true,
  else: true,
}

export const arrayKeywords = {
  items: true,
  allOf: true,
  anyOf: true,
  oneOf: true,
}

export const propsKeywords = {
  $defs: true,
  definitions: true,
  properties: true,
  patternProperties: true,
  dependencies: true,
}

export const skipKeywords = {
  default: true,
  enum: true,
  const: true,
  required: true,
  maximum: true,
  minimum: true,
  exclusiveMaximum: true,
  exclusiveMinimum: true,
  multipleOf: true,
  maxLength: true,
  minLength: true,
  pattern: true,
  format: true,
  maxItems: true,
  minItems: true,
  uniqueItems: true,
  maxProperties: true,
  minProperties: true,
}

// Rules:
// 1. Only recognize items with id or cid
// 2. Only recognize properties with type
// if (keyIndex && (keyIndex !== 'properties' || (keyIndex === 'properties' && schema.type))) {
//   buildPath += `${keyIndex}/`
// } else if (keyIndex && (keyIndex !== 'items' || (keyIndex === 'items' && (schema.cid || schema.uuid)))) {
//   buildPath += `${keyIndex}/`
// }
function _traverse(opts, pre, post, schema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex) {
  if (schema && typeof schema == 'object' && !Array.isArray(schema)) {
    pre(schema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex)
    for (let key in schema) {
      const sch = schema[key]
      let path = jsonPtr
      // if (key === 'properties' && sch.type) {
      //   path += `/${key}`
      // } else if (key === 'items' && (sch.cid || sch.uuid)) {
      //   path += `/${key}`
      // }
      if (Array.isArray(sch)) {
        if (key in arrayKeywords) {
          for (let i = 0; i < sch.length; i++)
            _traverse(opts, pre, post, sch[i], path + '/' + i, rootSchema, jsonPtr, key, schema, i)
        }
      } else if (key in propsKeywords) {
        if (sch && typeof sch == 'object') {
          for (let prop in sch)
            _traverse(
              opts,
              pre,
              post,
              sch[prop],
              path + '/' + escapeJsonPtr(prop),
              rootSchema,
              jsonPtr,
              key,
              schema,
              prop,
            )
        }
      } else if (key in keywords || (opts.allKeys && !(key in skipKeywords))) {
        _traverse(opts, pre, post, sch, path, rootSchema, jsonPtr, key, schema)
      }
    }
    post(schema, jsonPtr, rootSchema, parentJsonPtr, parentKeyword, parentSchema, keyIndex)
  }
}

function escapeJsonPtr(str) {
  return str.replace(/~/g, '~0').replace(/\//g, '~1')
}

export const traverse = function (schema, opts, cb) {
  if (typeof opts == 'function') {
    cb = opts
    opts = {}
  }

  cb = opts.cb || cb
  const pre = typeof cb == 'function' ? cb : cb.pre || function () {}
  const post = cb.post || function () {}

  _traverse(opts, pre, post, schema, '', schema)
}

export default traverse
