import React, { useEffect, useRef } from 'react'
import { useTemplateDataSource } from '../../hooks'
import UndoRedoSchema from '@common/UndoRedo'
import { updateSchema } from '@reducers/schema'
import { JSONSchemaBuilder } from '@documint/react-antd-json-schema-builder'

export default function SchemaBuilder({ onChange }) {
  const initializedRef = useRef(false)
  const { schemaInRedux, dispatch, refreshSchema } = useTemplateDataSource()

  useEffect(() => {
    if (!initializedRef.current) {
      refreshSchema()
      initializedRef.current = true
    }
  }, [refreshSchema])

  return (
    <div style={{ minHeight: '500px' }}>
      <JSONSchemaBuilder
        data={schemaInRedux}
        onChange={onChange}
        undoRedo={<UndoRedoSchema reducer='schema' />}
        dispatch={dispatch}
        updateSchema={updateSchema}
      />
    </div>
  )
}
