const labelSector = {
  id: 'label', //! IMPORTANT
  name: 'Label',
  open: true,
  properties: [
    {
      name: 'Position',
      id: 'checkbox-text-position',
      property: 'checkbox-text-position',
      type: 'checkbox-text-position',
      full: true,
    },
    {
      name: 'Alignment',
      id: 'checkbox-alignment', //!IMPORTANT
      property: 'checkbox-alignment',
      type: 'checkbox-alignment',
      full: true,
    },
  ],
}

export default labelSector
