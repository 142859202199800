import React, { useState } from 'react'

import { Typography, Tabs, Collapse, Button } from 'antd'
import CodeBlock from '@common/CodeBlock'
import globalConfig from '@config'

import { NewApiAppModal } from '@features/apiApps'
import { PlusOutlined } from '@ant-design/icons'
import { DataSourceInstructionsOptionsForm } from '../../components'
import { useTemplate } from '@hooks/useTemplate'

const { Text, Paragraph } = Typography

export default function RestApiInstructions() {
  const { template } = useTemplate()
  const [params, setParams] = useState(null)
  const [apiAppModalIsVisible, setApiAppModalIsVisible] = useState(false)
  const { testData = {} } = template

  const codeBlockProps = {
    showLineNumbers: true,
  }

  const handleOptionsChange = (values) => {
    setParams(values)
  }
  const showModal = () => setApiAppModalIsVisible(true)
  const hideModal = () => setApiAppModalIsVisible(false)

  return (
    <>
      <Paragraph>
        Below are working examples of creating documents via REST API with <strong>JavaScript</strong>,{' '}
        <strong>jQuery</strong>, or <strong>cURL</strong>
      </Paragraph>

      <Collapse className='mb-3' bordered={false}>
        <Collapse.Panel header='Authentication' key='1'>
          You must set the <Text code>api_key</Text> header with an API Key.{' '}
          <Button onClick={showModal} icon={<PlusOutlined />}>
            New API key
          </Button>
        </Collapse.Panel>
        <Collapse.Panel header='Options' key='2'>
          <DataSourceInstructionsOptionsForm onChange={handleOptionsChange} />
        </Collapse.Panel>
      </Collapse>

      <Tabs
        defaultActiveKey='javascript'
        type='card'
        tabBarStyle={{ marginBottom: 0 }}
        items={[
          {
            label: 'Javascript',
            key: 'javascript',
            children: (
              <CodeBlock
                language='javascript'
                {...codeBlockProps}
                code={createJavascript(template, testData, params)}
              />
            ),
          },
          {
            label: 'JQuery',
            key: 'jquery',
            children: (
              <CodeBlock language='javascript' code={createJquery(template, testData, params)} {...codeBlockProps} />
            ),
          },
          {
            label: 'cURL',
            key: 'curl',
            children: <CodeBlock language='bash' code={createCurl(template, testData, params)} {...codeBlockProps} />,
          },
        ]}
      />

      <Text>
        Check out the{' '}
        <a href={globalConfig.apiReferenceUrl} target='_blank' rel='noopener noreferrer'>
          API Reference
        </a>{' '}
        for more details.
      </Text>
      <NewApiAppModal open={apiAppModalIsVisible} onCancel={hideModal} onFinish={hideModal} />
    </>
  )
}

function toQs(obj) {
  var str = []
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(p + '=' + obj[p])
    }
  return str.join('&')
}
function createJavascript(template, data, params) {
  const json = JSON.stringify(data, null, 2)
  const qs = toQs(params)
  return `const body = ${json};
const requestOptions = {
  method: 'POST',
  headers: {
    'api_key': '[your_api_key]',
    'Content-Type':'application/json'
  },
  body: body,
  redirect: 'follow'
};

fetch("https://api.documint.me/1/templates/${template.id}/content${qs ? '?' + qs : ''}", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
}

function createJquery(template, data, params) {
  const json = JSON.stringify(data, null, 2)
  const qs = toQs(params)
  return `const settings = {
  url: 'https://api.documint.me/1/templates/${template.id}/content${qs ? '?' + qs : ''}',
  method: 'POST',
  timeout: 0,
  headers: {
    api_key: '[your_api_key]',
    'Content-Type':'application/json'
  },
  data: ${json},
};

$.ajax(settings).done(function (response) {
  console.log(response);
});`
}

function createCurl(template, data, params) {
  const json = JSON.stringify(data)
  const qs = toQs(params)
  return `curl --location --request POST 'https://api.documint.me/1/templates/${template.id}/content${
    qs ? '?' + qs : ''
  }' \\
  --header 'api_key: [your_api_key]' \\
  --header 'Content-Type: application/json' \\
  --data-raw '${json}'`
}
