import React from 'react'
import { Form, Select, Typography, Switch, Input } from 'antd'
import { forIn, merge, mapValues, isNumber } from 'lodash'
import { formats, renderEngineVersions } from '../options'
import utils from '../utils'
import InputUnit from '@common/InputUnit'

const { Text, Title } = Typography

export default function TemplateSettingsForm({ data, onChange }) {
  const [form] = Form.useForm()
  const { documentNamePattern, options: { margin, format, isLandscape, width, height } = {}, renderEngine } = data || {}

  const getFormatDimensions = (name) =>
    formats.find(({ value }) => value === name) || { height: '8.5in', width: '11in' }
  const formatDim = getFormatDimensions(format)
  const initialWidth = width || formatDim.width
  const initialHeight = height || formatDim.height

  const initialValues = {
    documentNamePattern,
    margin: mapValues(margin, utils.parseUnitValue),
    format,
    width: utils.parseUnitValue(initialWidth || '8.5in'),
    height: utils.parseUnitValue(initialHeight || '11in'),
    isLandscape,
    renderEngine,
  }

  function handleFieldChange(changedValues, allValues) {
    const { format, margin, width, height, isLandscape, documentNamePattern, renderEngine } = allValues

    // Check to make sure margins have proper values
    forIn(margin, ({ value, unit } = {}, key) => {
      if (!isNumber(value)) form.setFieldsValue({ margin: { ...margin, [key]: { value: 0, unit } } })
      if (!utils.isValid(unit)) form.setFieldsValue({ margin: { ...margin, [key]: { value, unit: 'in' } } })
    })

    const updatedOptions = {
      margin: mapValues(margin, utils.stringifyUnitValueObj),
      width: utils.stringifyUnitValueObj(width),
      height: utils.stringifyUnitValueObj(height),
      isLandscape,
    }

    if (changedValues.format == null && data.options.format) {
      const prevFormatDims = getFormatDimensions(data.options.format)
      if (!utils.isValidUnitValue(updatedOptions.width)) updatedOptions.width = prevFormatDims.width
      if (!utils.isValidUnitValue(updatedOptions.height)) updatedOptions.height = prevFormatDims.height

      form.setFieldsValue({
        width: utils.parseUnitValue(updatedOptions.width),
        height: utils.parseUnitValue(updatedOptions.height),
      })
    }

    const update = { options: merge({}, data.options, updatedOptions) }
    // Add format after because it can have a null
    // value which would be overwritten by merge
    update.options.format = format

    update.documentNamePattern = documentNamePattern

    update.renderEngine = Number(renderEngine)

    onChange(update)
  }

  const formItemProps = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: 'left',
  }

  const isCustomFormat = data?.options?.format === null

  return (
    <Form
      layout='horizontal'
      form={form}
      onValuesChange={handleFieldChange}
      initialValues={initialValues}
      {...formItemProps}
      colon={false}
    >
      <Title level={5}>Document</Title>

      <Form.Item name={['documentNamePattern']} label='Name'>
        <Input placeholder='Document name pattern...' />
      </Form.Item>

      <Form.Item name={['renderEngine']} label='Render Engine'>
        <Select options={renderEngineVersions} />
      </Form.Item>

      <Title level={5}>Page</Title>

      <Form.Item label='Format' name={['format']}>
        <Select options={formats} />
      </Form.Item>

      {isCustomFormat && (
        <>
          <InputUnit label='Width' name={['width']} />
          <InputUnit label='Height' name={['height']} />
        </>
      )}

      <Form.Item name={['isLandscape']} label='Landscape' valuePropName='checked'>
        <Switch />
      </Form.Item>

      <Text strong>Margins</Text>
      <InputUnit label='Top' name={['margin', 'top']} />
      <InputUnit label='Left' name={['margin', 'left']} />
      <InputUnit label='Right' name={['margin', 'right']} />
      <InputUnit label='Bottom' name={['margin', 'bottom']} />
    </Form>
  )
}
