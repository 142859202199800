import styled, { css } from 'styled-components'
import { Typography, Card } from 'antd'
import { FaIcon } from '@components/icons'
import CodeEditor from 'react-simple-code-editor'

const { Title } = Typography

const baseContainerStyles = css`
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #d3ddf2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ConnectionContainer = styled.div`
  ${baseContainerStyles}
`

export const ConnectionContainerButton = styled.div`
  ${baseContainerStyles}
  gap: 20px;
  cursor: pointer;

  &:hover {
    border: 1px solid #009bff;
  }
`

export const ConnectionTitle = styled(Title)`
  &.ant-typography {
    font-size: 15px;
    margin-bottom: 0;
  }
`

export const ConnectionSubTitle = styled.div`
  font-size: 12.5px;
  line-height: 20px;
  color: #626c7e;
  font-weight: 400;
`

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => props.$flexDirection ?? 'row'};

  gap: ${(props) => props.$gap ?? '5px'};

  ${(props) =>
    props.$alignItems &&
    css`
      align-items: ${props.$alignItems};
    `}

  ${(props) =>
    props.$justifyContent &&
    css`
      justify-content: ${props.$justifyContent};
    `}

  width: ${(props) => props.$width ?? 'auto'};

  flex-grow: ${(props) => props.$grow ?? 0};

  flex-shrink: ${(props) => props.$shrink ?? 0};

  flex-basis: ${(props) => props.$basis ?? 'auto'};

  button {
    text-align: left;
  }

  button svg {
    color: #009bff;
  }

  button .anticon-delete svg {
    color: #f40;
  }

  button .anticon-disconnect svg {
    color: #da810c;
  }

  .ant-dropdown-trigger:hover {
    background: transparent;
  }
`
export const StyledConnectionCard = styled(Card)`
  display: flex;
  flex-direction: row;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid #d3ddf2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: all 0.3s;

  ${(props) =>
    !props.$readonly &&
    `
    &:hover {
      border-color: #009bff;
      cursor: pointer;
    }
    
    &:hover .data-source-card__text * {
      color: #009bff!important;
    }  
    `}

  & * {
    transition: color 0.3s !important;
  }
`

export const ConnectionCardImg = styled.img`
  width: auto;
  height: 35px;
  display: block;
`

export const ConnectionListContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
`

export const ConnectorCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background-color: #f9f9f9;
  }
`

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  padding: 3rem 2rem;
`

export const Icon = styled(FaIcon)`
  font-size: 75px;
  margin-top: 0.25em;
  padding: 20px;
`

export const Editor = styled(CodeEditor)`
  min-height: 300px;
  background-color: #fff;
  border: solid 1px #d9d9d9;
  border-radius: 5px;
`
