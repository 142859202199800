import React, { useState } from 'react'
import { Form, Input, Button, Space, Alert } from 'antd'
import { Link } from 'react-router-dom'

import { useRecoverPassword } from '../hooks'

export default function RecoverPasswordForm({ onSubmit, onChange, ...rest }) {
  const [recover, { isRecovering }] = useRecoverPassword()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const validateMessages = {
    required: 'This is required',
    types: { email: 'Please enter a valid email address' },
  }

  async function handleSubmit(values) {
    try {
      await recover({ email })
      if (onSubmit) onSubmit({ email })
      onSubmit(values)
    } catch (error) {
      setError(error?.data?.message || error?.message)
    }
  }

  function handleChange({ currentTarget: input }) {
    setEmail((prev) => input.value)
  }

  return (
    <Form
      validateMessages={validateMessages}
      className='mb-2'
      onFinish={handleSubmit}
      onChange={onChange}
      validateTrigger={['onSubmit']}
      {...rest}
    >
      {error && <Alert type='error' message={error || 'Something went wrong'} className='mb-3' />}

      {/* EMAIL ADDRESS */}
      <Form.Item name={['user', 'email']} rules={[{ type: 'email' }]}>
        <Input value={email} size='large' placeholder='Email address' className='w-100' onChange={handleChange} />
      </Form.Item>

      {/* BUTTONS */}
      <Space direction='vertical' size='middle' className='d-flex justify-content-center text-center'>
        <Button type='primary' block={true} size='large' htmlType='submit' loading={isRecovering}>
          email me a password reset link
        </Button>
        <Link to='/login'>return to login</Link>
      </Space>
    </Form>
  )
}
