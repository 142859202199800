import _ from 'lodash'
import { format as dateFormat } from 'date-fns'
import numeral from 'numeral'
import traverse from './traverse'
import { deepClone } from './'

export function addIdsToSchema(schema) {
  let newSchema = { properties: {} }

  traverse(schema, (schema, _parent, _root, _parentJSONParent, _parentKeyword, _parentSchema, keyIndex) => {
    if (!keyIndex) return

    newSchema['properties'][keyIndex] = {
      id: `${keyIndex}_${Date.now()}`,
      ...schema,
    }
  })

  return newSchema
}

export function convertTypes(schema, dates = true) {
  traverse(schema, (schema, _parent, _root, _parentJSONParent, _parentKeyword, parentSchema, keyIndex) => {
    if (!keyIndex) return

    const { type } = schema
    parentSchema['properties'][keyIndex] = {
      ...schema,
      ...{
        type: type === 'currency' || type === 'percent' ? 'number' : type === 'date' && dates ? Date : type,
      },
    }
    if (type !== 'array') {
      try {
        delete parentSchema['properties'][keyIndex].items
      } catch (error) {
        // no items
      }
    } else {
      const itemsType = parentSchema['properties'][keyIndex].items?.type
      if (itemsType) {
        parentSchema['properties'][keyIndex].items.type =
          itemsType === 'currency' || itemsType === 'percent'
            ? 'number'
            : itemsType === 'date' && dates
            ? Date
            : itemsType
      }
    }
  })

  return schema
}

function addItemsToProperty(property) {
  try {
    const properties = deepClone(property.properties)
    delete property.properties
    property.type = 'array'
    property.items = {
      type: 'object',
      properties,
    }
  } catch (error) {
    // no items
  }
}

function traverseAndAddItemsToCollection(property) {
  if (property.items?.type === 'collection') {
    try {
      const properties = deepClone(property.items.properties)
      delete property.items.properties
      property.items.type = 'array'
      property.items.items = {
        type: 'object',
        properties,
      }
    } catch (error) {
      // no items
    }
  } else if (property.items) {
    traverseAndAddItemsToCollection(property.items)
  }
}

export function addItemsToCollection(schema) {
  traverse(schema, (schema, _parent, _root, _parentJSONParent, _parentKeyword, parentSchema, keyIndex) => {
    if (!keyIndex) return

    if (schema.type !== 'array') {
      try {
        delete parentSchema['properties'][keyIndex].items
      } catch (error) {
        // no items
      }
    }

    if (schema.type === 'array') traverseAndAddItemsToCollection(parentSchema['properties'][keyIndex])
    else if (schema.type === 'collection') addItemsToProperty(parentSchema['properties'][keyIndex])
  })

  return schema
}

function traverseAndRemoveItemsToCollection(property) {
  if (property.items?.type === 'object') {
    try {
      const properties = deepClone(property.items.properties)
      delete property.items
      property.type = 'collection'
      property.properties = properties
    } catch (error) {
      // no items
    }
  } else if (property.items?.type === 'array') {
    traverseAndRemoveItemsToCollection(property.items)
  }
}

export function removeItemsFromCollection(schema) {
  traverse(schema, (schema, _parent, _root, _parentJSONParent, _parentKeyword, parentSchema, keyIndex) => {
    if (!keyIndex) return

    if (schema.type === 'array') traverseAndRemoveItemsToCollection(parentSchema['properties'][keyIndex])
  })

  return schema
}

export function crossRef(key, editor) {
  const ref = editor.SchemaKeysRef
  if (!key) return ''
  return Object.keys(ref).find((k) => ref[k].formattedKey === key) || ''
}

export function getLastKey(key) {
  return key.split('.').pop()
}

export function crossRefKey(key, editor) {
  const ref = editor.SchemaKeysRef
  if (!key) return ''
  return Object.keys(ref).find((k) => ref[k].key === key) || ''
}

export function getUpdatedField(map, fromKey, updatedKey, editor, uuid) {
  const key = map.length === 2 ? fromKey.replace(map[0], map[1]) : updatedKey
  const updatedLastKey = editor.SchemaKeys && editor.SchemaKeys[uuid || crossRefKey(fromKey, editor)]
  return map.length === 2 && updatedLastKey?.key ? key.replace(getLastKey(key), getLastKey(updatedLastKey.key)) : key
}

export function updateSortKeys(sorts, editor) {
  return sorts.map(({ order, field }) => {
    const newField = editor.SchemaKeys && editor.SchemaKeys[crossRef(field, editor)]
    return { order, field: newField ? newField.formattedKey : field }
  })
}

export function updateVariableNames(name, editor) {
  const newField = editor.SchemaKeys && editor.SchemaKeys[crossRef(name, editor)]
  return newField ? newField.formattedKey : name
}

export const deepObject = (obj) => JSON.stringify(addItemsToCollection(deepClone(obj)))

export function updateQueryTreeKeys(tree, editor, metadata, map = [], list = '') {
  if (!editor) return tree

  metadata = metadata || {}

  return Object.keys(tree).map((item) => {
    const treeItem = tree[item]

    const { uuid } = treeItem.properties
    const updateProperty = editor.SchemaKeys && editor.SchemaKeys[uuid]
    let fieldId = {}
    let field = {}
    let meta = {}

    const fromKeyId = treeItem.properties.fieldId || treeItem.properties.field
    const updatedKeyId = updateProperty?.key ? getLastKey(updateProperty.key) : fromKeyId
    const keyId = getUpdatedField(map, fromKeyId, updatedKeyId, editor, uuid)

    const fromKey = treeItem.properties.field
    const updatedKey = updateProperty?.key ? updateProperty.key : fromKey
    const key = getUpdatedField(map, fromKey, updatedKey, editor, uuid)

    fieldId = { fieldId: keyId }
    field = { field: list && key && key.startsWith(list) ? key.replace(`${list}.`, '') : key }
    meta = metadata[key]

    if (treeItem.children1 && treeItem.children1.length) {
      treeItem.children1 = updateQueryTreeKeys(deepClone(treeItem.children1), editor, metadata, [fromKey, key])
    }

    return {
      ...treeItem,
      ...field,
      ...fieldId,
      properties: {
        ...treeItem.properties,
        ...meta,
        ...field,
      },
    }
  }, {})
}

/**
 * Gets the difference between two objects
 * @param {Object} a first object
 * @param {Object} b second object
 * @param {String[]} [paths] list of paths to check
 * @returns
 */
export function getDifferences(a, b, paths) {
  if (!a || !b || typeof a !== 'object' || typeof b !== 'object') return

  if (paths) {
    a = _.pick(a, paths)
    b = _.pick(b, paths)
  }

  if (Object.keys(a).length <= 0) return paths || Object.keys(b)

  return Object.entries(a).reduce((acc, [key, value]) => {
    let bVal = b[key]
    if (!bVal) {
      acc.push(key)
      return acc
    }

    if (typeof bVal === 'object') bVal = JSON.stringify(bVal)
    const aVal = typeof value === 'object' ? JSON.stringify(value) : value
    if (aVal !== bVal) acc.push(key)

    return acc
  }, [])
}

/**
 * Returns an object with only the changes
 * @param {Object} originalObject
 * @param {Object} newObject
 * @param {String[]} [paths] specific paths to compare
 * @returns {Object} changed values
 */
export function getObjectChanges(originalObject, newObject, paths) {
  if (!originalObject || !newObject || typeof originalObject !== 'object' || typeof newObject !== 'object') return

  if (paths) {
    originalObject = _.pick(originalObject, paths)
    newObject = _.pick(newObject, paths)
  }

  if (Object.keys(originalObject).length <= 0) return paths || Object.keys(newObject)

  return Object.entries(newObject).reduce((acc, [key, value]) => {
    const originalValue = originalObject[key]
    const newValue = newObject[key]

    // If the new key doesn't exist in the original object
    // add it to the result and exit
    if (!originalValue) {
      acc[key] = newValue
      return acc
    }

    let newCompareValue = typeof newValue === 'object' ? JSON.stringify(newValue) : newValue
    let originalCompareValue = typeof originalValue === 'object' ? JSON.stringify(originalValue) : originalValue

    if (newCompareValue !== originalCompareValue) acc[key] = newValue
    return acc
  }, {})
}

// check if the passed value is a number
export function isNumber(value) {
  return typeof value === 'number' && !isNaN(value)
}

/**
 * Gets all of the paths of an object including nested paths
 * @param {Object} object
 * @param {String} [prevPath]
 * @returns
 */
export function getPaths(object, prevPath) {
  if (!object || (object instanceof Object && Object.keys(object).length === 0)) return

  if (typeof object === 'string') {
    try {
      object = JSON.parse(object)
    } catch (error) {
      return []
    }
  }

  const paths = []
  const keys = Object.keys(object)

  keys.forEach((key) => {
    const path = prevPath ? `${prevPath}.${key}` : key
    const prop = object[key]

    if (Array.isArray(prop)) return

    if (prop instanceof Object) {
      const childPaths = getPaths(prop, path)
      if (childPaths) paths.push(...childPaths)
    } else {
      paths.push(path)
    }
  })

  return paths
}

/**
 * Checks if object exists in a collection
 * @param {Object} obj object to search for
 * @param {Object[]} collection collection to search in
 * @returns Boolean
 */
export function existsInCollection(obj, collection) {
  if (!Array.isArray(collection)) throw new TypeError('Invalid argument. Must be of type Array')
  return !collection.every((item) => !_.isEqual(obj, item))
}

/**
 * Removes duplicate objects from collection
 * @param {Object[]} collection
 */
export function uniqueCollection(collection) {
  if (!Array.isArray(collection)) throw new TypeError('Collection must be type of Array')

  return collection.reduce((acc, cur) => {
    if (!existsInCollection(cur, acc) || acc.length === 0) acc.push(cur)
    return acc
  }, [])
}

/**
 * Checks if string exists in a collection
 * @param {String} string string to search for
 * @param {Object[]} collection collection to search in
 * @returns Boolean
 */
export function stringExistsInCollection(string, collection) {
  if (!_.isString(string)) throw new TypeError('Invalid argument. Must be of type String')
  if (!Array.isArray(collection)) throw new TypeError('Invalid argument. Must be of type Array')
  return !collection.every((item) => !_.isEqual(string, item))
}

/**
 * Removes duplicate strings from collection
 * @param {String[]} collection
 */
export function uniqueStringCollection(collection) {
  if (!Array.isArray(collection)) throw new TypeError('Collection must be type of Array')

  return collection.reduce((acc, cur) => {
    if (!stringExistsInCollection(cur, acc) || acc.length === 0) acc.push(cur)
    return acc
  }, [])
}

/**
 * Used to compare components when memoized
 * @param {Object} prev
 * @param {Object} curr
 * @param {String[]}
 */
export function isUnchanged(prev, curr, paths) {
  if (!paths) return _.isEqual(prev, curr)

  const previousValues = _.pick(prev, paths)
  const currentValues = _.pick(curr, paths)

  const result = _.isEqual(previousValues, currentValues)
  return result
}

export function formatDate(date, format = 'MM/dd/yyyy') {
  if (!date) return
  const dateObj = _.isDate(date) ? date : new Date(date)
  return dateFormat(dateObj, format)
}

export function formatNumber(value, format = '0,0') {
  if (_.isNil(value)) return
  return numeral(value).format(format)
}

const dataUtils = {
  addIdsToSchema,
  convertTypes,
  addItemsToCollection,
  getDifferences,
  getObjectChanges,
  isNumber,
  getPaths,
  existsInCollection,
  uniqueCollection,
  stringExistsInCollection,
  uniqueStringCollection,
  isUnchanged,
  formatDate,
  formatNumber,
}

export default dataUtils
