import { Typography, Tabs } from 'antd'
import React from 'react'

import { Query } from '@react-awesome-query-builder/antd'
import useConditionEditor from './hooks/useConditionEditor'
import CodeEditor from './components/CodeEditor'

const { Paragraph } = Typography

export default function Condition({ condition, setCondition }) {
  const { state, code, builder, handleChange, handleChangeCustom } = useConditionEditor({ condition, setCondition })

  return (
    <>
      <Paragraph className='mt-1 mb-4'>
        Conditions allow you to dynamically include or exclude sections of your template. Use the expression builder
        below to set the condition when you want this element to be displayed.
      </Paragraph>

      <div className='p-3'>
        <Tabs
          defaultActiveKey={code ? '2' : '1'}
          items={[
            {
              label: 'Builder',
              key: '1',
              children: <Query {...state.config} value={state.tree} onChange={handleChange} renderBuilder={builder} />,
            },

            { label: 'Custom', key: '2', children: <CodeEditor code={code} onChange={handleChangeCustom} /> },
          ]}
        />
      </div>
    </>
  )
}
