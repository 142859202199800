import React from 'react'
import { Layout, Row, Button, Space, Dropdown } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { useBreakpoint } from '../../hooks/helperHooks'

const { Header } = Layout

export default function AppBar({
  left,
  center,
  right,
  actions,
  className,
  leftProps = {},
  centerProps = {},
  rightProps = {},
  ...rest
}) {
  const breakpoint = useBreakpoint()

  if (actions) right = renderActions(actions, breakpoint)

  return (
    <Header className={`page-header px-3 ${className}`} {...rest}>
      <Row style={{ height: '100%', alignItems: 'center', flexWrap: 'nowrap' }}>
        {/* LEFT */}
        <div style={{ display: 'flex', flex: '0 0 33.33%', flexWrap: 'nowrap' }} {...leftProps}>
          {left}
        </div>

        {/* CENTER */}
        <div
          style={{
            display: 'flex',
            flex: '1 1 250px',
            justifyContent: 'center',
            flexWrap: 'nowrap',
          }}
          {...centerProps}
        >
          {center}
        </div>

        {/* RIGHT */}
        <div
          style={{
            display: 'flex',
            flex: '0 0 33.33%',
            justifyContent: 'flex-end',
            flexWrap: 'nowrap',
          }}
          {...rightProps}
        >
          {right}
        </div>
      </Row>
    </Header>
  )
}

function renderActions(actions = [], breakpoint = 'lg') {
  const collapse = ['xs', 'sm', 'md'].includes(breakpoint)

  const buttons = actions.map(({ icon, label, ...rest }, i) => {
    if (icon && !label) return <Button icon={icon} {...rest} />
    return (
      <Button key={i} icon={icon} block={collapse} {...rest}>
        {label}
      </Button>
    )
  })

  const spaceProps = collapse ? { direction: 'vertical', align: 'center' } : { direction: 'horizontal' }

  const menu = <Space {...spaceProps}>{buttons}</Space>
  const collapsed = (
    <Dropdown overlay={menu} trigger='click'>
      <Button icon={<EllipsisOutlined rotate={90} />} />
    </Dropdown>
  )
  return collapse ? collapsed : menu
}
