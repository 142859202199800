import { DefaultDataSource } from './default'

import { airtableConfig, AirtableDataSource, AirtableInstructions } from './airtable'
import { hubspotConfig, HubSpotInstructions } from './hubspot'
import { makeConfig, MakeInstructions } from './make'
import { nolocoConfig, NolocoInstructions } from './noloco'
import { poryConfig, PoryInstructions } from './pory'
import { restApiConfig, RestApiInstructions } from './rest-api'
import { softrConfig, SoftrInstructions } from './softr'
import { stackerConfig, StackerInstructions } from './stacker'
import { zapierConfig, ZapierInstructions } from './zapier'

export { defaultConfig, DefaultDataSource } from './default'
export { AirtableInstructions, airtableConfig } from './airtable'
export { HubSpotInstructions, hubspotConfig } from './hubspot'
export { MakeInstructions, makeConfig } from './make'
export { NolocoInstructions, nolocoConfig } from './noloco'
export { PoryInstructions, poryConfig } from './pory'
export { RestApiInstructions, restApiConfig } from './rest-api'
export { SoftrInstructions, softrConfig } from './softr'
export { StackerInstructions, stackerConfig } from './stacker'
export { ZapierInstructions, zapierConfig } from './zapier'

const dataSourceMap = {
  default: DefaultDataSource,
  airtable: AirtableDataSource,
}

/**
 * Get a connector by id
 * @param {string} id
 * @returns {import('../../types').Connector | undefined}
 */
export const getDataSource = (id) => dataSourceMap[id]
export const getDefaultDataSource = () => dataSourceMap.default

export const instructionList = [
  { ...airtableConfig, content: <AirtableInstructions /> },
  { ...hubspotConfig, content: <HubSpotInstructions /> },
  { ...makeConfig, content: <MakeInstructions /> },
  { ...nolocoConfig, content: <NolocoInstructions /> },
  { ...poryConfig, content: <PoryInstructions /> },
  { ...restApiConfig, content: <RestApiInstructions /> },
  { ...softrConfig, content: <SoftrInstructions /> },
  { ...stackerConfig, content: <StackerInstructions /> },
  { ...zapierConfig, content: <ZapierInstructions /> },
]

export default Object.values(dataSourceMap)
