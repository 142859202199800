import React, { useMemo } from 'react'

import { createFormula } from '../airtable/utils'
import { Typography, Card } from 'antd'
import CodeBlock from '@common/CodeBlock'
import ConfigurationForm from '../airtable/AirtableInstructions/ConfigurationForm'
import { useInstructions } from '../../hooks'
import { useTemplate } from '@hooks/useTemplate'

const { Title, Paragraph } = Typography

export default function StackerInstructions() {
  const { template } = useTemplate()
  const { config } = useInstructions()
  const formula = useMemo(() => createFormula(template.id, config), [config, template.id])

  return (
    <Card title='Formula Builder'>
      <Paragraph>
        Use this tool to create the formula that you'll add to a Formula field or Button field in your table.
      </Paragraph>

      <Title level={5}>Formula</Title>

      <CodeBlock
        code={formula || '// Formula will be displayed here after you add your Table URL'}
        style={{ marginBottom: '30px' }}
      />

      <ConfigurationForm />
    </Card>
  )
}
