import React, { useEffect, useState } from 'react'
import { Alert, ConfigProvider, Space,  Segmented } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import {
  TemplatesProvider,
  TemplatesSearch,
  TemplatesSort,
  TemplateGridList,
  TemplateTableList,
} from '../../features/templates'
import Loading from '@common/Loading'
import FaIcon from '../../components/icons/FaIcon'

const VIEW_MODE_KEY = 'templateListViewMode'

export default function Templates() {
  const initialViewMode = sessionStorage.getItem(VIEW_MODE_KEY) || 'grid'
  const [viewMode, setViewMode] = useState(initialViewMode)

  const handleViewModeChange = (mode) => {
    setViewMode(mode)
    sessionStorage.setItem(VIEW_MODE_KEY, mode)
  }

  useEffect(() => {
    document.title = 'My Templates | Trash'
  }, [])

  return (
    <React.Suspense fallback={<Loading />}>
      <Alert.ErrorBoundary>
        <TemplatesProvider query={{ isTrashed: true }}>
          <div style={{ maxWidth: '1700px' }}>
            <ConfigProvider>
              <PageHeader
                title='Trash'
                extra={
                  <Space size='large'>
                    <TemplatesSearch />
                    <TemplatesSort />
                    <>|</>
                    <Segmented
                      defaultValue={viewMode}
                      options={[
                        { icon: <FaIcon icon='faGrid2' />, value: 'grid' },
                        { icon: <FaIcon icon='faTableList' />, value: 'table' },
                      ]}
                      onChange={handleViewModeChange}
                    />
                  </Space>
                }
              >
                {viewMode === 'grid' ? <TemplateGridList /> : <TemplateTableList />}
              </PageHeader>
            </ConfigProvider>
          </div>
        </TemplatesProvider>
      </Alert.ErrorBoundary>
    </React.Suspense>
  )
}
