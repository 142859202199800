import { TYPES } from '../consts'
import { getAndMergeModels } from '../../utils'
import { loopAllComponents } from '../../../utils'
import { approximateColumns, insertTempColumns, mapAlignment, removeDisplayBefore, removeDisplayAfter } from './Section'
import merge from 'lodash/merge'

import { resizerNone } from '../sharedConfig'

export const type = TYPES.row

export const protectedCss = `
  /* ROW COMPONENT */
  *[data-gjs-type="${type}"]{
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    min-height: 0;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    color: inherit;
  }
`

export default function rowType(dc, { editor }) {
  const models = getAndMergeModels(dc, [TYPES.template])
  const model = {
    extend: TYPES.template,
    defaults: {
      tagName: 'div',
      name: 'Row (Legacy)',
      icon: '<i class="gjs-badge__icon fa-kit fa-row"></i>',
      attributes: {
        'data-dm-category': 'layout',
      },
      traits: [],
      resizable: {
        ...resizerNone,
        bc: true,
        keyHeight: 'height',
        autoHeight: true,
        currentUnit: false,
        minDim: 15,
        unitHeight: 'px',
        step: 1,
      },
      draggable: `[data-gjs-type=${TYPES.column}]`, // IT CAN BE DRAGGED INTO these components
      droppable: `[data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.dynamic_column}]`, // these components CAN BE DROPPED INTO IT
      stylable: [
        'height',
        'min-height',
        'overflow',
        'page-break-inside',
        // 'flex-wrap',
        'font-family',
        'font-size',
        'color',
        'font-weight',
        'text-align',

        'background-color',
        'background-image',
        'background-size',
        'background-position',
        'background-repeat',

        'better-borders',
        'border-width',
        'border-color',

        'padding',
        'padding-top',
        'padding-left',
        'padding-right',
        'padding-bottom',

        'border-radius',
        'border-top-left-radius',
        'border-top-right-radius',
        'border-bottom-left-radius',
        'border-bottom-right-radius',
      ],
    },
    init() {
      this.addClass('pt-10 pb-10 mh-0')
    },
    insertTempColumns,
    approximateColumns,
    mapAlignment,
    removeDisplayBefore,
    removeDisplayAfter,
    bump() {
      this.approximateColumns()
      this.mapAlignment()
      this.insertTempColumns()
      this.set({
        type: TYPES.row_2,
      })
      this.addAttributes({ 'data-gs-type': 'row' })
      this.addStyle({ 'border-collapse': 'separate' })
      const ifTrue = (c) => c.bump
      const clb = (c) => c.bump()
      loopAllComponents(this.components(), ifTrue, clb)
      editor.setSelected()
    },
  }
  dc.addType(type, { model: merge({}, models, model) })
}
