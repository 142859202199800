const showLayers = {
  run(_editor, _sender) {
    const el = document.getElementById('rc-tabs-0-tab-layer-manager')
    if (el) el.click()
  },
}

const updateEmptyState = {
  run(editor) {
    const { Canvas, getComponents } = editor
    const className = 'empty-state'
    const body = Canvas.getBody()
    const components = getComponents()
    components.length <= 0 ? body.classList.add(className) : body.classList.remove(className)
  },
}

const editLogic = {
  run(_editor) {},
}

export const commands = ({ editor, $ }) => {
  const { Commands } = editor
  const commands = [
    { id: 'open-layers', command: showLayers },
    { id: 'update-empty-state', command: updateEmptyState },
    { id: 'edit-logic', command: editLogic },
    { id: 'edit-token', command: editLogic },
    { id: 'clear-canvas', command: editLogic },
    { id: 'convert-legacy', command: editLogic },
    { id: 'revert-canvas', command: editLogic },
    { id: 'reload-canvas', command: editLogic },
    { id: 'variable-error', command: editLogic },
    { id: 'up-schema', command: editLogic },
  ]
  commands.forEach(({ id, command }) => Commands.add(id, command))
  Commands.extend('export-template', {
    run(editor, sender, opts = {}) {
      sender && sender.set && sender.set('active', 0)
      const config = editor.getConfig()
      const modal = editor.Modal
      const pfx = config.stylePrefix
      this.cm = editor.CodeManager || null
  
      if (!this.$editors) {
        const oHtmlEd = this.buildEditor('htmlmixed', 'default', 'HTML')
        const oCsslEd = this.buildEditor('css', 'default', 'CSS')
        this.htmlEditor = oHtmlEd.model
        this.cssEditor = oCsslEd.model
        const $editors = $(`<div class="${pfx}export-dl"></div>`)
        $editors.append(oHtmlEd.el).append(oCsslEd.el)
        this.$editors = $editors
      }
  
      modal
        .open({
          title: config.textViewCode,
          content: this.$editors,
        })
        .getModel()
        .once('change:open', () => editor.stopCommand(`${this.id}`))
      this.htmlEditor.setContent(editor.getHtml(opts.optsHtml))
      this.cssEditor.setContent(editor.getCss(opts.optsCss))
      const cont = modal.getContentEl()
      const codeEls = cont.querySelectorAll('.gjs-cm-editor-c .CodeMirror')
      codeEls.forEach((el) => el.style.height = (cont.offsetHeight - 46) + 'px')
    },
  })
}

export default commands
