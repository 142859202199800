import { TYPES, MAX_GRID } from '../consts'
import { deepClone } from '@utils'
import { getAndMergeModels } from '../../utils'
import { convert } from '../../../config/toolbarItems'
import { loopAllComponents } from '../../../utils'

export const type = TYPES.section

export const protectedCss = `
  /* SECTION COMPONENT */
  *[data-gjs-type="dm-section"]{
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    color: inherit;
    box-sizing: border-box;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
`

export function approximateColumns() {
  const comps = this.components()

  let total = 0
  let totalCols = 0
  comps.each((c) => (total += c.view.el.offsetWidth))
  comps.each((c) => {
    const p = (c.view.el.offsetWidth / total) * MAX_GRID
    const n = parseInt(Math.floor(p)) || 1
    c.set('tempColumns', n)
    totalCols += n
  })
  let r = MAX_GRID - totalCols
  comps.each((c) => {
    const cols = parseInt(c.get('tempColumns'))
    if (r === 0 || cols === 1) {
      // Do nothing
    } else if (r > 0) {
      c.set('tempColumns', cols + 1)
      r -= 1
    } else {
      c.set('tempColumns', cols - 1)
      r += 1
    }
  })
}

export function mapAlignment() {
  const style = this.getStyle()['justify-content']
  style &&
    this.addStyle({
      'vertical-align':
        style === 'center' || style === 'space-evenly' ? 'middle' : style === 'flex-end' ? 'bottom' : 'top',
    })
}

const hiddenClass = 'was-hidden'

export function removeDisplayBefore() {
  removeDisplay(this)
}

export function removeDisplay(component) {
  const style = component.getStyle()['display']
  if (style && style === 'none') {
    component.addClass(hiddenClass)
  }
  component.removeStyle('display')
}

export function removeDisplayAfter() {
  addNoneDisplay(this)
}

export function addNoneDisplay(component) {
  if (component.getClasses().includes(hiddenClass)) {
    component.addStyle({
      display: 'none',
    })
    component.removeClass(hiddenClass)
  }
}

export function insertTempColumns() {
  const model0 = this.components().models[0]
  model0 &&
    model0.get('type') !== TYPES.temp_columns &&
    this.components().reset({
      type: TYPES.temp_columns,
      style: {
        height: this.getStyle()['height'] || 'auto', //this.view.el.offsetHeight + 'px'
      },
      components: deepClone(this.components()),
    })
  this.removeStyle('height')
}

export default function sectionType(dc, { editor }) {
  const model = getAndMergeModels(dc, [TYPES.row])
  model.extend = TYPES.template
  const defaults = model.defaults

  defaults.name = 'Section (Legacy)'
  defaults.icon = '<i class="gjs-badge__icon fa-kit fa-row"></i>'
  defaults.traits = []
  defaults.attributes['data-dm-category'] = 'layout'
  defaults.draggable = '[data-gjs-type=wrapper]' // IT CAN BE DRAGGED INTO these components
  defaults.droppable = `[data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.dynamic_column}]` // these components CAN BE DROPPED INTO IT
  defaults.stylable = [
    'height',
    'min-height',
    'page-break-inside',
    'overflow',
    'font-family',
    'font-size',
    'font-weight',
    'color',
    'text-align',

    'background-color',
    'background-image',
    'background-size',
    'background-position',
    'background-repeat',

    'better-borders',
    'border-width',
    'border-color',

    'padding',
    'padding-top',
    'padding-right',
    'padding-left',
    'padding-bottom',

    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-left-radius',
    'border-bottom-right-radius',
  ]

  model.init = function () {
    this.addClass('pt-10 pb-10')
  }

  model.insertTempColumns = insertTempColumns

  model.mapAlignment = mapAlignment

  model.approximateColumns = approximateColumns

  model.removeDisplayBefore = removeDisplayBefore

  model.removeDisplayAfter = removeDisplayAfter

  model.bump = function () {
    this.approximateColumns()
    this.mapAlignment()
    this.insertTempColumns()
    this.set({
      type: TYPES.row_2,
    })
    this.addAttributes({ 'data-gs-type': 'row' })
    this.addStyle({ 'border-collapse': 'separate' })
    const ifTrue = (c) => c.bump
    const clb = (c) => c.bump()
    loopAllComponents(this.components(), ifTrue, clb)
    editor.setSelected()
  }

  model.initToolbar = function () {
    const tb = this.get('toolbar')
    const tbExists = tb.some((item) => item.command === convert.command)

    if (!tbExists && this.parent().get('type') === 'wrapper') {
      tb.unshift(convert)
      this.set('toolbar', tb)
    }
  }

  dc.addType(type, { model })
}
