export const config = {
  id: 'stacker',
  icon: {
    type: 'image',
    value: '/images/delivery-methods/stacker-logo.svg',
  },
  name: 'Stacker',
  description: 'Add document creation to your Stacker app.',

  overview: `The Stacker integration leverages the Airtable Generation Link integration. 
    This allows you to create a Generation Link in Airtable and use it as a source for a button or embedded iframe in Stacker. 
    This integration is perfect for creating documents from your Airtable data in Stacker.`,

  setupSteps: [
    'Using the Formula Builder below, create a Generation Link by adding your table URL.',
    'Copy the generated formula.',
    'In Airtable, create a new formula field (in the same table as the URL) and add the generated formula.',
    'In Stacker, sync your base and add the new formula field as the source for a button or embedded iframe.',
  ],
  documentationUrl: 'https://docs.documint.me/integrations/stacker',
}

export default config
