import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Button } from 'antd'
import { FaIcon } from '@components/icons'
import { TemplateName, SaveStatus, ModeSwitch, TemplateControls, SaveButton } from './components'
import { useTemplateSettings } from '@features/template-settings/hooks/useTemplateSettings'
import { useTemplateEdit } from '../../hooks'

const PageHeader = () => {
  const { autoSave } = useTemplateSettings()
  const { isSaving } = useTemplateEdit()

  const wrap = useCallback((el) => {
    if (isSaving) return el
    return <Link to='/' className='d-flex align-items-center mr-1'>{el}</Link>
  }, [isSaving])

  return (
    <Layout.Header
      id='template-top-bar'
      className='pl-2 pr-4'
      style={{
        display: 'flex',
        flexDirection: 'row',
        background: '#fff',
        justifyContent: 'space-between',
        borderBottom: 'solid 1px #e8e8e8',
        height: '65px',
        zIndex:1000
      }}
    >
      <div
        className='h-100'
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'start',
          flex: '0 0 calc(50% - 250px)',
          display: 'flex',
          minWidth: '300px',
        }}
      >
        <div className='d-flex mr-3'>
          {wrap(<Button icon={<FaIcon icon='faChevronLeft' />} size='large' type='text' disabled={isSaving} />)}
        </div>

        <TemplateName className='mr-2' />

        <div className='d-lg-block'>
          {autoSave ? <SaveStatus style={{ width: '180px' }} /> : <SaveButton />}
        </div>
      </div>

      <div style={{ flex: '1 1 500px', textAlign: 'center' }}>
        <ModeSwitch />
      </div>

      <div className='justify-content-end align-items-center d-flex' style={{ flex: '0 0 calc(50% - 250px)' }}>
        <TemplateControls />
      </div>
    </Layout.Header>
  )
}

export default PageHeader
