import React, { useEffect, useState } from 'react'
import { Modal, Tabs, Table, Collapse } from 'antd'
import { dataTypes, defaults } from './consts'

import Condition from './Condition'
import Loop from './Loop'

export default function LogicModal({ selectedComponent, visible, modalProps, onOk, onCancel }) {
  const [condition, setCondition] = useState(defaults.condition)
  const [loop, setLoop] = useState(defaults.loop)
  const [sorts, setSorts] = useState(defaults.sorts)

  useEffect(() => {
    if (!selectedComponent) return
    const condition = selectedComponent.getCondition && selectedComponent.getCondition()
    setCondition(condition)
    const loop = selectedComponent.getLoop && selectedComponent.getLoop()
    setLoop(loop)
    const sorts = selectedComponent.getSorts && selectedComponent.getSorts()
    setSorts(sorts)
  }, [selectedComponent])

  function handleCancel() {
    if (onCancel) onCancel()
  }

  const handleConditionChange = (value) => {
    setCondition(value)
  }

  const handleConditionClear = () => {
    setCondition(defaults.condition)
  }

  const handleLoopChange = ({ loop, sorts } = {}) => {
    setLoop(loop)
    setSorts(sorts)
  }

  /**
   * Handle Save
   */
  function handleOk() {
    if (selectedComponent.setCondition) selectedComponent.setCondition(condition)
    if (selectedComponent.setLoop) selectedComponent.setLoop(loop)
    if (selectedComponent.setSorts) selectedComponent.setSorts(sorts)
    if (onOk) onOk()
  }

  return (
    <Modal
      {...modalProps}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={750}
      okText='Save'
      title='Logic Editor'
      destroyOnClose={true}
    >
      <Tabs
        className='mb-5'
        items={[
          {
            label: 'Condition',
            key: 'condition',
            children: <Condition data={condition} onChange={handleConditionChange} onClear={handleConditionClear} />,
          },
          {
            label: 'Repeat/Loop',
            key: 'loop',
            children: <Loop data={{ loop, sorts }} onChange={handleLoopChange} />,
          },
        ]}
      />
      <Info />
    </Modal>
  )
}

export function Info() {
  return (
    <Collapse>
      <Collapse.Panel header='Data Types'>
        <Table columns={dataTypes.cols} dataSource={dataTypes.data} pagination={false} title={false} bordered />
      </Collapse.Panel>
    </Collapse>
  )
}
