import { TYPES } from '../../componentTypes/consts'

const rowBlock = {
  name: 'dm-dynamic-row',
  label: 'Row',
  iconClass: 'fa-kit fa-row',
  content: {
    type: TYPES.dynamic_row,
  },
}

export default rowBlock

export const Row = {
  name: 'dm-row',
  label: 'Row',
  iconClass: 'fa-kit fa-row',
  content: {
    type: TYPES.row_2,
  },
}
