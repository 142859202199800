import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  jwt: null,
  connections: [],
  projectId: null,
}

export const slice = createSlice({
  name: 'activePieces',
  initialState,
  reducers: {
    setJwt(state, action) {
      state.jwt = action.payload
    },
    setConnections(state, action) {
      state.connections = action.payload
    },
    setProjectId(state, action) {
      state.projectId = action.payload
    },
  },
})

// Actions
export const { setJwt, setProjectId, setConnections } = slice.actions

// Selectors
export const selectJwt = (state) => state.activePieces.jwt
export const selectConnections = (state) => state.activePieces.connections
export const selectProjectId = (state) => state.activePieces.projectId

// Reducer
export const reducer = slice.reducer

export default slice.reducer
