import React, { useEffect } from 'react'
import { TemplateDesigner, useEditor } from '@features/template-designer'
import { useTemplateEdit } from '../hooks'
import { MODES } from '../modes'

export default function DesignTab(props) {
  const { mode } = useTemplateEdit()
  const { editor } = useEditor()

  // Store dirty changes when switching modes
  useEffect(() => {
    if (mode !== MODES.design.value) {
      const dirtyCount = editor?.getDirtyCount()
      if (dirtyCount > 0 && editor.storables) editor?.store?.()
    }
  }, [mode, editor])

  return <TemplateDesigner {...props} />
}
