import { useCallback, useEffect, useState, useMemo } from 'react'
import { Utils, AntdConfig } from '@react-awesome-query-builder/antd'
import '@react-awesome-query-builder/ui/css/styles.css'
import { useDesigner, useEditor } from '../../../hooks'
import renderBuilder from '../components/renderBuilder'
import {
  formatQueryTree,
  generateLocalDataFields,
  generateLocalDataLoopFields,
  getSchemaCollectionItems,
  getSchemaCollectionSortItems,
  toJson,
} from '../utils'
import { convertTypes, deepClone } from '@utils'

const InitialConfig = AntdConfig
const emptyTree = { id: Utils.uuid(), type: 'group' }

const getTree = (queryValue, config) => {
  let tree = Utils.loadTree(emptyTree)
  if (queryValue.type) {
    tree = Utils.loadTree(queryValue)
  } else if (queryValue.logic && !queryValue.type) {
    const logicTree = toJson(queryValue.logic)
    tree = Utils.loadFromJsonLogic(logicTree, config)
  }
  return Utils.checkTree(tree, config)
}

const useLoopEditor = ({ loop, setLoop }) => {
  const { template: { fieldSchema } } = useDesigner()
  const { retrieveArrayTempParent, retrieveArrayTempParents } = useEditor()

  // INITIALIZATION OF VALUES - TODO REFACTORING OF MEMOIZATION AS THEY ONLY NEED TO RUN ONCE
  const { queryValue, value, parsedLoop } = useMemo(() => {
    const parsedLoop = toJson(loop)
    const parsedLoopValue = parsedLoop ? Object.keys(parsedLoop)[0] : null
    const value =
      parsedLoopValue && parsedLoopValue !== 'undefined' ? parsedLoopValue : !parsedLoop && loop ? loop : null

    const queryValue = parsedLoop && parsedLoop[value] ? parsedLoop[value] : emptyTree

    return { queryValue, value, parsedLoop }
  }, [loop])

  const { parsedData, metaData } = useMemo(() => {
    const schemaKey = retrieveArrayTempParent()

    const { parsedData, metaData } = generateLocalDataFields(
      convertTypes(deepClone(fieldSchema), false),
      value || schemaKey,
    )

    return { parsedData, metaData }
  }, [fieldSchema, retrieveArrayTempParent, value])

  const subfields = useMemo(
    () => (parsedData.items?.type === '!struct' ? parsedData.items.subfields : {}),
    [parsedData],
  )

  const config = useMemo(
    () => ({
      ...InitialConfig,
      settings: {
        ...InitialConfig.settings,
        maxNesting: 1,
      },
      fields: {
        ...subfields,
        ...parsedData,
      },
    }),
    [parsedData, subfields],
  )

  // STATES ACTUALLY USED AFTER INITIALIZATION
  const [filterOptions, setFilterOptions] = useState(value ? [{ value }] : null)
  const [filterValue, setFilterValue] = useState(value)
  const [sortOptions, setSortOptions] = useState(null)
  const [uuid, setUuid] = useState(parsedLoop?.uuid ?? '')
  const [cid, setCid] = useState(parsedLoop?.cid ?? '')
  const [code, setCode] = useState(
    value && parsedLoop && parsedLoop[value] && parsedLoop[value]['custom-condition']
      ? parsedLoop[value]['custom-condition']
      : '',
  )

  const [state, setState] = useState({
    tree: getTree(queryValue, config),
    config,
  })

  const [stateMetadata, setMetadata] = useState(metaData)

  const builder = useCallback((props) => renderBuilder(props), [])

  const handleChange = useCallback(
    (immutableTree, config) => {
      console.log(config)
      setState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config,
      }))
      const rawTree = Utils.getTree(immutableTree)
      setLoop &&
        setLoop(
          JSON.stringify({
            [filterValue]: {
              ...rawTree,
              children1: formatQueryTree(rawTree.children1, stateMetadata),
              logic: Utils.jsonLogicFormat(immutableTree, config).logic,
            },
            uuid,
            ...(cid ? { cid } : {}),
          }),
        )
    },
    [setLoop, filterValue, stateMetadata, uuid, cid],
  )

  const handleChangeCustom = useCallback(
    (custom) => {
      setCode(custom)
      setLoop &&
        setLoop(
          JSON.stringify({
            [filterValue]: {
              'custom-condition': custom,
            },
          }),
        )
    },
    [filterValue, setLoop],
  )

  const handleFilterChange = useCallback(
    (value, opts) => {
      setFilterValue(value || null)
      const rawTree = Utils.getTree(state.tree)
      const children1 = rawTree.children1 ? formatQueryTree(rawTree.children1, stateMetadata) : {}
      setLoop &&
        setLoop(
          value
            ? JSON.stringify({
                [value]: {
                  ...rawTree,
                  children1,
                  logic: Utils.jsonLogicFormat(state.tree, config).logic,
                },
                uuid: opts?.uuid,
                ...(opts?.cid ? { cid: opts?.cid } : {}),
              })
            : '',
        )
      setUuid(value ? opts?.uuid : '')
      setCid(value ? opts?.cid : '')
    },
    [state, config, setLoop, stateMetadata],
  )

  const getLocalVariables = useCallback(() => {
    if (!filterValue || !filterOptions) return

    const loopSchema = filterOptions.find(({ value }) => value === filterValue)

    if (!loopSchema || !loopSchema.metaData) return

    const { parsedData, metaData } = generateLocalDataLoopFields(loopSchema.metaData)

    setState((prevState) => ({
      ...prevState,
      config: {
        ...config,
        fields: {
          ...parsedData,
          ...subfields,
        },
      },
    }))

    setMetadata(metaData)
  }, [filterValue, filterOptions, config, subfields])

  const getFilterOptions = useCallback(() => {
    const SchemaKeys = retrieveArrayTempParents()

    setFilterOptions(getSchemaCollectionItems(fieldSchema, SchemaKeys))
    setSortOptions(getSchemaCollectionSortItems(fieldSchema))
  }, [fieldSchema, retrieveArrayTempParents])

  useEffect(() => {
    getFilterOptions()
  }, [fieldSchema, getFilterOptions])

  useEffect(() => {
    getLocalVariables()
  }, [filterValue, getLocalVariables])

  return {
    state,
    code,
    filterOptions,
    filterValue,
    sortOptions,
    handleFilterChange,
    builder,
    handleChange,
    handleChangeCustom,
  }
}

export default useLoopEditor
