import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from '../../config'

export const documintApi = createApi({
  reducerPath: 'documintApi',
  baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl, credentials: 'include' }),
  tagTypes: [
    'Template',
    'Templates',
    'Assets',
    'Users',
    'Account',
    'ApiApps',
    'Profile',
    'Integrations',
    'Subscription',
    'Portal',
    'PaymentMethods',
    'Plans',
    'ActivePieces',
    'Providers',
  ],
  endpoints: (build) => ({}),
})

export default documintApi
