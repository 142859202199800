import React, { useMemo } from 'react'
import { Row, Col, Typography, Input, Space, Tooltip, Form, Select, Button, Alert } from 'antd'
import { QuestionCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { syntaxRules } from './consts'
import { isBlank } from './utils'
import isNil from 'lodash/isNil'
import { toJson } from '../NewLogicModal/utils'

const { Option } = Select
const { Paragraph, Title, Text } = Typography

const renderTooltip = ({ title }) => (
  <Tooltip className='tooltip-dark' title={title}>
    <QuestionCircleOutlined />
  </Tooltip>
)

const parseSorts = (value) => {
  if (Array.isArray(value)) return value
  if (!value || value === '') return []
  return value.split(',').map((value) => {
    const order = value[0] === '-' ? '-' : ''
    const field = order === '-' ? value.substring(1, value.length) : value
    return { order, field }
  })
}

const renderSortsExplanation = (sorts) => {
  const parsedSorts = parseSorts(sorts)
  return parsedSorts.map(({ order, field }, i) => {
    const isLast = i >= parsedSorts.length - 1
    return (
      <>
        <Text code>{field}</Text> in {order === '-' ? 'descending' : 'ascending'} order {!isLast && 'then by'}
      </>
    )
  })
}

export default function Loop({ data, onChange }) {
  const [form] = Form.useForm()

  const parsedLoop = toJson(data.loop)
  const parsedLoopValue = parsedLoop ? Object.keys(parsedLoop)[0] : null
  const value =
    parsedLoopValue && parsedLoopValue !== 'undefined' ? parsedLoopValue : !parsedLoop && data.loop ? data.loop : ''

  const initialValues = useMemo(
    () => ({
      loop: value,
      sorts: parseSorts(data.sorts),
    }),
    [data.sorts, value],
  )

  const loopExplanation = !isBlank(data.loop) && (
    <Text>
      Repeat this element for each item in <Text code>{data.loop}</Text>
    </Text>
  )
  const sortExplanation = !isBlank(data.sorts) && <> and sort by {renderSortsExplanation(data.sorts)}</>

  const handleChange = () => {
    function handleValidationResult({ values = {}, errorFields = [] }) {
      if (errorFields?.length > 0) return
      let sorts = values?.sorts
      if (sorts?.length > 0) {
        sorts = values.sorts
          .filter((v) => !isNil(v.field))
          .map(({ field, order = '' }) => `${order}${field}`)
          .join(',')
      }
      onChange && onChange({ sorts, loop: values.loop })
    }

    form
      .validateFields()
      .then((values) => handleValidationResult({ values }))
      .catch(({ values, errorFields }) => handleValidationResult({ values, errorFields }))
  }

  return (
    <Form
      name='loop'
      form={form}
      layout='vertical'
      size='large'
      onValuesChange={handleChange}
      initialValues={initialValues}
    >
      <Paragraph className='mt-1 mb-4'>Repeat this element for each item in a list.</Paragraph>

      <Row gutter={10}>
        <Col span={12}>
          <Title level={5} className='mt-1 mb-3'>
            List variable{' '}
            {renderTooltip({
              title: (
                <>
                  Enter the name of the variable that will contain a list of items.
                  <br />
                  Examples:{' '}
                  <Text code={true} style={{ backgroundColor: '#f2f2f2', color: '#333' }}>
                    invoice_items
                  </Text>
                </>
              ),
            })}
          </Title>
          <Form.Item name='loop' rules={[...syntaxRules]}>
            <Input placeholder='Variable name' allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Title level={5} className='mt-1 mb-3'>
            Sort by fields{' '}
            {renderTooltip({
              title: (
                <>
                  <div>
                    Enter the field, or fields to sort by. To sort in ascending order, don't add a prefix.
                    <br />
                    Example:<Text type='code'>name</Text>.
                  </div>
                  <div>
                    Sort by sub-properties by using the path to the property. <br />
                    Example: <Text type='code'>name.first</Text>
                  </div>
                </>
              ),
            })}
          </Title>
          <Form.List name='sorts'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space key={key} style={{ display: 'flex', justifyContent: 'space-between' }} align='start'>
                    <Form.Item style={{ width: '98%' }}>
                      <Space.Compact>
                        <Form.Item name={[name, 'order']} noStyle>
                          <Select defaultValue='' className='select-before' style={{ width: '30%' }}>
                            <Option value=''>Asc</Option>
                            <Option value='-'>Desc</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          name={[name, 'field']}
                          noStyle
                          rules={[...syntaxRules, { required: true, message: 'Field name required' }]}
                        >
                          <Input placeholder='Field name' style={{ width: '70%' }} />
                        </Form.Item>
                      </Space.Compact>
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} style={{ marginTop: '8px', fontSize: '22px' }} />
                  </Space>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Col>
      </Row>
      {loopExplanation && (
        <Alert
          message={
            <>
              {loopExplanation}
              {sortExplanation && sortExplanation}
            </>
          }
        />
      )}
    </Form>
  )
}
