import { Select, Typography, Input, Space, Tooltip, Button, Form } from 'antd'
import React, { useEffect, useState, useMemo } from 'react'
import { QuestionCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import isNil from 'lodash/isNil'
import { syntaxRules } from '../LogicModal/consts'
import { uniqueCollection } from '@utils'

const { Title, Text } = Typography
const { Option } = Select

const renderTooltip = ({ title }) => (
  <Tooltip className='tooltip-dark' title={title}>
    <QuestionCircleOutlined />
  </Tooltip>
)

export const parseSorts = (value) => {
  if (Array.isArray(value)) return value
  if (!value || value === '') return []
  return value.split(',').map((value) => {
    const order = value[0] === '-' ? '-' : ''
    const field = order === '-' ? value.substring(1, value.length) : value
    return { order, field }
  })
}

export default function Sorts({ sorts, setSorts, sortOptions, filterValue }) {
  const [form] = Form.useForm()
  const [isInit, setIsInit] = useState(false)

  const initialValues = useMemo(
    () => ({
      sorts: parseSorts(sorts),
    }),
    [sorts],
  )

  const filtererdOptions = useMemo(() => {
    const options =
      sortOptions &&
      uniqueCollection(sortOptions.filter((option) => option.value.split('.')[0] === `${filterValue}`))
        .filter((o, _, a) => (a.length > 1 && o.value !== filterValue) || a.length <= 1)
        .map((o) => {
          const value = o.value.split(`${filterValue}.`)[1]
          return {
            label: value ? value.replaceAll('[', '').replaceAll(']', '') : o.label,
            value: value ? value.replaceAll('[', '').replaceAll(']', '') : o.value.replaceAll('[', '').replaceAll(']', ''),
          }
        })

    return options?.length ? options : [{ label: filterValue, value: filterValue }]
  }, [filterValue, sortOptions])

  const handleSortsChange = () => {
    function handleValidationResult({ values = {}, errorFields = [] }) {
      if (errorFields?.length > 0) return
      let sorts = values?.sorts
      if (sorts?.length > 0) {
        sorts = values.sorts
          .filter((v) => !isNil(v.field))
          .map(({ field, order = '' }) => `${order}${field}`)
          .join(',')
      }
      setSorts && setSorts(sorts)
    }

    form
      .validateFields()
      .then((values) => handleValidationResult({ values }))
      .catch(({ values, errorFields }) => handleValidationResult({ values, errorFields }))
  }

  useEffect(() => {
    isInit && form.setFieldsValue({ sorts: [] })
    setIsInit(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue])

  return (
    <>
      <Title level={5}>
        Sort by fields{' '}
        {renderTooltip({
          title: (
            <>
              <div>
                Enter the field, or fields to sort by. To sort in ascending order, don't add a prefix.
                <br />
                Example:<Text type='code'>name</Text>.
              </div>
              <div>
                Sort by sub-properties by using the path to the property. <br />
                Example: <Text type='code'>name.first</Text>
              </div>
            </>
          ),
        })}
      </Title>
      <Form
        name='loop'
        form={form}
        layout='vertical'
        size='large'
        onValuesChange={handleSortsChange}
        initialValues={initialValues}
      >
        <Form.List name='sorts'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space key={key} style={{ display: 'flex', justifyContent: 'space-between' }} align='start'>
                  <Form.Item style={{ width: '130%' }}>
                    <Space.Compact>
                      <Form.Item name={[name, 'order']} noStyle>
                        <Select defaultValue='' className='select-before' style={{ width: '75px' }}>
                          <Option value=''>Asc</Option>
                          <Option value='-'>Desc</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[name, 'field']}
                        noStyle
                        rules={[...syntaxRules, { required: true, message: 'Field name required' }]}
                      >
                        <Select placeholder='Field name' options={filtererdOptions} style={{ width: '240px' }} />
                      </Form.Item>
                    </Space.Compact>
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    style={{ position: 'relative', marginTop: '8px', fontSize: '22px', zIndex: 1 }}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
                  Add field
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </>
  )
}
