import React, { useCallback, useEffect } from 'react'
import { Alert, Button, Select } from 'antd'

import isEqual from 'lodash/isEqual'
import { FaIcon } from '@components/icons'
import pick from 'lodash/pick'
import { useDataSourceConfig } from '@features/template-data-source/hooks'

export default function EntityConfigField({ field, onChange, value, disabled, connection, provider, onRefresh }) {
  const { options, prevQuery, loadOptions, isLoading, error } = useDataSourceConfig({
    field,
    provider,
    connectionName: connection.name,
  })
  const prevConnectionRef = React.useRef()

  const handleSelect = useCallback(
    (id, selectedOption) => {
      field.setValue({ id, name: selectedOption.label })
      if (onChange) onChange(selectedOption, field)
    },
    [field, onChange],
  )

  const refreshOptions = useCallback(async () => {
    await loadOptions()
    onRefresh?.()
  }, [loadOptions, onRefresh])

  // Load options on mount
  useEffect(() => {
    // TODO: Clean this up
    const query = field?.getQuery()
    const paramsHaveChanged = !isEqual(pick(prevQuery.lastArg, Object.keys(query)), query)
    const connectionHasChanged = prevConnectionRef.current !== connection?.id

    const proceedWithError = error ? paramsHaveChanged || connectionHasChanged : true
    if (!disabled && !isLoading && proceedWithError && (paramsHaveChanged || connectionHasChanged || !options)) {
      prevConnectionRef.current = connection?.id
      refreshOptions()
    }
  }, [field, refreshOptions, options, disabled, value, prevQuery, isLoading, connection, error])

  return (
    <div className='ant-form-item' style={{ marginBottom: '24px' }}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}
        className='ant-form-item-label'
      >
        <label>{field.name}</label>
        <Button
          size='small'
          icon={<FaIcon icon='faSync' spin={isLoading} faProps={{ size: 'sm' }} style={{ padding: '3px' }} />}
          onClick={refreshOptions}
          type='text'
        />
      </div>

      <Select
        value={value}
        options={disabled ? undefined : options}
        style={{ width: '100%' }}
        placeholder={field.label}
        onChange={handleSelect}
        loading={isLoading}
        disabled={disabled || isLoading}
      />

      {error && !disabled && (
        <Alert
          message={error?.data?.message || 'Something went wrong'}
          type='error'
          showIcon
          style={{ marginTop: 8 }}
        />
      )}
    </div>
  )
}
