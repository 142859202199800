import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography

export const dataTypes = {
  cols: [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (content) => <b>{content}</b>,
    },
    {
      title: 'Description',
      dataIndex: 'desc',
    },
    {
      title: 'Example',
      dataIndex: 'example',
      render: (content) => <Text code={true}>{content}</Text>,
      align: 'right',
    },
  ],
  data: [
    {
      key: 'variable',
      name: 'Variable',
      desc: (
        <>
          Can be a new or existing variable. For <Text code={true}>Value</Text> field, if a variable starts with a number, contain any spaces or any special characters other than <Text code={true}>_</Text> surround it with  <Text code={true}>[]</Text> eg. <Text code={true}>[customer name]</Text>{' '}
        </>
      ),
      example: 'total_amount',
    },
    {
      key: 'string',
      name: 'String',
      desc: (
        <>
          Can be any text. Must be wrapped in <Text code={true}>""</Text>{' '}
        </>
      ),
      example: '"New York"',
    },
    {
      key: 'number',
      name: 'Number',
      desc: 'Can be any positive or negative integer or decimal value. Do not include formatting such as "$", "%", or "," ',
      example: '1500.25',
    },
  ],
}
export const operators = [
  { value: '==', title: 'is equal to', label: 'is equal to' },
  { value: '!=', title: 'is not equal to', label: 'is not equal to' },
  { value: '>', title: 'is greater than', label: 'is greater than' },
  { value: '>=', title: 'is greater than or equal to', label: 'is greater than or equal to' },
  { value: '<', title: 'is less than', label: 'is less than' },
  { value: '<=', title: 'is less than or equal to', label: 'is less than or equal to' },
  { value: 'isIn', title: 'is in', label: 'is in' },
  { value: 'isNotIn', title: 'is not in', label: 'is not in' },
  { value: 'contains', title: 'contains', label: 'contains' },
  { value: 'doesNotContain', title: 'does not contain', label: 'does not contain' },
  { value: 'isEmpty', title: 'is empty', label: 'is empty' },
  { value: 'isNotEmpty', title: 'is not empty', label: 'is not empty' },
  { value: 'isNull', title: 'is null', label: 'is null' },
  { value: 'isNotNull', title: 'is not null', label: 'is not null' },
  { value: 'matches', title: 'matches', label: 'matches' },
]

export const defaults = {
  condition: {
    operand1: '',
    operator: null,
    operand2: '',
  },
  loop: '',
  sorts: [],
}

export const formStyle = { background: '#f5f5f5', padding: '1em', borderRadius: '5px' }

export const syntaxRules = [
  { pattern: /^[^{}]+$/, message: 'Variable names should not contain "{{" or "}}"' },
  { pattern: /^[^\[\]]+$/, message: 'Variable names should not contain "[" or "]"' },
  // { pattern: /^[^\s]+$/, message: 'Variable names should not contain spaces' },
]

const consts = { dataTypes, operators, defaults, formStyle }

export default consts
