import React, { useState, useEffect, useCallback } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import DocumintEditor from '@documint/ckeditor5-build'
import { isFunction, pick, isEqual } from 'lodash'

import { useDesigner, useEditor } from '../hooks'

const config = {
  toolbar: [
    'undo',
    'redo',
    '|',
    'fontfamily',
    'fontsize',
    'bold',
    'italic',
    'underline',
    'fontcolor',
    '|',
    'link',
    'imageUpload',
    '|',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    'alignment:justify',
    '|',
    'insertTable',
    'htmlembed',
  ],
  table: {
    contentToolbar: ['tableProperties', 'tableCellProperties', '|', 'tableColumn', 'tableRow', 'mergeTableCells'],
  },
  image: {
    toolbar: ['imageStyle:full', 'imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight'],
    styles: ['full', 'alignLeft', 'alignCenter', 'alignRight'],
  },
}

export default function HeaderFooterEditor({ open, onClose }) {
  const { template, updateHeaderFooterTemplate } = useDesigner()
  const { editor } = useEditor()
  const [header, setHeader] = useState(template?.headerTemplate)
  const [footer, setFooter] = useState(template?.footerTemplate)

  const getEditorWidth = useCallback(() => {
    if (!editor) return 930
    const body = editor.Canvas?.getBody?.()
    if (!body) return 930
    return body.offsetWidth
  }, [editor])

  const editorWidth = getEditorWidth()

  const handleClose = useCallback(() => {
    const updateValues = { headerTemplate: header, footerTemplate: footer }
    const hasUnsavedChanges = !isEqual(pick(template, ['headerTemplate', 'footerTemplate']), updateValues)
    if (hasUnsavedChanges) updateHeaderFooterTemplate(updateValues)
    if (isFunction(onClose)) onClose(updateValues)
  }, [header, footer, template, onClose, updateHeaderFooterTemplate])

  // Save changes if header footer template is closed
  useEffect(() => {
    if (open === false) handleClose()
  }, [open, handleClose])

  const wrapperProps = {
    style: {
      position: 'fixed',
      top: '65px',
      bottom: 0,
      left: 0,
      right: 0,
      display: open ? 'block' : 'none',
      zIndex: 1000,
    },
  }

  const editorWrapperStyles = {
    position: 'absolute',
    width: `${editorWidth}px`,
    left: `calc(50% - ${editorWidth / 2}px)`,
    height: '40%',
    minHeight: '200px',
    maxHeight: '50%',
    border: 'none',
    zIndex: 15,
  }

  return (
    <div id='header-footer-editor' {...wrapperProps}>
      <div className='editor__wrapper editor__wrapper--header' style={{ ...editorWrapperStyles, top: 0 }}>
        <CKEditor
          className='header-editor'
          editor={DocumintEditor}
          data={header}
          config={config}
          onChange={(event, editor) => setHeader(editor.getData())}
        />
      </div>

      <div className='editor__wrapper editor__wrapper--footer' style={{ ...editorWrapperStyles, bottom: 0 }}>
        <CKEditor
          className='footer-editor'
          editor={DocumintEditor}
          data={footer}
          config={config}
          style={{ height: '100%' }}
          onChange={(event, editor) => setFooter(editor.getData())}
        />
      </div>

      <div
        className='mask'
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          background: 'rgba(0,0,0,.3)',
          zIndex: 5,
        }}
        onClick={handleClose}
      ></div>
    </div>
  )
}

HeaderFooterEditor.defaultProps = {
  headerData: '<div>Header Data</div>',
  footerData: '<div>Footer Data</div>',
}
