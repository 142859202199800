const type = 'wrapper'

export const protectedCss = `
  /* WRAPPER COMPONENT */
  .${type}{
    font-family: Arial, sans-serif;
    font-size: 14px;
    font-weight: 400;
    background-repeat: no-repeat;
  }
  #${type}{
    position: relative;
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;  
    float: none;
  }
`

export default function wrapperType(dc, options) {
  dc.addType(type, {
    model: {
      defaults: {
        tagName: 'body',
        name: 'Body',
        icon: '<i class="fa fa-file"></i>',
        attributes: {
          id: type,
        },
        classes: `${type}`,
        traits: [],
        removable: false,
        copyable: false,
        draggable: false,
        droppable: true,
        selectable: true,
        badgable: true,
        hoverable: false,
        stylable: [
          'font-family',
          'font-size',
          'text-align',
          'line-height',
          'background-color',
          'background-image',
          'background-size',
          'background-position',
          'background-repeat',
          // 'padding',
          'better-borders',
          'border-color',
          'border-width',
          'border-style',
          'border-radius',
          'border-top-left-radius',
          'border-top-right-radius',
          'border-bottom-right-radius',
          'border-bottom-left-radius',
        ],
      },
    },
    isComponent(el) {},
  })
}
