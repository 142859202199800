import { createContext, useRef, useEffect, useCallback } from 'react'
import config from '@config'
import { useSelector } from 'react-redux'
import { selectJwt } from '../slice'

export const ActivePiecesContext = createContext()

const loadActivePieces = () => {
  return new Promise((resolve, reject) => {
    if (window.activepieces) return window.activepieces
    const activePieces = document.createElement('script')
    activePieces.setAttribute('src', 'https://cdn.activepieces.com/sdk/embed/0.3.0.js')
    activePieces.addEventListener('load', () => resolve(window.activepieces))
    activePieces.addEventListener('error', reject)
    document.head.appendChild(activePieces)
  })
}

export const ActivePiecesProvider = ({ children }) => {
  const iframeRef = useRef(null)
  const apRef = useRef(null)
  const jwt = useSelector(selectJwt)

  const initialize = useCallback(async () => {
    if (!jwt) return

    // Load activepieces
    const ap = await loadActivePieces()

    // Configure activepieces
    ap.configure({ jwtToken: jwt, instanceUrl: config.activePiecesInstanceUrl })

    return ap
  }, [jwt])

  useEffect(() => {
    if (jwt && !window.activepieces) initialize()
  }, [jwt, initialize])

  return (
    <ActivePiecesContext.Provider value={{ iframeRef, activepieces: apRef.current }}>
      {children}
    </ActivePiecesContext.Provider>
  )
}
