import React, { useState, useEffect, useCallback, useRef } from 'react'
import { notification } from 'antd'
import { AutoForm, AutoFields } from 'uniforms-antd'
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema'

import { pickWithJsonSchema, convertTypes, addItemsToCollection, deepClone } from '@utils'
import Button from '@common/Button'

export default function TemplatePreviewForm(props) {
  const { template, onSubmit, ...rest } = props
  const { fieldSchema, testData } = template
  const [bridge, setBridge] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)

  const schema = React.useMemo(() => {
    if (!fieldSchema) return null
    let schema = { ...fieldSchema }
    delete schema.$sourceMetaData

    // Remove source meta data from schema
    // This is necessary because the schema is used to generate the form
    // and the $sourceMetaData is not a valid JSON schema property
    function deepRemoveSourceMetaData(obj) {
      let newObj = { ...obj }
      delete newObj.$sourceMetaData
      if (newObj.properties) {
        newObj.properties = Object.entries(newObj.properties).reduce((acc, [key, value]) => {
          acc[key] = deepRemoveSourceMetaData(value)
          return acc
        }, {})
      }
      if (newObj.items) {
        newObj.items = deepRemoveSourceMetaData(newObj.items)
      }
      return newObj
    }

    schema = deepRemoveSourceMetaData(schema)

    return addItemsToCollection(convertTypes(deepClone(schema)))
  }, [fieldSchema])

  const formRef = useRef(null)

  const handleSubmit = useCallback(
    (model) => {
      setHasChanges(false)
      onSubmit && onSubmit(model)
    },
    [onSubmit],
  )

  useEffect(() => {
    try {
      if (!schema) return
      const validator = (model) => null
      const newBridge = new JSONSchemaBridge({schema, validator})
      setBridge(newBridge)
    } catch (error) {
      console.log(error)
      notification.error({ message: 'Invalid variable' })
    }
  }, [setBridge, schema])

  const handleChange = useCallback(() => !hasChanges && setHasChanges(true), [hasChanges])

  if (!bridge) return <></>

  const buttonTooltip = !hasChanges && { title: 'Add or edit the test data to update the preview' }

  return (
    <div className='d-flex flex-column' style={{ flex: '1 1 auto', maxHeight: '100%' }} {...rest}>
      <div style={{ flex: '1 1 auto', overflow: 'auto' }}>
        <AutoForm
          schema={bridge}
          onChange={handleChange}
          onSubmit={handleSubmit}
          model={pickWithJsonSchema(schema, testData)}
          layout="vertical"
          ref={formRef}
          className={"preview-auto-form"}
        >
          <AutoFields />
        </AutoForm>
      </div>
      <div style={{ flex: '0 0 50px', padding: '10px', borderTop: 'solid 1px #eee' }}>
        <Button
          disabled={!hasChanges}
          tooltip={buttonTooltip}
          type='primary'
          style={{ height: '100%' }}
          onClick={() => formRef.current.submit()}
          block
          size='large'
        >
          Preview document
        </Button>
      </div>
    </div>
  )
}
