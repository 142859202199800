import React, { useCallback, useEffect, useState } from 'react'
import { Alert, notification, Card, Button, Typography, Row, Col } from 'antd'
import { useTemplateDataSource } from '../../hooks'
import { defaultSchema } from '@documint/react-antd-json-schema-builder'
import '@documint/react-antd-json-schema-builder/dist/esm/style.css'
import { MODES, MODE_CONTENTS } from './consts'

import { Container, Icon } from '../index.style'
import PasteSchemaInput from './PasteSchemaInput'
import SchemaBuilder from './SchemaBuilder'
import { FaIcon } from '@components/icons'
import Actions from './Actions'
import { DataSourceIcon } from '..'
import { DisconnectOutlined } from '@ant-design/icons'

const { Title } = Typography

export default function CustomDataSource() {
  const {
    selectedDataSource,
    fieldSchema,
    isModalOpen,
    toggleIsModalOpen,
    downloadSchema,
    clearHistory,
    refreshSchema,
    updateFieldSchema,
    clearSchema,
  } = useTemplateDataSource()
  const [tempFieldSchema, setTempFieldSchema] = useState()
  const [mode, setMode] = useState(fieldSchema && MODES.build)
  const isDisconnected = selectedDataSource?.disconnected
  useEffect(() => {
    if (tempFieldSchema) setMode(MODES.build)
  }, [tempFieldSchema, isModalOpen])

  useEffect(() => {
    if (fieldSchema && !tempFieldSchema) setTempFieldSchema(fieldSchema)
  }, [fieldSchema, tempFieldSchema])

  const handleSavePastedSchema = useCallback(
    (value) => {
      setTempFieldSchema(value)
      refreshSchema(value)
      setMode(MODES.build)
    },
    [setTempFieldSchema, refreshSchema],
  )

  const handleModeSelect = useCallback(
    (mode) => {
      setMode(mode.key)
      if (mode.key === MODES.build) refreshSchema(fieldSchema || defaultSchema)
    },
    [fieldSchema, refreshSchema],
  )

  const handleSave = useCallback(async () => {
    try {
      updateFieldSchema(tempFieldSchema)
      toggleIsModalOpen(false)
      setMode(null)
    } catch (error) {
      notification.error({ message: 'Error', description: error.message, placement: 'bottom' })
    }
  }, [updateFieldSchema, toggleIsModalOpen, tempFieldSchema])

  const handleUnsavedCancel = useCallback(() => {
    toggleIsModalOpen(false)
    setTempFieldSchema(fieldSchema)
    clearHistory()
    setMode(null)
  }, [toggleIsModalOpen, fieldSchema, clearHistory])

  const handleSchemaBuilderChange = useCallback((schema) => setTempFieldSchema(schema), [setTempFieldSchema])

  const handleDownloadSchema = useCallback(() => downloadSchema(), [downloadSchema])

  const handleClearSchema = useCallback(() => {
    try {
      clearSchema()
      setMode(null)
    } catch (error) {
      notification.error({ message: 'Error', description: error.message, placement: 'bottom' })
    }
  }, [clearSchema])

  return (
    <Alert.ErrorBoundary>
      <Row className='mb-2'>
        <Col span={6}>
          {(mode === MODES.paste || (mode === MODES.build && !fieldSchema)) && (
            <Button icon={<FaIcon icon='faChevronLeft' />} onClick={() => setMode(null)} type='text'>
              Back
            </Button>
          )}
        </Col>
        <Col span={12}>
          <div className='d-flex align-items-center justify-content-center'>
            <Title level={4} className='mb-0'>
              <DataSourceIcon
                icon={selectedDataSource?.icon}
                style={{ maxHeight: '26px', fontSize: '20px', marginRight: '12px', marginTop: '-4px' }}
                badge={isDisconnected && <DisconnectOutlined style={{fontSize:'10px'}} />}
                badgeProps={{
                  style: { backgroundColor: 'rgb(255 102 102)', color: 'white', borderRadius: '20px', padding: '3px', border:'solid 2px white' },
                  offset: [-13, 16],
                }}
              />
              {fieldSchema ? 'Schema Designer' : 'Create a custom schema'}
            </Title>
          </div>
        </Col>
      </Row>

      {/* BUILDER MODE */}
      {mode === MODES.build ? (
        <SchemaBuilder onChange={handleSchemaBuilderChange} />
      ) : mode === MODES.paste ? (
        <PasteSchemaInput onSave={handleSavePastedSchema} />
      ) : (
        <Container>
          {MODE_CONTENTS.map((m) => (
            <Card key={m.key} cover={<Icon icon={m.icon} />} hoverable onClick={() => handleModeSelect(m)}>
              <Card.Meta title={m.title} description={m.description} />
            </Card>
          ))}
        </Container>
      )}

      <Actions
        className='d-flex align-items-center justify-content-end pt-3'
        style={{ gap: '10px' }}
        fieldSchema={fieldSchema}
        onDownload={handleDownloadSchema}
        onSave={handleSave}
        onCancel={handleUnsavedCancel}
        onClear={handleClearSchema}
        mode={mode}
      />
    </Alert.ErrorBoundary>
  )
}
