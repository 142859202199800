import React, { useEffect, useState, useCallback } from 'react'
import { Modal, Alert, Typography } from 'antd'
import { defaults } from './consts'
import { toJson, validateCustomCondition } from '../NewLogicModal/utils'
import Value from './Value'
import { useDesigner, useEditor } from '../../hooks'
import { uniqueCollection } from '@utils'

const { Text } = Typography
const { error } = Modal
const { type: t, data: d, path: p, condition: c, ...rest } = defaults
const defaultMetaData = { ...rest }

export default function TokenModal({ visible, modalProps, onOk, onCancel }) {
  const { template } = useDesigner()
  const { editor } = useEditor()
  const selectedComponent = editor?.getSelected()
  const attr = selectedComponent?.getAttrToHTML() ?? {}

  const dataArrayNesting = attr['data-array-nesting']
  const dataParentType = attr['data-parent-type']
  const dataTypeAttr = attr['data-type']
  const dataParentNesting = attr['data-parent-nesting']
  const dataArrayType = attr['data-array-type']
  const dataPlaceholder = attr['data-placeholder']
  const dataTokenMetadata = attr['data-token-meta-data']

  const { fieldSchema } = template || {}

  const [type, setType] = useState(t)
  const [data, setData] = useState(d)
  const [path, setPath] = useState(p)
  const [nesting, setNesting] = useState(0)
  const [parentNesting, setParentNesting] = useState(0)
  const [objectNesting, setObjectNesting] = useState(0)
  const [arrayType, setArrayType] = useState('')
  const [condition, setCondition] = useState(c)
  const [parentType, setParentType] = useState(null)
  const [tokenMetaData, setTokenMetaData] = useState(defaultMetaData)
  // const [forwardedMetaData, setForwardedMetaData] = useState({});
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    if (!attr['data-type']) return
    const arrayNesting = dataArrayNesting
    const parentType = dataParentType
    const dataType = dataTypeAttr
    setType(dataType)
    setParentType(parentType)
    setNesting(arrayNesting)
    setParentNesting(dataParentNesting)
    setArrayType(arrayNesting || parentType === 'array' ? dataArrayType : 'string')

    const tokenKey = dataPlaceholder
    if (tokenKey) {
      const ref = editor.SchemaKeys
      const id = Object.keys(ref).find((k) => ref[k]?.key === tokenKey.split('.')[0]) || ''
      const rootNesting = editor.SchemaKeys[id]?.schemaMetaData?.nestingObj ?? {}
      if ([dataType, parentType].includes('array')) {
        setObjectNesting(rootNesting)
      } else {
        setObjectNesting({})
      }
    }

    setData(tokenKey)
    setPath(tokenKey)
    setCondition('')
    setTokenMetaData(defaultMetaData)
    const parsedMetaData = toJson(dataTokenMetadata)
    if (parsedMetaData) {
      const { condition, ...rest } = parsedMetaData
      condition && setCondition(condition)
      setTokenMetaData(rest)
    }
    // const parsedForwardedMetaData = toJson(attr["data-schema-meta-data"]);
    // parsedForwardedMetaData && setForwardedMetaData(parsedForwardedMetaData);
 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataArrayNesting,
    dataParentType, 
    dataTypeAttr,
    dataParentNesting,
    dataArrayType,
    dataPlaceholder,
    dataTokenMetadata,
    fieldSchema,
    editor,
    visible
  ])

  function handleCancel() {
    setValidationError('')
    if (onCancel) onCancel()
  }

  const handleChange = (value) => {
    setTokenMetaData({ ...tokenMetaData, ...value })
  }

  /**
   * Handle Save
   */
  function handleOk() {
    const parsedCondition = toJson(condition)

    const isConditionValid =
      condition == null ||
      condition === '' ||
      (parsedCondition &&
        parsedCondition['custom-condition'] &&
        validateCustomCondition(parsedCondition['custom-condition']).status) ||
      !!parsedCondition?.type

    if (!selectedComponent)
      error({
        title: 'Upgrade failed',
        content: 'There is no selected token element.',
      })
    if (isConditionValid && selectedComponent)
      selectedComponent.addAttributes({
        'data-token-meta-data': JSON.stringify({ ...tokenMetaData, condition }),
      })
    if (onOk && isConditionValid) {
      setValidationError('')
      onOk()
    } else setValidationError(validateCustomCondition(parsedCondition['custom-condition']).error)
  }

  const hasContext = useCallback(() => {
    if (!selectedComponent) return false
    const attributes = selectedComponent.getAttrToHTML()

    const formattedName = attributes['data-formatted-placeholder']
    const contextList = toJson(attributes['data-context'])
    const context = contextList ? uniqueCollection(contextList) : ['root']
    const lastContext = context[context.length - 1]

    return lastContext !== 'root' && formattedName?.includes(lastContext)
  }, [selectedComponent])

  return (
    <Modal
      {...modalProps}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={650}
      okText='Save'
      title='Edit token'
      destroyOnClose={true}
    >
      <Value
        data={{
          type,
          parentType,
          data,
          path,
          nesting,
          parentNesting,
          objectNesting,
          arrayType,
          tokenMetaData,
          // forwardedMetaData,
        }}
        condition={condition}
        setCondition={setCondition}
        onChange={handleChange}
        hasContext={hasContext()}
      />
      {validationError && <Alert message={<Text>{validationError}</Text>} type='error' />}
    </Modal>
  )
}
