import React, { useRef, useCallback, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Typography, Row, Col, Divider } from 'antd'

import MainLayout from '@Layouts/MainLayout'
import { LoginForm } from '@features/auth'
import { useValidateSession } from '@features/auth'
import config from '../../config'

const { Title } = Typography

export default function Login() {
  const history = useHistory()
  const location = useLocation()
  const [validate] = useValidateSession()

  const redirect = useCallback(() => {
    history.replace(fromRef.current)
  }, [history])

  useEffect(() => {
    validate().then((isValid) => {
      if (isValid) return redirect()
    })
  }, [redirect])

  const fromRef = useRef(location?.state?.from || { pathname: '/' })

  return (
    <MainLayout>
      <Row align='stretch' className='vh-100'>
        <Col xs={24} md={12} lg={10} xl={8} xxl={6} className='bg--white'>
          <div className='d-flex align-items-center h-100 p-5'>
            <div id='login' className='w-100'>
              <a href='https://documint.me'>
                <img id='register__logo' src='/images/logo.svg' alt='Documint Logo' />
              </a>

              <Title level={3}>Welcome Back!</Title>

              {/* FORM */}
              <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
                <LoginForm onFinish={redirect} />
              </GoogleReCaptchaProvider>

              <Divider />

              {/* ADDITIONAL LINKS */}
              <div className='text-center'>
                <p>
                  Don't have an account yet?{' '}
                  <Link to={{ pathname: '/register', state: { from: location } }}>Sign up</Link>
                </p>
                <Link to={{ pathname: '/auth/recover-password', state: { from: location } }}>
                  Forgot your password?
                </Link>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={0} md={12} lg={14} xl={16} xxl={18} className='bg p-5'>
          <div
            className='h-100'
            style={{
              backgroundImage: 'url(/images/register-bg.svg)',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          ></div>
        </Col>
      </Row>
    </MainLayout>
  )
}
