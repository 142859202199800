import { isUuidv4, uuidv4, getFieldById } from '../../utils'
import { FieldType } from '@airtable/blocks/models'
import { createField } from './fields'

const { MULTIPLE_RECORD_LINKS } = FieldType

const isCalculatedField = (fieldType) =>
  fieldType === FieldType.FORMULA || fieldType === FieldType.MULTIPLE_LOOKUP_VALUES || fieldType === FieldType.ROLLUP

const getCalculatedTypeIcon = (field) => {
  return FieldType.MULTIPLE_LOOKUP_VALUES === field.type
    ? 'fa-kit fa-airtable-lookup'
    : `fa-kit fa-airtable-${field.type}`
}

const isLinkedRecordField = (field) => {
  return (
    field?.options?.result?.type === MULTIPLE_RECORD_LINKS ||
    field?.result?.type === MULTIPLE_RECORD_LINKS ||
    field?.originalType === MULTIPLE_RECORD_LINKS ||
    field?.type === MULTIPLE_RECORD_LINKS ||
    field === MULTIPLE_RECORD_LINKS
  )
}

export function toJsonSchema(
  atFields,
  {
    editor,
    tables,
    linkedRecords, // Optional: Only return linked record fields
    parentIds = [],
    parentInverseLinkFieldId = [],
    parentKey = '',
    parentsCompoundFieldId = '',
  },
) {
  return atFields.reduce(
    (schema, field) => {
      const fieldResultType = field?.options?.result?.type

      if (linkedRecords && !isLinkedRecordField(field)) return schema

      const lookupLinkedField = getFieldById(tables, field?.options?.recordLinkFieldId) ?? {}

      let usedField = field

      if (lookupLinkedField && isLinkedRecordField(fieldResultType)) {
        const { id, name, ...originalField } = field
        const options = { ...field.options, ...(lookupLinkedField.options || {}) }
        usedField = { ...originalField, ...lookupLinkedField, id, name, options }
      }

      const parsedField = createField(usedField, {
        editor,
        parentsCompoundFieldId,
        linkedRecords,
      })

      // * Handle occurance of new unsuppported fields
      if (!parsedField) return schema

      const { id, cid, type, $sourceMetaData, items, properties, fields, iconClass } = parsedField

      // const buUUID = getUuidFromFormattedKey(parentKey ? `${parentKey}.[${id}]` : `[${id}]`) // Backup uuid: uses formatted key to reference uuid
      const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${cid ?? id}` : cid ?? id
      const uuid = editor?.SchemaIds[compoundFieldId]?.uuid

      const property = {
        id,
        uuid: isUuidv4(uuid) ? uuid : uuidv4(),
        cid,
        type,
        $sourceMetaData: {
          ...$sourceMetaData,
          ...(isCalculatedField(field.type) ? { iconClass: getCalculatedTypeIcon(field) } : { iconClass }),
        },
        ...(items ? { items } : {}),
        ...(properties ? { properties } : {}),
      }

      const lookupLinkedId = lookupLinkedField?.id ? [lookupLinkedField?.id] : []

      const circularReference =
        parentInverseLinkFieldId.includes(cid) ||
        parentIds.includes(cid) ||
        parentInverseLinkFieldId.includes(lookupLinkedId[0]) ||
        parentIds.includes(lookupLinkedId[0])

      if (!circularReference && isLinkedRecordField($sourceMetaData)) {
        const { properties } = fields
          ? toJsonSchema(fields, {
              linkedRecords,
              parentIds: [...parentIds, ...lookupLinkedId, cid],
              parentInverseLinkFieldId: [...parentInverseLinkFieldId, $sourceMetaData.inverseLinkFieldId],
              parentKey: parentKey ? `${parentKey}.[${id}]` : `[${id}]`,
              parentsCompoundFieldId: compoundFieldId,
              editor,
              tables,
            })
          : { properties: property.properties ?? {} }

        const hasProps = Object.keys(properties).length
        schema.properties[id] = {
          ...property,
          type: 'array',
          items: { type: hasProps ? 'object' : 'string', ...(hasProps ? { properties } : {}) },
        }
      } else if (!isLinkedRecordField($sourceMetaData.originalType)) {
        schema.properties[id] = property
      }

      return schema
    },
    {
      $schema: 'http://json-schema.org/draft-07/schema#',
      type: 'object',
      title: 'root',
      properties: {},
    },
  )
}

const AirtableParser = { toJsonSchema }

export default AirtableParser
