import React, { useState } from 'react'
import { Form, Radio, Select, Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'

import { pickBy, identity, isFunction } from 'lodash'

const { Text } = Typography

const WATCH_MODES = [
  { label: 'None', value: '' },
  { label: 'Watch paths', value: 'watch_paths' },
  { label: 'Ignore paths', value: 'ignore_paths' },
]

const RENDER_MODES = {
  standard: { label: 'Default', value: '' },
  preview: { label: 'Preview', value: 'preview' },
  active: { label: 'Force Active', value: 'active' },
}

export default function DataSourceInstructionsOptionsForm({ onChange, renderModes, ...rest }) {
  const [values, setValues] = useState({})

  const [isValid, setIsValid] = useState(false)

  const _renderModes = renderModes?.map?.((mode) => RENDER_MODES[mode]).filter((mode) => !!mode)

  const handleFieldsChange = (changedField, allFields) => {
    const hasErrors = !allFields.every(({ errors }) => errors.length <= 0)
    if (hasErrors === isValid) setIsValid(!hasErrors)
    const values = allFields.reduce((acc, cur) => Object.assign({}, acc, { [cur.name[0]]: cur.value }), {})
    setValues(values)
    const cleanedValues = mapValues(values)

    setValues(values)
    if (!hasErrors && isFunction(onChange)) onChange(cleanedValues)
  }

  const fields = [
    {
      name: 'renderMode',
      input: <Radio.Group options={_renderModes} optionType='button' />,
      render: !!_renderModes,
      label: {
        value: 'Mode',
        help: (
          <>
            <b>Preview Mode</b>
            <br /> Documents created in preview mode will be rendered with document branding and won't count towards
            your quota
            <br />
            <b>Active Mode</b>
            <br /> Overrides template's Active status forcing an inactive template to be active.
          </>
        ),
      },
      className: !_renderModes ? 'd-none' : '',
    },
    {
      name: 'watchMode',
      input: <Radio.Group options={WATCH_MODES} optionType='button' />,
      label: {
        value: 'Watch / ignore data changes',
        help: (
          <>
            List of fields in your data to either watch or ignore when checking for an existing document created from
            this template.
            <a
              href='https://docs.documint.me/integrations/airtable/generation-link#duplicate-document-avoidance'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more &gt;
            </a>
          </>
        ),
      },
    },
    {
      name: 'watchFields',
      render: !!values.watchMode,
      input: (
        <Select
          mode='tags'
          placeholder={
            <>
              Press <Text code>enter</Text>/<Text code>return</Text> to add multiple items.
            </>
          }
          filterOption={false}
          allowClear={true}
          open={false}
        />
      ),
      label: {
        value: 'Watch / ignore data changes',
        help: (
          <>
            List of fields in your data to either watch or ignore when checking for an existing document created from
            this template.
            <a
              href='https://docs.documint.me/integrations/airtable/generation-link#duplicate-document-avoidance'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more &gt;
            </a>
          </>
        ),
      },
    },
  ]

  return (
    <Form onFieldsChange={handleFieldsChange} {...rest}>
      {fields.map(({ name, input, label, render, ...props }) => {
        if (render === false) return null
        return (
          <Form.Item key={name} name={name} {...props} label={renderLabel(label)}>
            {input}
          </Form.Item>
        )
      })}
    </Form>
  )
}

function mapValues({ renderMode, watchMode, watchFields }) {
  const mappedValues = {}
  if (renderMode) mappedValues[renderMode] = true

  const watchFieldURI = Array.isArray(watchFields) && watchFields.map(encodeURIComponent).join(',')
  if (watchMode && watchFieldURI) mappedValues[watchMode] = watchFieldURI

  return pickBy(mappedValues, identity)
}

export function renderLabel({ value = '', help, ...props }) {
  const content = <Text {...props}>{value}</Text>

  if (!help) return content

  return (
    <Tooltip title={help}>
      {content}
      <QuestionCircleOutlined className='ml-1' />
    </Tooltip>
  )
}

DataSourceInstructionsOptionsForm.defaultProps = {
  renderModes: Object.keys(RENDER_MODES),
}
