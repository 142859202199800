/**
 * @typedef EntityField
 * @property {string} id
 * @property {string} name
 * @property {string} action
 * @property {string} value
 * @property {string} key
 * @property {function} getQuery
 * @property {function} handleResponse
 * @property {Connection} connection
 */
export class EntityConfigField {
  _value = null
  _entity = null
  _dataSource = null

  constructor(config = {}, dataSource) {
    const { id, name, action, handleResponse, value, key, getQuery, getValue, setValue } = config
    this.id = id
    this.name = name
    this.action = action
    this._value = value
    this.key = key || id
    if (handleResponse) this.handleResponse = handleResponse
    if (getQuery) this.getQuery = getQuery
    if (getValue) this.getValue = getValue
    if (setValue) this.setValue = setValue

    if (dataSource) {
      this._dataSource = dataSource
      this._entity = dataSource.entity
    }
  }

  get entity() {
    return this._entity
  }

  set entity(entity) {
    throw new Error('Cannot set entity directly. Use the constructor to set the entity.')
  }

  get value() {
    return this._value
  }

  set value(value) {
    this._value = value
  }

  handleResponse(response) {
    return this.handleResponse?.(response) || response
  }

  getQuery() {
    return { action: this.action }
  }

  getValue() {
    return this.value
  }

  setValue(value) {
    this.value = value
  }

  toObject() {
    const { id, name, action, value, key } = this
    return { id, name, action, value, key }
  }
}

export default EntityConfigField
