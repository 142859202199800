import { FaIcon } from '@components/icons'
import React from 'react'
import {
  BarsOutlined,
  CheckOutlined,
  NumberOutlined,
  PercentageOutlined,
  FontSizeOutlined,
  DollarOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  AppstoreOutlined,
} from '@ant-design/icons'
import { Badge } from 'antd'

export const GeneralIcon = ({ types, ...props }) => {
  switch (types) {
    case 'array':
      return <BarsOutlined {...props} />
    case 'boolean':
      return <CheckOutlined {...props} />
    case 'number':
      return <NumberOutlined {...props} />
    case 'percent':
      return <PercentageOutlined {...props} />
    case 'currency':
      return <DollarOutlined {...props} />
    case 'date':
      return <CalendarOutlined {...props} />
    case 'duration':
      return <ClockCircleOutlined {...props} />
    case 'object':
      return <AppstoreOutlined {...props} />
    case 'nested':
      return <FaIcon icon='faListTree' {...props} />
    default:
      return <FontSizeOutlined {...props} />
  }
}

export default function DataSourceIcon({ icon, name = '', style = {}, badge, badgeProps, ...rest }) {
  const { style: iconStyle = {}, type, value, ...iconProps } = icon || {}

  style = { ...iconStyle, ...style }
  const otherProps = { ...iconProps, ...rest }

  let iconComponent = null

  switch (type) {
    case 'image':
      iconComponent = (
        <img src={value} alt={name} style={{ maxWidth: '100%', maxHeight: '34px', ...style }} {...otherProps} />
      )
      break
    case 'icon':
      iconComponent = <FaIcon icon={value} style={style} {...otherProps} />
      break
    default:
      iconComponent = null
  }

  if (!badge || !iconComponent) return iconComponent

  return (
    <Badge count={badge} {...badgeProps}>
      {iconComponent}
    </Badge>
  )
}
