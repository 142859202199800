import React from 'react'
import { Space, Typography } from 'antd'

import { DataSourceCards } from '@features/template-data-source'
import VariableList from './VariableList'
import config from '@config'

const { Title, Text, Link } = Typography

export default function DataPanel() {
  return (
    <div>
      <div className='p-3'>
        <DataSourceCards
          title={
            <Title level={5} className='mb-3'>
              Source
            </Title>
          }
        />
      </div>

      <VariableList title={<Title level={5}>Tokens</Title>} />

      <Space direction='vertical' className='m-3'>
        <Title level={5} className='text--primary'>
          What are Variables?
        </Title>

        <Text className='text--light'>
          Variables act as placeholders in your template. They tell Documint where to place data when creating a
          document.
        </Text>

        <Link href={`${config.documentationUrl}/guides/adding-variables`} target='_blank'>
          Learn how to add Variables to your template
        </Link>
      </Space>
    </div>
  )
}
