import { TYPES } from '../consts'
import { listenTraits, getScopedName, refreshIndex, applyRoots } from './Checkbox'
import { parent, clone, remove } from '../../../config/toolbarItems'
import { getParentLoop } from './Token'

export const type = TYPES.link

export const protectedCss = `
  /* BUTTON COMPONENT */
  *[data-gjs-type="${type}"]{
    text-decoration: none;
  }
`

export default function linkType(dc, { editor }) {
  const model = {
    defaults: {
      name: 'Link',
      icon: '<i class="fa fa-link"></i>',
      draggable: false,
      droppable: false,
      traits: [
        {
          name: 'data-value',
          type: 'switch',
          valueTrue: 'variable',
          valueFalse: 'static',
          label: 'Variable',
        },
        {
          label: 'URL',
          name: 'href',
          parent: 'data-value=static',
          placeholder: 'https://documint.me',
        },
        {
          name: 'varName',
          type: 'combo',
          label: 'Variable Name',
          parent: 'data-value=variable',
          placeholder: 'e.g. var',
          supportedTypes: ['string'],
          changeProp: true,
        },
        {
          name: 'variableIndex',
          type: 'info-text',
          info: 'You can use multiple indices by comma separating them.',
          label: 'Index',
          parent: 'data-value=variable',
          placeholder: '0',
          changeProp: true,
          min: 0,
        },
      ],
      attributes: {
        'data-value': 'static',
        'data-context': '',
      },
      stylable: [
        'background-color',
        'background-image',
        'background-size',
        'background-position',
        'background-repeat',
        'color',
        'text-align',
        'font-size',
        'font-family',
        'font-style',
        'text-decoration',
        'font-weight',
        'line-height',
        'word-break',
        'word-wrap',
        'text-transform',
        'letter-spacing',
        'border-width',
        'border-color',
        'border-style',
        'padding',
        'padding-top',
        'padding-right',
        'padding-bottom',
        'padding-left',
        'better-borders',

        'border-radius',
        'border-top-left-radius',
        'border-top-right-radius',
        'border-bottom-right-radius',
        'border-bottom-left-radius',
      ],
      toolbar: [parent, clone, remove],
    },
    init() {
      listenTraits(this)
      this.on('change:attributes:data-value', this.handleValueChange)
      this.on('change:varName change:attributes:data-context', this.handleVariableChange)
      this.on('change:variableIndex', this.updateIndices)
      this.afterInit()
    },
    handleValueChange() {
      const value = this.getAttributes()['data-value']
      if (value !== 'static') this.handleVariableChange()
      else this.getAttributes()['href']?.startsWith('{') && this.addAttributes({ href: '' })
    },
    handleVariableChange() {
      this.refreshIndex()

      this.updateIndices()
    },
    updateIndices() {
      let varName = this.get('varName')
      varName = this.applyRoots(editor, varName)
      varName = getScopedName(varName, this.getAttrToHTML())
      varName && this.addAttributes({ href: `{{${varName}}}` })
    },
    afterInit() {},
    setContext() {
      this.addAttributes({ 'data-context': getParentLoop(this, this.get('varName')) })
    },
    refreshIndex,
    applyRoots,
    hasCondition() {
      return false
    },
    hasLoop() {
      return false
    },
  }

  dc.addType(type, { model })
}
