export default function infoTextTraitType(editor, opts = {}) {
  const options = {
    name: 'info-text',
  }
  const { name } = options
  const tm = editor.Traits
  const text = tm.getType('text')

  tm.types[name] = text.extend({
    createLabel({ label, trait: { model } }) {
      const title = model.get('info')
      return `<div class="gjs-label-wrp-2" ${title ? `title="${title}"` : ''}>
            ${label}
            ${title ? `<i class="fa-solid fa-square-info"></i>` : ''}
          </div>`
    },
  })
}
