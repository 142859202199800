import _, { take, takeRight, isInteger, round } from 'lodash'

import handlebars from 'handlebars'
import customHelpers from '@documint/custom-handlebars-helpers'

import * as dataUtils from './data'
import * as jsonUtils from './json'
import * as fileUtils from './file'

customHelpers({ handlebars })

/**
 * @param {String} template Handlebars HTML Template
 */
export function validateTemplate(template) {
  try {
    handlebars.compile(template)()
  } catch (error) {
    return error.message
  }
}

/**
 *
 * @param {String} unitValue ex: 1in
 * @param {Object} [options]
 * @param {String} [options.outputType='px']
 * @param {Number} [options.ppi=96]
 * @returns
 */
export function convertUnit(input, { outputUnit = 'px', ppi = 96, precision, returnNumber = false } = {}) {
  const unitToInchMap = { px: ppi, in: 1, mm: 25.4, cm: 2.54 }

  let inputValue = input
  let inputUnit = 'px'

  // Parse input value and unit from string
  if (typeof input === 'string') {
    const inputValueStr = take(input, input.length - 2).join('')
    inputValue = parseFloat(inputValueStr)
    if (isNaN(inputValue)) return undefined

    inputUnit = takeRight(input, 2).join('')
  }

  let outputValue

  //Return the input value if input unit and output unit are the same
  if (inputUnit === outputUnit) {
    outputValue = inputValue
  } else {
    // convert incoming unit into inches to get pixels
    const valueInInches = inputValue / unitToInchMap[inputUnit]
    outputValue = valueInInches * unitToInchMap[outputUnit]
  }

  // Apply precision
  if (precision !== undefined && isInteger(precision)) outputValue = round(outputValue, precision)

  return returnNumber ? outputValue : `${outputValue}${outputUnit}`
}

export function getViewportDimensions({ format, isLandscape, width, height, ppi = 96 }) {
  width = convertUnit(width, { returnNumber: true })
  height = convertUnit(height, { returnNumber: true })

  let result = [612, 729]
  let dimensions = [width, height]

  const formatDimensionMap = {
    Letter: ['8.5in', '11in'],
    Legal: ['8.5in', '14in'],
    Tabloid: ['11in', '17in'],
    A3: ['297mm', '420mm'],
    A4: ['210mm', '297mm'],
    A5: ['148mm', '210mm'],
    A6: ['105mm', '148mm'],
  }

  if (format) dimensions = formatDimensionMap[format]

  const conversionOptions = { ppi, precision: 0 }

  if (dimensions && dimensions.length === 2)
    result = [convertUnit(dimensions[0], conversionOptions), convertUnit(dimensions[1], conversionOptions)]
  if (isLandscape) result = _.reverse(result)

  return { width: result[0], height: result[1] }
}

/**
 *
 * @param {Object} obj
 */
export function deepClone(obj) {
  return obj ? JSON.parse(JSON.stringify(obj)) : {}
}

export * from './data'
export * from './json'
export * from './file'

const allUtils = {
  validateTemplate,
  convertUnit,
  getViewportDimensions,
  deepClone,
  ...jsonUtils,
  ...dataUtils,
  ...fileUtils,
}

export default allUtils
