export default function errorTextType(editor, opts = {}) {
  const options = {
    name: 'error-text',
    info: "Don't include double curly brackets '{{...}}' in your variable name",
  }
  const { name, info } = options
  const tm = editor.Traits
  const text = tm.getType('text')

  tm.types[name] = text.extend({
    onRender() {
      this.listenTo(this.model, 'change:value', this.updateInputEl)
      this.updateInputEl()
    },

    hasVariableError(varName) {
      return varName && varName.startsWith('{')
    },

    updateInputEl() {
      const v = this.hasVariableError(this.model.get('value'))
      const el = this.el?.querySelector('.gjs-field.gjs-field-error-text')
      const localInfo = this.model.get('info')
      if (!el) return
      if (v) {
        el.style.border = 'solid 1px red'
        el.style.backgroundColor = '#ffe5e5'
        this.model.set({ info })
        !localInfo && editor.runCommand('variable-error')
      } else {
        el.style.border = 'solid 1px #f1f1f1'
        el.style.backgroundColor = '#fff'
        this.model.set({ info: '' })
      }
    },

    createLabel({ label, trait: { model } }) {
      const title = model.get('info')
      return `<div class="gjs-label-wrp-2" ${title ? `title="${title}"` : ''}>
              ${label}
              ${title ? `<i class="fa-solid fa-square-info"></i>` : ''}
            </div>`
    },
  })
}
