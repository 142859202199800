import { FieldType } from '@airtable/blocks/models'
import { uuidv4 } from '../../utils'

export const fieldTypes = {}

const getField = (field) => {
  const Field = fieldTypes[field?.options?.result?.type ?? field.type]
  if (!Field) return Default
  return Field
}

export const MAX_DEPTH = 3

export function createField(field, options) {
  try {
    const Field = getField(field)
    const newField = new Field(field, options)
    return newField
  } catch (error) {
    console.error(error)
  }
}

export function toAirtableFields(typedObject, jsonSchema, { cleanUndefineds = true } = {}) {
  return Object.keys(jsonSchema.properties).reduce((fields, key) => {
    const property = jsonSchema.properties[key]

    if (cleanUndefineds && !typedObject[key]) return fields

    fields[property.title] = typedObject[key]
    return fields
  }, {})
}

export function getUuidFromFormattedKey(editor, formattedKey) {
  if (editor?.SchemaIds) return null
  const key = Object.keys(editor?.SchemaIds).find((key) => editor?.SchemaIds[key].formattedKey === formattedKey)
  if (!key) return null
  return editor?.SchemaIds[key]?.uuid
}

// DEFAULT
export class Default {
  constructor(_field, _options) {
    let { id, name, type, options } = _field
    this.$sourceMetaData = { ...options, originalType: type }
    this.id = name
    this.cid = id
    this.type = 'string'
    this.iconClass = ''
    this._options = _options
  }

  get items() {
    if (!this._items) return undefined
    const { type, properties, items } = this._items
    return { type, ...(items ? { items } : {}), ...(properties ? { properties } : {}) }
  }

  set items(items) {
    if (items) {
      this._items = createField({ ...items }, this._options)
    }
  }

  get properties() {
    return this._properties
  }

  set properties(properties) {
    if (properties) {
      this._properties = Object.keys(properties).reduce((acc, key) => {
        const value = properties[key]
        const field = createField({ ...value, name: key }, this._options)
        const { type, items, properties: pts } = field
        const property = {
          type,
          ...(items ? { items } : {}),
          ...(pts ? { properties: pts } : {}),
        }
        acc[key] = property
        return acc
      }, {})
    }
  }
}

// BASE
// //////////////////////////////////

// BOOLEAN
export class Boolean extends Default {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-checkbox'
    this.type = 'boolean'
  }
}
fieldTypes['boolean'] = Boolean

// NUMBER
export class Number extends Default {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-number'
    this.type = 'number'
    this.$sourceMetaData = { ...this.$sourceMetaData, precision: _field.options?.precision }
  }
}
fieldTypes['number'] = Number
fieldTypes[FieldType.NUMBER] = Number

// TEXT
export class String extends Default {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-single-line-text'
    this.type = 'string'
  }
}
fieldTypes['string'] = String

// OBJECT
export class Obj extends Default {
  constructor(_field, _options) {
    super(_field, _options)
    this.type = 'object'
  }
}
fieldTypes['object'] = Obj

export class Arr extends Default {
  constructor(_field, _options) {
    super(_field, _options)
    this.type = 'array'
    this.items = { type: 'string' }
  }
}
fieldTypes['array'] = Arr

// ATTACHMENT
export class Attachment extends Obj {
  constructor(_field, _options) {
    super(_field, _options)
    const { id, name } = _field
    const { parentsCompoundFieldId, editor } = _options
    const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${id ?? name}` : id ?? name

    this.iconClass = 'fa-kit fa-airtable-attachment'
    this._properties = {
      id: { uuid: editor?.SchemaIds[`${compoundFieldId}-id`]?.uuid ?? uuidv4(), type: 'string' },
      url: { uuid: editor?.SchemaIds[`${compoundFieldId}-url`]?.uuid ?? uuidv4(), type: 'string' },
      filename: { uuid: editor?.SchemaIds[`${compoundFieldId}-filename`]?.uuid ?? uuidv4(), type: 'string' },
      size: { uuid: editor?.SchemaIds[`${compoundFieldId}-size`]?.uuid ?? uuidv4(), type: 'number' },
      width: { uuid: editor?.SchemaIds[`${compoundFieldId}-width`]?.uuid ?? uuidv4(), type: 'number' },
      height: { uuid: editor?.SchemaIds[`${compoundFieldId}-height`]?.uuid ?? uuidv4(), type: 'number' },
      type: { uuid: editor?.SchemaIds[`${compoundFieldId}-type`]?.uuid ?? uuidv4(), type: 'string' },
      thumbnails: {
        uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails`]?.uuid ?? uuidv4(),
        type: 'object',
        properties: {
          small: {
            uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-small`]?.uuid ?? uuidv4(),
            type: 'object',
            properties: {
              url: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-small-url`]?.uuid ?? uuidv4(),
                type: 'string',
              },
              width: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-small-width`]?.uuid ?? uuidv4(),
                type: 'number',
              },
              height: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-small-height`]?.uuid ?? uuidv4(),
                type: 'number',
              },
            },
          },
          large: {
            uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-large`]?.uuid ?? uuidv4(),
            type: 'object',
            properties: {
              url: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-large-url`]?.uuid ?? uuidv4(),
                type: 'string',
              },
              width: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-large-width`]?.uuid ?? uuidv4(),
                type: 'number',
              },
              height: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-large-height`]?.uuid ?? uuidv4(),
                type: 'number',
              },
            },
          },
          full: {
            uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-full`]?.uuid ?? uuidv4(),
            type: 'object',
            properties: {
              url: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-full-url`]?.uuid ?? uuidv4(),
                type: 'string',
              },
              width: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-full-width`]?.uuid ?? uuidv4(),
                type: 'number',
              },
              height: {
                uuid: editor?.SchemaIds[`${compoundFieldId}-thumbnails-full-height`]?.uuid ?? uuidv4(),
                type: 'number',
              },
            },
          },
        },
      },
    }
  }
}
fieldTypes['attachment'] = Attachment

// COLLABORATOR
export class Collaborator extends Obj {
  constructor(_field, _options) {
    super(_field, _options)
    const { id, name } = _field
    const { parentsCompoundFieldId, editor } = _options
    const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${id ?? name}` : id ?? name
    this.iconClass = 'fa-kit fa-airtable-collaborator'
    this._properties = {
      id: { uuid: editor?.SchemaIds[`${compoundFieldId}-id`]?.uuid ?? uuidv4(), type: 'string' },
      email: { uuid: editor?.SchemaIds[`${compoundFieldId}-email`]?.uuid ?? uuidv4(), type: 'string' },
      name: { uuid: editor?.SchemaIds[`${compoundFieldId}-name`]?.uuid ?? uuidv4(), type: 'string' },
      profilePicUrl: { uuid: editor?.SchemaIds[`${compoundFieldId}-profilePicUrl`]?.uuid ?? uuidv4(), type: 'string' },
    }
  }
}
fieldTypes['collaborator'] = Collaborator

// DATE & DATE TIME
export class DateTime extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.type = 'date'
    this.iconClass = 'fa-kit fa-airtable-date'
    const {
      dateFormat = { name: 'local', format: 'l' },
      timeFormat = { format: 'h:mma' },
      timeZone,
    } = _field.options?.result?.options || _field.options || {}
    this.$sourceMetaData = { ...this.$sourceMetaData, dateFormat, timeFormat, timeZone }
  }
}
fieldTypes[FieldType.DATE] = DateTime
fieldTypes[FieldType.DATE_TIME] = DateTime

// //////////////////////////////////
// AIRTABLE TYPES
// //////////////////////////////////

// AI TEXT
export class AiText extends Obj {
  constructor(_field, _options) {
    super(_field, _options)
    const { id, name } = _field
    const { parentsCompoundFieldId, editor } = _options
    const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${id ?? name}` : id ?? name
    this._properties = {
      state: { uuid: editor?.SchemaIds[`${compoundFieldId}-state`]?.uuid ?? uuidv4(), type: 'string' },
      isStale: { uuid: editor?.SchemaIds[`${compoundFieldId}-isStale`]?.uuid ?? uuidv4(), type: 'boolean' },
      value: { uuid: editor?.SchemaIds[`${compoundFieldId}-value`]?.uuid ?? uuidv4(), type: 'string' },
      errorType: { uuid: editor?.SchemaIds[`${compoundFieldId}-errorType`]?.uuid ?? uuidv4(), type: 'string' }, // Only if state is error
    }
  }
}
fieldTypes[FieldType.AI_TEXT] = AiText

// AUTO NUMBER
export class AutoNumber extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-auto-number'
  }
}
fieldTypes[FieldType.AUTO_NUMBER] = AutoNumber

// BARCODE
export class Barcode extends Obj {
  constructor(_field, _options) {
    super(_field, _options)
    const { id, name } = _field
    const { parentsCompoundFieldId, editor } = _options
    const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${id ?? name}` : id ?? name
    this.iconClass = 'fa-kit fa-airtable-barcode'
    this._properties = {
      text: { uuid: editor?.SchemaIds[`${compoundFieldId}-text`]?.uuid ?? uuidv4(), type: 'string' },
      type: { uuid: editor?.SchemaIds[`${compoundFieldId}-type`]?.uuid ?? uuidv4(), type: 'string' },
    }
  }
}
fieldTypes[FieldType.BARCODE] = Barcode

// BUTTON
export class Button extends Obj {
  constructor(_field, _options) {
    super(_field, _options)
    const { id, name } = _field
    const { parentsCompoundFieldId, editor } = _options
    const compoundFieldId = parentsCompoundFieldId ? `${parentsCompoundFieldId}-${id ?? name}` : id ?? name
    this.iconClass = 'fa-kit fa-airtable-button'
    this._properties = {
      label: { uuid: editor?.SchemaIds[`${compoundFieldId}-label`]?.uuid ?? uuidv4(), type: 'string' },
      url: { uuid: editor?.SchemaIds[`${compoundFieldId}-url`]?.uuid ?? uuidv4(), type: 'string' },
    }
  }
}
fieldTypes[FieldType.BUTTON] = Button

// CHECKBOX
export class Checkbox extends Boolean {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-checkbox'
  }
}
fieldTypes[FieldType.CHECKBOX] = Checkbox

// COUNT
export class Count extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-count'
  }
}
fieldTypes[FieldType.COUNT] = Count

// CREATED BY
export class CreatedBy extends Collaborator {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-created-by'
  }
}
fieldTypes[FieldType.CREATED_BY] = CreatedBy

// CREATED TIME
export class CreatedTime extends DateTime {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-created-time'
  }
}
fieldTypes[FieldType.CREATED_TIME] = CreatedTime

// CURRENCY
export class Currency extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-currency'
    const { precision, symbol } = _field.options
    this.$sourceMetaData = { ...this.$sourceMetaData, precision, symbol }
  }
}
fieldTypes[FieldType.CURRENCY] = Currency

// DURATION
export class Duration extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-duration'
    const { durationFormat } = _field.options
    this.$sourceMetaData = { ...this.$sourceMetaData, durationFormat }
  }
}
fieldTypes[FieldType.DURATION] = Duration

// EMAIL
export class Email extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-email'
  }
}
fieldTypes[FieldType.EMAIL] = Email

// EXTERNAL_SYNC_SOURCE
export class ExternalSyncSource extends String {}
fieldTypes[FieldType.EXTERNAL_SYNC_SOURCE] = ExternalSyncSource

// LAST_MODIFIED_BY
export class LastModifiedBy extends Collaborator {}
fieldTypes[FieldType.LAST_MODIFIED_BY] = LastModifiedBy

// LAST_MODIFIED_TIME
export class LastModifiedTime extends DateTime {}
fieldTypes[FieldType.LAST_MODIFIED_TIME] = LastModifiedTime

// MULTILINE TEXT
export class MultilineText extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-long-text'
  }
}
fieldTypes[FieldType.MULTILINE_TEXT] = MultilineText
fieldTypes['longText'] = MultilineText

// MULTIPLE ATTACHMENTS
export class MultipleAttachments extends Arr {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-attachment'
    this.items = { id: _field.id, type: 'attachment' }
  }
}
fieldTypes[FieldType.MULTIPLE_ATTACHMENTS] = MultipleAttachments

// MULTIPLE COLLABORATORS
export class MultipleCollaborators extends Arr {
  constructor(_field, _options) {
    super(_field, _options)
    this.items = { id: _field.id, type: 'collaborator' }
  }
}
fieldTypes[FieldType.MULTIPLE_COLLABORATORS] = MultipleCollaborators

// MULTIPLE RECORD LINKS
export class MultipleRecordLinks extends Arr {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-linked-record'
    const { options, fields } = _field
    const { inverseLinkFieldId } = options

    this.fields = fields?.filter((field) => field.id !== inverseLinkFieldId)
  }
}
fieldTypes[FieldType.MULTIPLE_RECORD_LINKS] = MultipleRecordLinks

// MULTIPLE SELECTS
export class MultipleSelects extends Arr {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-multiple-select'
    this.items = { id: _field.id, type: FieldType.SINGLE_SELECT }
  }
}
fieldTypes[FieldType.MULTIPLE_SELECTS] = MultipleSelects

// PERCENT
export class Percent extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-percent'
  }
}
fieldTypes[FieldType.PERCENT] = Percent

// PHONE NUMBER
export class PhoneNumber extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-phone'
  }
}
fieldTypes[FieldType.PHONE_NUMBER] = PhoneNumber

// RATING
export class Rating extends Number {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-rating'
  }
}
fieldTypes[FieldType.RATING] = Rating

// RICH TEXT
export class RichText extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-long-text'
  }
}
fieldTypes[FieldType.RICH_TEXT] = RichText

// SINGLE COLLABORATOR
export class SingleCollaborator extends Collaborator {}
fieldTypes[FieldType.SINGLE_COLLABORATOR] = SingleCollaborator

// SINGLE LINE TEXT
export class SingleLineText extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-single-line-text'
  }
}
fieldTypes[FieldType.SINGLE_LINE_TEXT] = SingleLineText

// SINGLE SELECT
export class SingleSelect extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-single-select'
  }
}
fieldTypes[FieldType.SINGLE_SELECT] = SingleSelect

// URL
export class Url extends String {
  constructor(_field, _options) {
    super(_field, _options)
    this.iconClass = 'fa-kit fa-airtable-url'
  }
}
fieldTypes[FieldType.URL] = Url

export default fieldTypes
