import { isNil } from 'lodash'

export function isBlank(arg) {
  return isNil(arg) || arg === ''
}

export function dependencyValidator(dependencies, message = 'Required') {
  return ({ getFieldValue, getFieldInstance }) => ({
    validator(rule, value) {
      const dependenciesAreBlank = dependencies.reduce((acc, cur) => {
        if (!isBlank(getFieldValue(cur))) acc = false
        return acc
      }, true)
      if (isBlank(value) && !dependenciesAreBlank) {
        return Promise.reject(message)
      }
      return Promise.resolve()
    },
  })
}

const logicModalUtils = { dependencyValidator, isBlank }

export default logicModalUtils
