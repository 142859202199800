import { protectedCss as componentCss } from '../plugins/componentTypes'

const css = `
  /* GRID SYSTEM */
  [data-gjs-type^=gs-]{
    font-weight: inherit;
    break-inside: auto;
  }
      
  [data-gjs-type=gs-table]{
    vertical-align:top;    
  }

  [data-gjs-type=gs-column]{
    height: 15px; 
  }
`

export default [css, componentCss].join('')
