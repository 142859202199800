import React from 'react'
import { Row } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

export default function Loading({ minHeight, fontSize, backgroundColor, style={} }) {
  return (
    <Row justify='center' align='middle' style={{ minHeight: minHeight, width: '100%', backgroundColor, ...style }}>
      <LoadingOutlined className='dm-loading-spinner' style={{ fontSize }} spin />
    </Row>
  )
}

Loading.defaultProps = {
  minHeight: '300px',
  fontSize: '40px',
}
