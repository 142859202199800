import { createSlice } from '@reduxjs/toolkit'
import undoable from 'redux-undo'

const initialState = {
  schema: {},
}

export const schemaReducer = createSlice({
  name: 'schema',
  initialState,
  reducers: {
    updateSchema: (state, action) => {
      state.schema = action.payload
    },
  },
})

export const { updateSchema } = schemaReducer.actions
export const selectSchema= (state) => state.schema

export default undoable(schemaReducer.reducer)
