import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as regularSvgIcons from '@fortawesome/pro-regular-svg-icons'
import * as solidSvgIcons from '@fortawesome/pro-solid-svg-icons'

import Icon from '@ant-design/icons'

/**
 *
 * @param {Object} params0
 * @param {import('@fortawesome/fontawesome-svg-core').IconProp} params0.icon
 * @param {import('@fortawesome/fontawesome-svg-core').SizeProp} params0.size
 * @param {boolean} [params0.raw=false]
 * @returns
 */
export default function FaIcon(props) {
  const { icon, size, faProps = {}, iconStyle = {}, spin, raw, style = {}, color, className, outlined, ...rest } = props

  const iconPack = 'outlined' in props || outlined === true ? regularSvgIcons : solidSvgIcons

  const _faProps = {
    icon: props.icon,
    size,
    className,
    style: { ...iconStyle, ...style },
    color,
    ...faProps,
  }

  if (color) _faProps.style.color = color

  if (typeof icon === 'string') _faProps.icon = iconPack[icon] || <></>

  if (raw === true || 'raw' in props) return <FontAwesomeIcon {..._faProps} {...faProps} spin={spin} />

  return (
    <Icon
      {...rest}
      size={size}
      className={className}
      style={style}
      component={() => <FontAwesomeIcon icon={_faProps.icon} {...faProps} spin={spin}/>}
    />
  )
}
