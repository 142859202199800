import React from 'react'
import {
  Row,
  Col,
  Breadcrumb,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Tabs,
  Collapse,
  Tooltip,
  Typography,
} from 'antd'
import { Query } from '@react-awesome-query-builder/antd'
import { GeneralIcon } from '@features/template-data-source'

import useConditionEditor from '../NewLogicModal/hooks/useConditionEditor'
import CodeEditor from '../NewLogicModal/components/CodeEditor'
import styled from 'styled-components'
import { numberFormatOptions, dateFormatOptions, currencyFormatOptions, localeLabels, locales, timezones } from './consts'

const { Panel } = Collapse
const { Text } = Typography

const Breadcrumbs = ({ items }) => {
  return (
    <Breadcrumb>
      {items.map((item, i) => (
        <Breadcrumb.Item key={i}>{item}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  )
}

const FlexColumn = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.$reversed ? 'column-reverse' : 'column')};
  gap: ${(props) => props.$gap ?? 8}px;
`

export default function Value({ data, onChange, condition, setCondition, hasContext }) {
  const { tokenMetaData } = data

  const { state, code, builder, handleChange, handleChangeCustom } = useConditionEditor({ condition, setCondition })

  const nameItems = data.data.split('.')

  const excludeArray = data.type !== 'array' ? nameItems[nameItems.length - 2] : nameItems[nameItems.length - 1]

  const allArrayKeys = Object.keys(data.objectNesting).filter((k) => data.data.includes(k))
  const arrayKeys = allArrayKeys.filter((k) => k !== excludeArray)

  return (
    <>
      <FlexColumn $gap={24}>
        <Row>
          <div className='mr-2 p-1'>
            <Tooltip title={data.type}>
              <GeneralIcon types={data.type} />
            </Tooltip>
          </div>
          <div className='p-1'>
            <Breadcrumbs items={nameItems} />
          </div>
        </Row>

        {/*<Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Type" name="type">
              <Select disabled>
                <Select.Option value="string">String</Select.Option>
                <Select.Option value="boolean">Boolean</Select.Option>
                <Select.Option value="number">Number</Select.Option>
                <Select.Option value="currency">currency</Select.Option>
                <Select.Option value="percent">Percent</Select.Option>
                <Select.Option value="date">Date</Select.Option>
                <Select.Option value="duration">Duration</Select.Option>
                <Select.Option value="array">Array</Select.Option>
                <Select.Option value="object">Object</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Data" name="data">
              <Input placeholder="Path string" disabled />
            </Form.Item>
          </Col>
        </Row>*/}
        {(!['object', 'boolean', 'date', 'duration'].includes(data.type) ||
          [data.type, data.arrayType].includes('string') ||
          [data.type, data.arrayType].includes('number')) && (
          <Row gutter={16}>
            {!['object', 'boolean', 'date', 'duration'].includes(data.type) && (
              <Col span={12}>
                {[data.type, data.arrayType].includes('string') && (
                  <FlexColumn>
                    <Text>Default Value</Text>
                    <Input
                      value={tokenMetaData.defaultValue}
                      onChange={(e) => onChange({ defaultValue: e.target.value })}
                      placeholder='Default Value'
                    />
                  </FlexColumn>
                )}
                {[data.type, data.arrayType].includes('number') && (
                  <FlexColumn>
                    <Text>Default Value</Text>
                    <InputNumber
                      value={tokenMetaData.defaultValue}
                      onChange={(val) => onChange({ defaultValue: val })}
                      placeholder='0'
                    />
                  </FlexColumn>
                )}
                {/*{[data.type, data.arrayType].includes("boolean") && (
                  <Form.Item label="Default Value" name="defaultValue">
                    <Switch />
                  </Form.Item>
                )}
                {[data.type, data.arrayType].includes("date") && (
                  <Form.Item label="Default Value" name="defaultValue">
                    <DatePicker />
                  </Form.Item>
                )}
                {[data.type, data.arrayType].includes("duration") && (
                  <Form.Item label="Default Value" name="defaultValue">
                    <Switch />
                  </Form.Item>
                )}*/}
              </Col>
            )}
            {[data.type, data.arrayType].includes('number') && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Number Type</Text>
                  <Radio.Group
                    value={tokenMetaData.numberType}
                    onChange={(e) => onChange({ numberType: e.target.value })}
                  >
                    <Radio.Button value='plain'>Plain</Radio.Button>
                    <Radio.Button value='currency'>Currency</Radio.Button>
                    <Radio.Button value='percent'>Percent</Radio.Button>
                  </Radio.Group>
                </FlexColumn>
              </Col>
            )}
            {[data.type, data.arrayType].includes('string') && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Text Type</Text>
                  <Radio.Group value={tokenMetaData.textType} onChange={(e) => onChange({ textType: e.target.value })}>
                    <Radio.Button value='plain'>Plain</Radio.Button>
                    <Radio.Button value='markdown'>Markdown</Radio.Button>
                  </Radio.Group>
                </FlexColumn>
              </Col>
            )}
          </Row>
        )}
        {[data.type, data.arrayType].includes('object') && (
          <Row gutter={16}>
            {data.type === 'object' && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Path</Text>
                  <Input value={data.path} placeholder='Collection path string' disabled />
                </FlexColumn>
              </Col>
            )}
            <Col span={12}>
              <FlexColumn>
                <Text>Filter</Text>
                <Input
                  value={tokenMetaData.filter}
                  onChange={(e) => onChange({ filter: e.target.value })}
                  placeholder='Object property name'
                  allowClear
                />
              </FlexColumn>
            </Col>
          </Row>
        )}
        {!hasContext && (data.type === 'array' || data.parentType === 'array') && (
          <FlexColumn $gap={0} $reversed={true}>
            <Collapse>
              <Panel header={`${excludeArray} List/Collection return types`} key='rt'>
                <Row gutter={16}>
                  <Col span={12}>
                    <FlexColumn>
                      <Text>Return Type</Text>
                      <Select
                        value={tokenMetaData.returnType}
                        onChange={(val) => onChange({ returnType: val })}
                        placeholder='First'
                      >
                        <Select.Option value='join'>All</Select.Option>
                        <Select.Option value='first'>First</Select.Option>
                        <Select.Option value='last'>Last</Select.Option>
                        {(data.type === 'number' || data.parentType === 'number') && (
                          <>
                            <Select.Option value='sum'>Sum</Select.Option>
                            <Select.Option value='count'>Count</Select.Option>
                            <Select.Option value='min'>Min</Select.Option>
                            <Select.Option value='max'>Max</Select.Option>
                            <Select.Option value='mean'>Mean</Select.Option>
                          </>
                        )}
                        <Select.Option value='custom'>Custom</Select.Option>
                      </Select>
                    </FlexColumn>
                  </Col>
                  {tokenMetaData.returnType === 'custom' && (
                    <Col span={12}>
                      <FlexColumn>
                        <Text>Index</Text>
                        <InputNumber
                          value={tokenMetaData.index}
                          onChange={(val) => onChange({ index: val })}
                          placeholder='0'
                        />
                      </FlexColumn>
                    </Col>
                  )}
                </Row>
                <Row gutter={16}>
                  {tokenMetaData.returnType === 'join' && (
                    <Col span={12}>
                      <FlexColumn>
                        <Text>Separator</Text>
                        <Input
                          value={tokenMetaData.separator}
                          onChange={(e) => onChange({ separator: e.target.value })}
                          placeholder='Variable separator'
                          allowClear
                        />
                      </FlexColumn>
                    </Col>
                  )}
                  {tokenMetaData.returnType === 'join' && (
                    <Col span={12}>
                      <FlexColumn>
                        <Text>Final Separator</Text>
                        <Input
                          value={tokenMetaData.final}
                          onChange={(e) => onChange({ final: e.target.value })}
                          placeholder='Variable separator'
                          allowClear
                        />
                      </FlexColumn>
                    </Col>
                  )}
                </Row>
              </Panel>
            </Collapse>
            {allArrayKeys.map((k, h) => (
              <>
                {!!data.objectNesting[k].dimensions && (
                  <Collapse key={h}>
                    <Panel header={`${k}(stacked) List/Collection return types`} key={k}>
                      {[...Array(data.objectNesting[k].dimensions).keys()].map((_, i) => (
                        <Row gutter={16} key={h + i}>
                          <Col span={12}>
                            <FlexColumn>
                              <Text>{`Return Type ${i + 1}`}</Text>
                              <Select
                                value={tokenMetaData[`returnType${k + i}`]}
                                onChange={(val) => onChange({ [`returnType${k + i}`]: val })}
                                placeholder='First'
                              >
                                <Select.Option value='first'>First</Select.Option>
                                <Select.Option value='custom'>Custom</Select.Option>
                              </Select>
                            </FlexColumn>
                          </Col>
                          {tokenMetaData[`returnType${k + i}`] === 'custom' && (
                            <Col span={12}>
                              <FlexColumn>
                                <Text>{`Index ${i + 1}`}</Text>
                                <InputNumber
                                  value={tokenMetaData[`index${k + i}`]}
                                  onChange={(val) => onChange({ [`index${k + i}`]: val })}
                                  placeholder='0'
                                />
                              </FlexColumn>
                            </Col>
                          )}
                        </Row>
                      ))}
                    </Panel>
                  </Collapse>
                )}
              </>
            ))}
            {arrayKeys.map((k, i) => (
              <Collapse key={i}>
                <Panel header={`${k} List/Collection return types`} key={i}>
                  <Row gutter={16}>
                    <Col span={12}>
                      <FlexColumn>
                        <Text>Return Type</Text>
                        <Select
                          value={tokenMetaData[`returnType${k}`]}
                          onChange={(val) => onChange({ [`returnType${k}`]: val })}
                          placeholder='First'
                        >
                          <Select.Option value='first'>First</Select.Option>
                          <Select.Option value='custom'>Custom</Select.Option>
                        </Select>
                      </FlexColumn>
                    </Col>
                    {tokenMetaData[`returnType${k}`] === 'custom' && (
                      <Col span={12}>
                        <FlexColumn>
                          <Text>Index</Text>
                          <InputNumber
                            value={tokenMetaData[`index${k}`]}
                            onChange={(val) => onChange({ [`index${k}`]: val })}
                            placeholder='0'
                          />
                        </FlexColumn>
                      </Col>
                    )}
                  </Row>
                </Panel>
              </Collapse>
            ))}
          </FlexColumn>
        )}
        {[data.type, data.arrayType].some((i) => ['number', 'date'].includes(i)) && (
          <Row gutter={16}>
            <Col span={12}>
              <FlexColumn>
                <Text>Format</Text>
                <Select
                  placeholder='Variable format'
                  value={tokenMetaData.format}
                  onChange={(val) => onChange({ format: val })}
                  options={
                    ['number', 'percent'].includes(data.type)
                      ? numberFormatOptions.map((option) => ({ value: option }))
                      : data.type === 'date'
                      ? dateFormatOptions.map((option) => ({ value: option }))
                      : currencyFormatOptions.map((option) => ({
                          value: option,
                        }))
                  }
                />
              </FlexColumn>
            </Col>
            {tokenMetaData.numberType === 'currency' && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Locale</Text>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }                
                    placeholder='[default]'
                    value={tokenMetaData.locale}
                    onChange={(val) => onChange({ locale: val })}
                    options={locales.map((locale, i) => ({
                      label: localeLabels[i],
                      value: locale,
                    }))}
                  />
                </FlexColumn>
              </Col>
            )}
            {tokenMetaData.numberType === 'percent' && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Percentage Symbol</Text>
                  <Input
                    value={tokenMetaData.percent}
                    placeholder='%'
                    onChange={(e) => onChange({ percent: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
            )}
            {[data.type, data.arrayType].includes('date') && (
              <Col span={12}>
                <FlexColumn>
                  <Text>Timezone</Text>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }                
                    placeholder='America/New_York'
                    value={tokenMetaData.timezone}
                    onChange={(val) => onChange({ timezone: val })}
                    options={timezones.map((timezone, i) => ({
                      label: timezone,
                      value: timezone,
                    }))}
                  />
                </FlexColumn>
              </Col>
            )}
          </Row>
        )}

        {[data.type, data.arrayType].includes('date') && tokenMetaData.format === 'custom' && (
          <Row gutter={16}>
            <Col span={12}>
                <FlexColumn>
                  <Text>Pattern</Text>
                  <Input
                    value={tokenMetaData.pattern}
                    placeholder='Variable format'
                    onChange={(e) => onChange({ pattern: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
          </Row>
        )}
        {[data.type, data.arrayType].some((i) => 'number' === i) && tokenMetaData.format === 'custom' && (
          <Row gutter={16}>
            <Col span={6}>
              <FlexColumn>
                <Text>Decimal Places</Text>
                <Select value={tokenMetaData.decimalPlaces} onChange={(val) => onChange({ decimalPlaces: val })}>
                  <Select.Option value='0'>0</Select.Option>
                  <Select.Option value='1'>1</Select.Option>
                  <Select.Option value='2'>2</Select.Option>
                  <Select.Option value='3'>3</Select.Option>
                  <Select.Option value='4'>4</Select.Option>
                  <Select.Option value='5'>5</Select.Option>
                </Select>
              </FlexColumn>
            </Col>
            <Col span={6}>
              <FlexColumn>
                <Text>Thousands Sep</Text>
                <Input
                  value={tokenMetaData.thousandsSeparator}
                  placeholder=','
                  onChange={(e) => onChange({ thousandsSeparator: e.target.value })}
                  allowClear
                />
              </FlexColumn>
            </Col>
            <Col span={6}>
              <FlexColumn>
                <Text>Decimal Sep</Text>
                <Input
                  value={tokenMetaData.decimalSeparator}
                  placeholder='.'
                  onChange={(e) => onChange({ decimalSeparator: e.target.value })}
                  allowClear
                />
              </FlexColumn>
            </Col>
            <Col span={6}>
              <FlexColumn>
                <Text>Abbreviate</Text>
                <Switch
                  checked={tokenMetaData.abbreviate}
                  onChange={(val) => onChange({ abbreviate: val })}
                  style={{ width: 44 }}
                />
              </FlexColumn>
            </Col>
          </Row>
        )}
        {[data.type, data.arrayType].some((i) => 'number' === i) &&
          tokenMetaData.numberType === 'currency' &&
          tokenMetaData.locale === 'custom' && (
            <Row gutter={16}>
              <Col span={6}>
                <FlexColumn>
                  <Text>Currency Symbol</Text>
                  <Input
                    value={tokenMetaData.currencySymbol}
                    placeholder='$'
                    onChange={(e) => onChange({ currencySymbol: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
              <Col span={6}>
                <FlexColumn>
                  <Text>Thousands Abbr</Text>
                  <Input
                    value={tokenMetaData.th}
                    placeholder='k'
                    onChange={(e) => onChange({ th: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
              <Col span={6}>
                <FlexColumn>
                  <Text>Millions Abbr</Text>
                  <Input
                    value={tokenMetaData.mi}
                    placeholder='m'
                    onChange={(e) => onChange({ mi: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
              <Col span={6}>
                <FlexColumn>
                  <Text>Billions Abbr</Text>
                  <Input
                    value={tokenMetaData.bi}
                    placeholder='b'
                    onChange={(e) => onChange({ bi: e.target.value })}
                    allowClear
                  />
                </FlexColumn>
              </Col>
            </Row>
          )}
        <FlexColumn label='Display Rule'>
          <Text>Display Rule</Text>
          <Tabs
            defaultActiveKey={code ? '2' : '1'}
            items={[
              {
                label: 'Builder',
                key: '1',
                children: (
                  <Query {...state.config} value={state.tree} onChange={handleChange} renderBuilder={builder} />
                ),
              },
              {
                label: 'Custom',
                key: '2',
                children: <CodeEditor code={code} onChange={handleChangeCustom} />,
              },
            ]}
          />
        </FlexColumn>
      </FlexColumn>
    </>
  )
}
