const GF_FAMILIES = [
  'Courier+Prime',
  'Dancing+Script',
  'Inter',
  'Lato',
  'Montserrat',
  'Nunito',
  'Open+Sans',
  'PT+Sans',
  'PT+Serif',
  'Quicksand',
  'Roboto',
  'Playfair+Display',
  'Source+Sans+Pro',
]

const GF_VARIANTS = 'ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700'

const googleFontStylesheet = `https://fonts.googleapis.com/css2?display=swap&${GF_FAMILIES.map(
  (f) => `family=${f}:${GF_VARIANTS}`,
).join('&')}`

const canvasStyleConfig = {
  styles: [googleFontStylesheet, `/stylesheets/canvas-style.css?${Date.now()}`],
  customBadgeLabel(component) {
    return (
      '<div class="gjs-badge-inner">' +
      component.getIcon() +
      '  ' +
      component.getName() +
      '  ' +
      '<span style="font-size: 10px;">' +
      (component.getEl().offsetWidth || 0) +
      'x' +
      (component.getEl().offsetHeight || 0) +
      '</span></div>'
    )
  },
}

export default canvasStyleConfig
