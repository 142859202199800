// Object of keymaps
const keymaps = {
  defaults: {
    'core:component-delete': {
      keys: 'backspace, delete',
      handler: 'core:component-delete',
      opts: { prevent: true },
    },
    'core:component-enter': { keys: 'i', handler: 'core:component-enter' },
    'core:component-exit': { keys: 'o', handler: 'core:component-exit' },
    'core:component-next': { keys: 'n', handler: 'core:component-next' },
    'core:component-prev': { keys: 'p', handler: 'core:component-prev' },
    'core:copy': { keys: '⌘+c, ctrl+c', handler: 'core:copy' },
    'core:paste': { keys: '⌘+v, ctrl+v', handler: 'core:paste' },
    'core:undo': { keys: '⌘+z, ctrl+z', handler: 'core:undo' },
    'core:redo': { keys: '⌘+shift+z, ctrl+shift+z', handler: 'core:redo' },
  },
}

export default keymaps
