import { BLOCK_CATEGORIES } from '../../consts'

import contentBlocks from './content'
import layoutBlocks from './layout'

const categories = [
  {
    name: BLOCK_CATEGORIES.content,
    blocks: contentBlocks,
  },
  {
    name: BLOCK_CATEGORIES.layout,
    blocks: layoutBlocks,
  },
]

function customRender({ model, className }) {
  return `
      <div class="gjs-block-header">
        <i class="dm-grip block-handle"></i>
      </div>
      <div class="gjs-block-icon">
        <i class="${model.get('iconClass')}"></i>
      </div>
      <div class="gjs-block-label">${model.get('label')}</div>
  `
}

export const blocks = ({ editor }) => {
  const bm = editor.Blocks
  const blocks = categories.reduce((acc, cat) => {
    const categoryBlocks = Object.values(cat.blocks).map((b) => ({ ...b, category: cat.name, render: customRender }))
    return [...acc, ...categoryBlocks]
  }, [])

  blocks.forEach((block) => bm.add(block.name, block))
}

export default blocks
