import React, { useEffect } from 'react'
import { Alert } from 'antd'
import Loading from '@common/Loading'
const AssetsList = React.lazy(() => import('../../features/assets/components/AssetsList'))

export default function Assets() {
  useEffect(() => {
    document.title = 'Assets'
  }, [])
  
  return (
    <React.Suspense fallback={<Loading />}>
      <Alert.ErrorBoundary>
        <div style={{ maxWidth: '1700px' }}>
          <AssetsList />
        </div>
      </Alert.ErrorBoundary>
    </React.Suspense>
  )
}
