import { useCallback, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { selectConnections, setConnections } from '../slice'
import { useLazyGetConnectionsQuery } from '@documintApi/activepieces'

export const useConnections = () => {
  const connections = useSelector(selectConnections)
  const dispatch = useDispatch()
  const [getConnections, { isLoading, error }] = useLazyGetConnectionsQuery()

  const fetchConnections = useCallback(async () => {
    const response = await getConnections()
    const { data } = response?.data || {}
    if (data) dispatch(setConnections(data))
    return response
  }, [getConnections, dispatch])

  useEffect(() => {
    fetchConnections()
  }, [fetchConnections])

  const result = useMemo(
    () => ({
      connections,
      fetchConnections,
      isLoading,
      error,
    }),
    [connections, fetchConnections, isLoading, error],
  )

  return result
}

export default useConnections
