/**
 * @typedef Entity
 * @property {object} value
 * @property {EntityField[]} configFields
 */
export class Entity {
  _value = null
  _dataSource = null

  constructor(config = {}, dataSource) {
    if (config === null || typeof config !== 'object') config = { value: config }
    this._value = config
    this._dataSource = dataSource
  }

  get dataSource() {
    return this._dataSource
  }

  set dataSource(dataSource) {
    throw new Error('Cannot set dataSource directly. Use the constructor to set the dataSource.')
  }

  get value() {
    return this._value
  }

  set value(value) {
    this._value = value
  }

  get(key) {
    if (!key) return undefined
    return this._value[key]
  }

  set(key, value) {
    if (typeof key === 'object') {
      this._value = { ...this._value, ...key }
    } else if (typeof key === 'string') {
      this._value[key] = value
    }
  }

  patchValue(value = {}) {
    this._value = { ...(this._value || {}), ...value }
  }

  toObject() {
    return this.value
  }
}

export default Entity
