import React, { useState } from 'react'
import { Modal, Typography } from 'antd'
import { TYPES } from './Editor/plugins/componentTypes/consts'
import { useEditor,useDesigner } from '../hooks'
import style from './Editor/config/style'
import { deepClone } from '@utils'
import { loopAllComponents, loopAllComponentsWithoutBreak, loadData, setDevice, updateCanvasPosition } from './Editor/utils'
import FaIcon from '@components/icons/FaIcon'
import { SyncOutlined } from '@ant-design/icons'
import config from '@config'
import { removeDisplay, addNoneDisplay } from './Editor/plugins/componentTypes/_deprecated/Section'

const { Paragraph, Link } = Typography
const { info } = Modal

const messageMap = {
  all: (
    <>
      <Paragraph>You are about to upgrade the layout elements in this template to new layout element types. </Paragraph>
      <Paragraph>
        <b>Note:</b> you can upgrade individual sections by selecting a section and clicking the "
        {<FaIcon icon='faCircleUp' />}" icon on the toolbar.
      </Paragraph>
    </>
  ),
  section: 'You are about to upgrade the layout elements in this section to new layout element types.',
  loading: (
    <>
      <Paragraph>
        <SyncOutlined spin /> The conversion is in progress please wait...
      </Paragraph>
    </>
  ),
}

function onComplete() {
  return info({
    title: 'Upgrade successful',
    content: (
      <Paragraph>
        You can undo this upgrade by clicking the "{<FaIcon icon='faUndo' />}" icon on the toolbar.
      </Paragraph>
    ),
  })
}

export default function LegacyConvertModal({ selectedComponent, visible, modalProps, onOk, onCancel }) {
  const [loading, setLoading] = useState(false)
  const { setHasLegacy, editor, setRevertToLegacy, revertSectionsControls } = useEditor()
  const { template } = useDesigner()
  const { options } = template || {}

  function handleCancel() {
    if (onCancel) onCancel()
  }

  /**
   * Handle Save
   */
  function handleOk() {
    const components = editor.Components.getComponents()
    if (selectedComponent) {
      revertSectionsControls.push({
        components: deepClone(components.toJSON()),
        styles: deepClone(editor.getStyle().toJSON()),
      })
      // Show all components hidden in layers for correct rendering
      const clbB = (c) => removeDisplay(c)
      selectedComponent && removeDisplay(selectedComponent)
      loopAllComponentsWithoutBreak(selectedComponent.components(), clbB)
      // Convert
      selectedComponent && selectedComponent.bump()
      setHasLegacy(false)
      // Scan template for legacy components
      const ifTrue = (c) => c.get('type') === TYPES.section || c.get('type') === TYPES.row
      const clb = () => setHasLegacy(true)
      loopAllComponents(components, ifTrue, clb)
      loadData(editor, deepClone(components?.toJSON()), deepClone(editor.getStyle().toJSON()), style)
      // Hide all previously hidden components
      const clbA = (c) => addNoneDisplay(c)
      loopAllComponentsWithoutBreak(components, clbA)
      editor.Layers.view?.render()
      editor.UndoManager.clear()
    } else {
      setRevertToLegacy({
        components: deepClone(components.toJSON()),
        styles: deepClone(editor.getStyle().toJSON()),
      })
      // Show all components hidden in layers for correct rendering
      const clbB = (c) => removeDisplay(c)
      loopAllComponentsWithoutBreak(components, clbB)
      // Convert
      const ifTrue = (c) => c.bump
      const clb = (c) => c.bump()
      loopAllComponents(components, ifTrue, clb)
      setHasLegacy(false)
      loadData(editor, deepClone(components?.toJSON()), deepClone(editor.getStyle().toJSON()), style)
      // Hide all previously hidden components
      const clbA = (c) => addNoneDisplay(c)
      loopAllComponentsWithoutBreak(components, clbA)
      editor.Layers.view?.render()
      editor.UndoManager.clear()
    }

    const bodyEl = editor.Canvas.getBody()
    const documentWrapper = document.createElement('div')
    documentWrapper.classList.add('document')
    documentWrapper.appendChild(editor.getWrapper().view.el)
    bodyEl.appendChild(documentWrapper)

    setDevice(editor, options)
    updateCanvasPosition(editor)

    onComplete()
    if (onOk) onOk()
  }

  const handleClose = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1)
  }

  const handleOkAsync = () => {
    setLoading(true)
    setTimeout(handleOk, 1)
  }

  return (
    <Modal
      {...modalProps}
      open={visible}
      onOk={handleOkAsync}
      onCancel={handleCancel}
      afterClose={handleClose}
      width={500}
      okText='Upgrade elements'
      title='Upgrade Legacy Elements'
      destroyOnClose={true}
    >
      <Paragraph className='mt-1 mb-4'>
        {loading ? messageMap.loading : selectedComponent ? messageMap.section : messageMap.all}
      </Paragraph>
      <Paragraph>
        There may be minor changes to column widths and row spacing. You can undo these changes if you'd like.
      </Paragraph>

      <Link href={`${config.documentationUrl}/additional-resources/changelog#1.16.0-2023-06-30`} target='_blank'>
        Learn more
      </Link>
    </Modal>
  )
}
