import useBreakpoint from './useBreakpoint'
import useDownload from './useDownload'
import useScale from './useScale'
import useTimeout from './useTimeout'
import useToggle from './useToggle'
import useStackState from './useStack'
import useCache from './useCache'

export { useToggle } from './useToggle'
export { useBreakpoint } from './useBreakpoint'
export { useDownload } from './useDownload'
export { useTimeout } from './useTimeout'
export { useScale } from './useScale'
export { useStackState } from './useStack'
export { useCache } from './useCache'

const defaults = {
  useBreakpoint,
  useToggle,
  useDownload,
  useTimeout,
  useScale,
  useStackState,
  useCache
}

export default defaults
