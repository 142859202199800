import { Empty } from 'antd'
import { Typography } from 'antd'
import React from 'react'

export default function DataSourcePreviewEmptyState() {
  return (
    <Empty
      description=''
      image='/images/empty-states/template-preview-data-source.png'
      imageStyle={{ height: '270px' }}
    >
      <Typography.Title level={5}>Select a record to preview</Typography.Title>
    </Empty>
  )
}
