import { TYPES } from "../../../plugins/componentTypes/consts"
import { changeAll, updateVisibility, setCustom } from "./linked"

const DEFAULT_BORDER_RADIUS = {
  type: 'integer',
  default: '0',
  min: 0,
  units: ['px'],
  unit: 'px',
}

const borderKeys = ['border-top-left-radius', 'border-top-right-radius', 'border-bottom-left-radius', 'border-bottom-right-radius']
const allKey = '--border-radius-all'

const config = {
  sectorId: 'borders',
  name: 'Borders & Corners',
  open: true,
  buildProps: [
    'border-collapse',
    'better-borders',
    'border-width',
    'border-color',
  ],
  properties: [
    {
      name: 'Collapse Borders <i class="fa-solid fa-square-info"></i>',
      id: 'border-collapse',
      info: 'Setting this to no will allow you to add padding to row and enable rounded corners but borders will stack inbetween columns',
      property: 'border-collapse',
      type: 'radio',
      default: 'separate',
      full: true,
      options: [
        { value: 'collapse', name: 'Yes' },
        { value: 'separate', name: 'No' },
      ],
      requires: { display: ['table'] },
      onChange({ property, value }) {
        const cls = 'gjs-not-border-collapse'
        const clsRow = 'gjs-component-row'
        const clsColumn = 'gjs-component-column'
        const smEl = document.querySelector('#style-manager')
        smEl && smEl.classList.remove(cls)
        smEl && smEl.classList.remove(clsRow)
        smEl && smEl.classList.remove(clsColumn)
        const component = property.em.getEditor().getSelected()
        if (component && component.attributes.type === TYPES.row_2) {
          ['', 'separate'].includes(value) && smEl && smEl.classList.add(cls)
          smEl && smEl.classList.add(clsRow)
        } else if (component && component.attributes.type === TYPES.column_2) {
          const row = component.parent()?.parent()
          const el = row.getEl();
          let backupVal
          if (el?.style) backupVal = getComputedStyle(el)['border-collapse'];
          const seperate = row.getStyle('border-collapse') || backupVal || ''
          row &&
            row.get('type') === TYPES.row_2 &&
            ['', 'separate'].includes(seperate) &&
            smEl &&
            smEl.classList.add(cls)
          smEl && smEl.classList.add(clsColumn)
        }
      },
    },
    {
      name: 'Borders',
      id: 'better-borders',
      property: 'better-borders',
      type: 'better-borders',
    },
    {
      ...DEFAULT_BORDER_RADIUS,
      name: 'Width',
      property: 'border-width',
      type: 'slider',
      default: '1',
      full: false,
    },
    {
      name: 'Color',
      property: 'border-color',
      type: 'color',
      default: 'inherit',
      full: false,
    },
    // {
    //   ...DEFAULT_BORDER_RADIUS,
    //   name: 'Separate',
    //   property: 'border-spacing',
    //   type: 'slider',
    //   default: '0',
    //   min: -20,
    //   max: 20,
    //   full: false,
    // },
    {
      id: 'border-radius',
      name: 'Rounded Corners',
      property: 'border-radius',
      detached: true,
      type: 'composite',
      properties: [
        {
          ...DEFAULT_BORDER_RADIUS,
          name: ' ',
          property: '--border-radius-all',
          className: 'no-reset',
          onChange({ to, from, value, property, opts }) {
            !opts.__up && changeAll(property, value, allKey, borderKeys, { ...opts, to, from })
          },
        },
        {
          property: '--border-type',
          name: ' ',
          type: 'radio',
          full: false,
          default: 'all',
          className: 'no-reset',
          options: [
            { value: 'all', className: 'fa-regular fa-square', title: 'All' },
            { value: 'custom', className: 'fa-duotone fa-square-dashed', title: 'Custom' },
          ],
          onChange({ to, from, value, property, opts }) {
            updateVisibility(property, value, allKey, borderKeys, opts)
          },
        },
        {
          ...DEFAULT_BORDER_RADIUS,
          name: 'Top Left',
          property: 'border-top-left-radius',
          className: 'hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, borderKeys, opts)
          },
        },
        {
          ...DEFAULT_BORDER_RADIUS,
          name: 'Top Right',
          property: 'border-top-right-radius',
          className: 'hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, borderKeys, opts)
          },
        },
        {
          ...DEFAULT_BORDER_RADIUS,
          name: 'Bottom Left',
          property: 'border-bottom-left-radius',
          className: 'hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, borderKeys, opts)
          },
        },
        {
          ...DEFAULT_BORDER_RADIUS,
          name: 'Bottom Right',
          property: 'border-bottom-right-radius',
          className: 'hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, borderKeys, opts)
          },
        },
      ],
    },
  ],
}

export default config
