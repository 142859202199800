import { TYPES } from '../consts'

export default function dynamicColumnType(dc, options) {
  dc.addType(TYPES.dynamic_column, {
    extends: TYPES.dynamic,
    model: {
      defaults: {
        switch: [
          {
            parents: ['wrapper'],
            type: TYPES.row_2,
            components: [
              {
                type: 'gs-columns',
                components: {
                  type: TYPES.column_2,
                },
              },
            ],
          },
          {
            parents: [TYPES.row, TYPES.section],
            type: TYPES.column,
          },
          {
            parents: ['gs-columns'],
            type: TYPES.column_2,
          },
        ],
        draggable: `[data-gjs-type="wrapper"], [data-gs-type="columns"], [data-gjs-type="${TYPES.row}"], [data-gjs-type="${TYPES.section}"]`,
        attributes: {
          'data-gs-type': 'column',
        },
      },
      getSpan() {},
      setSizeClass() {},
      getColumns() {},
      removeColumns() {},
      resetHandles() {},
      correctWidth() {},
    },
  })
}
