export default function switchTraitType(editor, { $ }) {
  const options = {
    name: 'switch',
    cls: 'ant-switch-checked',
  }
  const { name, cls } = options
  const tm = editor.Traits

  tm.addType(name, {
    eventCapture: ['click .ant-switch'],

    createInput() {
      const $el = $(
        `<button type="button" role="switch" class="ant-switch ant-switch-checked" ant-click-animating="false"><div class="ant-switch-handle"></div><span class="ant-switch-inner"></span></button>`
      )
      $el.on('click', () => {
        $el.toggleClass(cls)
      })

      let checked, targetValue
      const { model, target } = this
      const { valueFalse } = model.attributes
      const name = model.get('name')

      if (model.get('changeProp')) {
        checked = target.get(name)
        targetValue = checked
      } else {
        targetValue = target.get('attributes')[name]
        checked = targetValue || targetValue === '' ? !0 : !1
      }

      if (valueFalse !== void 0 && targetValue === valueFalse) {
        checked = !1
      }

      checked && $el.addClass(cls)
      !checked && $el.removeClass(cls)

      return $el.get(0)
    },

    // Update the component based on element changes
    onEvent({ elInput }) {
      const value = elInput.classList.contains(cls)
      this.model.set('value', this.getCheckedValue(value))
    },

    getCheckedValue(checked) {
      let result = checked
      const { valueTrue, valueFalse } = this.model.attributes

      if (result && valueTrue !== void 0) {
        result = valueTrue
      }

      if (!result && valueFalse !== void 0) {
        result = valueFalse
      }

      return result
    },
  })
}
