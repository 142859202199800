import React from 'react'
import { Builder } from '@react-awesome-query-builder/antd'

const renderBuilder = (props) => (
  <div className='query-builder-container'>
    <div className='query-builder qb-lite'>
      <Builder {...props} />
    </div>
  </div>
)

export default renderBuilder