import React from 'react'
import { Typography, Collapse, Button } from 'antd'
const { Title, Paragraph } = Typography

export default function DataSourceInstructions({ name, overview, documentationUrl, setupSteps, children }) {
  return (
    <>
      {overview && (
        <>
          <Title level={4}>Overview</Title>
          <Paragraph>{overview}</Paragraph>
        </>
      )}

      {setupSteps && documentationUrl ? (
        <Collapse bordered={false} className='mb-3'>
          <Collapse.Panel header='Setup Instructions' key='1'>
            <ol>
              {setupSteps.map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ol>
            {documentationUrl && (
              <Button href={documentationUrl} htmlType='a' target='_blank' className='mt-2' type='primary'>
                Complete Setup Guide
              </Button>
            )}
          </Collapse.Panel>
        </Collapse>
      ) : documentationUrl ? (
        <Button href={documentationUrl} htmlType='a' target='_blank' className='my-2' type='primary'>
          Complete Setup Guide
        </Button>
      ) : null}

      {children}
    </>
  )
}
