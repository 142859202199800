import { useContext } from 'react'
import { TemplateContext } from '../contexts/TemplateContext2'

export const useTemplateContext = () => {
  const context = useContext(TemplateContext)

  if (!context) {
    throw new Error('useEditorInstance must be used within EditorInstanceProvider')
  }
  
  return context
}
