import React, { useCallback, useEffect, useState, useRef } from 'react'
import Loading from '@common/Loading'
import { Alert } from 'antd'
import config from '@config'
import { useActivePieces } from '../hooks'

const { activePiecesInstanceUrl } = config

const lazyLoadActivePiecesFromCDN = (callback, errorCallback) => {
  const activePieces = document.createElement('script')
  activePieces.setAttribute('src', 'https://cdn.activepieces.com/sdk/embed/0.2.2.js')
  activePieces.addEventListener('load', () => callback())
  activePieces.addEventListener('error', () => errorCallback())
  document.head.appendChild(activePieces)
}

export default function ActivePieces({ page, settings = false }) {
  const { jwt, jwtError, projectId } = useActivePieces()

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const piecesRef = useRef(null)

  const configureActivePieces = useCallback(() => {
    const path = settings || (!settings && projectId)

    if (piecesRef.current?.innerHTML || !path || !jwt) return

    const newPath = settings
      ? `/settings/integrations/${page ?? 'connections'}`
      : `/templates/${projectId}/${page ?? 'connections'}`

    window.history.pushState({}, null, newPath)

    window.activepieces.configure({
      prefix: settings ? '/settings/integrations' : `/templates/${projectId}`,
      instanceUrl: activePiecesInstanceUrl,
      jwtToken: jwt,
      embedding: {
        containerId: 'active-pieces-container',
        builder: {
          disableNavigation: true,
        },
        dashboard: {
          hideSidebar: true,
        },
        hideFolders: true,
      },
    })

    if (!piecesRef.current) return

    const frame = piecesRef.current.querySelector('iframe')
    frame.style = 'width: 100%; height: calc(100vh - 69px);'
  }, [settings, projectId, jwt, page])

  useEffect(() => {
    if (jwt && loading) {
      lazyLoadActivePiecesFromCDN(
        () => {
          configureActivePieces()
          setError(false)
          setLoading(false)
        },
        () => {
          setLoading(false)
          setError(true)
        },
      )
    } else if (jwt && !error) {
      configureActivePieces()
    } // TODO way to reload if we fail to load from cdn
  }, [configureActivePieces, error, jwt, loading])

  return (
    <Alert.ErrorBoundary message='Something went wrong'>
      <div
        id='active-pieces-container'
        ref={piecesRef}
        style={{ width: '100%', height: 'calc(100vh - 65px)', borderLeft: '1px solid #f0f0f0' }}
      >
        {!jwtError && loading && <Loading />}
        {error && <Alert message='Error' description='Failed to load integrations.' type='error' showIcon />}
        {jwtError && <Alert message='Error' description='Failed to authenticate.' type='error' showIcon />}
      </div>
    </Alert.ErrorBoundary>
  )
}
