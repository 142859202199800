import { useCallback } from 'react'
import useTemplateDataSourceContext from './useTemplateDataSourceContext'
import { useConnections } from '@features/activePieces'
import { addIdsToSchema } from '@documint/react-antd-json-schema-builder'
import DataSource from '../data-sources/DataSource'

import { saveAs } from 'file-saver'
import { addItemsToCollection, removeItemsFromCollection, deepClone, deepObject } from '@utils'
import { selectSchema, updateSchema } from '@reducers/schema'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { useDispatch, useSelector } from 'react-redux'
import { defaultSchema } from '@documint/react-antd-json-schema-builder'
import { useLazyGetDataFromProviderQuery } from '@services/documintApi/providers'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { useTemplate } from '@hooks/useTemplate'

const bridgeIn = (schema) => removeItemsFromCollection(deepClone(schema))
const bridgeOut = (schema) => addItemsToCollection(deepClone(schema))

export function useTemplateDataSource() {
  const {
    dataSource,
    setDataSource,
    fieldSchema,
    setFieldSchema,
    selectedDataSource,
    setSelectedDataSource,
    isModalOpen,
    isSyncing,
    setIsSyncing,
    toggleIsModalOpen,
    editor,
    setEditor,
  } = useTemplateDataSourceContext()
  const { template, update: updateTemplate } = useTemplate()
  const { connections } = useConnections()
  const dispatch = useDispatch()
  const schemaInRedux = useSelector(selectSchema)
  const [fetchData] = useLazyGetDataFromProviderQuery()

  /**
   * Updates the field schema in the database
   * @param {Object} update - The updated field schema
   */
  const updateFieldSchema = useCallback(
    (update) => {
      const schema = update && bridgeOut(update)
      setFieldSchema(schema)
      return updateTemplate({ fieldSchema: addIdsToSchema(schema) })
    },
    [setFieldSchema, updateTemplate],
  )

  /**
   * Updates the data source in the database
   */
  const updateDataSource = useCallback(
    async (dataSource) => {
      setDataSource(dataSource)
      const dataSourceObject = dataSource instanceof DataSource ? dataSource.toObject() : dataSource
      await updateTemplate({ dataSource: dataSourceObject })
    },
    [setDataSource, updateTemplate],
  )

  // Connected Data Source Specific Logic
  const syncDataSource = useCallback(async () => {
    try {
      setIsSyncing(true)

      for (let field of dataSource?.entityConfigFields) {
        const fieldQuery = field?.getQuery()
        const connectionName = dataSource.connection?.name
        const action = field.action
        const provider = dataSource.id
        const response = await fetchData({ provider, action, connectionName, ...fieldQuery })
        field.handleResponse(response)
      }

      const fieldSchema = dataSource.getJsonSchema(editor, { expandedFields: dataSource.expandedFields })
      updateFieldSchema(fieldSchema)

      await updateDataSource(dataSource)
      setIsSyncing(false)
    } catch (error) {
      setIsSyncing(false)
      throw error
    }
  }, [setIsSyncing, updateDataSource, updateFieldSchema, dataSource, fetchData, editor])

  /**
   * Disconnects the data source
   */
  const disconnectDataSource = useCallback(async () => {
    selectedDataSource?.disconnect()
    setDataSource(selectedDataSource)
    await updateDataSource(selectedDataSource)
    await updateFieldSchema(fieldSchema)
  }, [fieldSchema, updateDataSource, updateFieldSchema, setDataSource, selectedDataSource])

  /**
   * Reconnects the data source
   */
  const reconnectDataSource = useCallback(async () => {
    selectedDataSource.reconnect()
    setDataSource(selectedDataSource)
    await updateDataSource(selectedDataSource)
    syncDataSource()
  }, [selectedDataSource, setDataSource, updateDataSource, syncDataSource])

  const toggleDataSource = useCallback(async () => {
    if (dataSource?.isConnected) dataSource?.disconnect()
    else dataSource?.connect()
    await updateDataSource(dataSource)
  }, [updateDataSource, dataSource])

  // Custom Data Source Specific Logic
  /**
   * Refreshes the schema in the redux store
   */
  const refreshSchema = useCallback(
    (schema) => {
      schema = schema || fieldSchema || defaultSchema
      if (deepObject(schema) === deepObject(schemaInRedux.present.schema)) return
      dispatch(updateSchema(bridgeIn(schema)))
      dispatch(UndoActionCreators.clearHistory())
    },
    [dispatch, schemaInRedux, fieldSchema],
  )

  /**
   * Clears the schema in the redux store
   */
  const clearSchema = useCallback(() => {
    dispatch(updateSchema(defaultSchema))
    return updateFieldSchema(null)
  }, [dispatch, updateFieldSchema])

  /**
   * Clears the schema in the redux store
   */
  const clearHistory = useCallback(() => {
    dispatch(UndoActionCreators.clearHistory())
  }, [dispatch])

  const deleteDataSource = useCallback(() => {
    Modal.confirm({
      title: 'Confirm',
      content: 'You are about to delete this datasource and its schema',
      icon: <ExclamationCircleOutlined />,
      okText: 'Reset',

      onOk: () => {
        updateDataSource(null)
        clearSchema()
      },
    })
  }, [clearSchema, updateDataSource])

  /**
   * Downloads the schema as a JSON file
   */
  const downloadSchema = useCallback(() => {
    const filename = `${template?.name}-${Date.now()}.json`
    const toSave = new Blob([JSON.stringify(fieldSchema)], { type: 'application/json' })
    saveAs(toSave, filename)
  }, [fieldSchema, template?.name])

  return {
    fieldSchema,
    setFieldSchema,
    dataSource,
    setDataSource,
    connections,
    selectedDataSource,
    setSelectedDataSource,
    isModalOpen,
    toggleIsModalOpen,
    updateDataSource,
    updateFieldSchema,
    editor,
    setEditor,

    // Connected Data Source Specific items
    isSyncing,
    syncDataSource,
    disconnectDataSource,
    reconnectDataSource,
    toggleDataSource,
    deleteDataSource,

    // Custom Data Source Specific items
    refreshSchema,
    downloadSchema,
    clearHistory,
    clearSchema,
    dispatch,
    selectSchema,
    schemaInRedux,
  }
}

export default useTemplateDataSource
