import { useSelector } from 'react-redux'
import { selectProfile } from '../authSlice'
/**
 *
 * @returns
 */
export const useGetProfile = () => {
  return useSelector(selectProfile)
}

export default useGetProfile
