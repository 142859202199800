import React, { useCallback, useMemo, useState } from 'react'
import { Collapse, Tree, Empty, Badge, Input } from 'antd'
import FaIcon from '@components/icons/FaIcon'
import { TYPES } from '../Editor/plugins/componentTypes/consts'
import { GeneralIcon } from '@features/template-data-source/components/DataSourceIcon'
import styled from 'styled-components'
import { parseTreeSchema } from '@utils/json'
import { addItemsToCollection } from '@utils/data'
import { deepClone } from '@utils/index'
import { useEditor } from '../../hooks'
import { useTemplate } from '@hooks/useTemplate'

const { Panel } = Collapse
const { Search } = Input

const Ellipsis = styled.div`
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: ${(props) => props.$mw};
`

const TitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 3px 10px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(156, 163, 175, 0.4) 0px 3px 3px -1px, rgba(156, 163, 175, 0.5) 0px 2px 4px -2px;
  }
`

const CollapsibleContainer = styled(Collapse)`
  border: none;
  .ant-collapse-content {
    overflow: hidden;
  }
  .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-normal {
    width: 100%;
  }
`

function renderTitle({ title, strTitle, key, formattedKey, type, uuid, cid, schemaMetaData }, editor) {
  if (!editor.SchemaTypes) editor.SchemaTypes = {}
  editor.SchemaTypes[formattedKey] = type

  const bm = editor.Blocks
  const { Block, startDrag, endDrag } = bm
  const dragStart = startDrag.bind(bm)
  const dragEnd = endDrag.bind(bm)
  const parentKey = formattedKey.split(`.[${strTitle}]`)[0]
  const { nesting, arrayType, parentNesting, nestingObj, $sourceMetaData, splitPath } = schemaMetaData

  const block = {
    label: strTitle ?? title,
    content: {
      type: TYPES.token,
      // Add block values here...
      attributes: {
        'data-token-id': uuid,
        'data-token-cid': cid,
        'data-placeholder': key,
        'data-formatted-placeholder': formattedKey,
        'data-type': type,
        'data-parent-type': editor.SchemaTypes[parentKey] || '',
        'data-parent-key': parentKey || '',
        // "data-schema-meta-data": JSON.stringify(schemaMetaData),
        'data-array-nesting': nesting || 0,
        'data-parent-nesting': parentNesting || 0,
        'data-array-type': arrayType || '',
        'data-object-nesting': JSON.stringify(nestingObj || {}),
        'data-split-path': JSON.stringify(splitPath),
        'data-title': strTitle ?? title,
      },
    },
  }

  const model = new Block(block)

  // Attach events
  return (
    <TitleContainer draggable={true} onDragStart={() => dragStart(model)} onDragEnd={dragEnd}>
      <FaIcon icon='faGripDotsVertical' />
      {$sourceMetaData?.iconClass ? (
        <i
          className={$sourceMetaData?.iconClass}
          style={{ marginLeft: '10px', fontSize: '17px' }}
          title={nesting ? 'nested array' : type}
          types={nesting ? 'nested' : type}
        ></i>
      ) : (
        <GeneralIcon
          style={{ marginLeft: '10px' }}
          title={nesting ? 'nested array' : type}
          types={nesting ? 'nested' : type}
        />
      )}
      <Ellipsis $mw={'75%'}>{title}</Ellipsis>
      {schemaMetaData.nesting > 0 && (
        <>
          <span style={{ position: 'absolute', right: '15px' }}>
            <Badge
              style={{
                color: 'rgb(255, 255, 255)',
                backgroundColor: 'rgb(0, 155, 255)',
              }}
              title={`Array is nested ${schemaMetaData.nesting} times`}
              count={schemaMetaData.nesting}
            />
          </span>
          <span style={{ position: 'absolute', right: '4px', top: '-3px' }}>
            <Badge
              style={{
                color: 'rgb(0, 155, 255)',
                backgroundColor: 'transparent',
              }}
              title={`${arrayType} subtype`}
              count={<GeneralIcon title={arrayType} types={arrayType} />}
            />
          </span>
        </>
      )}
      {schemaMetaData.nesting <= 0 && type === 'array' && (
        <span style={{ position: 'absolute', right: '10px' }}>
          <Badge
            style={{
              color: 'rgb(0, 155, 255)',
              backgroundColor: 'transparent',
            }}
            title={`${arrayType} subtype`}
            count={<GeneralIcon title={arrayType} types={arrayType} />}
          />
        </span>
      )}
    </TitleContainer>
  )
}

const generateList = (data) => {
  const dataList = []
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const { key } = node
    dataList.push({
      key,
      title: key,
    })
    if (node.children) {
      generateList(node.children)
    }
  }
  return dataList
}

const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

export default function VariableList({ title, showWhenEmpty = false, ...rest }) {
  const { editor } = useEditor()
  const { template } = useTemplate()
  const draggableTitle = useCallback((node) => renderTitle(node, editor), [editor])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [autoExpandParent, setAutoExpandParent] = useState(true)

  const onExpand = useCallback((newExpandedKeys) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }, [])

  const variableLists = useMemo(
    () => [
      {
        header: title || 'Template Variables',
        key: '1',
        data: parseTreeSchema(addItemsToCollection(deepClone(template?.fieldSchema))),
        showArrow: false,
        collapsible: false,
      },
    ],
    [template?.fieldSchema, title],
  )

  const loop = useCallback(
    (data) =>
      data
        .map((item) => {
          const strTitle = item.title
          const index = strTitle.toLowerCase().indexOf(searchValue.toLowerCase())
          const beforeStr = strTitle.substring(0, index)
          const betweenStr = strTitle.substring(index, index + searchValue.length)
          const afterStr = strTitle.slice(index + searchValue.length)
          const title =
            index > -1 ? (
              <>
                {beforeStr}
                <span style={{ color: '#009BFF' }}>{betweenStr}</span>
                {afterStr}
              </>
            ) : (
              strTitle
            )
          if (item.children) {
            return {
              ...item,
              title,
              strTitle,
              children: loop(item.children),
            }
          }
          return index > -1
            ? {
                ...item,
                strTitle,
                title,
              }
            : null
        })
        .filter((item) => item),
    [searchValue],
  )

  if (!template?.fieldSchema && !showWhenEmpty) return null

  return (
    <>
      <CollapsibleContainer {...rest} activeKey={'1'} collapsible={false}>
        {variableLists.map(({ data, header, ...rest }) => {
          const dataList = generateList(data)

          const onChange = (e) => {
            const { value } = e.target
            const newExpandedKeys = dataList
              .map((item) => {
                if (item.title.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                  return getParentKey(item.key, data)
                }
                return null
              })
              .filter((item, i, self) => item && self.toLowerCase().indexOf(item.toLowerCase()) === i)
            setExpandedKeys(newExpandedKeys)
            setSearchValue(value)
            setAutoExpandParent(true)
          }

          return (
            <Panel header={header} className='variable-list-wrapper' {...rest}>
              {editor && data && data.length > 0 ? (
                <>
                  <Search
                    style={{
                      marginBottom: 8,
                    }}
                    placeholder='Search'
                    onChange={onChange}
                  />
                  <Tree
                    treeData={loop(data)}
                    blockNode={true}
                    draggable={false}
                    defaultExpandAll={true}
                    onExpand={onExpand}
                    expandedKeys={expandedKeys}
                    autoExpandParent={autoExpandParent}
                    titleRender={draggableTitle}
                    selectable={false}
                    showIcon={true}
                    showLine={{ showLeafIcon: false }}
                  />
                </>
              ) : (
                <Empty
                  className='py-3'
                  description='No variables yet...'
                  image='/images/empty-states/variable-list-empty-state.svg'
                />
              )}
            </Panel>
          )
        })}
      </CollapsibleContainer>
    </>
  )
}
