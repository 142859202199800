import React, { useState } from 'react'
import { Button, Typography } from 'antd'
import { NewApiAppModal } from '@features/apiApps'

const { Title, Paragraph } = Typography
const marketplaceUrl = 'https://airtable.com/marketplace/blkTK0uVJlZrho1Bu/documint'

export default function AirtableApp() {
  const [apiAppModalIsVisible, setApiAppModalIsVisible] = useState(false)
  const hideModal = () => setApiAppModalIsVisible(false)
  
  return (
    <>
      <Title level={3}>Overview</Title>
      <Paragraph>
        Documint's Airtable app allows you to easily create documents from your templates within Airtable.
      </Paragraph>

      <Title level={4}>Setup</Title>
      <Paragraph>
        <ol>
          <li>
            Add the Documint app to your base from the{' '}
            <a href={marketplaceUrl} target='_blank' rel='noopener noreferrer'>
              Airtable marketplace
            </a>
          </li>
          <li>
            Create a{' '}
            <Button size='small' onClick={() => setApiAppModalIsVisible(true)}>
              new API key
            </Button>{' '}
            to use in Airtable.
          </li>
          <li>Add your API key to the settings of the Airtable app.</li>
          <li>Select the template you want to want to merge your data with.</li>
          <li>Select the Attachment field where you want to store your documents.</li>
          <li>
            Select a record and click "Preview Document" to create a preview or "Generate Document" to create a live
            document.
          </li>
        </ol>
      </Paragraph>
      <div className='mb-4'>
        <Button
          href='https://docs.documint.me/integrations/airtable/airtable-app'
          htmlType='a'
          target='_blank'
          rel='noopener noreferrer'
        >
          Read complete guide
        </Button>
      </div>
      <iframe
        width='560'
        height='315'
        src='https://www.youtube.com/embed/88YShTRwQ94'
        title='YouTube video player'
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>

      <NewApiAppModal open={apiAppModalIsVisible} onCancel={hideModal} onFinish={hideModal} />
    </>
  )
}
