import { TYPES } from '../consts'
import { loopAllComponents } from '../../../utils'
import { removeDisplayBefore, removeDisplayAfter } from './Section'

export const protectedCss = `
  /* SECTION COMPONENT */
  *[data-gjs-type="${TYPES.temp_columns}"]{
    display: flex;
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    color: inherit;
    box-sizing: border-box;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
`

export default function columnsType(dc, options) {
  dc.addType(TYPES.temp_columns, {
    model: {
      extend: TYPES.template,
      defaults: {
        name: 'Columns (Legacy)',
        icon: '<i class="gjs-badge__icon fa-kit fa-columns-1-1-1-1"></i>',
        selectable: false,
        hoverable: false,
        draggable: `[data-gjs-type=wrapper], [data-gjs-type=gs-columns], [data-gjs-type="${TYPES.column_2}"]`,
      },
      // This replaces it with the new column
      bump() {
        this.set({
          type: 'gs-columns',
        })
        this.addAttributes({ 'data-gs-type': 'columns' })
        const ifTrue = (c) => c.bump
        const clb = (c) => c.bump()
        loopAllComponents(this.components(), ifTrue, clb)
      },
      removeDisplayBefore,
      removeDisplayAfter,
    },
  })
}
