import React, { useEffect, useRef, useState } from 'react'
import { Layout, Tabs } from 'antd'
import { useToggle } from '@hooks/helperHooks'
import { useEditor } from '../../hooks'
import PropertiesPanel from './PropertiesPanel'
import { FaIcon } from '@components/icons'
import SiderToggle from './SiderToggle'

const PROPERTIES_KEY = 'properties'

export default function Sider({ onEditHeaderFooter, children, ...rest }) {
  const [activeTab, setActiveTab] = useState()
  const [isCollapsed, toggleCollapsed] = useToggle(false)
  const prevTab = useRef(null)
  const { editor, run, selectedComponent } = useEditor()

  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  const handleToggleLogic = () => {
    if (!editor) return
    run('edit-logic')
  }

  useEffect(() => {
    if (selectedComponent) {
      setActiveTab(PROPERTIES_KEY)
    } else {
      const prevTabKey = prevTab.current
      setActiveTab(prevTabKey || 'blocks')
    }
  }, [selectedComponent])

  useEffect(() => {
    if (activeTab !== PROPERTIES_KEY) prevTab.current = activeTab
  }, [selectedComponent, activeTab])

  const siderToggle = <SiderToggle isCollapsed={isCollapsed} onClick={toggleCollapsed} />

  return (
    <Layout.Sider
      id='left-panel'
      className='sider'
      style={{ overflow: 'hidden', position: 'relative' }}
      collapsed={isCollapsed}
      collapsible={true}
      collapsedWidth={40}
      onCollapse={toggleCollapsed}
      trigger={null}
      {...rest}
    >
      {isCollapsed && siderToggle}

      <Tabs
        tabBarStyle={{ display: 'flex' }}
        style={{
          height: '100%',
          minWidth: rest.width,
          visibility: isCollapsed ? 'hidden' : 'visible',
          transition: 'visibility 0s, opacity 0.5s',
        }}
        tabBarExtraContent={{ left: siderToggle }}
        defaultActiveKey='blocks'
        centered={false}
        activeKey={activeTab}
        renderTabBar={(props, DefaultTabBar) =>
          isCollapsed ? null : <DefaultTabBar {...props} style={{ margin: 0 }} />
        }
        onChange={handleTabChange}
        items={[
          {
            key: 'blocks',
            label: (
              <>
                <FaIcon icon='faPlus' style={{ marginRight: '8px' }} />
                Add
              </>
            ),
            forceRender: true,
            children: <div id='block-manager' />,
          },
          {
            key: PROPERTIES_KEY,
            label: (
              <>
                <FaIcon icon='faSliders' style={{ marginRight: '8px' }} />
                Properties
              </>
            ),
            disabled: !selectedComponent,
            forceRender: true,
            children: <PropertiesPanel selectedComponent={selectedComponent} onEditLogic={handleToggleLogic} />,
          },
        ]}
      />
    </Layout.Sider>
  )
}
