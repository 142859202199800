import { useCallback, useMemo } from 'react'
import { useTemplateDesignerContext } from './useTemplateDesignerContext'

import { useGetAssetsQuery } from '@services/documintApi/assets'

import _ from 'lodash'
import { getJsonSchemaFromTemplate } from '@utils/json'
import Handlebars from 'handlebars'
import { getObjectChanges } from '@utils'

import { useActivePieces } from '@features/activePieces'
import { useTemplateEdit } from '@pages/templates/template-edit/hooks'

// * include html to auto detect schema in canvas
const SCHEMA_PROPS = ['css', 'documentNamePattern', 'headerTemplate', 'footerTemplate']

export function useDesigner() {
  const { ref, showHeaderFooter, toggleHeaderFooter } = useTemplateDesignerContext()
  const { template, update, isUpdating } = useTemplateEdit()
  const assets = useGetAssetsQuery()
  const activePieces = useActivePieces()

  const updateHeaderFooterTemplate = useCallback(
    (updates) => update({ ..._.pick(updates, ['headerTemplate', 'footerTemplate']) }),
    [update],
  )

  const updateAssets = useCallback(
    (assets) => {
      if (!assets) return
      const currentAssetIds = template?.assets?.map((a) => (a?.id ? a.id : a))
      if (_.isEqual(currentAssetIds, assets)) return
      return update({ assets })
    },
    [template, update],
  )

  const helperNames = useMemo(() => Object.keys(Handlebars.helpers), [])

  const getUpdatedSchema = useCallback(
    (updates = {}) => {
      const filteredUpdates = _.pick(updates, SCHEMA_PROPS)
      if (_.isEmpty(filteredUpdates)) return template.fieldSchema
      const currentValues = _.pick(template, SCHEMA_PROPS)
      const values = { ...currentValues, ...filteredUpdates }
      const bodySchema = getJsonSchemaFromTemplate(values.html)
      const headerSchema = getJsonSchemaFromTemplate(values.headerTemplate)
      const footerSchema = getJsonSchemaFromTemplate(values.footerTemplate)
      const documentNameSchema = getJsonSchemaFromTemplate(values.documentNamePattern)
      const updatedSchema = _.merge({}, bodySchema, headerSchema, footerSchema, documentNameSchema)

      // remove handlebar helpers from the schema
      updatedSchema.properties = _.pickBy(updatedSchema.properties, (value, key) => helperNames.includes(key) === false)

      return updatedSchema
    },
    [template, helperNames],
  )

  const updateTemplate = useCallback(
    async (patch) => {
      // Update the field schema if necessary
      const updatedSchema = getUpdatedSchema(patch)
      const fieldSchemaChanged = !_.isEqual(updatedSchema, template.fieldSchema)
      if (fieldSchemaChanged) patch.fieldSchema = _.merge({}, template.fieldSchema, updatedSchema)

      // Add the version to the request if it exists
      if (template.__v) patch.__v = template.__v
      const response = await update(patch)
      return response
    },
    [template, update, getUpdatedSchema],
  )

  /**
   * Updates content
   * @param {Object} content
   * @param {Object} content.components
   * @param {Object} content.styles
   * @param {String} content.html
   * @param {String} content.css
   */
  const updateContent = useCallback(
    (content) => {
      const contentProps = ['components', 'styles', 'html', 'css']
      const changes = getObjectChanges(template, content, contentProps)
      if (!_.isEmpty(changes)) update(changes)
    },
    [template, update],
  )

  return {
    template,
    update: updateTemplate,
    isUpdating,
    updateContent,
    updateAssets,
    updateHeaderFooterTemplate,

    ref,
    assets,
    showHeaderFooter,
    toggleHeaderFooter,
    activePieces,
  }
}

export default useDesigner
