import { useCallback } from 'react'
import { useEditorContext } from './useEditorContext'

import { zoomIn as _zoomIn, zoomOut as _zoomOut, setZoom as _setZoom } from '../components/Editor/utils'
import { toJson } from '../components/NewLogicModal/utils' // Move to utils
import { useTemplateEdit } from '@pages/templates/template-edit/hooks'

/* Function to return loop id of closest parent from schema */
function findLoop(component) {
  // Move to utils
  const parent = component.parent()
  const loop = parent?.getLoop && parent.getLoop()
  if (loop) {
    const parsedLoop = toJson(loop)
    const loopValue = Object.keys(parsedLoop)[0]
    return loopValue && loopValue !== 'undefined' ? loopValue : !parsedLoop && loop ? loop : 'root'
  } else if (!parent) {
    return 'root'
  }
  return findLoop(parent)
}

export const getAllLoopsToRoot = (component, loops = ['root']) => {
  const parent = component.parent()
  const loop = parent?.getLoop && parent.getLoop()
  if (loop) {
    const parsedLoop = toJson(loop)
    const loopValue = Object.keys(parsedLoop)[0]
    const value = loopValue && loopValue !== 'undefined' ? loopValue : !parsedLoop && loop ? loop : null
    value && loops.push(value)
  }
  if (parent?.parent()) getAllLoopsToRoot(parent?.parent(), loops)
  return loops
}

export const useEditor = () => {
  const {
    editor,
    setEditor: _setEditor,
    initialized,
    setInitialized,
    selectedComponent,
    setSelectedComponent,
    zoomValue,
    setZoomValue,
    guides,
    toggleGuides: _toggleGuides,
    changeCount,
    // setChangeCount,
    hasRedo,
    setHasRedo,
    hasUndo,
    setHasUndo,
    hasLegacy,
    setHasLegacy,
    revertToLegacy,
    setRevertToLegacy,
    revertSections,
    revertSectionsControls,
  } = useEditorContext()
  const { update, setEditor: setTemplateEditor } = useTemplateEdit()

  const setEditor = useCallback(
    (editor) => {
      _setEditor(editor)
      // Set template editor so other features (TemplateData) can access it
      setTemplateEditor(editor)
    },
    [_setEditor, setTemplateEditor],
  )

  const toggleGuides = useCallback(() => {
    if (guides) {
      editor.Commands.stop('sw-visibility')
    } else {
      editor.Commands.run('sw-visibility')
    }
    _toggleGuides()
  }, [guides, editor, _toggleGuides])

  const run = useCallback(
    (command) => {
      if (editor?.Commands) editor.Commands.run(command)
    },
    [editor],
  )

  const stop = useCallback(
    (command) => {
      if (editor?.Commands) editor.Commands.stop(command)
    },
    [editor],
  )

  const undo = useCallback(() => editor?.Commands?.run?.('core:undo'), [editor])

  const redo = useCallback(() => editor?.Commands?.run?.('core:redo'), [editor])

  const clear = useCallback(() => {
    editor?.Components?.clear?.()
    editor?.select?.()
  }, [editor])

  const showCode = useCallback(() => editor?.Commands?.run?.('export-template'), [editor])

  // ZOOM
  const zoomIn = useCallback(() => {
    console.log('zoomIn', editor)
    const value = _zoomIn(editor, 10)
    setZoomValue(value)
  }, [editor, setZoomValue])

  const zoomOut = useCallback(() => {
    const value = _zoomOut(editor, 10)
    setZoomValue(value)
  }, [editor, setZoomValue])

  const resetZoom = useCallback(() => {
    const value = _setZoom(editor, 100)
    setZoomValue(value)
  }, [editor, setZoomValue])

  const retrieveArrayTempParent = useCallback(() => {
    if (selectedComponent) return findLoop(selectedComponent)
    return 'root'
  }, [selectedComponent])

  const retrieveArrayTempParents = useCallback(() => {
    if (selectedComponent) return getAllLoopsToRoot(selectedComponent)
    return ['root']
  }, [selectedComponent])

  const updateContent = useCallback(
    ({ components, styles, css, html }) => {
      update({ components, styles, html, css })
    },
    [update],
  )

  return {
    editor,
    setEditor,
    initialized,
    setInitialized,

    updateContent,

    toggleGuides,
    showCode,
    zoomValue,
    resetZoom,
    zoomIn,
    zoomOut,

    changeCount,
    hasUndo,
    hasRedo,
    setHasUndo,
    setHasRedo,
    hasLegacy,
    setHasLegacy,
    revertToLegacy,
    setRevertToLegacy,
    revertSections,
    revertSectionsControls,

    selectedComponent,
    setSelectedComponent,

    guides,
    undo,
    redo,
    clear,
    run,
    stop,

    retrieveArrayTempParent,
    retrieveArrayTempParents,
  }
}
