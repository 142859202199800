import React from 'react'
import Button from '@common/Button'
import { Space, Input } from 'antd'
import { IoMdUndo, IoMdRedo } from 'react-icons/io'
import { PlusOutlined, LineOutlined } from '@ant-design/icons'
import FaIcon from '@components/icons/FaIcon'
import { useDesigner, useEditor } from '../hooks'
import style from './Editor/config/style'
import { loadData, setDevice, updateCanvasPosition } from './Editor/utils'

export default function Toolbar({ undoProps, redoProps, className, ...rest }) {
  const { toggleHeaderFooter, template } = useDesigner()
  const {
    editor,
    zoomIn,
    zoomOut,
    zoomValue,
    resetZoom,
    showCode,
    undo,
    redo,
    guides,
    toggleGuides,
    hasUndo,
    hasRedo,
    hasLegacy,
    setHasLegacy,
    revertToLegacy,
    setRevertToLegacy,
    revertSectionsControls,
    run,
  } = useEditor()
  const { options } = template || {}

  const buttonStyle = {
    height: '28px',
  }

  return (
    <div
      id='primary-toolbar'
      className={`px-3 ${className}`}
      style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(255,255,255,.7)' }}
      {...rest}
    >
      <Space
        direction='horizontal'
        align='middle'
        size='middle'
        style={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-start' }}
      >
        <div id='state-control'>
          <Button
            title='Undo'
            className='mr-1'
            icon={<IoMdUndo />}
            onClick={undo}
            {...undoProps}
            disabled={!hasUndo}
          />
          <Button title='Redo' icon={<IoMdRedo />} onClick={redo} {...redoProps} disabled={!hasRedo} />
        </div>
        <div id='rte-controls'></div>
      </Space>

      <Space
        direction='horizontal'
        align='middle'
        size='middle'
        style={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}
      >
        <Button type='link' onClick={showCode} style={{ color: '#eee' }}>
          .
        </Button>

        {/* BUMP COMPONENTS TO NEW */}
        {hasLegacy && (
          <Button
            icon={<FaIcon icon='faCircleUp' />}
            onClick={() => {
              editor.setSelected()
              run('convert-legacy')
            }}
            id='upgrade-legacy-elements'
            size='small'
            style={{ height: '28px', padding: '6px 2px' }}
            tooltip={{ title: 'Upgrade legacy elements', placement: 'bottom', mouseEnterDelay: 0.4 }}
          />
        )}

        {/* REVERT COMPONENTS TO OLD */}
        {!hasLegacy && revertToLegacy && (
          <Button
            icon={<FaIcon icon='faUndo' />}
            onClick={() => {
              loadData(editor, revertToLegacy.components, revertToLegacy.styles, style)

              const bodyEl = editor.Canvas.getBody()
              const documentWrapper = document.createElement('div')
              documentWrapper.classList.add('document')
              documentWrapper.appendChild(editor.getWrapper().view.el)
              bodyEl.appendChild(documentWrapper)

              setDevice(editor, options)
              updateCanvasPosition(editor)

              setRevertToLegacy(false)
              setHasLegacy(true)
              editor.UndoManager.clear()
            }}
            size='small'
            style={{ height: '28px', padding: '6px 2px' }}
            tooltip={{ title: 'Revert legacy elements', placement: 'bottom', mouseEnterDelay: 0.4 }}
          />
        )}

        {/* REVERT LAST SECTION TO OLD */}
        {!revertToLegacy && !revertSectionsControls.isEmpty() && (
          <Button
            icon={<FaIcon icon='faUndo' />}
            onClick={() => {
              const revertSection = revertSectionsControls.pop()
              loadData(editor, revertSection.components, revertSection.styles, style)

              const bodyEl = editor.Canvas.getBody()
              const documentWrapper = document.createElement('div')
              documentWrapper.classList.add('document')
              documentWrapper.appendChild(editor.getWrapper().view.el)
              bodyEl.appendChild(documentWrapper)

              setDevice(editor, options)
              updateCanvasPosition(editor)

              setHasLegacy(true)
              editor.UndoManager.clear()
            }}
            size='small'
            style={{ height: '28px', padding: '6px 2px' }}
            tooltip={{ title: 'Revert Last Section', placement: 'bottom', mouseEnterDelay: 0.4 }}
          />
        )}

        {/* ZOOM CONTROLS */}
        <Space.Compact size='small' style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            icon={<LineOutlined />}
            style={{ ...buttonStyle, borderRadius: '6px 0 0 6px' }}
            tooltip={{ title: 'Zoom out', mouseEnterDelay: 1, placement: 'bottom' }}
            onClick={zoomOut}

          />
          <Button
            style={{ height: '28px', width: '60px', background: 'transparent', color: '#555', padding: '4px 10px' }}
            onClick={resetZoom}
            tooltip={{ title: 'Reset zoom', mouseEnterDelay: 1, placement: 'bottom' }}

          >
            {zoomValue}%
          </Button>
          <Button
            icon={<PlusOutlined />}
            style={{ ...buttonStyle, borderRadius: '0 6px 6px 0' }}
            tooltip={{ title: 'Zoom in', mouseEnterDelay: 1, placement: 'bottom' }}
            onClick={zoomIn}

          />
        </Space.Compact>

        {/* SHOW / HIDE GUIDES BUTTON */}
        <Button
          icon={guides ? <FaIcon icon='faTableCells' /> : <FaIcon icon='faTableCells' style={{color:'#ddd'}} />}
          onClick={toggleGuides}
          size='small'
          style={{ height: '28px', padding: '6px 2px' }}
          tooltip={{ title: `Turn ${guides ? 'off' : 'on'} guides`, placement: 'bottom', mouseEnterDelay: 1 }}
        />

        {/* HEADER / FOOTER BUTTON */}
        <Button
          onClick={toggleHeaderFooter}
          size='small'
          style={{ padding: '3px 10px', height: '28px' }}
          tooltip={{ title: 'Edit header & footer', placement: 'bottom', mouseEnterDelay: 1 }}
        >
          Header & Footer
        </Button>
      </Space>
    </div>
  )
}
