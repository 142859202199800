import React from 'react'
import { Select } from 'antd'
import { useTemplatesContext } from '../context/TemplateContext'
import FaIcon from '../../../components/icons/FaIcon'

export default function TemplatesSort() {
  const { setSort, sort } = useTemplatesContext()

  const getLabel = (label, icon) => (
    <>
      {label} <FaIcon icon={icon} style={{ paddingLeft: '5px' }} />
    </>
  )

  const options = [
    { label: getLabel('Name', 'faArrowUp'), value: 'name' },
    { label: getLabel('Name', 'faArrowDown'), value: '-name' },
    { label: getLabel('Date Updated', 'faArrowUp'), value: 'updatedAt', title: 'name' },
    { label: getLabel('Date Updated', 'faArrowDown'), value: '-updatedAt' },
    { label: getLabel('Date Created', 'faArrowUp'), value: 'createdAt' },
    { label: getLabel('Date Created', 'faArrowDown'), value: '-createdAt' },
  ]

  return <Select options={options} value={sort} onChange={setSort} suffixIcon={null} popupMatchSelectWidth={false} />
}
