const iconSector = {
  name: 'Icon',
  open: true,
  properties: [
    {
      name: 'Type',
      id: 'checkbox-box-type',
      property: 'checkbox-box-type',
      type: 'checkbox-box-type',
      full: true,
    },
    {
      id: 'scale',
      property: 'invalid-scale',
      name: 'Size',
      full: true,
      type: 'slider',
      step: 0.1,
      min: 0.5,
      max: 2,
      default: '1',
      onChange({ to, from, value, property }) {
        const editor = property.em.getEditor()
        const target = editor.getSelected()
        if (!target || !target.getHolder) return
        const checkboxHolder = target.getHolder()
        // TODO SCALE WITH FONT SIZE
        checkboxHolder.addStyle({ scale: value })
      },
      emit() {},
    },
    //{
    //  name: 'Custom Icon',
    //  id: 'checkbox-icon',
    //  property: 'checkbox-icon',
    //  type: 'checkbox-icon',
    //  full: true,
    //}
  ],
}

export default iconSector
