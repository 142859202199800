import { TYPES } from '../consts'

export default function dynamicRowType(dc, options) {
  dc.addType(TYPES.dynamic_row, {
    extends: TYPES.dynamic,
    model: {
      defaults: {
        switch: [
          {
            parents: ['wrapper', TYPES.column_2],
            type: TYPES.row_2,
          },
          {
            parents: [TYPES.column],
            type: TYPES.row,
          },
          {
            parents: ['gs-columns'],
            type: TYPES.row_2,
          },
        ],
        draggable: `[data-gjs-type=wrapper], [data-gjs-type="${TYPES.column_2}"], [data-gjs-type="${TYPES.column}"]`,
        attributes: { 'data-gs-type': 'column' },
      },
      getSpan() {},
      setSizeClass() {},
      getColumns() {},
      removeColumns() {},
      resetHandles() {},
      correctWidth() {},
    },
  })
}
