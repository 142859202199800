import React from 'react'
import { Button as AntDButton, Tooltip, Popconfirm } from 'antd'

export default function Button({ tooltip, children, popconfirm, ...rest }) {
  const button = children ? <AntDButton {...rest}>{children}</AntDButton> : <AntDButton {...rest} />

  return tooltip ? (
    <Tooltip {...tooltip}>{button}</Tooltip>
  ) : popconfirm ? (
    <Popconfirm {...popconfirm}>{button}</Popconfirm>
  ) : (
    button
  )
}
