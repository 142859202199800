const config = {
  id: 'airtable',
  name: 'Airtable',
  description:'Generate documents from your Airtable data.',
  icon: {
    type: 'image',
    value: '/images/delivery-methods/airtable-logo.png',
    style: { width: 'auto', height: '30px' },
  },
}

export default config
