import { TYPES } from '../consts'
import { getAndMergeModels } from '../../utils'
import { listenTraits, getScopedName, refreshIndex, applyRoots } from './Checkbox'
import { getParentLoop } from './Token'

export const type = TYPES.image

export const protectedCss = `
  /* IMAGE COMPONENT */
  *[data-gjs-type="${type}"], 
  *[data-gjs-type="${TYPES.barcode}"], 
  *[data-gjs-type="${TYPES.qrcode}"] { 
    display: flex;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-width: 100%;
    page-break-inside: avoid;
  }
`

export function mapAlignment() {
  const style = this.getStyle()['align-self']
  style &&
    this.addStyle({
      'margin': style === 'center' ? '0 auto 0 auto' : style === 'flex-end' ? '0 0 0 auto' : '0 auto 0 0',
    })
}

export default function imageType(dc, { editor }) {
  const model = getAndMergeModels(dc, [TYPES.template, 'image'])
  const defaults = model.defaults

  const draggableBlock = `[data-gjs-type=wrapper], [data-gjs-type=gs-columns], [data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.gridItem}], [data-gjs-type=${TYPES.column_2}]`
  const draggable = `[data-gjs-type=gs-columns]:empty, [data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.gridItem}], [data-gjs-type=${TYPES.column_2}]`

  defaults.name = 'Image'
  defaults.icon = '<i class="gjs-badge__icon dm-icon dm-image"></i>'
  defaults.activeOnRender = true
  defaults.attributes = {
    ...defaults?.attributes,
    'data-dm-category': 'content',
    'data-value': 'static',
    'data-context': '',
  }
  defaults.traits = [
    {
      name: 'data-value',
      type: 'switch',
      valueTrue: 'variable',
      valueFalse: 'static',
      label: 'Variable',
    },
    {
      name: 'varName',
      type: 'combo',
      label: 'Variable Name',
      parent: 'data-value=variable',
      placeholder: 'e.g. var',
      supportedTypes: ['string'],
      changeProp: true,
    },
    {
      name: 'placeholderImage',
      label: 'Placeholder Image',
      parent: 'data-value=variable',
      placeholder: 'e.g. image.png',
      changeProp: true,
    },
    {
      name: 'variableIndex',
      type: 'info-text',
      info: 'You can use multiple indices by comma separating them.',
      label: 'Index',
      parent: 'data-value=variable',
      placeholder: '0',
      changeProp: true,
      min: 0,
    },
  ]
  defaults.draggable = draggableBlock
  defaults.droppable = false
  defaults.stylable = [
    'width',
    'max-width',
    'height',
    'max-height',
    'align-self',
    'margin',
    'float',

    'better-borders',
    'border-color',
    'border-width',
    'border-style',

    'border-radius',
    'border-top-left-radius',
    'border-top-right-radius',
    'border-bottom-right-radius',
    'border-bottom-left-radius',
  ]

  model.mapAlignment = mapAlignment

  model.bump = function () {
    this.mapAlignment()
  }

  model.init = function () {
    listenTraits(this)
    this.set({ draggable })
    this.addClass('mw-100pc')
    this.on('change:attributes:data-value', this.handleValueChange)
    this.on('change:varName change:attributes:data-context', this.handleVariableChange)
    this.on('change:variableIndex', this.updateIndices)
    this.afterInit()
  }

  model.handleValueChange = function () {
    const value = this.getAttributes()['data-value']
    if (value !== 'static') this.handleVariableChange()
    else this.get('src')?.startsWith('{') && this.set({ src: '' })
  }
  model.handleVariableChange = function () {
    this.refreshIndex()

    this.updateIndices()
  }
  model.updateIndices = function () {
    let varName = this.get('varName')
    varName = this.applyRoots(editor, varName)
    varName = getScopedName(varName, this.getAttrToHTML())
    varName && this.set({ src: `{{${varName}}}` })
  }
  model.afterInit = function () {}
  model.setContext = function () {
    this.addAttributes({ 'data-context': getParentLoop(this, this.get('varName')) })
  }
  model.refreshIndex = refreshIndex
  model.applyRoots = applyRoots
  model.hasCondition = function () {
    return false
  }
  model.hasLoop = function () {
    return false
  }

  const view = {
    init: function () {
      this.listenTo(this.model, 'change:placeholderImage', this.updatePlacholderImage)
      this.updatePlacholderImage()
    },
    updatePlacholderImage: function () {
      const placeholder = this.model.get('placeholderImage')
      if (this.el && placeholder) this.el.src = placeholder
    },
  }

  dc.addType(type, { extend: 'image', model, view })
}
