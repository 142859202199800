import React, { useEffect } from 'react'
import { Card, Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import FaIcon from '@components/icons/FaIcon'
import { PageHeader } from '@ant-design/pro-components'
import { useToggle } from '@hooks/helperHooks'
import { ApiAppList, NewApiAppModal } from '@features/apiApps'
import { ActivePiecesConnections } from '@features/activePieces'

export default function Integrations() {
  const [newModalIsOpen, toggleNewApiAppModal] = useToggle(false)

  useEffect(() => {
    document.title = 'Integrations'
  }, [])

  const actions = [
    <Button key='add' onClick={() => toggleNewApiAppModal(true)} icon={<FaIcon icon='faKey' />}>
      Create API Key
    </Button>,
  ]

  return (
    <PageHeader title='API Keys' style={{ maxWidth: '1100px' }}>
      <Card
        title={
          <>
            Api Keys{' '}
            <Tooltip title='Grant other applications access to your Documint account with Api Keys.'>
              <InfoCircleOutlined />
            </Tooltip>
          </>
        }
        extra={actions}
        style={{ marginBottom: '1.5em' }}
      >
        <ApiAppList />

        <NewApiAppModal
          open={newModalIsOpen}
          onFinish={() => toggleNewApiAppModal(false)}
          onCancel={() => toggleNewApiAppModal(false)}
        />
      </Card>
      <Card className='p-0' style={{ borderRadius: '6px', overflow: 'hidden' }} styles={{ body: { padding: 0 } }}>
        <ActivePiecesConnections style={{ marginTop: '-30px' }} />
      </Card>
    </PageHeader>
  )
}
