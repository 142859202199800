import React, { useState } from 'react'
import { Checkbox, Form, Input, InputNumber, Typography, Tooltip } from 'antd'
import { getPropsFromUrl } from '../utils'
import { identity, isFunction, pickBy } from 'lodash'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useInstructions } from '../../../hooks'

const { Text } = Typography

export default function AirtableOptionsForm({ data, onChange, ...rest }) {
  const [isValid, setIsValid] = useState(false)
  const { updateConfig } = useInstructions()

  const handleFieldsChange = (changedField, allFields) => {
    const hasErrors = !allFields.every(({ errors }) => errors.length <= 0)
    if (hasErrors === isValid) setIsValid(!hasErrors)
    const values = allFields.reduce((acc, cur) => Object.assign({}, acc, { [cur.name[0]]: cur.value }), {})

    const { table_id, base_id } = getPropsFromUrl(values.url)
    values.base_id = base_id
    values.table_id = table_id

    updateConfig(values)

    const cleanedValues = pickBy(values, identity)

    if (!hasErrors && isFunction(onChange)) onChange(cleanedValues)
  }

  const fields = [
    {
      name: 'url',
      label: {
        value: 'Table URL',
        help: 'The URL of the Airtable table that the document will be generated from.',
      },
      input: <Input placeholder='Ex. https://airtable.com/appQWSbCHbQe8fqwa/tbl8jWgLX2BxP5Gkq...' />,
      required: true,
      rules: [
        { required: true, message: 'This field is required.' },
        {
          pattern: /^(https:\/\/airtable.com\/app.*)/,
          message: `Invalid table URL. Please enter the URL of the Airtable table from which you will be generating documents.`,
        },
      ],
      onChange: ({ target }) => {
        updateConfig({ url: target.value })
      },
      style: { width: '100%' },
    },
    {
      name: 'attachment_field_name',
      label: {
        value: 'Attachment field name',
        help: (
          <>
            The name of the attachment field in your table where you'd like documents to be stored.
            <br />
            <b>Note</b>: The name should match exactly how it appears in Airtable.
          </>
        ),
      },
      input: <Input placeholder='Ex. Saved Invoices' />,
      required: true,
      onChange: ({ target }) => updateConfig({ 'attachment_field_name': target.value }),
    },
    {
      name: 'overwrite_attachments',
      input: <Checkbox checked={data?.overwrite_attachments} disabled={!data?.attachment_field_name} />,
      label: {
        value: 'Overwrite attachments',
        help: (
          <>
            Overwrites all attachments in attachment field. The default functionality adds new attachments to the list
            of existing attachments. <br />
            <b>Note</b>: Attachment Field Name required for this option to work.
          </>
        ),
        disabled: !data?.attachment_field_name,
      },
      valuePropName: 'checked',
    },
    {
      name: 'expand',
      label: {
        value: 'Expand linked records',
        help: (
          <>
            The number of levels deep to expand linked records. For this feature to work, linked records must have a
            respective Table_Id field.{' '}
            <a
              href='https://docs.documint.me/integrations/airtable/generation-link#joining-linked-records'
              target='_blank'
              rel='noopener noreferrer'
            >
              learn more
            </a>
          </>
        ),
      },
      input: <InputNumber min={0} max={5} />,
      onChange: ({ target }) => updateConfig({ 'expand': target.value }),
    },
  ]

  return (
    <Form onFieldsChange={handleFieldsChange} {...rest}>
      {fields.map(({ name, input, label, ...props }) => (
        <Form.Item key={name} name={name} {...props} label={renderLabel(label)}>
          {input}
        </Form.Item>
      ))}
    </Form>
  )
}

function renderLabel({ value = '', help, ...props }) {
  const content = <Text {...props}>{value}</Text>

  if (!help) return content

  return (
    <Tooltip title={help}>
      {content}
      <QuestionCircleOutlined className='ml-1' />
    </Tooltip>
  )
}
