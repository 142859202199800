import traverse from '@utils/traverse'
import _, { isNil, isFunction } from 'lodash'
import { queryFieldSettings } from './consts'
import { validateTemplate } from '@utils'

const dmDelimiter = 'dm-KDbe4p'

export function isBlank(arg) {
  return isNil(arg) || arg === ''
}

export function dependencyValidator(dependencies, message = 'Required') {
  return ({ getFieldValue }) => ({
    validator(_rule, value) {
      const dependenciesAreBlank = dependencies.reduce((acc, cur) => {
        if (!isBlank(getFieldValue(cur))) acc = false
        return acc
      }, true)
      if (isBlank(value) && !dependenciesAreBlank) {
        return Promise.reject(message)
      }
      return Promise.resolve()
    },
  })
}

function formatParentPath(path = '', key = '') {
  if (path === '') return ''
  const splitParent = path?.split('/')

  const parentFields = splitParent.filter((item) => item !== key)

  const formattedPath = parentFields?.filter(
    (item) => item !== (parentFields && isFunction(parentFields?.at) && parentFields?.at(-1)),
  )
  const stringParent = formattedPath.filter(Boolean).join('.subfields.')

  return !!stringParent ? stringParent : null
}

function generateSchemaMetaData(parentPath, keyIndex, uuid) {
  return {
    uuid,
    fieldId: keyIndex,
    path: parentPath && parentPath !== '' ? [...parentPath?.split('.subfields.'), keyIndex].join('.') : null,
  }
}

function baseUpdate(object, path, value) {
  path = path.split(dmDelimiter)

  const length = path.length
  const lastIndex = length - 1

  let index = -1
  let nested = object

  while (nested != null && ++index < length) {
    const key = path[index]
    if (index === lastIndex) {
      nested[key] = value(nested[key])
    }
    nested = nested[key]
  }
  return object
}

function generateFieldValues({ parentPath, data, metaData = {}, parsedData = {} }) {
  const { schema, /* parent, root, parentJSONParent, parentKeyword, parentSchema, */ keyIndex } = data

  let tempMetaData = { ...metaData }
  let tempParsedData = { ...parsedData }

  const fieldValues = () => {
    const { uuid, fieldId, path } = generateSchemaMetaData(parentPath, keyIndex, schema.uuid)

    if (fieldId) {
      tempMetaData[path ?? keyIndex] = {
        uuid,
        fieldId,
        path,
        parentPath,
        keyIndex,
        // data: {
        //   schema,
        //   parent,
        //   root,
        //   parentJSONParent,
        //   parentKeyword,
        //   parentSchema,
        //   keyIndex,
        //   parentPath
        // }
      }
    }

    const arrayType = ['array']
    const collectionTypes = ['array', 'object']

    if (schema.type !== 'array') {
      const subfields = schema.properties
        ? Object.keys(schema.properties).reduce((res, item) => {
            if (arrayType.includes(schema.properties[item].type)) return res

            return {
              ...res,
              [item]: {
                ...queryFieldSettings[schema.properties[item].type],
              },
            }
          }, {})
        : null
      return {
        ...queryFieldSettings[schema.type],
        ...(subfields ? { subfields } : {}),
      }
    }

    if (schema.items?.type && !collectionTypes.includes(schema.items.type))
      return {
        ...queryFieldSettings.primitiveArray,
        subfields: {
          items: {
            ...queryFieldSettings[schema.items.type],
          },
        },
      }

    if (schema.items?.type === 'object' && _.isObject(schema.items?.properties)) {
      const schemaProperties = schema.items.properties
      const subfields = Object.keys(schemaProperties).reduce((res, item) => {
        if (arrayType.includes(schemaProperties[item].type)) return res

        return {
          ...res,
          [item]: {
            ...queryFieldSettings[schemaProperties[item].type],
          },
        }
      }, {})

      return {
        ...queryFieldSettings.collectionArray,
        subfields,
      }
    }

    return {
      ...queryFieldSettings.collectionArray,
    }
  }

  if (!parentPath && keyIndex) {
    tempParsedData[keyIndex ?? 'items'] = fieldValues()
  } else if (keyIndex) {
    const newPath = parentPath.split('.subfields.').join(`${dmDelimiter}subfields${dmDelimiter}`)

    baseUpdate(tempParsedData, newPath, (item) => {
      return {
        ...item,
        ...(keyIndex
          ? {
              ...{
                subfields: {
                  ...item?.subfields,
                  [keyIndex ?? 'items']: fieldValues(),
                },
              },
            }
          : {
              ...{
                subfields: {
                  ...item?.subfields,
                  items: fieldValues(),
                },
              },
            }),
      }
    })
  }

  return { tempMetaData, tempParsedData }
}

export function generateLocalDataFields(schema, schemaKey = 'root') {
  let parsedData = {}
  let metaData = {}

  if (!schema || !schema.type || !schemaKey) return { parsedData, metaData }

  schemaKey = schemaKey.replace('[', '').replace(']', '')

  traverse(schema, (schema, parent, root, parentJSONParent, parentKeyword, parentSchema, keyIndex) => {
    if (schemaKey !== 'root' && !parentJSONParent?.includes(schemaKey)) return
    const parentPath = formatParentPath(parent, schemaKey)

    const { tempMetaData, tempParsedData } = generateFieldValues({
      parentPath,
      data: {
        schema,
        parent,
        root,
        parentJSONParent,
        parentKeyword,
        parentSchema,
        keyIndex,
      },
      metaData,
      parsedData,
    })

    parsedData = {
      ...tempParsedData,
    }

    metaData = {
      ...tempMetaData,
    }
  })

  return { parsedData, metaData }
}

export function generateLocalDataLoopFields(schema) {
  let parsedData = {}
  let metaData = {}

  if (!schema || !schema.type) return { parsedData, metaData }

  traverse(schema, (schema, parent, root, parentJSONParent, parentKeyword, parentSchema, keyIndex) => {
    const parentPath = formatParentPath(parent)

    const { tempMetaData, tempParsedData } = generateFieldValues({
      parentPath,
      data: {
        schema,
        parent,
        root,
        parentJSONParent,
        parentKeyword,
        parentSchema,
        keyIndex,
      },
      metaData,
      parsedData,
    })

    parsedData = {
      ...tempParsedData,
    }

    metaData = {
      ...tempMetaData,
    }
  })

  return { parsedData, metaData }
}

export function getSchemaCollectionItems(schema, schemaKeys = ['root']) {
  if (!schema || !schema.type || !schemaKeys) return []

  const parsedData = []

  schemaKeys.forEach((schemaKey) => {
    traverse(schema, (schema, parent, _root, parentJSONParent, _parentKeyword, _parentSchema, keyIndex) => {
      if (schemaKey !== 'root' && !parentJSONParent?.includes(schemaKey)) return

      if (parent !== '' && schema?.type === 'array' && keyIndex) {
        const parentKey = parentJSONParent
          ? parentJSONParent
              .split('/')
              .filter(Boolean)
              .map((v) => `[${v}]`)
              .join('.')
          : ''
        if (!parentKey || (parentKey && parentKey === schemaKey)) {
          parsedData.push({
            label: parentKey ? `${keyIndex} in ${parentKey}` : `${keyIndex}`,
            value: `[${keyIndex}]`,
            uuid: schema.uuid,
            cid: schema.cid,
            metaData: schema.items,
          })
        } else if (parentKey !== '') {
          parsedData.push({
            label: `${parentKey}.${keyIndex}`.replaceAll('[', '').replaceAll(']', ''),
            value: `${parentKey}.[${keyIndex}]`,
            uuid: schema.uuid,
            cid: schema.cid,
            metaData: schema.items,
          })
        }
      }
    })
  })

  return parsedData
}

export function getSchemaCollectionSortItems(schema, schemaKey = 'root') {
  if (!schema || !schema.type || !schemaKey) return

  const parsedData = []

  traverse(schema, (schema, parent, _root, parentJSONParent, _parentKeyword, parentSchema, keyIndex) => {
    if (schemaKey !== 'root' && !parentJSONParent?.includes(schemaKey)) return

    const prefix = parentJSONParent
      ? parentJSONParent
          .split('/')
          .filter(Boolean)
          .map((v) => `[${v}]`)
          .join('.')
      : ''

    if (parent !== '' && schema?.type === 'array') {
      const val = prefix ? `${prefix}.[${keyIndex}]` : `[${keyIndex}]`
      keyIndex &&
        parsedData.push({
          label: val.replaceAll('[', '').replaceAll(']', ''),
          value: val,
        })
    }
    if (parent !== '' && parentSchema?.type === 'array') {
      const recurse = (parent, schema) => {
        if (schema.type !== 'object' && schema?.properties) {
          Object.keys(schema.properties).forEach((item) => {
            item &&
              schema.properties[item].type !== 'array' &&
              parsedData.push({
                label: `${parent}.${item}`.replaceAll('[', '').replaceAll(']', ''),
                value: `${parent}.[${item}]`,
              })
          })
        } else if (schema?.properties) {
          Object.keys(schema.properties).forEach((item) => {
            item &&
              schema.properties[item].type !== 'array' &&
              parsedData.push({
                label: `${parent}.${item}`.replaceAll('[', '').replaceAll(']', ''),
                value: `${parent}.[${item}]`,
              })
            recurse(`${parent}.[${item}]`, schema.properties[item])
          })
        }
      }
      recurse(prefix, schema)
    }
  })

  return parsedData
}

export function getSchemaCollectionItemsByTypes(schema, schemaKey = 'root', types = []) {
  if (!schema || !schema.type || !schemaKey) return

  const parsedData = []

  traverse(schema, (schema, parent, _root, parentJSONParent, _parentKeyword, parentSchema, keyIndex) => {
    if (schemaKey !== 'root' && !parentJSONParent?.includes(schemaKey)) return

    const prefix = parentJSONParent
      ? parentJSONParent
          .split('/')
          .filter(Boolean)
          .map((v) => `[${v}]`)
          .join('.')
      : ''

    if (parent !== '' && ((types.length && types.includes(schema?.type)) || !types.length)) {
      const val = prefix ? `${prefix}.[${keyIndex}]` : `[${keyIndex}]`
      keyIndex &&
        parsedData.push({
          label: val.replaceAll('[', '').replaceAll(']', ''),
          value: val,
        })
    }
    if (parent !== '' && ((types.length && types.includes(parentSchema?.type)) || !types.length)) {
      const recurse = (parent, schema) => {
        if (schema.type !== 'object' && schema?.properties) {
          Object.keys(schema.properties).forEach((item) => {
            parent &&
              item &&
              parsedData.push({
                label: `${parent}.${item}`.replaceAll('[', '').replaceAll(']', ''),
                value: `${parent}.[${item}]`,
              })
          })
        } else if (schema?.properties) {
          Object.keys(schema.properties).forEach((item) => {
            parent &&
              item &&
              parsedData.push({
                label: `${parent}.${item}`.replaceAll('[', '').replaceAll(']', ''),
                value: `${parent}.[${item}]`,
              })
            recurse(`${parent}.[${item}]`, schema.properties[item])
          })
        }
      }
      recurse(prefix, schema)
    }
  })

  return parsedData
}

export function formatQueryTree(tree, metadata) {
  if (!metadata) return tree

  return Object.keys(tree).map((item) => {
    const treeItem = tree[item]

    return {
      ...treeItem,
      properties: {
        ...treeItem.properties,
        ...metadata[treeItem.properties.field],
      },
    }
  }, {})
}

export function validateCustomCondition(condition) {
  const error = validateTemplate(`{{#if (${condition}) }} content {{/if}}`)
  return error ? { status: false, error } : { status: true, error: '' }
}

export function toJsonLogic(operand1, operator, operand2 = null) {
  switch (operator) {
    case 'isIn':
    case 'contains':
      return { 'and': [{ 'in': [operand2, { var: operand1 }] }] }
    case 'isNotIn':
    case 'doesNotContain':
      return { 'and': [{ '!': { 'in': [operand2, { var: operand1 }] } }] }
    case 'isEmpty':
      return { 'and': [{ '!': { var: operand1 } }] }
    case 'isNotEmpty':
      return { 'and': [{ '!!': { var: operand1 } }] }
    case 'isNull':
      return { 'and': [{ '==': [{ var: operand1 }, null] }] }
    case 'isNotNull':
      return { 'and': [{ '!=': [{ var: operand1 }, null] }] }
    case 'matches':
    default:
      return { 'and': [{ [operator]: [{ var: operand1 }, operand2] }] }
  }
}

export function toJson(string) {
  try {
    return JSON.parse(string)
  } catch (e) {
    return false
  }
}

const schemaUtils = {
  dependencyValidator,
  isBlank,
  generateLocalDataFields,
  getSchemaCollectionItems,
}

export default schemaUtils
