import React from 'react'
import { useParams } from 'react-router-dom'

import { SubscriptionProvider } from '@features/subscription'
import { ActivePiecesProvider } from '@features/activePieces/context/ActivePiecesContext'
import { TemplateProvider } from '@contexts/TemplateContext2'
import { TemplateEditProvider } from './contexts'
import { TemplatePreviewProvider } from '@features/template-preview'
import { TemplateSettingsProvider } from '@features/template-settings'
import { TemplateDataSourceProvider } from '@features/template-data-source'
import Content from './Content'
import { TemplateDesignerProvider } from '@features/template-designer'

export default function TemplateEdit() {
  const { id } = useParams()

  return (
    <SubscriptionProvider>
      <ActivePiecesProvider>
        <TemplateProvider id={id}>
          <TemplateEditProvider>
            <TemplateDataSourceProvider>
              <TemplateSettingsProvider>
                <TemplatePreviewProvider>
                  <TemplateDesignerProvider>
                    <Content />
                  </TemplateDesignerProvider>
                </TemplatePreviewProvider>
              </TemplateSettingsProvider>
            </TemplateDataSourceProvider>
          </TemplateEditProvider>
        </TemplateProvider>
      </ActivePiecesProvider>
    </SubscriptionProvider>
  )
}
