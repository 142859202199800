import React from 'react'

import { Layout } from 'antd'
import { TopBar, SideBar } from './components'
import { NewTemplateProvider } from '../contexts/NewTemplateContext'
import { SubscriptionProvider } from '@features/subscription'
import { useBreakpoint } from '../hooks/helperHooks'
import { useLocation } from 'react-router-dom'

const { Content } = Layout

export default function DashboardLayout({ children, className, ...rest }) {
  const breakpoint = useBreakpoint()
  const locaction = useLocation()

  const contentStyle = {
    xs: { padding: '0px' },
    sm: { padding: '0px' },
    md: { padding: '20px' },
    lg: { padding: '20px' },
    xl: { padding: '20px' },
    xxl: { padding: '20px' },
  }[breakpoint]

  return (
    <Layout className={`layout-dashboard ${className}`} {...rest} style={{width:'100%'}} theme='light'>
      <SubscriptionProvider>
        <NewTemplateProvider>
          <TopBar />
          <Layout>
            <SideBar props={children.props} />
            <Layout>
              <Content
                className='dm-content'
                style={locaction?.pathname === '/settings/integrations' ? {} : contentStyle}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
        </NewTemplateProvider>
      </SubscriptionProvider>
    </Layout>
  )
}
