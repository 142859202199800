import { MAX_GRID } from '../plugins/componentTypes/consts'

export const style = [
  {
    selector: '.pt-10',
    style: {
      'padding-top': '10px',
    },
  },
  {
    selector: '.pl-10',
    style: {
      'padding-left': '10px',
    },
  },
  {
    selector: '.pb-10',
    style: {
      'padding-bottom': '10px',
    },
  },
  {
    selector: '.pr-10',
    style: {
      'padding-right': '10px',
    },
  },
  {
    selector: '.h-75',
    style: {
      height: '75px',
    },
  },
  {
    selector: '.mw-100pc',
    style: {
      'max-width': '100%',
    },
  },
  {
    selector: '.mh-0',
    style: {
      'min-height': '0',
    },
  },
  {
    selector: '.dm-btn',
    style: {
      color: 'white',
      'background-color': '#009BFF',
      'border-radius': '5px',
      'text-align': 'center',
    },
  },
  ...[...Array(MAX_GRID).keys()].map((k) => ({
    selector: `[data-gs-columns="${k + 1}"]`,
    style: {
      width: `${k + 1}%`,
    },
  })),
]

export default style
