import React from 'react'
import { Form, Input, InputNumber, Select } from 'antd'

const { Option } = Select

export default function InputUnit({ name, label, ...rest }) {
  const unitTypes = [
    { value: 'in', label: 'in' },
    { value: 'px', label: 'px' },
    { value: 'cm', label: 'cm' },
    { value: 'mm', label: 'mm' },
  ]

  return (
    <Form.Item label={label} required={false} {...rest}>
      <Input.Group compact>
        <Form.Item name={[...name, 'value']} noStyle>
          <InputNumber min={0} style={{ width: '50%' }} />
        </Form.Item>

        <Form.Item name={[...name, 'unit']} noStyle>
          <Select style={{ width: '50%' }}>
            {unitTypes.map(({ label, value }) => (
              <Option value={value} key={value}>
                {label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  )
}
