import React, { useEffect } from 'react'
import { Button, Drawer, notification, Card } from 'antd'
import { PageHeader } from '@ant-design/pro-components'
import { ProfileDetails, ProfileForm } from '@features/profile'
import { useToggle } from '@hooks/helperHooks'
import { useGetProfileQuery, useUpdateProfileMutation } from '@documintApi/profile'

export default function Profile() {
  const { data, isLoading } = useGetProfileQuery()
  const [update, { isLoading: isUpdating }] = useUpdateProfileMutation()
  const [drawerIsOpen, toggleDrawer] = useToggle(false)

  useEffect(() => {
    document.title = 'Profile Settings'
  }, [])

  const handleSubmit = async (values) => {
    try {
      const { error } = await update(values)
      if (error) throw new Error(error.data)
    } catch (error) {
      const description = error.message || 'An unknown error occurred.'
      notification.error({ message: 'Something went wrong', description })
    } finally {
      toggleDrawer(false)
    }
  }
  const handleCancel = () => toggleDrawer(false)

  const actions = <Button onClick={toggleDrawer}>Edit</Button>

  return (
    <PageHeader title='My Profile' extra={actions} style={{ maxWidth: '1100px' }}>
      <Card>
        <ProfileDetails profile={data} isLoading={isLoading} />

        <Drawer open={drawerIsOpen} onClose={handleCancel} closable={true} width='500px' title='Edit Profile'>
          <ProfileForm profile={data} onSubmit={handleSubmit} isUpdating={isUpdating} onCancel={handleCancel} />
        </Drawer>
      </Card>
    </PageHeader>
  )
}
