import React, { useState } from 'react'
import { Layout, Tabs } from 'antd'
import { useToggle } from '@hooks/helperHooks'
import { FaIcon } from '@components/icons'
import DataPanel from './DataPanel'
import SiderToggle from './SiderToggle'

export default function RightSider({ children, ...rest }) {
  const [isCollapsed, toggleCollapsed] = useToggle(false)
  const [activeTab, setActiveTab] = useState('data')

  const handleTabChange = (key) => {
    setActiveTab(key)
  }

  const siderTrigger = <SiderToggle isCollapsed={isCollapsed} onClick={toggleCollapsed} reverseIcons={true} />

  return (
    <Layout.Sider
      id='right-panel'
      className='sider'
      style={{ overflow: 'hidden', position: 'relative' }}
      collapsed={isCollapsed}
      collapsible={true}
      collapsedWidth={40}
      onCollapse={toggleCollapsed}
      trigger={null}
      {...rest}
    >
      {isCollapsed && siderTrigger}
      <Tabs
        tabBarStyle={{ display: 'flex' }}
        style={{
          height: '100%',
          minWidth: rest.width,
          visibility: isCollapsed ? 'hidden' : 'visible',
          transition: 'visibility 0s, opacity 0.5s',
        }}
        defaultActiveKey='blocks'
        tabBarExtraContent={{ right: siderTrigger }}
        renderTabBar={(props, DefaultTabBar) => (isCollapsed ? null : <DefaultTabBar {...props} />)}
        centered={false}
        activeKey={activeTab}
        onChange={handleTabChange}
        items={[
          {
            label: (
              <>
                <FaIcon icon='faBracketsCurly' style={{ marginRight: '8px' }} />
                Data
              </>
            ),
            key: 'data',
            forceRender: true,
            children: <DataPanel />,
          },
          {
            label: (
              <>
                <FaIcon icon='faLayerGroup' style={{ margin: '8px' }} />
                Contents
              </>
            ),
            key: 'content',
            forceRender: true,
            children: <div id='layer-manager' style={{ paddingBottom: '100px' }}></div>,
          },
        ]}
      />
    </Layout.Sider>
  )
}
