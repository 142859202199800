import layoutSector from './layoutSector'
import backgroundSector from './backgroundSector'
import textSector from './textSector'
import borderSector from './borderSector'
import sizingSector from './sizingSector'
import iconSector from '../checkboxSectors/iconSector'
import labelSector from '../checkboxSectors/labelSector'

const allSectors = [sizingSector, textSector, layoutSector, backgroundSector, borderSector, iconSector, labelSector]

export default allSectors
