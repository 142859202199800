export const formats = [
  { value: 'Letter', label: 'Letter (8.5in x 11in)', width: '8.5in', height: '11in' },
  { value: 'Legal', label: 'Legal (8.5in x 14in)', width: '8.5in', height: '14in' },
  { value: 'Tabloid', label: 'Tabloid (11in x 17in)', width: '11in ', height: '17in' },
  { value: 'A3', label: 'A3 (297mm x 420mm)', width: '297mm', height: '420mm' },
  { value: 'A4', label: 'A4 (210mm x 297mm)', width: '210mm', height: '297mm' },
  { value: 'A5', label: 'A5 (148mm x 210mm)', width: '148mm', height: '210mm' },
  { value: 'A6', label: 'A6 (105mm x 148mm)', width: '105mm', height: '148mm' },
  { value: null, label: 'Custom' },
]

export const renderEngineVersions = [
  { value: 1, label: '1 (Legacy)' },
  { value: 2, label: '2' },
]

const defaults = {
  formats,
  renderEngineVersions,
}

export default defaults
