export const config = {
  id: 'rest-api',
  name: 'REST API',
  description: 'Create your own custom integration with our REST API.',
  icon: { type: 'image', value: '/images/delivery-methods/rest-api.svg' },

  overview: 'The REST API integration allows you to create your own custom integration with Documint.',
  documentationUrl: 'https://documenter.getpostman.com/view/11741160/TVK5cLxQ',
}

export default config