export const TYPES = {
  // Template
  template: 'dm-template',

  // Layout
  section: 'dm-section', // Deprecated
  column: 'dm-column', // Deprecated
  row: 'dm-row', // Deprecated
  temp_columns: 'dm-temp-columns', // Deprecated
  row_2: 'dm-row-2',
  column_2: 'dm-column-2',
  dynamic_row: 'dm-row-dynamic',
  dynamic_column: 'dm-column-dynamic',
  dynamic_columns: 'dm-columns-dynamic',
  grid: 'dm-grid',
  gridItem: 'dm-grid-item',

  // Content
  text: 'text',
  image: 'dm-image',
  token: 'dm-token',
  verticalSpacer: 'dm-vertical-spacer',
  pageBreak: 'dm-page-break',
  link: 'link',
  checkbox: 'dm-checkbox',
  toggle: 'dm-toggle',
  label: 'dm-label',
  icon: 'dm-toggle-icon',
  holder: 'dm-toggle-holder',
  button: 'dm-button',
  qrcode: 'dm-qr-code',
  barcode: 'dm-barcode',

  // Plugins
  dynamic: 'dynamic',
}

export const MAX_GRID = 100

const consts = { TYPES, MAX_GRID }

export default consts
