import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { Form, Typography } from 'antd'
import { useTemplateDataSource } from '../../hooks'
import ConnectionField from './ConnectionField'
import EntityConfigField from './EntityConfigField'
import ExpandedFields from './ExpandedFields'

import { DataSourceIcon } from '..'
import Actions from './Actions'
const { Title } = Typography

export default function ConnectedDataSource() {
  const {
    selectedDataSource,
    setSelectedDataSource,
    updateFieldSchema,
    updateDataSource,
    syncDataSource,
    isSyncing,
    disconnectDataSource,
    isModalOpen,
    toggleIsModalOpen,
    editor,
  } = useTemplateDataSource()

  const [connection, setConnection] = useState(null)
  const [entityConfigFields, setEntityConfigFields] = useState(null)
  const [expandableFields, setExpandableFields] = useState()
  const [expandedFieldKeys, setExpandedFieldKeys] = useState()

  const entityConfigFieldsAreComplete = useMemo(
    () => entityConfigFields?.every((field) => (typeof field === 'object' ? !!field?.value?.value : !!field.value)),
    [entityConfigFields],
  )

  const initFormFields = useCallback(() => {
    const { connection } = selectedDataSource || {}
    setConnection(connection)
    const newValues = selectedDataSource?.entityConfigFields?.map((field) => ({
      id: field.id,
      value: field?.value?.id ? { value: field?.value?.id, label: field?.value?.name } : undefined,
      field,
    }))
    setEntityConfigFields(newValues)
  }, [selectedDataSource])

  const resetEntityConfigFields = useCallback(() => {
    if (!selectedDataSource) return
    const newValues = selectedDataSource?.entityConfigFields?.map((field) => ({ id: field.id, value: null, field }))
    setEntityConfigFields(newValues)
    setExpandableFields(null)
    setExpandedFieldKeys(null)
    selectedDataSource.clearConfigFieldValues()
  }, [selectedDataSource])

  const refreshExpandableFields = useCallback(() => {
    if (!selectedDataSource && entityConfigFieldsAreComplete) return
    const treeData = selectedDataSource?.getExpandableFields?.()
    setExpandableFields(treeData)
  }, [selectedDataSource, setExpandableFields, entityConfigFieldsAreComplete])

  const updateEntityConfigFieldValue = useCallback(
    (id, value) => {
      setEntityConfigFields((prev) => {
        let newValueHasBeenSet = false
        return prev.map((formField, i) => {
          if (formField.id === id) {
            formField.value = value
            formField.field.value = { id: value.value, name: value.label }
            newValueHasBeenSet = true
          } else if (newValueHasBeenSet) {
            formField.value = undefined
            formField.field.value = undefined
          }
          return formField
        })
      })
    },
    [setEntityConfigFields],
  )

  /**
   * Handle change to the connection field
   * @param {object} values
   */
  const handleConnectionFieldChange = useCallback(
    (connection) => {
      setConnection(connection)
      resetEntityConfigFields()
      setExpandableFields(null)
      setExpandedFieldKeys(null)
      selectedDataSource.connection.values = connection
    },
    [setConnection, selectedDataSource, resetEntityConfigFields],
  )

  /**
   * Handle changes to the config fields
   * @param {string} value field value
   * @param {object} field
   */
  const handleEntityConfigFieldChange = useCallback(
    (selectedOption, field) => {
      updateEntityConfigFieldValue(field.id, selectedOption)
      refreshExpandableFields()
    },
    [updateEntityConfigFieldValue, refreshExpandableFields],
  )

  /** Handle refresh of the config fields */
  const handleEntityConfigFieldRefresh = useCallback(() => {
    if (!selectedDataSource || !entityConfigFieldsAreComplete) return
    const treeData = selectedDataSource.getExpandableFields()
    setExpandableFields(treeData)
  }, [entityConfigFieldsAreComplete, selectedDataSource])

  // Handle changes to the expanded fields
  const handleExpandedFieldsChange = useCallback((expandedFields) => {
    setExpandedFieldKeys(expandedFields)
  }, [])

  const handleSync = useCallback(async () => {
    await syncDataSource(editor)
    refreshExpandableFields()
  }, [syncDataSource, refreshExpandableFields, editor])

  const handleDisconnect = () => {
    disconnectDataSource()
  }

  /**
   * Handle connection form submission
   */
  const handleSubmit = useCallback(async () => {
    updateDataSource(selectedDataSource)
    const fieldSchema = selectedDataSource?.getJsonSchema?.(editor, { expandedFields: expandedFieldKeys })
    if (fieldSchema) updateFieldSchema(fieldSchema)
    setSelectedDataSource(null)
    toggleIsModalOpen(false)
  }, [
    updateDataSource,
    selectedDataSource,
    editor,
    updateFieldSchema,
    setSelectedDataSource,
    toggleIsModalOpen,
    expandedFieldKeys,
  ])

  /**
   * Handle cancel/close modal without saving
   */
  const handleCancel = useCallback(() => {
    toggleIsModalOpen(false)
    setConnection(null)
    setEntityConfigFields(null)
    setExpandedFieldKeys(null)
    setSelectedDataSource(null)
  }, [setSelectedDataSource, toggleIsModalOpen])

  // Initialize form fields
  useEffect(() => {
    if (selectedDataSource && !entityConfigFields && isModalOpen) initFormFields()
  }, [entityConfigFields, initFormFields, selectedDataSource, isModalOpen])

  // Initialize expanded fields
  useEffect(() => {
    if (selectedDataSource?.expandedFields && !expandedFieldKeys)
      setExpandedFieldKeys(selectedDataSource.expandedFields)
  }, [selectedDataSource, expandedFieldKeys])

  // Initialize expandable fields
  useEffect(() => {
    if (entityConfigFieldsAreComplete && !expandableFields) {
      refreshExpandableFields()
    }
  }, [refreshExpandableFields, entityConfigFieldsAreComplete, expandableFields])

  useEffect(() => {
    if (entityConfigFieldsAreComplete) refreshExpandableFields()
  }, [refreshExpandableFields, entityConfigFieldsAreComplete, entityConfigFields])

  return (
    <>
      <div className='d-flex align-items-center justify-content-center mb-3' style={{ gap: '10px' }}>
        <DataSourceIcon icon={selectedDataSource?.icon} style={{ marginLeft: '-20px' }} />
        <Title level={5} className='mb-0'>
          Connect to {selectedDataSource?.name}
        </Title>
      </div>
      <Form layout='vertical'>
        {/* Connection Field */}
        <ConnectionField
          onChange={handleConnectionFieldChange}
          dataSource={selectedDataSource}
          value={{ value: connection?.id, label: connection?.name }}
        />

        {/* Resource Configuration */}
        {entityConfigFields?.map(({ value, field, id }, i) => (
          <EntityConfigField
            key={id}
            connection={connection}
            provider={selectedDataSource?.id}
            field={field}
            onChange={handleEntityConfigFieldChange}
            value={value}
            disabled={i === 0 ? !connection?.id : !entityConfigFields[i - 1].value}
            onRefresh={handleEntityConfigFieldRefresh}
          />
        ))}

        {/* EXPANDABLE FIELDS */}
        {expandableFields?.length > 0 && (
          <ExpandedFields
            dataSource={selectedDataSource}
            value={expandedFieldKeys}
            onChange={handleExpandedFieldsChange}
            expandableFields={expandableFields}
          />
        )}

        {/* BUTTONS */}
        <div className='d-flex align-items-center justify-content-end pt-3' style={{ gap: '10px' }}>
          <Actions
            onCancel={handleCancel}
            onDisconnect={handleDisconnect}
            onSubmit={handleSubmit}
            onSync={handleSync}
            isSyncing={isSyncing}
            dataSource={selectedDataSource}
            entityConfigFieldsAreComplete={entityConfigFieldsAreComplete}
          />
        </div>
      </Form>
    </>
  )
}
