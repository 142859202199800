import { useState, useRef } from 'react'
import { isNumber } from '../../utils'

const DEFAULT_VALUES = { mode: null, value: 1, width: null, height: null }
const MODES = ['fit_page', 'fit_width', 'fit_page_shrink']

export function useScale({ min = 0.2, max = 6, incrementor = 1, containerRef, padding = 20 }) {
  const [data, setData] = useState(DEFAULT_VALUES)
  const initial = useRef(null)

  // Returns the initial dimensions of the scaled object
  const getInitialDims = () => {
    let width = initial.current?.width
    let height = initial.current?.height
    return { width, height }
  }

  // Returns the dimensions of the container
  const getContainerDims = () => {
    const element = containerRef.current
    if (!element) return
    let width = element.offsetWidth
    let height = element.offsetHeight

    // shrink container to give element padding
    if (isNumber(padding)) {
      width -= padding * 2
      height -= padding * 2
    }
    return { width, height }
  }

  const setInitialDims = ({ width, height } = {}) => {
    initial.current = { width, height }
  }

  const getDimsFromScale = (scale) => {
    const initial = getInitialDims()
    if (!initial || !isNumber(scale)) return
    return { width: initial.width * scale, height: initial.height * scale }
  }

  const getScaleFromMode = (mode) => {
    let scale
    const initial = getInitialDims()
    const container = getContainerDims()

    if (!initial || !container) return

    const widthDiff = initial.width - container.width
    const heightDiff = initial.height - container.height

    switch (MODES.indexOf(mode)) {
      // Fit Page (Contain)
      case 0:
        scale = widthDiff > heightDiff ? container.width / initial.width : container.height / initial.height
        break
      // Fit width
      case 1:
        scale = container.width / initial.width
        break
      // Fit Page Shrink
      case 2:
        scale = widthDiff > heightDiff ? container.width / initial.width : container.height / initial.height
        if (scale > 1) scale = 1
        break
      default:
        break
    }
    return scale
  }

  const setValue = (value) => {
    setData({ mode: null, value })
  }

  const setMode = (mode) => {
    if (!MODES.includes(mode)) return
    const scaleValue = getScaleFromMode(mode)
    const dims = getDimsFromScale(scaleValue)
    if (!scaleValue || !dims) return
    setData({ mode, value: scaleValue, width: dims.width, height: dims.height })
  }

  const toggleMode = () => {
    let newModeIndex = 0
    if (data.mode) {
      const currentIndex = MODES.indexOf(data.mode)
      newModeIndex = currentIndex === 0 ? 1 : 0
    }
    setMode(MODES[newModeIndex])
  }

  // const setWidth = (width) => {}

  // const setHeight = (height) => {}

  const increment = () => {
    let newScaleValue = data.value + incrementor
    if (newScaleValue >= max) newScaleValue = max
    const dims = getDimsFromScale(newScaleValue)

    if (newScaleValue === data.value) return

    if (!dims) return
    setData((prev) => ({ ...prev, value: newScaleValue, width: dims.width, height: dims.height }))
  }

  const decrement = () => {
    let newScaleValue = data.value - incrementor
    if (newScaleValue <= min) newScaleValue = min

    if (newScaleValue === data.value) return

    const dims = getDimsFromScale(newScaleValue)
    if (!dims) return
    setData((prev) => ({ ...prev, value: newScaleValue, width: dims.width, height: dims.height }))
  }

  return { ...data, increment, decrement, setValue, setMode, toggleMode, setInitialDims, getInitialDims, MODES }
}

export default useScale