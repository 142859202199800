import { useState, useCallback } from 'react'

export const useToggle = (defaultState) => {
  const [state, setState] = useState(defaultState ?? false)

  const toggleState = useCallback((value) => {
    setState((prev) => (typeof value === 'boolean' ? value : !prev))
  }, [])

  return [state, toggleState]
}

export default useToggle
