import React, { useCallback, useState } from 'react'
import { Form, Modal, Switch, Typography } from 'antd'
import SettingsForm from './TemplateSettingsForm'
import { useTemplateSettings } from '../hooks/useTemplateSettings'
import { SettingOutlined,
  //  DeleteOutlined 
  } from '@ant-design/icons'
import { FaIcon } from '@components/icons'
// import { clearCanvasModal } from '../../../pages/templates/template-edit/features/TemplateDesigner'
// import Button from '@common/Button'

export default function TemplateSettingsModal({ onEditorClear, ...props }) {
  const { settings: initialSettings, showModal, toggleModal, update, autoSave, toggleAutoSave } = useTemplateSettings()
  const [settings, setSettings] = useState(initialSettings)

  const handleSave = useCallback(() => {
    update(settings)
    toggleModal(false)
  }, [settings, update, toggleModal])

  const handleCancel = () => {
    setSettings(initialSettings)
    toggleModal(false)
  }

  const formProps = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
    labelAlign: 'left',
  }

  const title = (
    <>
      <FaIcon icon="faGear" size='sm' className='mr-1'raw /> Template Settings
    </>
  )
  return (
    <Modal
      title={title}
      open={showModal}
      onOk={handleSave}
      onCancel={handleCancel}
      okText='Save'
      destroyOnClose={true}
      maskClosable={false}
      closeIcon={false}
      style={{ top: '30px' }}
      {...props}
    >
      <Typography.Title level={5}>Template</Typography.Title>

      {/* AUTO SAVE SWITCH */}
      <Form.Item label='Auto Save' {...formProps} colon={false}>
        <Switch
          defaultChecked={autoSave || true}
          onChange={toggleAutoSave}
          checkedChildren='On'
          unCheckedChildren='Off'
        />
      </Form.Item>

      {/* CLEAR CANVAS BUTTON */}
      {/* <Form.Item label='Clear Canvas' {...formProps} colon={false}>
        <Button
          type='ghost'
          icon={<DeleteOutlined />}
          onClick={() =>
            clearCanvasModal({
              onOk() {
                onEditorClear()
              },
            })
          }
          size='small'
          style={{ height: '28px', padding: '6px 2px' }}
        />
      </Form.Item> */}

      <SettingsForm data={settings} onChange={(value) => setSettings(value)} formProps={formProps} />
    </Modal>
  )
}
