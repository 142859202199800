import React from 'react'
import { ActionCreators as UndoActionCreators } from 'redux-undo'
import { connect } from 'react-redux'
import { Button, Tooltip, Space } from 'antd'
import { IoMdUndo, IoMdRedo } from 'react-icons/io'

export const UndoRedoUI = ({ canUndo, canRedo, onUndo, onRedo }) => (
  <Space className='reducer-state-control'>
    <Tooltip title='Undo'>
      <Button title='Undo' icon={<IoMdUndo />} onClick={onUndo} disabled={!canUndo} />
    </Tooltip>
    <Tooltip title='Redo'>
      <Button title='Redo' icon={<IoMdRedo />} onClick={onRedo} disabled={!canRedo} />
    </Tooltip>
  </Space>
)

export const UndoRedoForUndoableReducer = ({ reducer }) => {
  const mapStateToProps = (state) => ({
    canUndo: state[reducer].past.length > 0,
    canRedo: state[reducer].future.length > 0,
  })

  const mapDispatchToProps = {
    onUndo: UndoActionCreators.undo,
    onRedo: UndoActionCreators.redo,
  }

  const UndoRedo = connect(mapStateToProps, mapDispatchToProps)(UndoRedoUI)

  return <UndoRedo />
}

export default UndoRedoForUndoableReducer
