export const config = {
  id: 'noloco',
  icon: { type: 'image', value: '/images/delivery-methods/noloco-logo.png' },
  name: 'Noloco',
  description: 'Add document creation to your Noloco app.',

  overview: 'The Noloco integration leverages the Airtable Generation Link integration.',

  setupSteps: [
    'Using the Formula Builder below, create a Generation Link by adding your table URL.',
    'Copy the generated formula.',
    'In Airtable, create a new formula field (in the same table as the URL) and add the generated formula.',
    'In Noloco, sync your base and add the new formula field as the source for a button or embedded iframe.',
  ],
  documentationUrl: 'https://docs.documint.me/integrations/noloco',
}

export default config
