import { DefaultParser } from './parser'
import DataSource from '../DataSource'
import config from './config'

export class DefaultDataSource extends DataSource {
  constructor() {
    super({
      ...config,
      parser: DefaultParser,
      disconnected: true,
    })
  }

  getLabel() {
    return 'Custom schema'
  }
}

export default DefaultDataSource
