import { Row, Col, Select, Typography, Tabs, Empty } from 'antd'
import React from 'react'
import { Query } from '@react-awesome-query-builder/antd'
import useLoopEditor from './hooks/useLoopEditor'
import CodeEditor from './components/CodeEditor'
import Sorts from './Sorts'

const { Paragraph, Title } = Typography

export default function Loop({ loop, setLoop, sorts, setSorts }) {
  const {
    state,
    code,
    builder,
    handleChange,
    handleChangeCustom,
    handleFilterChange,
    filterOptions,
    filterValue,
    sortOptions,
  } = useLoopEditor({ loop, setLoop })

  return (
    <>
      <Paragraph className='p-3 mt-1 mb-4'>
        Repeat this element for each item in a list. Use the expression builder below to set the condition for filtering
        elements.
      </Paragraph>

      <Row gutter={10} className='px-3'>
        <Col span={12}>
          <Title level={5}>List Field</Title>
          <div className='mb-3'>
            <Select
              placeholder='Field name'
              options={filterOptions}
              className='loop-select'
              notFoundContent={
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No List Fields Found in Schema</span>} />
              }
              value={filterValue}
              onChange={handleFilterChange}
              style={{ width: '100%' }}
              allowClear={true}
            />
          </div>
        </Col>
        <Col span={12}>
          {filterValue && (
            <Sorts sorts={sorts} setSorts={setSorts} sortOptions={sortOptions} filterValue={filterValue} />
          )}
        </Col>
      </Row>
      {filterValue && (
        <div className='p-3'>
          <Title level={5}>Filter List</Title>
          <Tabs
            defaultActiveKey={code ? '2' : '1'}
            items={[
              {
                label: 'Builder',
                key: '1',
                children: (
                  <Query {...state.config} value={state.tree} onChange={handleChange} renderBuilder={builder} />
                ),
              },
              { label: 'Custom', key: '2', children: <CodeEditor code={code} onChange={handleChangeCustom} /> },
            ]}
          />
        </div>
      )}
    </>
  )
}
