import { useRef, useCallback } from 'react'
import { isEqual } from 'lodash'

export function useCache() {
  const dataRef = useRef(null)
  const paramsRef = useRef(null)

  const get = useCallback((newParams) => {
    return isEqual(paramsRef.current, newParams) ? dataRef.current : null
  }, [])

  /**
   * Sets the cache
   * @param {*} data
   * @param {*} params
   */
  const set = useCallback((data, params) => {
    dataRef.current = data
    paramsRef.current = params
  }, [])

  const clear = useCallback(() => set(null, null), [set])

  return { get, set, clear }
}

export default useCache
