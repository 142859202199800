import { useActivePieces } from '@features/activePieces'
import { useCallback, useState } from 'react'

const { useLazyGetDataFromProviderQuery } = require('@services/documintApi/providers')

export const useDataSourceConfig = ({ field, provider, connectionName }) => {
  const { jwt } = useActivePieces()
  const [fetchData, { isFetching: isLoading, error }, prevQuery] = useLazyGetDataFromProviderQuery()
  const [options, setOptions] = useState(undefined)

  const loadOptions = useCallback(async () => {
    const query = field?.getQuery() || {}
    // Add timestamp to query to force refresh
    query.timestamp = Date.now()
    const response = await fetchData({ action: field.action, provider, connectionName, accessToken: jwt, ...query })
    const items = field.handleResponse(response)

    if (!items) return
    setOptions(items.map((item) => ({ label: item.name, value: item.id })))
  }, [fetchData, field, provider, connectionName, jwt])

  return { loadOptions, options, isLoading, error, prevQuery }
}

export default useDataSourceConfig
