export const MODES = { build: 'build', paste: 'paste', upload: 'upload' }

export const MODE_CONTENTS = [
  {
    key: MODES.build,
    title: 'Start from Scratch',
    description: 'Create a new schema from scratch',
    icon: 'faCubes',
  },
  {
    key: MODES.paste,
    title: 'Paste from Clipboard',
    description: 'Create a new schema by pasting JSON Schema',
    icon: 'faClipboard',
  },
  {
    key: MODES.upload,
    title: 'Upload JSON Schema File',
    description: 'Create a new schema by uploading a JSON Schema file',
    icon: 'faUpload',
  },
]
