import { TYPES } from '../consts'

export default function dynamicColumnType(dc, options) {
  dc.addType(TYPES.dynamic_columns, {
    extends: TYPES.dynamic,
    model: {
      defaults: {
        switch: [
          {
            parents: ['wrapper'],
            type: TYPES.row_2,
            components: [
              {
                type: 'gs-columns',
                components: [
                  { type: TYPES.column_2, columns: 12 },
                  { type: TYPES.column_2, columns: 12 },
                ],
              },
            ],
          },
          {
            parents: ['gs-columns'],
            type: 'components',
            components: [
              { type: TYPES.column_2, columns: 12, attributes: { 'data-columns-reset': 'column' } },
              { type: TYPES.column_2, columns: 12, attributes: { 'data-columns-reset': 'column' } },
            ],
          },
        ],
        draggable: `[data-gjs-type=wrapper], [data-gjs-type=gs-columns], [data-gjs-type="${TYPES.column_2}"]`,
        attributes: { 'data-gs-type': 'column', 'data-dm-category': 'column' },
      },
      getSpan() {},
      setSizeClass() {},
      getColumns() {},
      removeColumns() {},
      resetHandles() {},
      correctWidth() {},
    },
  })
}
