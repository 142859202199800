import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLazyValidateSessionQuery } from '@documintApi/auth'
import { selectProfile, setProfile, setAccount } from '../authSlice'

/**
 * Session Validation Hook
 * @returns
 */
export const useValidateSession = () => {
  const profile = useSelector(selectProfile)
  const dispatch = useDispatch()
  const [_validate, { isFetching, error }] = useLazyValidateSessionQuery()
  const [isValid, setIsValid] = useState(false)
  const [isValidating, setIsValidating] = useState(false)

  const hasValue = (value) => value !== null && value !== undefined

  useEffect(() => {
    if (!isValid && hasValue(profile)) setIsValid(!!profile)

    if (error && isValid) setIsValid(false)

    if (isFetching !== isValidating) setIsValidating(isFetching)
  }, [error, profile, isFetching, isValid, isValidating])

  /**
   * Manually call the validate fn
   */
  const validate = async () => {
    if (isFetching) return

    const { data } = await _validate({}, false)

    const hasSession = !!data?.user
    const profileIsSet = !!profile
    const _isValid = hasSession

    if (hasSession && !profileIsSet) {
      dispatch(setProfile(data.user))
      dispatch(setAccount(data.account))
    }

    if (!hasSession && profileIsSet) {
      dispatch(setProfile(null))
      dispatch(setAccount(null))
    }

    if (_isValid !== isValid) setIsValid(_isValid)
    return _isValid
  }

  return [validate, { isValid, isValidating }]
}

export default useValidateSession