import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useActivePieces } from '../hooks'
import config from '@config'
import { Alert } from 'antd'
import Loading from '@components/common/Loading'

const { activePiecesInstanceUrl } = config

const lazyLoadActivePiecesFromCDN = (callback, errorCallback) => {
  try {
    const exists = document.querySelector('#active-pieces')
    if(exists) return
    const activePieces = document.createElement('script')
    activePieces.setAttribute('id', 'active-pieces')
    activePieces.setAttribute('src', 'https://cdn.activepieces.com/sdk/embed/0.3.0.js')
    activePieces.addEventListener('load', callback)
    activePieces.addEventListener('error', errorCallback)
    document.head.appendChild(activePieces) 
  } catch (error) {
    console.log(error)
  }
}

export default function ActivePiecesConnections({ style = {}, ...props }) {
  const { jwt, jwtError } = useActivePieces()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const piecesWrapperRef = useRef(null)
  const apRef = useRef(null)
  const initializedRef = useRef(false)

  const handleLoad = useCallback(async () => {
    try {
      if(apRef.current) return

      apRef.current = window.activepieces

      const configuration = {
        prefix: '/',
        jwtToken: jwt,
        instanceUrl: activePiecesInstanceUrl,
        embedding: {
          containerId: 'active-pieces-container',
          builder: {
            disableNavigation: true,
          },
          dashboard: {
            hideSidebar: true,
          },
          hideFolders: true,
        },
      }

      await apRef.current.configure(configuration)
      
      window.activepieces.navigate({ route: '/connections' })

      // Set the iframe to take up the full height of the screen
      const frame = piecesWrapperRef.current.querySelector('iframe')
      frame.style = 'width: 100%; height: calc(100vh - 350px);'
    } catch (error) {
      setError(error)
    } finally {
      initializedRef.current = true
      setLoading(false)
    }
  }, [jwt])

  const handleLoadError = useCallback((error) => {
    setError(error)
    setLoading(false)
  }, [])

  useEffect(() => {
    if (jwt && !initializedRef.current) lazyLoadActivePiecesFromCDN(handleLoad, handleLoadError)
  }, [jwt, handleLoadError, handleLoad])

  return (
    <div
      id='active-pieces-container'
      ref={piecesWrapperRef}
      style={{ width: '100%', height: 'calc(100vh - 300px)', overflow: 'auto', ...style }}
      {...props}
    >
      {!jwtError && loading && <Loading />}
      {error && <Alert message='Error' description='Failed to load integrations.' type='error' showIcon />}
      {jwtError && <Alert message='Error' description='Failed to authenticate.' type='error' showIcon />}
    </div>
  )
}
