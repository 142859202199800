import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import { useTemplateDataSource } from '../hooks'
import CustomDataSource from './CustomDataSource'
import ConnectedDataSource from './ConnectedDataSource'

export default function DataSourceEditModal() {
  const { selectedDataSource, isModalOpen } = useTemplateDataSource()
  const [width, setWidth] = useState(1100)
  const isDefault = selectedDataSource?.isDefault

  const bodyStyle = isDefault ? { borderRadius: '7px', padding: '1em 3em', backgroundColor: '#fafbff' } : {}

  // Set the width of the modal based on the connection status of the selected data source
  // use state and effect to prevent the modal from resizing when closing and reopening
  useEffect(() => {
    if (!selectedDataSource) return
    setWidth(selectedDataSource.isConnected ? 500 : 1100)
  }, [selectedDataSource])

  return (
    <Modal
      open={isModalOpen}
      style={{ overflow: 'hidden' }}
      styles={{ body: bodyStyle }}
      maskClosable={false}
      closeIcon={<></>}
      width={width}
      destroyOnClose={true}
      footer={false}
    >
      {selectedDataSource?.isDefault || selectedDataSource?.disconnected ? (
        <CustomDataSource />
      ) : (
        <ConnectedDataSource />
      )}
    </Modal>
  )
}
