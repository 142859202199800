import { Col, Modal, Row, Tabs, Typography, Alert, Segmented } from 'antd'
import React, { useState, useEffect } from 'react'
import Button from '@common/Button'
import Condition from './Condition'
import useLogicEditor from './hooks/useLogicEditor'
import Loop from './Loop'
import LegacyLoop from '../LogicModal/Loop'
import { Info } from '../LogicModal'
import { toJson, validateCustomCondition } from './utils'
import { useDesigner } from '../../hooks'
// import DataSourceModal from '../../../TemplateData/components/DataSourceModal'

const { Text } = Typography

export default function NewLogicModal({ selectedComponent, visible, modalProps, onOk, onCancel }) {
  const { template = {} } = useDesigner()
  const { fieldSchema } = template
  const { hasSchema, isSaving } = useLogicEditor(selectedComponent)

  const [useLegacy, setUseLegacy] = useState('restricted')
  const [condition, setCondition] = useState(null)
  const [loop, setLoop] = useState(null)
  const [sorts, setSorts] = useState([])
  const [validationError, setValidationError] = useState('')

  useEffect(() => {
    if (!selectedComponent) return
    const condition = selectedComponent.getCondition && selectedComponent.getCondition()
    setCondition(condition)
    const loop = selectedComponent.getLoop && selectedComponent.getLoop()
    setLoop(loop)
    const sorts = selectedComponent.getSorts && selectedComponent.getSorts()
    setSorts(sorts)
    const customLoop = selectedComponent && selectedComponent.get('customLoop')
    setUseLegacy(customLoop ? 'free' : 'restricted')
  }, [selectedComponent, fieldSchema, visible])

  function handleCancel() {
    setValidationError('')
    if (onCancel) onCancel()
  }

  const handleConditionChange = (value) => {
    setCondition(value)
  }

  const handleLoopChange = (value) => {
    setLoop(value)
  }

  const handleSortsChange = (value) => {
    setSorts(value)
  }

  const handleLegacyLoopChange = ({ loop, sorts } = {}) => {
    setLoop(loop)
    setSorts(sorts)
  }

  const handleModeChange = (value) => {
    setUseLegacy(value)
    selectedComponent && selectedComponent.set('customLoop', value === 'free' ? true : false)
  }

  /**
   * Handle Save
   */
  function handleOk() {
    const parsedCondition = typeof condition === 'string' ? toJson(condition) : condition
    const parsedLoop = toJson(loop)
    const value = parsedLoop ? Object.keys(parsedLoop)[0] : loop ? loop : null

    const isConditionValid =
      (parsedCondition && Object.hasOwn(parsedCondition, 'operand1')) ||
      condition == null ||
      condition === '' ||
      (parsedCondition &&
        parsedCondition['custom-condition'] &&
        validateCustomCondition(parsedCondition['custom-condition']).status) ||
      !!parsedCondition?.type

    const isLoopValid =
      (!parsedLoop && loop?.length) ||
      loop == null ||
      loop === '' ||
      (value &&
        parsedLoop &&
        parsedLoop[value] &&
        parsedLoop[value]['custom-condition'] &&
        validateCustomCondition(parsedLoop[value]['custom-condition']).status) ||
      !!(value && parsedLoop[value]?.type)

    if (selectedComponent?.setCondition && isConditionValid) selectedComponent.setCondition(condition)
    if (selectedComponent?.setLoop && isLoopValid) selectedComponent.setLoop(loop)
    if (selectedComponent?.setSorts) selectedComponent.setSorts(sorts)
    if (onOk && isConditionValid && isLoopValid) {
      setValidationError('')
      onOk()
    } else
      setValidationError(
        `${
          !isConditionValid
            ? `Display rule error: ${validateCustomCondition(parsedCondition['custom-condition']).error}. `
            : ''
        }
        ${!isLoopValid ? `Loop error: ${validateCustomCondition(parsedLoop[value]['custom-condition']).error}.` : ''}`,
      )
  }

  return (
    <>
      <Modal
        {...modalProps}
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={750}
        okText='Save'
        title='Logic Editor'
        destroyOnClose={true}
        footer={[
          <Button key='back' onClick={handleCancel}>
            Close
          </Button>,
          <Button key='save' type='primary' loading={isSaving} onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <>
          {hasSchema ? (
            <>
              <Tabs
                className='mb-3'
                items={[
                  {
                    label: 'Display Rule',
                    key: 'condition',
                    children: <Condition condition={condition} setCondition={handleConditionChange} />,
                  },
                  {
                    label: 'Repeat/Loop',
                    key: 'loop',
                    children: (
                      <>
                        <Segmented
                          value={useLegacy}
                          onChange={handleModeChange}
                          options={[
                            { label: 'Use Autogenerated Fields', value: 'restricted' },
                            { label: 'Use Manual Input', value: 'free' },
                          ]}
                        />
                        {useLegacy === 'restricted' ? (
                          <Loop loop={loop} setLoop={handleLoopChange} sorts={sorts} setSorts={handleSortsChange} />
                        ) : (
                          <>
                            <LegacyLoop data={{ loop, sorts }} onChange={handleLegacyLoopChange} />
                            <Info />
                          </>
                        )}
                      </>
                    ),
                  },
                ]}
              />

              {validationError && <Alert message={<Text>{validationError}</Text>} type='error' />}
            </>
          ) : (
            <Row column>
              <Col span={12} offset={6}>
                <div className='d-flex flex-column justify-content-center align-items-center text-center'>
                  <Typography.Paragraph className='mt-1 mb-4'>
                    You must first create the schema before adding rules. Please click below to create a schema.
                  </Typography.Paragraph>
                </div>
              </Col>
            </Row>
          )}
        </>
      </Modal>
      {/* <DataSourceModal isVisible={dataSourceModalIsVisible} onCancel={handleCancelDataSourceModal} /> */}
    </>
  )
}
