import React, { useCallback, useEffect, useRef, useState } from 'react'

import { Typography, Tooltip } from 'antd'
import { SyncOutlined } from '@ant-design/icons'
import { useTemplate } from '@hooks/useTemplate'
import { FaIcon } from '@components/icons'
import { formatDistanceToNow } from 'date-fns'

const { Text } = Typography

export default function StatusIndicator({ className, ...props }) {
  const { isUpdating, updateError, isUpdateSuccess } = useTemplate()
  const [formattedUpdatedAt, setFormattedUpdatedAt] = useState(null)
  const timerRef = useRef()
  const updatedAtRef = useRef(null)

  const status = isUpdating ? 'saving' : updateError ? 'error' : updatedAtRef.current ? 'saved' : null

  const updateDisplay = useCallback(() => {
    setFormattedUpdatedAt(formatDistanceToNow(updatedAtRef.current, { includeSeconds: true }))
  }, [])

  const startTimer = useCallback(() => {
    if (timerRef.current) clearInterval(timerRef.current)
    timerRef.current = setInterval(updateDisplay, 1000)
  }, [updateDisplay])

  useEffect(() => {
    if (isUpdateSuccess && !isUpdating) {
      updatedAtRef.current = new Date()
      startTimer()
    }
  }, [isUpdateSuccess, startTimer, isUpdating])

  useEffect(() => {
    return () => clearInterval(timerRef.current)
  }, [])

  const currentStatus = {
    saved: {
      content: (
        <Tooltip title={`Last saved ${formattedUpdatedAt}`}>
          <div style={{display:'inline'}}>
            <FaIcon icon='faCloudCheck' color='#bbb' raw />
          </div>
        </Tooltip>
      ),
    },
    saving: {
      icon: <SyncOutlined spin />,
      content: 'Saving changes...',
    },
    error: {
      icon: <FaIcon icon='faFloppyDiskCircleXmark' style={{ color: '#eb2f96' }} raw />,
      content: <Tooltip title={updateError?.data?.message || 'Unknown error'}>Failed to save</Tooltip>,
    },
  }[status]

  if (!currentStatus) return null

  return (
    <Text
      type='secondary'
      {...props}
      className={`${className} ${currentStatus?.className || ''}`}
      style={{ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap', gap: '6px', ...props?.style }}
    >
      {currentStatus.icon}{' '}
      <span style={{ color: status === 'error' && '#eb2f96', display: 'inline-block' }} className='pl-1'>
        {currentStatus.content}
      </span>
    </Text>
  )
}
