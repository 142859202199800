import { TYPES } from '../consts'

export const type = TYPES.dynamic

export default function dynamicType(domc, { editor }) {
  domc.addType(type, {
    model: {
      defaults: {
        content: `${type}...`,
        dynamicName: type,
      },
    },
  })

  const replace = (comp, swtchs) => {
    const parentComp = comp.parent()
    const parent = (parentComp && parentComp.get('type')) || 'wrapper'
    swtchs.forEach((swtch) => {
      const replaceWith = typeof swtch.type === 'string' ? { type: swtch.type } : swtch.type
      const components = swtch.components
      if (swtch.parents.includes(parent)) {
        if (components && replaceWith.type === 'components') editor.setSelected(comp.replaceWith(components))
        else if (components) editor.setSelected(comp.replaceWith({ ...replaceWith, components }))
        else editor.setSelected(comp.replaceWith(replaceWith))
      }
    })
    if (comp.get('dynamicName') === type) comp.remove()
  }

  // editor.on('block:drag:stop', (comp, block) => {
  //     const content = block.getContent()
  //     if (comp && content.switch) {
  //         replace(comp, content.switch)
  //     }
  // })

  editor.on('component:mount', (component) => {
    editor.UndoManager.stop()
    if (component && component.get('type').includes(type) && component.get('switch')) {
      replace(component, component.get('switch'))
    } else if (component && component.get('type').includes(type)) {
      component.remove()
    }
    editor.UndoManager.start()
  })
}
