import { useCallback } from 'react'
import { useRecoverPasswordMutation } from '@services/documintApi/auth'

/**
 * Use Recover Password
 */

export const useRecoverPassword = () => {
  const [_recover, { isLoading: isRecovering }] = useRecoverPasswordMutation()
  const recover = useCallback(
    async (payload) => {
      const { data, error } = await _recover(payload)
      if (error) throw error
      return data
    },
    [_recover],
  )
  return [recover, { isRecovering }]
}

export default useRecoverPassword
