import plugins, { options } from '../plugins'
import protectedCss from './protectedCss'
// import deviceManager from './deviceManager'
import styleManager from './styleManager'
import assetManager from './assetManager'
import storageManager from './storageManager'
import blockManager from './blockManager'
import richTextEditor from './rte'

import canvas from './canvas'
import keymaps from './keymaps'

const APP_BAR_HEIGHT = 65
const TOOLBAR_HEIGHT = 40

const config = {
  presetType: '',
  height: `calc(100vh - ${APP_BAR_HEIGHT + TOOLBAR_HEIGHT}px)`,
  panels: { defaults: [] }, // Avoid any default panels
  blockManager,
  layerManager: { appendTo: '#layer-manager', hideTextnode: true },
  traitManager: { appendTo: '#trait-manager' },
  selectorManager: { componentFirst: true },
  assetManager,
  // deviceManager,
  styleManager,
  storageManager,
  plugins,
  pluginsOpts: options,
  listenToEl: [document.querySelector('.gjs-cv-canvas__frames')],
  devicePreviewMode: 1,
  showOffsets: true,
  showOffsetsSelected: true,
  fromElement: false,
  protectedCss,
  keymaps,
  canvas,
  cssIcons: '//db.onlinewebfonts.com/c/8ca6bf77985692e1319f58705d4a14c7?family=Font+Awesome+6+Pro+Solid',
  richTextEditor,
  undoManager: {
    trackSelection: false,
  },
}

export default config
