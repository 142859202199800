import React, { useEffect, useRef } from 'react'
import { Row, Col, Card, Alert, Button } from 'antd'
import { PageHeader } from '@ant-design/pro-components'

import { useToggle } from '@hooks/helperHooks'
import {
  SubscriptionUsageChart,
  SubscriptionBillingDetails,
  SubscriptionPlanDetails,
  SubscriptionManageBillingButton,
  SubscriptionAlerts,
  ChangePlanButton,
  PaymentMethodModal,
  PaymentMethodsProvider,
  useSubscriptionContext,
} from '@features/subscription'

export default function Subscription() {
  const { isUninitialized, refetch, isFetching } = useSubscriptionContext()
  const [isPaymentMethodModalOpen, togglePaymentMethodModalOpen] = useToggle(false)
  const initRef = useRef(false)

  useEffect(() => {
    document.title = 'Subscription'
  }, [])

  useEffect(() => {
    if (initRef.current === false && isUninitialized === true && !isFetching) refetch()
    initRef.current = true
  }, [isFetching, refetch, isUninitialized])

  return (
    <Alert.ErrorBoundary>
      <PaymentMethodsProvider>
        <PageHeader title='Subscription' style={{ maxWidth: '1100px' }}>
          <SubscriptionAlerts />

          <Row gutter={26}>
            <Col md={16} xs={24}>
              <Card
                title='Plan Details'
                extra={[<ChangePlanButton label='Change plan' icon={false} className='mr-2' key='2' />]}
                className='mb-3'
              >
                <SubscriptionPlanDetails />
              </Card>

              <Card
                title='Billing Details'
                className='mb-4'
                extra={[
                  <SubscriptionManageBillingButton className='mr-2' key='1' size='small' />,
                  <Button key='3' onClick={togglePaymentMethodModalOpen}>
                    Payment methods
                  </Button>,
                ]}
              >
                <SubscriptionBillingDetails className='mb-4' size='small' />
              </Card>
              <p style={{ textAlign: 'center', fontSize: '.9em', color: '#888' }}>*All values are in USD</p>
            </Col>

            <Col md={8} xs={24}>
              <Card title='Usage this Period'>
                <SubscriptionUsageChart type='dashboard' fontSize='1em' trailColor='#F5F5F5' />
              </Card>
            </Col>
          </Row>

          <PaymentMethodModal isOpen={isPaymentMethodModalOpen} onClose={togglePaymentMethodModalOpen} />
        </PageHeader>
      </PaymentMethodsProvider>
    </Alert.ErrorBoundary>
  )
}
