import { TYPES } from '../consts'
import { getAndMergeModels } from '../../utils'
import { getParentLoop, up } from './Token'
import { toJson } from '../../../../NewLogicModal/utils'
import { uniqueCollection } from '@utils'

export const type = TYPES.checkbox

export function formatName(name) {
  if (!name) return ''

  const match = name.match(/.*\.\.\//)
  let prefix = ''
  let suffix = name

  if (match) {
    const splitIndex = match.index + match[0].length
    prefix = name.slice(0, splitIndex)
    suffix = name.slice(splitIndex)
  }

  const pathname = suffix
    .split('.')
    .map((n) => n.replace(/^\[|\]$/g, '') ?? n)
    .map((n) => n?.trim?.() ?? n)
    .map((n) => (n === 'this' ? 'this' : `[${n}]`))
    .join('.')

  return prefix + pathname
}

export const isThis = (name, context, hasFormatting) => {
  const strWithoutIndices = name.replace(/\.\[?\d+\]?/g, '');
  context = hasFormatting ? context.includes('[') ? context : `[${context}]` : context.replace('[').replace(']')
  let keys = strWithoutIndices.split(`${context}.`)
  for (let i = 0; i < keys.length; i++) {
    if (keys.slice(0, i + 2).join('.') === context) return true
  }
  return false
}

export function getScopedName(varName, attributes) {
  const contextList = toJson(attributes['data-context'])
  const context = contextList ? uniqueCollection(contextList) : ['root']
  const lastContext = context[context.length - 1]
  const hasMatchingContext = lastContext !== 'root' && varName.includes(lastContext)
  const hasFormatting = varName.includes('[')
  if (hasMatchingContext && isThis(varName, lastContext, hasFormatting)) {
    varName = 'this'
  } else if (hasMatchingContext) {
    const finalContext = lastContext.includes('[') ? lastContext : `[${lastContext}]`
    varName = hasFormatting ? varName.split(`${finalContext}.`).pop() : formatName(varName.split(`${lastContext.replace('[').replace(']')}.`).pop())
  } else {
    varName = hasFormatting ? varName : formatName(varName)
  }

  varName = varName.replace(/^\[\d+\]\./, '')

  return (
    Array.from(Array(hasMatchingContext ? context.length - 1 : context.length))
      .map(() => up)
      .join('') + varName
  )
}

export const listenTraits = (model) => {
  const traits = model.getTraits()
  const attrs = model.getAttributes()
  const childTraits = traits.filter((trait) => trait.get('parent'))
  const selectTraits = traits.filter((trait) => trait.get('type') === 'select' || trait.get('type') === 'switch')

  childTraits.forEach((trait) => {
    const parent = trait.get('parent')
    if (parent) {
      const values = parent.split('=')
      if (values[1]) {
        const def = attrs[values[0]]
        const dep = values[1]
        const isBool = typeof def === 'boolean'
        const isNum = typeof def === 'number'
        const parentValue = isBool ? (dep === 'true' ? true : false) : isNum ? parseFloat(dep) : dep
        if (def !== parentValue) {
          model.updateTrait(trait.get('name'), {
            attributes: { style: 'display: none;' },
          })
        }
      } else if (!attrs[parent]) {
        model.updateTrait(trait.get('name'), {
          attributes: { style: 'display: none;' },
        })
      }
    }
  })

  selectTraits.forEach((trait) => {
    const name = trait.get('name')
    model.on(`change:attributes:${name}`, (ev) => {
      const myChildTraits = childTraits.filter((trait) => trait.get('parent').split('=')[0] === name)
      myChildTraits.forEach((trait) => {
        const parent = trait.get('parent').split('=').pop()
        const changed = ev.changed.attributes[name]
        const isBool = typeof changed === 'boolean'
        const isNum = typeof changed === 'number'
        const parentValue = isBool ? (parent === 'true' ? true : false) : isNum ? parseFloat(parent) : parent
        const display = changed === parentValue ? 'block' : 'none'
        //@ts-ignore
        trait && (trait.view.el.style.display = display)
      })
    })
  })
}

export function refreshIndex() {
  const trait = this.getTrait('varName') || this.getTrait('placeholder')
  const ref = trait.get('metaOptions')
  const meta = ref?.find((r) => r.value === this.get('varName') || r.value === this.get('placeholder'))

  const display = meta?.type === 'array' || meta?.parentType === 'array' ? 'block' : 'none'

  const indexTrait = this.getTrait('variableIndex')
  indexTrait && indexTrait.view && (indexTrait.view.el.style.display = display)
  this.updateTrait('variableIndex', {
    attributes: { style: `display: ${display};` },
  })

  if (display === 'block') {
    this.set({ isArray: true })
  } else {
    this.set({ isArray: false })
  }
}

export function applyRoots(editor, tokenKey) {
  if (!tokenKey) return ''
  let varName = tokenKey
  const ref = editor.SchemaKeys
  if (!ref || !Object.keys(ref).length) return varName
  const id = Object.keys(ref).find((k) => ref[k].key === tokenKey.split('].[')[0].replace('[', '')) || ''
  const field = editor.SchemaKeys[id]
  if (!field) return varName
  const rootNesting = field.schemaMetaData.nestingObj
  const roots = Object.keys(rootNesting)
  const indices = (this.get('variableIndex') || '0').split(',')
  field.type === 'array' &&
    roots.forEach((root, i) => (varName = varName.replace(`[${root}]`, `[${root}].[${indices[i] || '0'}]`)))

  return varName
}

export const protectedCss = `
  /* CHECKBOX COMPONENT */
  div[data-gjs-type="${type}"]{ 
    display: flex;
    font-family: inherit;
    justify-content: flex-start;
  }

  [data-gjs-type="${type}"] input {
    display: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: inherit;
    border: none;
    background-color: rgba(0,0,0,0);
    box-sizing: border-box;
    width: 100%;
    position: relative;
    padding: 5px;
    z-index: 1;
  }
  [data-gjs-type="${type}"] i {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    box-sizing: border-box;
    display: block;
    height: 14px;
    margin: 0 5px;
    width: 6px;
  }
  [data-gjs-type="${type}"] i:empty {
    color: inherit !important;
  }
  [data-gjs-type="${type}"] [data-type="radio"] i {
    height: 1px;
    width: 1px;
    margin: 3px;
  }
  [data-gjs-type="${type}"] [data-type="xbox"] i {
    display: contents;
  }
  [data-gjs-type="${type}"] [data-type="xbox"] i:before,
  [data-gjs-type="${type}"] [data-type="xbox"] i:after {
    position: absolute;
    left: 7.5px;
    content: ' ';
    height: 17px;
    border-width: 0;
    border-color: inherit;
    border-style: solid;
  }
  [data-gjs-type="${type}"] [data-type="xbox"] i:before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  [data-gjs-type="${type}"] [data-type="xbox"] i:after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  [data-gjs-type="${type}"] input:checked + i {
    border-color: inherit;
    border-width: 0 2px 2px 0;
    border-style: solid;
  }
  [data-gjs-type="${type}"] [data-type="radio"] input:checked + i {
    border-width: 11px 11px 0 0;
    border-radius: 50%;
  }
  [data-gjs-type="${type}"] [data-type="xbox"] input:checked + i:before,
  [data-gjs-type="${type}"] [data-type="xbox"] input:checked + i:after {
    border-width: 0 2px 0 0;
  }
  [data-gjs-type="${type}"] [data-type] {
    margin: 8px;
    padding: 0;
    width: 17px;
    height: 17px;
    display: block;
    cursor: pointer;
    background-color: rgba(0,0,0,.1);
    border: none;
    box-shadow: none;
    border-radius: 2px;
    box-sizing: border-box;
    position: relative;
  }
  [data-gjs-type="${type}"] [data-type="radio"] {
    border-radius: 50%;
  }
  [data-gjs-type="${type}"] label { 
    font-weight: inherit;
    font-style: inherit;
  }
  [data-gjs-type="${type}"] > div:first-child {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
  }
`

export default function checkboxType(dc, { editor }) {
  const model = getAndMergeModels(dc, [TYPES.template, type])
  const defaults = model.defaults

  const draggableBlock = `[data-gjs-type=wrapper], [data-gjs-type=gs-columns], [data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.gridItem}], [data-gjs-type=${TYPES.column_2}]`
  const draggable = `[data-gjs-type=gs-columns]:empty, [data-gjs-type=${TYPES.column}], [data-gjs-type=${TYPES.gridItem}], [data-gjs-type=${TYPES.column_2}]`

  defaults.icon = '<i class="gjs-badge__icon fa-regular fa-square-check"></i>'
  defaults.attributes = { ...defaults?.attributes, 'data-dm-category': 'content', 'data-context': '' }
  defaults.draggable = draggableBlock
  defaults.traits = [
    {
      name: 'data-value',
      type: 'switch',
      valueTrue: 'variable',
      valueFalse: 'static',
      label: 'Variable',
    },
    {
      name: 'placeholder',
      type: 'combo',
      label: 'Variable Name',
      parent: 'data-value=variable',
      placeholder: 'e.g. var',
      supportedTypes: ['string', 'boolean', 'number'],
      changeProp: true,
    },
    {
      name: 'variableIndex',
      type: 'info-text',
      info: 'You can use multiple indices by comma separating them.',
      label: 'Index',
      parent: 'data-value=variable',
      placeholder: '0',
      changeProp: true,
      min: 0,
    },
    {
      name: 'varTrue',
      label: 'True Value',
      parent: 'data-value=variable',
      placeholder: 'true',
      type: 'info-text',
      info: "By default the checkbox will be checked if the variable's value is true. You can override this by including a custom value here, Ex. yes",
      changeProp: true,
    },
    {
      name: 'checked',
      parent: 'data-value=static',
      type: 'checkbox',
      changeProp: true,
    },
  ]
  defaults.stylable = [
    'color',
    'padding',
    'checkbox-box-type',
    'checkbox-text-position',
    'checkbox-alignment',
    'font-size',
    'justify-content',
    'invalid-scale',
  ]
  defaults.components = [
    {
      type: TYPES.holder + '-2',
      components: [
        {
          type: TYPES.holder,
          components: [
            {
              type: TYPES.toggle,
            },
            {
              type: TYPES.icon,
            },
          ],
        },
        {
          type: TYPES.label,
        },
      ],
    },
  ]

  model.init = function () {
    listenTraits(this)
    this.on('change:attributes:data-value', this.handleValueChange)
    this.on('change:placeholder change:attributes:data-context', this.handleVariableChange)
    this.on('change:variableIndex', this.updateIndices)
    this.on('change:checked', this.handleCheckedChange)

    this.set({ draggable })
  }

  model.handleVariableChange = function () {
    this.refreshIndex()

    this.updateIndices()
    this.getCheckbox().addAttributes({
      checked: false,
    })
  }

  model.setContext = function () {
    this.addAttributes({ 'data-context': getParentLoop(this, this.get('placeholder')) })
  }

  model.refreshIndex = refreshIndex

  model.applyRoots = applyRoots

  model.updateIndices = function () {
    const placeholder = this.get('placeholder')
    let varName = this.applyRoots(editor, placeholder)
    this.set({ varName: getScopedName(varName, this.getAttrToHTML()) })
  }

  dc.addType(type, { extend: type, model })
}
