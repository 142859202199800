export const MODES = {
  integrations: { value: 'integrations', label: 'Integrations' },
  design: { value: 'design', label: 'Design', isDefault: true },
  preview: { value: 'preview', label: 'Preview' },
}

export const MODES_LIST = [MODES.integrations, MODES.design, MODES.preview]

export function getMode(value) {
  return MODES_LIST.find((mode) => mode.value === value)
}

export function getDefaultMode() {
  return MODES_LIST.find((mode) => mode.isDefault)
}

export function getModes() {
  return MODES_LIST
}

const defaults = { MODES, MODES_LIST, getMode, getDefaultMode, getModes }

export default defaults
