import { pick, isNumber } from 'lodash'
import { devices } from './config/deviceManager'
import { deepClone } from '@utils/index'

export const vis = (function () {
  let stateKey
  let eventKey
  const KEYS = {
    hidden: 'visibilitychange',
    webkitHidden: 'webkitvisibilitychange',
    mozHidden: 'mozvisibilitychange',
    msHidden: 'msvisibilitychange',
  }
  for (stateKey in KEYS) {
    if (stateKey in document) {
      eventKey = KEYS[stateKey]
      break
    }
  }
  return function (callback) {
    const value = document['visibilityState'] === 'visible'
    if (callback) document.addEventListener(eventKey, (e) => callback(e, e.target.visibilityState === 'visible'))
    return value
  }
})()

/**
 * Sets the Editor device
 * @param {GJSEditor} editor editor instance
 * @param {Object} options template options
 * @returns
 */
export function setDevice(editor, options = {}) {
  if (!editor) return
  const body = editor.Canvas.getBody()

  const document = body?.querySelector?.('.document')

  if (!document) return

  const { format = 'letter', isLandscape = false, width = '8.5in', height = '11in', margin } = options

  let dimensions = devices[format] || { width, height }

  if (isLandscape) dimensions = { width: dimensions.height, height: dimensions.width }

  document.style.width = dimensions.width
  document.style.minHeight = dimensions.height

  document.style.paddingBottom = margin?.bottom ?? 0
  document.style.paddingTop = margin?.top ?? 0
  document.style.paddingRight = margin?.right ?? 0
  document.style.paddingLeft = margin?.left ?? 0

  const wrapper = document.querySelector('#wrapper')

  if (!wrapper) return

  wrapper.style.minHeight = `calc(${dimensions.height} - ${margin?.top ?? 0} - ${margin?.bottom ?? 0})`
  editor.refreshCanvas()
}

/**
 * Sets the editor margins
 * @param {GJSEditor} editor GrapesJS editor instance
 * @param {Object} margin Template margins object
 * @returns
 */
export function setMargin(editor, margin) {
  try {
    if (!editor || !margin) return

    const body = editor.Canvas.getBody()
    if (!body) return
    const document = body.querySelector('.document')
    if (!document) return
    const { top = 0, right = 0, bottom = 0, left = 0 } = margin
    document.style.paddingTop = top
    document.style.paddingRight = right
    document.style.paddingBottom = bottom
    document.style.paddingLeft = left
    editor.refreshCanvas()
  } catch (error) {
    console.error(error)
  }
}

// export function deselectComponents(editor) {
//   if (!editor) return
//   const um = editor.UndoManager
//   um.stop()
//   editor.select()
//   um.start()
// }

export function updateCanvasPosition(editor) {
  if (!editor?.Canvas) return
  const canvasEl = editor.Canvas.getElement()
  const framesWrapperEl = canvasEl.querySelector('.gjs-cv-canvas__frames')

  const xCoord = (canvasEl.offsetWidth - framesWrapperEl.offsetWidth) / 2
  const yCoord = (canvasEl.offsetHeight - framesWrapperEl.offsetHeight) / 2

  editor.Canvas.setCoords(xCoord, yCoord)
  editor.refreshCanvas()
}

export function setZoom(editor, value = 100) {
  if (!editor) return

  const min = 20
  const max = 300

  if (value < min) value = min
  if (value > max) value = max

  editor.Canvas.setZoom(value)

  const canvasEl = editor.Canvas.getElement()
  const framesWrapperEl = canvasEl.querySelector('.gjs-cv-canvas__frames')
  const percent = value / 100

  framesWrapperEl.style.width = `calc(100% / ${percent})`
  framesWrapperEl.style.height = `calc(100% / ${percent})`

  updateCanvasPosition(editor)

  return value
}

export function zoomIn(editor, amt = 10) {
  if (!editor || !isNumber(amt)) return
  const currentZoom = editor.Canvas.getZoom()
  return setZoom(editor, currentZoom + amt)
}

export function zoomOut(editor, amt = 10) {
  if (!editor || !isNumber(amt)) return
  const currentZoom = editor.Canvas.getZoom()
  return setZoom(editor, currentZoom - amt)
}

export function deselectComponents(editor) {
  if(!editor) return
  editor?.setSelected?.()
  editor?.getWrapper?.()?.view?.el?.classList?.remove?.('canvas-focused')
}

export function hasChanged(a, b, paths) {
  if (!a || !b || typeof a !== 'object' || typeof b !== 'object') return

  if (paths) {
    a = pick(a, paths)
    b = pick(b, paths)
  }

  if (Object.keys(a).length !== Object.keys(b).length) return true

  return !Object.entries(a).every(([key, value]) => {
    let bVal = b[key]
    if (!bVal) return false
    if (typeof bVal === 'object') bVal = JSON.stringify(bVal)
    const aVal = typeof value === 'object' ? JSON.stringify(value) : value
    return aVal === bVal
  })
}

/**
 * Load data to editor
 * @param {grapesjs.Editor} editor
 * @param {grapesjs.Components} components
 * @param {grapesjs.Selector[]} styles Selectors from data
 * @param {grapesjs.Selector[]} style Canvas default selectors from config
 */
export const loadData = (editor, components = {}, styles = {}, style) => {
  editor.Components.clear()
  editor.loadData({ styles: preprocessStyles(styles), components })
  style.forEach((s) => editor.Css.setRule(s.selector, s.style))
}

/**
 * Remove artifacts from styles
 * @param {grapesjs.Selector[]} styles Selectors from data
 * @returns {grapesjs.Selector[]}
 */
export const preprocessStyles = (styles) => {
  const stylesClone = deepClone(styles)
  ;stylesClone.forEach(({ style }) => {
    if (style?.__p) delete style.__p
  })
  return stylesClone
}

// Scan template for legacy components
export const loopAllComponents = (components, ifTrue, clb) => {
  components?.each?.((component) => {
    if (component) {
      if (ifTrue(component)) {
        clb(component)
      } else if ((component?.components?.().length ?? 0) > 0) {
        loopAllComponents(component.components(), ifTrue, clb)
      }
    }
  })
}

export const loopAllComponentsWithoutBreak = (components, clb) => {
  components?.each?.((component) => {
    if (component) {
      clb(component)
      const comps = component.get('components')
      if (comps.length) {
        loopAllComponentsWithoutBreak(comps, clb)
      }
    }
  })
}

const on = (el, ev, fn, opts) => {
  ev = ev.split(/\s+/)
  el = el instanceof Array ? el : [el]

  for (let i = 0; i < ev.length; ++i) {
    el.forEach((elem) => elem && elem.addEventListener(ev[i], fn, opts))
  }
}

const off = (el, ev, fn, opts) => {
  ev = ev.split(/\s+/)
  el = el instanceof Array ? el : [el]

  for (let i = 0; i < ev.length; ++i) {
    el.forEach((elem) => elem && elem.removeEventListener(ev[i], fn, opts))
  }
}

const utils = {
  setMargin,
  setDevice,
  deselectComponents,
  vis,
  hasChanged,
  setZoom,
  zoomIn,
  zoomOut,
  loadData,
  loopAllComponents,
  on,
  off,
}

export default utils
