import React, { useCallback } from 'react'
import { Collapse } from 'antd'

import AirtableOptionsForm from './AirtableOptionsForm'
import { DataSourceInstructionsOptionsForm } from '../../../components'
import { useTemplateDataSource } from '../../../hooks'
import NewAirtableOptionsForm from './NewAirtableOptionsForm'
import { useInstructions } from '../../../hooks'

export default function ConfigurationForm({ onChange, renderModes, ...rest }) {
  const { config, updateConfig } = useInstructions()
  const { dataSource } = useTemplateDataSource()

  const handleAdditionalOptionsChange = useCallback(
    (values) => {
      onChange?.({ ...config, ...values })
      updateConfig(values)
    },
    [onChange, updateConfig, config],
  )

  return (
    <div {...rest}>
      {dataSource?.id === 'airtable' ? <NewAirtableOptionsForm /> : <AirtableOptionsForm />}

      <Collapse bordered={false} className='mb-3' ghost>
        <Collapse.Panel header='Additional Options'>
          <DataSourceInstructionsOptionsForm onChange={handleAdditionalOptionsChange} renderModes={renderModes} />
        </Collapse.Panel>
      </Collapse>
    </div>
  )
}

ConfigurationForm.defaultProps = {
  layout: 'inline',
}
