import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { PasswordStrength } from 'tai-password-strength'

const PASSWORD_STRENGTH = new PasswordStrength()
const PASSWORD_STRENGTH_CODE = ['VERY_WEAK', 'WEAK', 'REASONABLE', 'STRONG', 'VERY_STRONG']
export const defaultSettings = {
  colorScheme: {
    levels: ['#ff4033', '#fe940d', '#ffd908', '#cbe11d', '#6ecc3a'],
    noLevel: 'lightgrey',
  },
  height: 3,
  alwaysVisible: false,
}

function getWrapperStyle(height) {
  return {
    lineHeight: height + 'px',
  }
}

function getIndicatorStyle(color, height) {
  return {
    display: 'inline-block',
    width: '20%',
    backgroundColor: color,
    height: height + 'px',
    borderRadius: '2px',
  }
}

export const passwordRules = [
  { min: 8, message: 'Password needs to be at least 8 characters' },
  { pattern: /[^a-zA-Z0-9]/, message: 'Password needs at least 1 special character' },
  { pattern: /[0-9]/, message: 'Password needs at least 1 number' },
  { pattern: /[A-Z]/, message: 'Password needs at least 1 capital letter' },
  { pattern: /[a-z]/, message: 'Password needs at least 1 lower case letter' },
]

export const PasswordInput = React.forwardRef(({ settings = defaultSettings, onLevelChange, ...props }, ref) => {
  const [input, setInput] = useState('')

  return (
    <>
      <Input.Password
        {...props}
        ref={ref}
        value={input}
        onChange={(e) => {
          setInput(e.target.value)
          props?.onChange?.(e)
        }}
      />
      <PasswordStrengthIndicator input={input} onLevelChange={onLevelChange} settings={settings} />
    </>
  )
})

export const PasswordStrengthIndicator = ({ input, settings, onLevelChange }) => {
  // Calculate level
  const level = React.useMemo(() => {
    return input.length === 0 ? -1 : PASSWORD_STRENGTH_CODE.indexOf(PASSWORD_STRENGTH.check(input).strengthCode)
  }, [input])

  // Calculate indicators
  const indicators = React.useMemo(() => {
    const ind = []
    for (let i = 0; i < 5; i++) {
      const color = i <= level ? settings.colorScheme.levels[level] : settings.colorScheme.noLevel
      ind.push(<div key={`indicator-${i}`} style={getIndicatorStyle(color, settings.height)} />)
    }
    return ind
  }, [level, settings])

  useEffect(() => {
    onLevelChange?.(level)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level])

  if (!settings.alwaysVisible && level < 0) {
    return null
  }

  return <div style={getWrapperStyle(settings.height)}>{indicators}</div>
}
