export default function comboType(editor, { $ }) {
  const options = {
    name: 'combo',
    cls: 'combo-toggle-checked',
    info: "Don't include double curly brackets '{{...}}' in your variable name",
    defaultInfo: 'Use custom variable',
  }
  const { name, cls, info, defaultInfo } = options
  const tm = editor.Traits

  tm.addType(name, {
    eventCapture: ['change input', 'change select'],

    getInputElStr(placeholder) {
      return `<input type="text" placeholder="${placeholder}">`
    },

    getSelectElStr(options) {
      const localOptions = options.length ? options : this.getOptionsWithMetaData()
      return `<select>${localOptions
        ?.map((option) => `<option value="${option.value}">${option.label || option.value}</option>`)
        .join('')}</select>`
    },

    getOptionsWithMetaData() {
      const options = editor.getVariableOptions('root', this.model.get('supportedTypes'))?.map((option) => {
        const ref = editor.SchemaKeys
        const meta = Object.keys(ref || {}).find((k) => ref[k].formattedKey === option.value)
        return {
          ...ref[meta],
          ...option,
        }
      })
      this.model.set({ metaOptions: options })
      return options
    },

    updateOptions(value) {
      const { $el, model } = this
      const options = model.get('options')
      const localOptions = options.length ? options : this.getOptionsWithMetaData()
      const optionsStr = localOptions.map(
        (option) => `<option value="${option.value}">${option.label || option.value}</option>`,
      )

      const sel = $el.find('select')
      sel.html(optionsStr)
      value && sel.val(value)
    },

    createLabel({ label, trait: { model } }) {
      const title = model.get('info')
      return `<div class="gjs-label-wrp-2" title="${title ? title : defaultInfo}">
              ${label}
              <div class="gjs-sm-toggle">(x)</div>
            </div>`
    },

    createInput() {
      let targetValue
      const { model, target } = this
      const name = model.get('name')
      const options = model.get('options')
      const placeholder = model.get('placeholder')

      if (model.get('changeProp')) {
        targetValue = target.get(name)
      } else {
        targetValue = target.get('attributes')[name]
      }

      const $el = $(
        '<div style="width:100%;">' + this.getSelectElStr(options) + this.getInputElStr(placeholder) + '</div>',
      )

      const input = $el.find('input')
      const select = $el.find('select')

      if (targetValue) {
        input.val(targetValue)
        select.val(targetValue)
      }

      const localOptions = options.length ? options : this.getOptionsWithMetaData()

      if (!targetValue || (targetValue && localOptions.find((opt) => opt.value === targetValue))) {
        input.hide()
        select.show()
      } else {
        input.show()
        select.hide()
      }

      return $el.get(0)
    },

    hasVariableError(varName) {
      return varName && varName.startsWith('{')
    },

    updateInputEl() {
      const v = this.hasVariableError(this.model.get('value'))
      const el = this.el?.querySelector('.gjs-field.gjs-field-combo')
      const localInfo = this.model.get('info')
      if (!el) return
      if (v) {
        el.style.border = 'solid 1px red'
        el.style.backgroundColor = '#ffe5e5'
        this.model.set({ info })
        !localInfo && editor.runCommand('variable-error')
      } else {
        el.style.border = 'solid 1px #f1f1f1'
        el.style.backgroundColor = '#fff'
        this.model.set({ info: '' })
      }
    },

    onRender() {
      this.listenTo(this.model, 'change:value', this.updateInputEl)
      this.updateInputEl()

      const { $el } = this
      const toggle = $el.find('.gjs-sm-toggle')
      const input = $el.find('input')
      const select = $el.find('select')

      const vis = input.get(0).style.display === 'block'

      vis && toggle.addClass(cls)
      !vis && toggle.removeClass(cls)

      toggle.on('click', () => {
        toggle.toggleClass(cls)
        if (input.get(0).style.display === 'block') {
          input.hide()
          select.show()
        } else {
          input.show()
          select.hide()
        }
      })
    },

    // Update the component based on element changes
    onEvent({ event }) {
      const { value } = event.target
      const { $el } = this

      $el.find('input').val(value)
      $el.find('select').val(value)

      this.model.set('value', event.target.value)
    },
  })
}
