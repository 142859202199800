import React, { useCallback, useMemo } from 'react'
import { Tree, Switch, Col, Row, Typography, Tooltip, Alert } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import { uniq } from 'lodash'

const { Title, Paragraph } = Typography

function renderTitle({ name, key }, checkedKeys, handleCheck) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }} className='ant-form-item-label'>
      <span>{name}</span>
      <Switch size='small' checked={checkedKeys?.includes(key)} onChange={(checked) => handleCheck({ checked, key })} />
    </div>
  )
}

export default function ExpandedFields({ dataSource, onChange, value = [], expandableFields }) {
  const handleCheck = useCallback(
    ({ checked, key }) => {
      // Determine if was a check or uncheck action
      let newCheckedValues = value

      if (!checked) {
        // Uncheck children if parent is unchecked
        newCheckedValues = [...(value || [])].filter((thisKey) => thisKey !== key && !thisKey.startsWith(key))
      } else {
        // Check parent if not checked
        const keysToCheck = []
        const allKeys = [...(value || []), key]
        allKeys.forEach((key) => {
          const keyParts = key.split('-')
          keyParts.pop()
          const parentKeys = keyParts.reduce((acc, part, i) => {
            if (i === 0) {
              acc.push(part)
            } else {
              acc.push(acc[i - 1] + '-' + part)
            }
            return acc
          }, [])
          keysToCheck.push(...parentKeys)
        })
        newCheckedValues = uniq([...keysToCheck, ...(value || []), key])
      }

      dataSource.expandedFields = newCheckedValues
      onChange?.(newCheckedValues)
    },
    [value, onChange, dataSource],
  )

  const defaultExpandedKeys = useMemo(() => {
    return value?.filter((key) => value.some((k) => k !== key && k.startsWith(key)))
  }, [value])

  const expandableTitle = useCallback((node) => renderTitle(node, value, handleCheck), [value, handleCheck])

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Title style={{ borderBottom: 'solid 1px #eee', paddingBottom: '5px' }} level={5}>
          Expand Fields
        </Title>

        <Paragraph>Select relationship fields you would like to expand.</Paragraph>
      </Col>
      <Col span={24}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0px 5px 10px',
            borderBottom: '1px solid #f0f0f0',
            marginBottom: '10px',
          }}
        >
          <span>Field Name</span>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
            <span>Expand</span>
            <Tooltip title={'Include linked record in imported schema'}>
              <InfoCircleFilled width='12px' height='12px' />
            </Tooltip>
          </div>
        </div>
      </Col>
      <Col span={24}>
        {expandableFields && (
          <Tree
            blockNode
            showLine
            titleRender={expandableTitle}
            fieldNames={{ children: 'expandableFields', title: 'name' }}
            treeData={expandableFields}
            selectable={false}
            checkStrictly
            defaultExpandedKeys={defaultExpandedKeys}
            className="pb-3"
          />
        )}
        <Alert
          style={{ padding: '10px' }}
          description={
            <p style={{ fontSize: '12px', margin: 0 }}>
              Only enable relationship that you intend to use in this template as each expansion will increase the
              render time.
            </p>
          }
        />
      </Col>
    </Row>
  )
}
