import { createContext, useState, useRef, useCallback } from 'react'
import { useToggle, useStackState } from '@hooks/helperHooks'

export const EditorContext = createContext(null)

export function EditorProvider({ children }) {
  const [_editor, _setEditor] = useState()
  const editorRef = useRef(null)
  const [selectedComponent, setSelectedComponent] = useState()
  const [zoomValue, setZoomValue] = useState(100)
  const [guides, toggleGuides] = useToggle(true)
  const [changeCount, setChangeCount] = useState(0)
  const [hasRedo, setHasRedo] = useState(false)
  const [hasUndo, setHasUndo] = useState(false)
  const [hasLegacy, setHasLegacy] = useState(false)
  const [revertToLegacy, setRevertToLegacy] = useState(false)
  const [revertSections, revertSectionsControls] = useStackState([])
  const [initialized, setInitialized] = useState(false)

  const setEditor = useCallback((editor) => {
    if (editorRef.current) return
    editorRef.current = editor
    _setEditor(editor)
  }, [])

  const editorContext = {
    editor: _editor,
    setEditor,
    ref: editorRef,
    initialized,
    setInitialized,
    selectedComponent,
    setSelectedComponent,
    zoomValue,
    setZoomValue,
    guides,
    toggleGuides,
    changeCount,
    setChangeCount,
    hasRedo,
    setHasRedo,
    hasUndo,
    setHasUndo,
    hasLegacy,
    setHasLegacy,
    revertToLegacy,
    setRevertToLegacy,
    revertSections,
    revertSectionsControls,
  }

  return <EditorContext.Provider value={editorContext}>{children}</EditorContext.Provider>
}
