import React from 'react'
import { Modal, Typography } from 'antd'
import DataSourceIcon from './DataSourceIcon'
import { DataSourceInstructions } from '.'
const { Title } = Typography

export default function ConnectorSetupModal({ dataSource, open, onCancel }) {
  const { icon, name, content } = dataSource || {}

  return (
    <Modal
      title={
        <Title
          level={4}
          style={{
            textAlign: 'center',
            marginBottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '15px',
          }}
        >
          <DataSourceIcon icon={icon} />
          {name} Integration
        </Title>
      }
      open={open}
      onCancel={onCancel}
      width={900}
      styles={{ body: { padding: '20px 30px' } }}
      footer={null}
    >
      <DataSourceInstructions {...dataSource}>{content}</DataSourceInstructions>
    </Modal>
  )
}
