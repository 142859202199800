import { createContext } from 'react'
import { useToggle } from '@hooks/helperHooks'

export const TemplateSettingsContext = createContext(null)

export function TemplateSettingsProvider({ children, value }) {
  const [showModal, toggleModal] = useToggle()
  const [autoSave, toggleAutoSave] = useToggle(true)

  const contextValue = {
    showModal,
    toggleModal,
    autoSave,
    toggleAutoSave
  }
  return <TemplateSettingsContext.Provider value={contextValue}>{children}</TemplateSettingsContext.Provider>
}
