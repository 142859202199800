import { changeAll, updateVisibility, setCustom } from './linked'

const DEFAULT_SPACING = {
  fixedValues: ['initial', 'inherit', 'auto'],
  type: 'integer',
  units: ['px'],
  unit: 'px',
  defaults: '0',
  min: 0,
}

const paddingKeys = ['padding-top', 'padding-right', 'padding-bottom', 'padding-left']
const allKey = '--padding-all'

const sizingSector = {
  sectorId: 'sizing',
  name: 'Size & Spacing',
  open: true,
  buildProps: ['flex', 'width', 'height', 'min-height', 'max-width', 'max-height'],
  properties: [
    {
      name: 'Width',
      id: 'width',
      type: 'integer',
      property: 'width',
      units: ['px', '%'],
      full: false,
      default: 'auto',
      min: null,
    },

    {
      id: 'flex',
      name: 'Width',
      type: 'integer',
      property: 'flex',
      units: [],
      full: false,
      default: 100,
      min: null,
      requires: { display: ['flex'] },
    },

    {
      id: 'height',
      name: 'Height',
      type: 'integer',
      property: 'height',
      units: ['px', '%'],
      unit: '',
      default: 'auto',
      full: false,
      min: null,
    },

    {
      id: 'min-height',
      name: 'Minimum Height',
      type: 'integer',
      property: 'min-height',
      units: ['px'],
      unit: 'px',
      default: 'auto',
      full: false,
      min: 0,
    },

    {
      id: 'max-width',
      name: 'Maximum Width',
      type: 'integer',
      property: 'max-width',
      units: ['px', '%'],
      unit: '%',
      default: 'auto',
      full: false,
      min: 0,
    },

    {
      id: 'max-height',
      name: 'Maximum Height',
      type: 'integer',
      property: 'max-height',
      units: ['px', '%'],
      unit: 'px',
      default: 'auto',
      full: false,
      min: 0,
    },
    {
      id: 'Spacing',
      label: 'Spacing',
      name: 'Spacing',
      property: 'padding',
      detached: true,
      type: 'composite',
      properties: [
        {
          ...DEFAULT_SPACING,
          name: ' ',
          property: '--padding-all',
          className: 'no-reset',
          onChange({ to, from, value, property, opts }) {
            !opts.__up && changeAll(property, value, allKey, paddingKeys,  { ...opts, to, from })
          },
        },
        {
          property: '--padding-type',
          name: ' ',
          type: 'radio',
          full: false,
          default: 'all',
          className: 'no-reset',
          options: [
            { value: 'all', className: 'fa-regular fa-square', title: 'All' },
            { value: 'custom', className: 'fa-duotone fa-square-dashed', title: 'Custom' },
          ],
          onChange({ to, from, value, property, opts }) {
            updateVisibility(property, value, allKey, paddingKeys, opts)
          },
        },
        {
          ...DEFAULT_SPACING,
          name: 'Top',
          property: 'padding-top',
          className: 'side side--top hidden',
          full: true,
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, paddingKeys, opts)
          },
        },

        {
          ...DEFAULT_SPACING,
          name: 'Left',
          property: 'padding-left',
          className: 'hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, paddingKeys, opts)
          },
        },
        {
          ...DEFAULT_SPACING,
          name: 'Right',
          property: 'padding-right',
          className: 'side side--right hidden',
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, paddingKeys, opts)
          },
        },
        {
          ...DEFAULT_SPACING,
          name: 'Bottom',
          property: 'padding-bottom',
          className: 'side side--bottom hidden',
          full: true,
          onChange({ to, from, value, property, opts }) {
            setCustom(property, value, allKey, paddingKeys, opts)
          },
        },
      ],
    },
  ],
}

export default sizingSector
