import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setProfile, setAccount } from '../authSlice'
import { useLogoutMutation } from '@services/documintApi/auth'
import { documintApi } from '@services/documintApi'

/**
 * Logout Hook
 * @returns
 */
export const useLogout = () => {
  const [_logout, { isLoading: isLoggingOut }] = useLogoutMutation()
  const dispatch = useDispatch()

  const logout = useCallback(async () => {
    await _logout()

    dispatch(setProfile(null))
    dispatch(setAccount(null))
    dispatch(documintApi.util.resetApiState())
  }, [_logout, dispatch])

  return [logout, { isLoggingOut }]
}

export default useLogout
