import React, { useCallback, useState } from 'react'
import { Editor } from '../index.style'
import { Typography, Button } from 'antd'
import { isValidJsonSchema } from '@utils/json'
import { highlight, languages } from 'prismjs/components/prism-core'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-javascript'
import 'prismjs/themes/prism.css'
import { FaIcon } from '@components/icons'

const { Title, Text } = Typography

export default function PasteSchemaInput({ onChange, onSave }) {
  const [pastedSchema, setPastedSchema] = useState('')
  const [isSchemaValid, setIsSchemaValid] = useState(null)

  const handleSchemaEditorChange = useCallback(
    (code) => {
      try {
        setPastedSchema(code)

        if (!code || code === '') {
          setIsSchemaValid(null)
          onChange?.(null, null)
          return
        }

        const parsedContent = JSON.parse(code)

        if (isValidJsonSchema(parsedContent)) {
          setIsSchemaValid(true)
          onChange?.(parsedContent, true)
        } else {
          setIsSchemaValid(false)
          onChange?.(null, false)
        }
      } catch (error) {
        setIsSchemaValid(false)
        onChange?.(null, false)
      }
    },
    [onChange],
  )

  return (
    <div>
      <div style={{ textAlign: 'left', marginBottom: '1em' }}>
        <Title level={5}>Paste your JSON Schema</Title>
        <Text>Create your template's schema by pasting JSON Schema below.</Text>
      </div>

      <Editor
        value={pastedSchema}
        onValueChange={handleSchemaEditorChange}
        highlight={(code) => highlight(code, languages.js)}
        padding={10}
        placeholder='Paste your JSON Schema here...'
        style={{ borderColor: isSchemaValid === false && 'red' }}
      />

      <div
        style={{
          textAlign: 'right',
          padding: '30px 0 20px',
          display: 'flex',
          gap: '15px',
          justifyContent: 'space-between',
        }}
      >
        <Text style={{ color: '#888' }}>
          {isSchemaValid === null ? (
            <></>
          ) : isSchemaValid ? (
            <>
              <FaIcon icon='faCheckCircle' style={{ marginRight: '5px' }} />
              Schema is valid
            </>
          ) : (
            <>
              <FaIcon icon='faCircleXmark' style={{ marginRight: '5px' }} />
              Schema is Invalid
            </>
          )}
        </Text>
        <Button type='primary' disabled={!isSchemaValid} onClick={() => onSave?.(JSON.parse(pastedSchema))}>
          Save
        </Button>
      </div>
    </div>
  )
}
