import React from 'react'
import { Button, Typography, Image, notification } from 'antd'
import { MinusCircleOutlined, UploadOutlined } from '@ant-design/icons'
import isFunction from 'lodash/isFunction'
import DetailList from '@components/DetailList'
import CustomUpload from '@components/CustomUpload'
import config from '../../../config'
import { DEFAULT_ACCOUNT } from '../consts'

const { Text } = Typography

export default function AccountDetails(props) {
  const { account, isLoading, onUpdate, isUpdating } = props
  const { id, company = {}, betaOptIn = false } = account
  const { name, address = {}, logo } = company
  const { street1, street2, city, state, zipCode, country } = address

  const handleUploadSuccess = async (files) => {
    const file = files[0]
    isFunction(onUpdate) && onUpdate({ 'company.logo': file.id })
  }

  const handleUploadError = (error) => {
    notification.error({ message: 'Upload Error', description: error?.message })
  }

  const handleRemoveLogo = () => {
    isFunction(onUpdate) && onUpdate({ 'company.logo': null })
  }

  const items = [
    { label: 'Name', value: name },
    {
      label: 'Logo',
      value: logo?.src ? (
        <>
          <Image
            src={logo.src}
            loading={isLoading}
            alt='avatar'
            style={{
              width: '100%',
            }}
          />
          <br />

          <Button
            type='text'
            icon={<MinusCircleOutlined />}
            onClick={handleRemoveLogo}
            loading={isUpdating}
            title='Remove'
            size='small'
            style={{ marginTop: '.3em' }}
          >
            Remove
          </Button>
        </>
      ) : (
        <CustomUpload
          onSuccess={handleUploadSuccess}
          onError={handleUploadError}
          data={{ extra: 'data' }}
          accept='image/*'
          action={`${config.apiUrl}/assets`}
        >
          <Button icon={<UploadOutlined />} loading={isUpdating}>
            Upload Logo
          </Button>
        </CustomUpload>
      ),
    },
    {
      label: 'Address',
      value: (
        <>
          {street1} {street2}
          {(city || state || zipCode) && (
            <>
              <br /> {city}, {state} {zipCode}
            </>
          )}
          {country && (
            <>
              <br /> {country}
            </>
          )}
        </>
      ),
    },
    { label: 'Account Id', value: <Text code>{id}</Text> },
    { label: 'Beta program enrollment', value: betaOptIn ? 'Enrolled' : 'Not Enrolled' },
  ]

  return <DetailList title='Company' isLoading={isLoading} items={items} />
}

AccountDetails.defaultProps = {
  account: DEFAULT_ACCOUNT,
  isLoading: false,
}
