import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, Space, Typography, Button } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

export default function ErrorModal({ error, onReload }) {
  const history = useHistory()
  const { status } = error || {}

  if (!error) return null

  const messages = {
    419: {
      title: 'Template out of date',
      message: (
        <div className='mb-3'>
          A newer version of this template is available.
          <br />
          Reload to edit the latest version.
        </div>
      ),
      action: (
        <Button type='primary' icon={<ReloadOutlined />} onClick={onReload}>
          Reload
        </Button>
      ),
    },
    500: {
      title: "Something wen't wrong",
      message: 'An unexpected error occurred. Please contact support if the problem persists',
      action: (
        <Button type='primary' onClick={() => history.push('/')}>
          Back Home
        </Button>
      ),
    },
  }

  const data = messages[status]

  if (!data) return null

  return (
    <Modal open={true} header={false} footer={false} closable={false} styles={{ body: { textAlign: 'center' } }}>
      <Space direction='vertical' align='center'>
        <Typography.Title level={4}>{data.title}</Typography.Title>
        <div>{data?.message && data.message}</div>
        {data?.action && data.action}
      </Space>
    </Modal>
  )
}
