import { TYPES } from './consts'
import { deepClone } from '@utils'
import { replaceWith } from '../utils'
import { isFunction } from 'lodash'
import { initValues } from '../../config/styleManager/sectors/linked'

export default function postProcessing(editor) {
  const selectImage = (component) => {
    const comp = component.collection ? component : component[0]
    const imgComp = comp?.find?.('img')
    if (editor && imgComp) {
      editor.setSelected(imgComp)
      const sel = editor.getSelected()
      sel && sel.view.onActive()
    } else {
      editor.setSelected(comp)
    }
  }

  editor.on('block:drag:start component:drag:start', (_comp, _block) => {})

  editor.on('block:drag:stop component:drag:end', (comp, block) => {
    let component = comp?.target ? comp.target : comp
    component = component && component[0] ? component[0] : component
    let style = {}
    let postComp

    const cloneComponent = (component) => {
      const clonedComponent = deepClone(component)
      style = component.getStyle()
      clonedComponent.style = style
      delete clonedComponent.attributes.id
      return clonedComponent
    }
    if (isFunction(component?.setContext)) {
      component.setContext()
      if (component.get('type') === TYPES.token && component.parent().get('type') !== TYPES.text) {
        postComp = {
          type: TYPES.text,
          components: block?.getContent ? block.getContent() : [cloneComponent(component)],
        }
      }
    }
    if (component?.parent()?.get('type') === 'gs-columns' && component?.get('type') !== TYPES.column_2) {
      postComp = {
        type: TYPES.column_2,
        components: postComp ? [postComp] : block?.getContent ? block.getContent() : [cloneComponent(component)],
      }
    }
    if (
      component?.parent()?.get('type') === 'wrapper' &&
      component?.get('type') !== TYPES.section &&
      component?.get('type') !== TYPES.row_2 &&
      component?.get('type') !== TYPES.verticalSpacer &&
      component?.get('type') !== TYPES.pageBreak
    ) {
      postComp =
        component.get('type') === TYPES.column_2
          ? {
              type: TYPES.row_2,
              components: [
                {
                  type: 'gs-columns',
                  components: block?.getContent ? block.getContent() : [cloneComponent(component)],
                },
              ],
            }
          : {
              type: TYPES.row_2,
              components: [
                {
                  type: 'gs-columns',
                  components: [
                    {
                      type: TYPES.column_2,
                      components: postComp
                        ? [postComp]
                        : block?.getContent
                        ? block.getContent()
                        : [cloneComponent(component)],
                    },
                  ],
                },
              ],
            }
    }
    if (postComp) {
      editor.Modal.close()
      const added = component.replaceWith(postComp)
      block && selectImage(added)
    }
  })

  editor.on('component:mount', (component) => {
    if (component && component.getAttributes()['data-gs-type'] === 'column') {
      const columns = component.get('tempColumns')
      columns && component?.setColumns?.(columns)
      component.set({ tempColumns: '' })
    }

    if (
      component &&
      [TYPES.text, 'dm-text', 'default'].includes(component.get('type')) &&
      component.parent()?.get('type').includes('column')
    ) {
      const el = component.view?.el
      if (el && (el.querySelector('div[data-gjs-type=text]') || el.querySelector('[data-gjs-type=default]'))) {
        const nestedTextEls = el.querySelectorAll('div[data-gjs-type=text]')
        const nestedDefaultEls = el.querySelectorAll('div[data-gjs-type=default]:empty')

        for (let i = 0; i < nestedTextEls.length; i++) {
          replaceWith(nestedTextEls[i], nestedTextEls[i].innerHTML + '<br>')
        }
        for (let i = 0; i < nestedDefaultEls.length; i++) {
          replaceWith(nestedDefaultEls[i], '<br>')
        }
        const nestedDefaultPEls = el.querySelectorAll('p')
        for (let i = 0; i < nestedDefaultPEls.length; i++) {
          nestedDefaultPEls[i].setAttribute('data-gjs-type', TYPES.text)
        }
        ;(nestedDefaultEls.length || nestedDefaultEls || nestedDefaultPEls) && component.components(el.innerHTML)
      }
      component.set({
        selectable: true,
        hoverable: true,
      })
    } else if (
      component &&
      [TYPES.text, 'dm-text', 'default'].includes(component.get('type')) &&
      !component.parent()?.get('type').includes('column')
    ) {
      component.set({
        selectable: false,
        hoverable: false,
      })
    }
  })

  editor.on('component:select', (component) => {
    if (component && component.get('type') === 'wrapper') editor.Selectors.setComponentFirst(false)
    else editor.Selectors.setComponentFirst(true)

    if (component && component.get('type') === TYPES.row_2) component.initRowHeight()

    if (component) {
      const map = {
        '--border-radius-all': [
          'border-top-left-radius',
          'border-top-right-radius',
          'border-bottom-left-radius',
          'border-bottom-right-radius',
        ],
        '--padding-all': ['padding-top', 'padding-right', 'padding-bottom', 'padding-left'],
      }
      Object.keys(map).forEach((key) => initValues(editor, key, map[key]))
      if (isFunction(component.refreshIndex)) {
        component.refreshIndex()
      }
    }
  })
}
