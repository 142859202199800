import { useContext } from 'react'
import { TemplateDataContext } from '../contexts'

export function useTemplateDataSourceContext() {
  const context = useContext(TemplateDataContext)

  if (!context) {
    throw new Error('useTemplateDataSourceContext must be used within a TemplateDataSourceProvider')
  }

  return context
}

export default useTemplateDataSourceContext
