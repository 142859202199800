import React from 'react'
import CodeBlock from '@common/CodeBlock'

import { Button, Typography, Alert, Collapse } from 'antd'
import PremiumFeatureFlag from '@common/PremiumFeatureFlag'
import ConfigurationForm from './ConfigurationForm'
import { useInstructions, useTemplateDataSource } from '../../../hooks'
import { useTemplate } from '@hooks/useTemplate'

const { Title, Paragraph } = Typography

const documentationLink = 'https://docs.documint.me/integrations/airtable/automation-script'

export default function AutomationScript() {
  const { template } = useTemplate()
  const { config } = useInstructions()

  const { dataSource } = useTemplateDataSource()
  const isConnected = dataSource.id === 'airtable'

  return (
    <>
      <Title level={4}>
        Overview <PremiumFeatureFlag />
      </Title>

      <Paragraph>
        This feature allows you to create documents within Airtable, automatically, using Airtable automations.
      </Paragraph>

      <Collapse className='mb-3' bordered={false}>
        <Collapse.Panel header='Setup Instructions' key='1'>
          <Paragraph>
            <ol>
              <li>Copy the URL of the table from which you'll be creating documents.</li>
              <li>Paste the table URL in the Table URL field and add the Attachment field name.</li>
              <li>Copy the script.</li>
              <li>Switch back to Airtable and open the Automations panel.</li>
              <li>Create a new automation.</li>
              <li>Add a trigger. This can be any trigger that you'd like.</li>
              <li>Add a "Run script" action.</li>
              <li>Paste your script.</li>
              <li>Add an input variable named `record_id` and set the value to the `Record ID`.</li>
              <li>Test your script and click "Finish editing"</li>
            </ol>
          </Paragraph>

          <Button href={documentationLink} htmlType='a' target='_blank' type='primary' className='mt-2 mb-4'>
            Complete Setup guide
          </Button>
        </Collapse.Panel>
      </Collapse>

      <div style={{ marginBottom: '15px' }}>
        {!config?.base_id || !config?.attachment_field_name ? (
          <Alert
            type='info'
            message='Automation Script'
            description={
              isConnected ? (
                <>
                  Select the <b>Attachment field</b> below to display the Automation Script.
                </>
              ) : (
                <>
                  Add your Airtable <b>Table URL</b> and <b>Attachment field name</b> below to display the Automation
                  Script.
                </>
              )
            }
          />
        ) : (
          <>
            <Title level={5}>Automation Script</Title>
            <CodeBlock code={renderCode(template, config)} showLineNumbers={false} />
          </>
        )}
      </div>

      <Title level={5}>Options</Title>
      <ConfigurationForm renderModes={false} />
    </>
  )
}

function renderCode(template, values) {
  const { table_id, base_id, url, attachment_field_name, ...rest } = values || {}
  const additionalParams = Object.entries(rest)
    .map(([key, value]) => `${key}: ${typeof value === 'string' ? `'${value}'` : value}`)
    .reverse()
    .join(', \n  ')

  return `const { record_id } = input.config()

const params = { 
  record_id,
  table_id:'${table_id || ''}', 
  base_id: base.id,
  attachment_field_name: '${attachment_field_name || ''}',${additionalParams && `\n  ${additionalParams}`}
}

const query = new URLSearchParams(params)
const url = \`https://api.documint.me/1/templates/${template.id}/content/airtable?$\{query.toString()}\`
const response = await fetch(url, { method: "POST" })
const data = await response.json()
if(response.status !== 200) throw new Error(data.message)`
}
