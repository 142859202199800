import { useContext } from 'react'
import { TemplateSettingsContext } from '../contexts/templateSettingsContext'

export function useTemplateSettingsContext() {
  const context = useContext(TemplateSettingsContext)

  if(!context) {
    throw new Error('useTemplateSettingsContext must be used within a TemplateSettingsProvider')
  }

  return context
}
