import React, { useMemo } from 'react'
import { Utils, AntdConfig } from '@react-awesome-query-builder/antd'
import { Typography, Collapse } from 'antd'
import { CaretRightOutlined, SettingOutlined } from '@ant-design/icons'
import { IoGitBranch } from 'react-icons/io5'
import { lowerCase } from 'lodash'

import { useTemplate } from '@hooks/useTemplate'
import { useEditor } from '../../hooks'
import Button from '@common/Button'
import { generateLocalDataFields } from '../NewLogicModal/utils'
import { TYPES } from '../Editor/plugins/componentTypes/consts'
import { retrieveTempParent } from '../../utils'

const { Text } = Typography

export default function PropertiesPanel({ onEditLogic, ...rest }) {
  const queryBuilderConfig = {
    ...AntdConfig,
    settings: {
      ...AntdConfig.settings,
      maxNesting: 1,
    },
  }
  const { template } = useTemplate()
  const { run, selectedComponent } = useEditor()

  const { fieldSchema } = template || {}

  const schemaKey = retrieveTempParent()
  const { parsedData } = generateLocalDataFields(fieldSchema, schemaKey)
  const config = {
    ...queryBuilderConfig,
    fields: {
      ...parsedData,
    },
  }

  const handleEditLogic = () => run('edit-logic')

  const handleEditToken = () => run('edit-token')

  const formatOperator = (operator) =>
    operator ? (operator.match(/^((?![>=<]).)*$/) ? lowerCase(operator) : operator) : ''

  const scProps = {}

  if (selectedComponent) {
    scProps.hasLogic = selectedComponent.get('hasLogic')
    if (scProps.hasLogic) {
      const { getCondition, getLoop, getSorts } = selectedComponent
      scProps.condition = getCondition && selectedComponent.getCondition()
      scProps.loop = getLoop && selectedComponent.getLoop()
      scProps.sorts = getSorts && selectedComponent.getSorts()
    }
  }

  const componentName = useMemo(
    () => selectedComponent?.attributes['custom-name'] || selectedComponent?.attributes?.name || '',
    [selectedComponent],
  )

  const { hasLogic, condition, loop, sorts } = scProps

  const logicValuesProps = { code: true, style: { fontSize: '1.2em', whiteSpace: 'nowrap' } }

  const renderSorts = (sorts) => {
    try {
      const sortsList = typeof sorts === 'string' ? sorts.split(',') : sorts

      if (!Array.isArray(sortsList)) return <></>

      return sortsList.map((sort, index) => {
        const order = sort[0] === '-' ? 'desc' : 'asc'
        sort = ['-', '+'].includes(sort[0]) ? sort.substring(1, sort.length) : sort
        return (
          <div key={index}>
            <Text {...logicValuesProps}>
              {sort}:{order}
            </Text>
          </div>
        )
      })
    } catch (error) {
      return <></>
    }
  }

  const JsonStringToObject = (string) => {
    try {
      return JSON.parse(string)
    } catch (e) {
      return false
    }
  }

  const renderConditions = (condition) => {
    const parsedCondition = JsonStringToObject(condition)
    if (condition.operand1) {
      return `${condition.operand1} ${formatOperator(condition.operator)} ${condition.operand2}`
    } else if (parsedCondition && parsedCondition.children1 && parsedCondition.children1.length) {
      const tree = Utils.loadTree(condition)
      const queryStr = Utils.queryString(tree, config, true)
      return (
        JSON.stringify(queryStr, undefined, 2) ||
        (parsedCondition && parsedCondition['custom-condition']
          ? parsedCondition['custom-condition']
          : 'custom condition')
      )
    } else {
      return ''
    }
  }

  const renderLoops = (loop) => {
    const parsedLoop = loop ? JsonStringToObject(loop) : null
    if (parsedLoop) {
      const loop = Object.keys(parsedLoop)[0]
      return loop && loop !== 'undefined' ? loop.replaceAll('[', '').replaceAll(']', '') : ''
    }
    return loop ? loop.replaceAll('[', '').replaceAll(']', '') : 'Custom'
  }

  return (
    <div className={`${selectedComponent ? 'component-selected' : ''}`} style={{ overflowX: 'auto' }}>
      <div
        style={{
          width: '100%',
          height: '60px',
          padding: '20px 1em',
          fontSize: '1.2em',
          textOverflow: 'ellipsis',
          display: 'block',
          overflow: 'hidden',
          borderBottom: '1px solid rgb(221, 221, 221)',
          whiteSpace: 'nowrap',
        }}
      >
        {componentName} Properties
      </div>
      {hasLogic && (
        <div style={{ borderRight: 'none', backgroundColor: '#fafafa' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }} className='d-block p-4'>
            {selectedComponent && selectedComponent.attributes.type !== TYPES.token && (
              <Button
                size='large'
                block
                onClick={handleEditLogic}
                icon={<IoGitBranch style={{ margin: '0 4px -2px 0' }} />}
                type='primary'
              >
                Edit logic
              </Button>
            )}

            {selectedComponent && selectedComponent.attributes.type === TYPES.token && (
              <Button
                size='large'
                block
                onClick={handleEditToken}
                icon={<SettingOutlined style={{ margin: '0 4px -2px 0' }} />}
                type='primary'
              >
                Edit Token
              </Button>
            )}

            {condition && renderConditions(condition) && (
              <div>
                <div className='m-1'>Display this element when:</div>
                <Text {...logicValuesProps}>{renderConditions(condition)}</Text>
              </div>
            )}

            {loop && renderLoops(loop) && (
              <div>
                <div className='m-1'>Repeat for each item in:</div>
                <Text {...logicValuesProps}>{renderLoops(loop)}</Text>
                {sorts && (
                  <div>
                    <div className='m-1'>Sort by:</div>
                    {renderSorts(sorts)}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      <div id='trait-manager'></div>
      <div id='style-manager' />
    </div>
  )
}
