import React from 'react'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

const { confirm } = Modal


export default function clearCanvasModal({ modalProps, onOk, onCancel }) {
  function handleCancel() {
    if (onCancel) onCancel()
  }

  /**
   * Handle Save
   */
  function handleOk() {
    if (onOk) onOk()
  }

  return confirm({
    onOk: handleOk,
    onCancel: handleCancel,
    width: 500,
    okText: 'Clear',
    title: 'Clear Canvas',
    icon: <ExclamationCircleOutlined />,
    destroyOnClose: true,
    content: 'You are about to clear all elements on the canvas.',
    ...modalProps,
  })
}
