const storageManager = {
  autosave: false,
  autoload: false,
  stepsBeforeSave: 1,
  checkLocal: false,
  storeComponents: true,
  storeStyles: true,
  storeHtml: true,
  storeCss: true,
  id: '',
}

export default storageManager
