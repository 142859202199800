export const config = {
  id: 'softr',
  name: 'Softr',
  description: 'Add document creation to your Softr app.',
  icon: {
    type: 'image',
    value: '/images/delivery-methods/softr-logo.svg',
  },

  overview: 'The Softr integration leverages the Airtable Generation Link integration.',
  documentationUrl: 'https://docs.documint.me/integrations/softr',
  setupSteps: [
    'Using the Formula Builder below, create a Generation Link by adding your table URL.',
    'Copy the generated formula.',
    'In Airtable, create a new formula field (in the same table as the URL) and add the generated formula.',
    'In Softr, sync your base and add the new formula field as the source for a button or embedded iframe.',
  ],
}

export default config