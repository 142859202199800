import { useCallback } from 'react'
import { useTemplate } from '@hooks/useTemplate'
import { useTemplateSettingsContext } from './useTemplateSettingsContext'
import { pick } from 'lodash'

export function useTemplateSettings() {
  const { showModal, toggleModal, autoSave, toggleAutoSave } = useTemplateSettingsContext()
  const { template, update: updateTemplate } = useTemplate()
  const settings = pick(template, ['documentNamePattern', 'options', 'renderEngine'])

  const update = useCallback((settings) => updateTemplate(settings), [updateTemplate])

  const activate = useCallback(() => updateTemplate({ isActive: true }), [updateTemplate])

  const deactivate = useCallback(() => updateTemplate({ isActive: false }), [updateTemplate])

  const toggleActive = useCallback(
    () => updateTemplate({ isActive: !template?.isActive }),
    [updateTemplate, template?.isActive],
  )

  const rename = useCallback((name) => updateTemplate({ name }), [updateTemplate])

  return { showModal, toggleModal, settings, update, activate, deactivate, rename, toggleActive, autoSave, toggleAutoSave }
}
