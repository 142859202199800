import isNil from 'lodash/isNil'
import isFunction from 'lodash/isFunction'
import { updateQueryTreeKeys, updateSortKeys, convertTypes, crossRef, updateVariableNames, deepClone } from '@utils'
import { TYPES } from '@features/template-designer/components/Editor/plugins/componentTypes/consts'
import { toJson, generateLocalDataFields } from '@features/template-designer/components/NewLogicModal/utils'
import { parseSorts } from '@features/template-designer/components/NewLogicModal/Sorts'
export { v4 as uuidv4, validate as isUuidv4 } from 'uuid'

export const capitalizeFirstLetter = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const setIdKeyMap = ({ key, formattedKey, type, uuid, cid, compoundFieldId, title, schemaMetaData, editor }) => {
  editor.SchemaTypes[formattedKey] = type
  editor.SchemaIds[compoundFieldId] = { uuid, formattedKey }

  const parentKey = formattedKey.split(`.[${title}]`)[0]
  const parentType = editor.SchemaTypes[parentKey]
  editor.SchemaKeys[uuid] = {
    uuid,
    key,
    formattedKey,
    type,
    title,
    schemaMetaData,
    parentKey,
    parentType,
    ...(cid ? { cid } : {}),
  }
}

export const setComponentKeys = (
  component,
  tokenId,
  { cid, key, formattedKey, type, parentKey, parentType, schemaMetaData, title },
) => {
  const { arrayType, nesting, parentNesting, nestingObj, splitPath } = schemaMetaData
  component.addAttributes({
    'data-token-id': tokenId,
    'data-token-cid': cid,
    'data-placeholder': key,
    'data-formatted-placeholder': formattedKey,
    'data-type': type,
    'data-parent-type': parentType || '',
    'data-parent-key': parentKey || '',
    'data-array-nesting': nesting || 0,
    'data-parent-nesting': parentNesting || 0,
    'data-array-type': arrayType || '',
    'data-object-nesting': JSON.stringify(nestingObj || {}),
    'data-split-path': JSON.stringify(splitPath),
    'data-title': title,
  })
}

export const refreshTokenMap = (data, editor) => {
  data.forEach((token) => {
    setIdKeyMap({ ...token, editor })
    if (token.children) refreshTokenMap(token.children, editor)
  })
}

export const getTokens = (components, tokenComps = []) => {
  components?.each?.((component) => {
    if (component && component?.attributes?.type === TYPES.token) {
      tokenComps.push(component)
    } else {
      return getTokens(component?.components?.(), tokenComps)
    }
  })
  return tokenComps
}

export const getComponentsWithLogic = (components, logicComps = []) => {
  components?.each?.((component) => {
    if (component && component?.attributes?.hasLogic) {
      const { condition, loop, varName } = component?.attributes || {}
      if (condition || loop || varName) {
        logicComps.push(component)
      }
    } else if (component && component?.attributes?.varName) {
      logicComps.push(component)
    }
    getComponentsWithLogic(component?.components?.(), logicComps)
  })
  return logicComps
}

export const refreshTokenComponents = (components, fieldSchema, retrieveTempParent, editor) => {
  const schemaKey = retrieveTempParent()
  const { metaData } = generateLocalDataFields(convertTypes(deepClone(fieldSchema), false), schemaKey)
  components.forEach((component) => {
    const attr = component.getAttrToHTML()
    const tokenId = attr['data-token-id']
    const tokenData = editor.SchemaKeys[tokenId]

    if (tokenData) {
      setComponentKeys(component, tokenId, tokenData)
      component.removeClass('disconnected')
    } else {
      // Reconnect tokens
      const newId = crossRef(attr['data-formatted-placeholder'], editor)

      const newTokenData = editor.SchemaKeys[newId]
      if (newTokenData) {
        setComponentKeys(component, newId, newTokenData)
        component.removeClass('disconnected')
      } else {
        component.addClass('disconnected')
      }
    }

    const meta = toJson(attr['data-token-meta-data'])
    const condition = toJson(meta?.condition)
    if (condition && condition.children1 && condition.children1.length) {
      condition.children1 = updateQueryTreeKeys(deepClone(condition.children1), editor, metaData)
      meta.condition = JSON.stringify(condition)
      component.addAttributes({
        'data-token-meta-data': JSON.stringify(meta),
      })
    }
  })
}

export const refreshLogicComponents = (components, fieldSchema, retrieveTempParent, editor) => {
  const schemaKey = retrieveTempParent()
  const { metaData } = generateLocalDataFields(convertTypes(deepClone(fieldSchema), false), schemaKey)
  components.forEach((component) => {
    const condition = isFunction(component.getCondition) && toJson(component.getCondition())
    if (condition && condition.children1 && condition.children1.length) {
      condition.children1 = updateQueryTreeKeys(deepClone(condition.children1), editor, metaData)
      component.setCondition(JSON.stringify(condition))
    }

    const loop = isFunction(component.getLoop) && component.getLoop()
    const parsedLoop = toJson(loop)
    const parsedLoopValue = parsedLoop ? Object.keys(parsedLoop)[0] : null
    const value =
      parsedLoopValue && parsedLoopValue !== 'undefined' ? parsedLoopValue : !parsedLoop && loop ? loop : null

    const loopCondition = parsedLoop && parsedLoop[value]

    if (value) {
      const uuid = crossRef(value, editor) || ''
      const newKey = editor.SchemaKeys && editor.SchemaKeys[uuid]?.formattedKey

      !loopCondition && component.setLoop(newKey || value)

      const sorts = isFunction(component.getSorts) && parseSorts(component.getSorts())
      const newSorts = sorts && updateSortKeys(sorts, editor)
      if (newSorts?.length > 0) {
        component.setSorts(
          newSorts
            .filter((v) => !isNil(v.field))
            .map(({ field, order = '' }) => `${order}${field}`)
            .join(','),
        )
      }
    }

    if (loopCondition && loopCondition.children1) {
      const { metaData } = generateLocalDataFields(convertTypes(deepClone(fieldSchema), false), value)
      const uuid = parsedLoop.uuid
      const newKey = editor.SchemaKeys && editor.SchemaKeys[uuid]?.formattedKey
      loopCondition.children1 = updateQueryTreeKeys(
        deepClone(loopCondition.children1),
        editor,
        metaData,
        [],
        newKey || value,
      )
      component.setLoop(
        JSON.stringify({
          [newKey || value]: {
            ...loopCondition,
          },
          uuid: parsedLoop.uuid,
          ...(parsedLoop.cid ? { cid: parsedLoop.cid } : {}),
        }),
      )
    }

    const isCheckbox = component?.attributes?.type === TYPES.checkbox
    const oldName = isCheckbox ? component.get('placeholder') : component.get('varName')
    const trait = isCheckbox ? component.getTrait('placeholder') : component.getTrait('varName')
    const varName = updateVariableNames(oldName, editor)

    trait && isFunction(trait.view?.updateOptions) && trait.view.updateOptions(varName)
    isCheckbox ? component.set({ placeholder: varName }) : component.set({ varName })
    trait && trait.setValue(varName)
    varName && isFunction(component.updateIndices) && component.updateIndices()
    isFunction(component.refreshIndex) && component.refreshIndex()
  })
}

export function getAccessTokenFromConnection(connection) {
  switch (connection.type) {
    case 'SECRET_TEXT':
      return connection.value.secret_text
    case 'CLOUD_OAUTH2':
      return connection.value.access_token
    case 'CUSTOM_AUTH':
      return connection.value.props
    default:
      return connection.value
  }
}

export const hubspotObjects = [
  {
    id: 'CONTACT',
    name: 'contact',
  },
  {
    id: 'COMPANY',
    name: 'company',
  },
  {
    id: 'DEAL',
    name: 'deal',
  },
]

export const getFieldById = (tables, fieldId) => {
  if (!tables?.length || !fieldId) return null

  for (let i = 0; i < tables.length; i++) {
    if (!tables[i]?.fields?.length) continue
    for (let j = 0; j < tables[i].fields.length; j++) {
      if (tables[i].fields[j].id === fieldId) return tables[i].fields[j]
    }
    return null
  }
}

export const updateExpandedLinkedRecords = (tree, expandedLinkedRecords, tables) => {
  let outRecords = {}

  Object.keys(tree).forEach((treeKey) => {
    const { schemaMetaData, key, uuid } = tree[treeKey]
    if (expandedLinkedRecords[uuid]?.expanded) {
      outRecords[uuid] = {
        ...expandedLinkedRecords[uuid],
        tableId:
          schemaMetaData?.$sourceMetaData?.linkedTableId ??
          getFieldById(tables, schemaMetaData?.$sourceMetaData?.recordLinkFieldId)?.options?.linkedTableId ??
          '',
        key,
        path: schemaMetaData.splitPath,
      }
    }

    if (tree[treeKey].children)
      outRecords = {
        ...outRecords,
        ...updateExpandedLinkedRecords(tree[treeKey].children, expandedLinkedRecords, tables),
      }
  })

  return outRecords
}

// export const getJsonSchema = async (provider, connection, baseId, baseName, tableId, expandedKeys, editor) => {
//   const token = getAccessTokenFromConnection(connection)

//   const response = await fetch(
//     `/1/providers?${new URLSearchParams({
//       provider,
//       id: baseId,
//       action: provider === 'airtable' ? 'getTableSchemas' : 'getObjectSchema',
//     })}`,
//     { headers: { Authorization: `Bearer ${token}` } },
//   )

//   const schemas = await response.json()

//   if (provider === 'airtable') {
//     const expandedFields = Object.keys(expandedKeys).reduce((result, uuid) => {
//       if (expandedKeys[uuid].expanded) {
//         const table = schemas?.schema?.find((table) => table?.id === expandedKeys[uuid].tableId) ?? {
//           fields: {},
//         }
//         const { fields } = table
//         result[uuid] = fields
//       }
//       return result
//     }, {})

//     const airtableTableSchema = schemas?.schema?.find((_table) => _table?.id === tableId)

//     const settingsTree =
//       airtableTableSchema && expandedFields
//         ? parseTreeSchema(
//             AirtableParser.toJsonSchema(airtableTableSchema.fields, {
//               linkedRecords: true,
//               expandedLinkedRecords: expandedKeys,
//               expandedFields,
//               editor,
//               tables: schemas?.schema,
//             }),
//           )
//         : []

//     return AirtableParser.toJsonSchema(airtableTableSchema.fields, {
//       connection: { id: connection?.id, name: connection?.name },
//       table: schemas?.tables?.find((_table) => _table.id === tableId),
//       base: { id: baseId, name: baseName },
//       expandedLinkedRecords: updateExpandedLinkedRecords(settingsTree, expandedKeys, schemas?.schema),
//       expandedFields,
//       editor,
//       tables: schemas?.schema,
//     })
//   } else {
//     return HubspotParser.toJsonSchema(schemas, {
//       connection: { id: connection?.id, name: connection?.name },
//       object: hubspotObjects.find((obj) => obj.id === baseId),
//       editor,
//     })
//   }
// }

/**
 * Set context for component
 * @param {Object} comp
 */
const setContext = (comp) => {
  if (isFunction(comp?.setContext)) {
    comp.setContext()
    comp.addAttributes({ 'data-condition': comp.hasCondition?.() ? true : false })
    comp.addAttributes({ 'data-loop': comp.hasLoop?.() ? true : false })
  }
}

/**
 * Recursively set context for all components
 * @param {Array} components
 */
export const setContexts = (components) => {
  let comps = components
  if (isFunction(components?.setContext)) {
    setContext(components)
    comps = components?.components?.()
  }
  comps.length && comps?.each?.((component) => {
    setContext(component)
    const childComps = component?.components?.()
    childComps !== true && childComps?.length && setContexts(childComps)
  })
}

// export const uuidv4 = () => {
//   // @ts-ignore
//   return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => {
//     return (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
//   })
// }

// export const isUuidv4 = (uuid) => {
//   return /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(uuid)
// }
