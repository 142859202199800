export const config = {
  id: 'pory',
  name: 'Pory',
  icon: { type: 'image', value: '/images/delivery-methods/pory-logo.svg' },
  description: 'Add document creation to your Pory app.',

  overview: 'The Pory integration leverages the Airtable Generation Link integration.',
  setupSteps: [
    'Using the Formula Builder below, create a Generation Link by adding your table URL.',
    'Copy the generated formula.',
    'In Airtable, create a new formula field (in the same table as the URL) and add the generated formula.',
    'In Pory, sync your base and add the new formula field as the source for a button or embedded iframe.',
  ],
  documentationUrl: 'https://docs.documint.me/integrations/pory',
}

export default config