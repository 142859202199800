import React, { useEffect } from 'react'
import { Tabs } from 'antd'
import AirtableApp from './AirtableApp'
import AutomationScript from './AutomationScript'
import GenerationLink from './GenerationLink'
import { useInstructions, useTemplateDataSource } from '../../../hooks'

export default function AirtableInstructions() {
  const { dataSource } = useTemplateDataSource()
  const { config, updateConfig } = useInstructions()

  useEffect(() => {
    const table_id = dataSource?.entity?.getConfigFieldValue('table').id
    if (dataSource?.id === 'airtable' && table_id !== config.table_id) {
      const base_id = dataSource?.entity?.value?.baseId
      const url = `https://airtable.com/${base_id}/${table_id}`
      updateConfig({ url, base_id, table_id })
    }
  }, [dataSource, config, updateConfig])

  const items = [
    {
      key: 'generation-link',
      label: 'Generation Link',
      children: <GenerationLink />,
    },
    {
      key: 'automation-script',
      label: 'Automation Script',
      children: <AutomationScript />,
    },
    {
      key: 'app',
      label: 'Airtable App',
      children: <AirtableApp />,
    },
  ]

  return <Tabs items={items} direction='vertical' defaultActiveKey='generation-link' />
}
