import React from 'react'
import { Typography, Row, Col, Input, Select, Form } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import Button from '@common/Button'
import { operators, syntaxRules } from './consts'
import { dependencyValidator, isBlank } from './utils'
import { isNil } from 'lodash'

const { Title, Paragraph, Text } = Typography

export default function Condition({ data, onChange, onClear }) {
  const [form] = Form.useForm()

  const fields = [
    { name: 'operand1', value: data?.operand1 },
    { name: 'operator', value: data?.operator },
    { name: 'operand2', value: data?.operand2 },
  ]

  const singleOperandOperators = ['isEmpty', 'isNotEmpty', 'isNull', 'isNotNull']
  const requiresSingleOperand = singleOperandOperators.includes(data?.operator)

  const conditionIsEmpty = isBlank(data?.operand1) && isBlank(data?.operator) && isBlank(data?.operand2)

  const handleChange = (values, allValues) => {
    const requiresSingleOperand = singleOperandOperators.includes(allValues.operator)
    if (requiresSingleOperand) allValues.operand2 = null
    const result = {
      operand1: allValues?.operand1,
      operator: allValues.operator,
      operand2: allValues?.operand2,
    }
    onChange(result)
  }

  const handleClear = () => {
    // form.setFieldsValue(defaults.condition)
    form.resetFields()
    if (onClear) onClear()
  }

  return (
    <>
      <Paragraph className='mt-1 mb-4'>
        Conditions allow you to dynamically include or exclude sections or your template. Use the expression builder
        below to set the condition when you want this element to be displayed.
      </Paragraph>

      <Form name='condition' fields={fields} form={form} layout='vertical' onValuesChange={handleChange}>
        <Title level={5} className='mt-1 mb-3'>
          Display this element when...
        </Title>
        <Row gutter='10'>
          <Col span={7}>
            <Form.Item
              name='operand1'
              label={<small>Variable</small>}
              tooltip={
                <>
                  Enter the name of the <b>variable</b> that will be compared to the "value".
                  <br />
                  Example:
                  <Text code={true} style={{ background: '#fff' }}>
                    customer_type
                  </Text>
                  <br />
                  (see table below for details on the variable data type)
                </>
              }
              dependencies={['operator', 'operand2']}
              rules={[dependencyValidator(['operator', 'operand2']), ...syntaxRules]}
            >
              <Input placeholder='variable' size='large' allowClear />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name='operator'
              label={<small>Operator</small>}
              initialValue={data?.operator}
              dependencies={['operand1', 'operand2']}
              rules={[dependencyValidator(['operand1', 'operand2'])]}
            >
              <Select allowClear options={operators} size='large' />
            </Form.Item>
          </Col>

          <Col span={7}>
            {!requiresSingleOperand && !isNil(data?.operator) && (
              <Form.Item
                name='operand2'
                label={<small>Value</small>}
                tooltip={
                  <>
                    Enter a <b>string</b>, a number or a <b>variable</b> here to compare with the "variable".
                    <br />
                    Example:
                    <Text code={true} style={{ background: '#fff' }}>
                      "new_customer"
                    </Text>
                    <br />
                    (see table below for details on data types)
                  </>
                }
                // initialValue={data.operand2}
                dependencies={['operand1', 'operator']}
                rules={[dependencyValidator(['operator', 'operand1'])]}
              >
                <Input allowClear size='large' placeholder='variable | number | string' />
              </Form.Item>
            )}
          </Col>

          <Col span={2} className='d-flex align-items-end'>
            <Form.Item>
              <Button
                type='text'
                disabled={conditionIsEmpty}
                tooltip={{ title: 'Clear' }}
                className='mb-1'
                onClick={handleClear}
                icon={<CloseCircleOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}
