import React from 'react'
import { Layout, Image } from 'antd'
import AppBar from '@common/AppBar'

const DEFAULT_LOGO_SRC = 'https://documint.s3.amazonaws.com/assets/brand/documint-logo.svg'
export default function FixedHeaderLayout({
  children,
  className,
  left,
  center,
  right,
  logoSrc,
  logoLink,
  showHeader,
  actions,
  ...rest
}) {
  const { Content } = Layout
  left = left || Logo({ src: logoSrc || DEFAULT_LOGO_SRC, link: logoLink })

  const contentProps = {
    style: {
      height: showHeader ? 'calc(100vh - 64px)' : '100vh',
    },
  }

  return (
    <Layout className={`layout-fixed-header ${className}`} {...rest} theme='light'>
      {showHeader && <AppBar left={left} center={center} right={right} actions={actions} />}
      <Content className='dm-content' {...contentProps}>
        {children}
      </Content>
    </Layout>
  )
}

FixedHeaderLayout.defaultProps = {
  logoSrc: DEFAULT_LOGO_SRC,
  logoLink: '/',
  showHeader: true,
}

function Logo({ src, link }) {
  const img = (
    <Image
      src={src}
      alt='Logo'
      preview={false}
      style={{ maxHeight: '42px', maxWidth: '150px', width: 'auto', height: 'auto' }}
    />
  )
  const wrapperProps = { className: 'd-flex align-items-center', style: { height: '100%' } }
  return link ? (
    <a href={link} {...wrapperProps}>
      {img}
    </a>
  ) : (
    <span {...wrapperProps}>{img}</span>
  )
}
