import Section, { protectedCss as sectionCss } from './Section'
import Column, { protectedCss as columnCss } from './Column'
import Row, { protectedCss as rowCss } from './Row'
import Columns, { protectedCss as columnsCss } from './Columns'

export const protectedCss = `
  /* 
   * DEPRECATED COMPONENTS
   **************************/
  ${sectionCss}
  ${columnCss}
  ${rowCss}
  ${columnsCss}
  `
const deprecatedTypes = [Row, Column, Section, Columns]

export default deprecatedTypes 