import React from 'react'
import { Typography, Space, Image } from 'antd'

const { Paragraph } = Typography

export default function Zapier({ data }) {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Paragraph>Below is a sample workflow that can be created using Zapier.</Paragraph>
      <Image src='/images/delivery-methods/zapier-guide-overview.png' preview={false} width='600px' />
    </Space>
  )
}
