import React, { useCallback, useEffect } from 'react'
import { useTemplatePreview, TemplatePreview, MODES } from '@features/template-preview'
import { useTemplateEdit } from '../hooks'

import { MODES as EDITOR_MODES } from '../modes'
import { notification } from 'antd'
import { useTemplateDataSource } from '@features/template-data-source'

export default function TemplatePreviewTab(props) {
  const { template, mode: editorMode } = useTemplateEdit()
  const { mode, createDataSourcePreview, createPreview, selectedRecord } = useTemplatePreview()
  const { dataSource } = useTemplateDataSource()

  const doPreview = useCallback(async () => {
    try {
      if (!template) return

      if (mode === MODES.dataSource.value && selectedRecord && dataSource) {
        const { value: recordId } = selectedRecord
        const { provider, connectionName, expandedFields } = dataSource
        const entity = dataSource?.entity?.value ?? {}
        await createDataSourcePreview(template, { recordId, provider, connectionName, expandedFields, entity })
      } else if (mode === MODES.manual.value) {
        await createPreview(template, template.testData)
      }
    } catch (error) {
      notification.error({ message: 'Error', description: error.message, placement: 'bottom' })
    }
  }, [mode, createPreview, template, createDataSourcePreview, selectedRecord, dataSource])

  useEffect(() => {
    const isPreviewMode = editorMode === EDITOR_MODES.preview.value

    if (isPreviewMode) doPreview()
  }, [editorMode, doPreview])

  return <TemplatePreview dataSource={dataSource} {...props} />
}
