import { useCallback } from 'react'
import { useTemplatePreviewContext } from '.'

import useHttp from '@hooks/httpHooks'
import config from '@config'
import { pick } from 'lodash'

const apiUrl = config.apiUrl
const apiEndpoint = `${apiUrl}/templates`

const PROPS_TO_SEND = ['html', 'css', 'headerTemplate', 'footerTemplate', 'options', 'renderEngine']

export function useTemplatePreview() {
  const { preview, setPreview, mode, setMode, selectedRecord, setSelectedRecord, checkIsCached, setCacheHash } =
    useTemplatePreviewContext()
  const { post } = useHttp()

  /**
   * Create preview
   * @param {Object} testData
   */

  const createPreview = useCallback(
    async (template, mergeData, { ignoreCache = false } = {}) => {
      try {
        if (preview.isLoading) return preview.data

        if (!mergeData) mergeData = template?.testData || {}

        const templateObj = pick(template, PROPS_TO_SEND)
        const body = { template: templateObj, data: mergeData }

        const isCached = checkIsCached(body)

        if (isCached && ignoreCache !== true) return preview.data

        setPreview({ isLoading: true, error: null, data: null })

        const requestOptions = { headers: { accept: 'application/pdf' }, responseType: 'arraybuffer' }
        const response = await post(`${apiEndpoint}/preview`, body, requestOptions)

        setPreview({ data: response.data, isLoading: false, error: null })
        setCacheHash(body)

        return response.data
      } catch (error) {
        setPreview({ isLoading: false, error })
        throw error
      }
    },
    [post, setPreview, preview, setCacheHash, checkIsCached],
  )

  /**
   * Create preview
   * @param {Object} params0
   * @param {string} params0.recordId
   * @param {string} params0.connectionName
   * @param {string} params0.provider
   * @param {object} params0.entity
   * @returns {Promise<[null, any] | [any, null]>}
   */
  const createDataSourcePreview = useCallback(
    async (template, record, { ignoreCache = false } = {}) => {
      try {
        if (preview.isLoading) return preview.data

        const { recordId, connectionName, provider, expandedFields, entity } = record

        const templateObj = pick(template, PROPS_TO_SEND)
        
        const body = { template: templateObj, recordId, connectionName, provider, entity, expandedFields }

        const isCached = checkIsCached(body)

        if (isCached && ignoreCache !== true) return preview.data

        setPreview({ isLoading: true, error: null, data: null })
        setCacheHash(body)

        const requestOptions = { headers: { accept: 'application/pdf' }, responseType: 'arraybuffer' }

        const response = await post(`${apiUrl}/templates/preview-source`, body, requestOptions)

        setPreview({ data: response.data, isLoading: false, error: null })

        return response.data
      } catch (error) {
        setPreview({ isLoading: false, error })
        throw error
      }
    },
    [setPreview, post, preview, setCacheHash, checkIsCached],
  )

  return {
    preview,
    createPreview,
    mode,
    setMode,
    selectedRecord,
    setSelectedRecord,
    createDataSourcePreview,
  }
}

export default useTemplatePreview
