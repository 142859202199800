import { Checkbox, Form, Select } from 'antd'
import React, { useCallback } from 'react'
import { useInstructions, useTemplateDataSource } from '../../../hooks'

export default function NewAirtableOptionsForm({ onChange }) {
  const { dataSource } = useTemplateDataSource()
  const { config, updateConfig } = useInstructions()
  const attachmentFields = dataSource?.entity?.fields?.filter(({ type }) => type === 'multipleAttachments')
  
  const updateValues = useCallback(
    (values = {}) => {
      onChange?.(values, config)
      updateConfig(values)
    },
    [config, onChange, updateConfig],
  )

  return (
    <Form layout='vertical'>
      <Form.Item label='Attachment Field'>
        <Select
          value={config.attachment_field_name}
          options={attachmentFields?.map((a) => ({ value: a.name, label: a.name }))}
          onChange={(value) => updateValues({ attachment_field_name: value })}
          style={{ width: 250 }}
          required={true}
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          checked={config?.overwrite_attachments}
          onChange={({ target }) => updateValues({ overwrite_attachments: target?.checked })}
        >
          Overwrite attachments
        </Checkbox>
      </Form.Item>
    </Form>
  )
}
