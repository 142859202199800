import { useCallback, createContext } from 'react'
import { useGetTemplateQuery, useUpdateTemplateMutation } from '../services/documintApi/templates'

export const TemplateContext = createContext(null)

export function TemplateProvider({ id, children }) {
  const template = useGetTemplateQuery(id)

  const [
    updateTemplate,
    {
      isError: isUpdateError,
      isLoading: isUpdating,
      isSuccess: isUpdateSuccess,
      error: updateError,
      fulfilledTimeStamp: updatedAt,
      reset,
    },
  ] = useUpdateTemplateMutation()

  const update = useCallback(
    async (data) => {
      await updateTemplate({ id, ...data })
      if (isUpdateSuccess) reset()
    },
    [id, updateTemplate, reset, isUpdateSuccess],
  )

  const providerValue = {
    templateId: id,
    ...template,
    update,
    isUpdateError,
    isUpdating,
    isUpdateSuccess,
    updateError,
    updatedAt,
  }

  return <TemplateContext.Provider value={providerValue}>{children}</TemplateContext.Provider>
}
