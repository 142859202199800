import React from 'react'
import { Button, Card, Typography } from 'antd'
import {
  DataSourceInstructionsList,
  DataSourceCards,
  instructionList,
  InstructionProvider,
  useTemplateDataSource,
} from '@features/template-data-source'
import { useTemplateEdit } from '../hooks'

const { Title, Text } = Typography

export default function TemplateIntegrations(props) {
  const { template } = useTemplateEdit()
  const { dataSource } = useTemplateDataSource()

  return (
    <div {...props}>
      <div
        style={{
          padding: '2em',
          display: 'flex',
          flexDirection: 'column',
          gap: '40px',
          maxWidth: '950px',
          margin: '0 auto',
        }}
      >
        <div style={{ textAlign: 'center' }}>
          <Title level={3}>Template Integrations</Title>
        </div>

        {/* DATA SOURCES */}
        <div>
          <Title level={4}>{dataSource ? 'Data Source' : 'Connect to a Data Source'}</Title>
          <hr className='mt-2 mb-3' color='#ddd' size='1' />
          <DataSourceCards />
        </div>

        {/* SETUP INSTRUCTIONS */}
        <div>
          <Title level={4}>Setup Instructions</Title>
          <hr className='mt-2 mb-3' color='#ddd' size='1' />
          <InstructionProvider>
            <DataSourceInstructionsList dataSources={instructionList} />
          </InstructionProvider>
        </div>

        {/* REQUEST DATA SOURCE */}
        <div>
          {/* <hr className='mb-5' color='#ddd' size='1' /> */}
          <Card style={{ background: 'rgba(255,255,255,.6)' }}>
            <div style={{ display: 'flex', gap: '15px' }}>
              <img
                src='/images/delivery-methods/request-integration.svg'
                alt='Request Integration'
                style={{ width: '60px' }}
              />
              <div style={{ flex: '1 1 auto' }}>
                <Title level={5}>Request an Integration</Title>
                <Text>Don't see your data source listed above? Let us know!</Text>
              </div>
              <Button
                type='ghost'
                style={{ marginTop: '1em' }}
                href={`https://airtable.com/embed/shrT7hV0q2bE1pZBK?prefill_AccountId=${template?.account}`}
                target='_blank'
              >
                Request Integration
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  )
}
