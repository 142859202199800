import React from 'react'
import { Tooltip, Typography, Button } from 'antd'
import { useTemplateEdit } from '../../../hooks'
import { FaIcon } from '@components/icons'
const { Paragraph } = Typography

export default function ActiveSwitch({ switchProps, ...rest }) {
  const { template, activate, deactivate } = useTemplateEdit()
  const { isActive } = template || {}

  const handleIsActiveToggle = () => (isActive ? deactivate() : activate())

  return (
    <Tooltip
      mouseEnterDelay={0.5}
      showArrow={false}
      overlayStyle={{ marginLeft: '-10px' }}
      title={
        <div className='px-2 pt-2 d-flex flex-column align-items-end'>
          <Paragraph>
            Documents created from active templates <u>will not</u> include Documint branding and <u>will</u> count
            towards your quota.
          </Paragraph>
          <div>
            <a
              href='https://docs.documint.me/templates/creating-documents-merging#active-templates'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more &gt;
            </a>
          </div>
        </div>
      }
    >
      {isActive ? (
        <Button type='primary' onClick={handleIsActiveToggle}>
          Active <FaIcon icon='faToggleOn' />
        </Button>
      ) : (
        <Button type='default' onClick={handleIsActiveToggle}>
          Inactive <FaIcon icon='faToggleOff' />
        </Button>
      )}
    </Tooltip>
  )
}
