import { createContext } from 'react'
import { useToggle } from '@hooks/helperHooks'
import { EditorProvider } from './EditorContext'
export const TemplateDesignerContext = createContext(null)

export function TemplateDesignerProvider({ children }) {
  const [showHeaderFooter, toggleHeaderFooter] = useToggle()

  const designerContext = {
    showHeaderFooter,
    toggleHeaderFooter,
  }

  return (
    <TemplateDesignerContext.Provider value={designerContext}>
      <EditorProvider>{children}</EditorProvider>
    </TemplateDesignerContext.Provider>
  )
}
