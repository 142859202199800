import { pickBy, identity } from 'lodash'

export function getPropsFromUrl(url) {
  if (!url || typeof url != 'string') return {}
  const parts = url.substring(url.indexOf('airtable.com')).split('/')
  const base_id = parts[1]
  const table_id = parts[2]
  return { base_id, table_id }
}

export function createFormula(template_id, values) {
  if (!template_id || !values || !values.url || !values.attachment_field_name) return

  const { url, ...rest } = pickBy(values, identity)
  try {
    const { base_id, table_id } = getPropsFromUrl(values.url)
    if (!base_id || !table_id) return
    const query = Object.entries({ template_id, base_id, table_id, ...rest })
      .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
      .join('&')
    return `"${window.location.origin}/integrations/airtable?${query}&record_id=" & RECORD_ID()`
  } catch (error) {
    return ''
  }
}
const airtableUtils = { getPropsFromUrl, createFormula }

export default airtableUtils
