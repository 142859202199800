import { loadStripe } from '@stripe/stripe-js'
import { useStripe } from '@stripe/react-stripe-js'

const stripePublicKey = process?.env.REACT_APP_STRIPE_PUBLIC_KEY

if (!stripePublicKey) {
  console.error('**Stripe publishable key environment variable not set**')
  console.error('**Add an environment variable REACT_APP_STRIPE_PUBLISHABLE_KEY**')
  console.error('**Replace .env.example with .env and **')
}

export const stripePromise = loadStripe(stripePublicKey)

export function usePaymentMethods() {
  const stripe = useStripe()

  /**
   * Create A Payment Method
   * @param {CardElement} cardElement
   * @param {Object} [template]
   * @param {Object} [template.billing]
   */
  const create = async (cardElement, data = {}) => {
    return stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: data.billing,
    })
  }

  return { create }
}
