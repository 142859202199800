import { useTemplateContext } from './useTemplateContext'

export function useTemplate() {
  const {
    data,
    isLoading,
    isError,
    isFetching,
    isSuccess,
    error,
    update,
    isUpdateError,
    isUpdating,
    updateError,
    isUpdateSuccess,
    updatedAt,
  } = useTemplateContext()

  return {
    template: data,
    isLoading,
    isFetching,
    isSuccess,
    isError,
    error,
    update,
    isUpdateError,
    updateError,
    isUpdateSuccess,
    isUpdating,
    updatedAt,
  }
}
