import { documintApi } from '.'

export const activepiecesApi = documintApi.injectEndpoints({
  endpoints: (build) => ({
    getJwt: build.query({
      query: (data) => ({ url: '/activepieces/jwt', method: 'POST', body: data }),
    }),
    getConnection: build.query({
      query: ({ connectionName }) => ({
        url: '/activepieces/obtain-connection',
        method: 'POST',
        body: { connectionName },
      }),
    }),
    getConnections: build.query({
      query: (data) => ({ url: `/activepieces/list-connections?${new URLSearchParams(data)}` }),
    }),
    getProjects: build.query({
      query: (data) => ({ url: `/activepieces/list-projects?${new URLSearchParams(data)}` }),
    }),
  }),
})

export const {
  useGetJwtQuery,
  useLazyGetJwtQuery,
  useGetConnectionQuery,
  useLazyGetConnectionQuery,
  useGetConnectionsQuery,
  useLazyGetConnectionsQuery,
  useGetProjectsQuery,
  useLazyGetProjectsQuery,
} = activepiecesApi

export default activepiecesApi
