import { getAttrString } from '../utils'
import { TYPES } from './consts'
import toolbarItems from '../../config/toolbarItems'
import { toJson } from '../../../NewLogicModal/utils'
import { getParentLoop } from './content/Token'
import { isFunction, isNil } from 'lodash'
import { formatName } from './content/Checkbox'
import { uniqueCollection } from '@utils'

function isBlank(value) {
  return isNil(value) || value === ''
}

function isJsonString(string) {
  try {
    JSON.parse(string)
  } catch (e) {
    return false
  }
  return true
}

export const type = TYPES.template

const setContext = (comp) => {
  if (isFunction(comp?.setContext)) {
    comp.setContext()
    comp.addAttributes({ 'data-condition': comp.hasCondition?.() ? true : false })
    comp.addAttributes({ 'data-loop': comp.hasLoop?.() ? true : false })
  }
}

export const setContexts = (components) => {
  let comps = components
  if (isFunction(components?.setContext)) {
    setContext(components)
    comps = components?.components?.()
  }
  comps.length && comps?.each?.((component) => {
    setContext(component)
    const childComps = component?.components?.()
    childComps !== true && childComps?.length && setContexts(childComps)
  })
}

export default function templateType(dc, options) {
  const model = {
    defaults: {
      traits: [],
      loop: '',
      condition: '',
      customLoop: false,
      sorts: null,
      hasLogic: true,
      toolbar: toolbarItems.all,
    },
    setDefaults() {
      this.setContext()
    },
    getCondition() {
      return this.get('condition')
    },
    /**
     * Sets the condition property
     * @param { Object } condition
     * @param { string } condition.operand1
     * @param { string } condition.operator
     * @param { string } condition.operand2
     */
    setCondition(condition) {
      const type = typeof condition
      const NAME = 'condition'
      let value = condition

      // Set to default value if no condition
      if (type === 'object' && isBlank(condition.operator)) {
        value = { operand1: '', operator: null, operand2: '' }
      }

      this.set(NAME, value)
    },
    hasCondition() {
      const condition = this.get('condition')
      if (typeof condition === 'object') return !isBlank(condition?.operator)
      const hasNewCondition = toJson(condition)
      return hasNewCondition && hasNewCondition.children1 && hasNewCondition.children1.length
    },
    hasLoop() {
      const loop = this.get('loop')
      if (isBlank(loop)) return false
      return true
    },
    getLoop() {
      const loop = this.get('loop')
      if (isBlank(loop)) return
      return loop
    },
    setLoop(value) {
      const attrName = 'loop'
      if (isBlank(value)) {
        const attrs = this.getAttributes()
        delete attrs[attrName]
        this.setAttributes(attrs)
      } else {
        // this.addAttributes({ [attrName]: value })
      }
      this.set('loop', value)
      setContexts(this)
    },
    getSorts() {
      const sorts = this.get('sorts')
      if (isBlank(sorts)) return null
      return sorts
    },
    setSorts(value) {
      const attrName = 'sorts'

      if (isBlank(value) || value.length <= 0) {
        const attrs = this.getAttributes()
        delete attrs[attrName]
        this.setAttributes(attrs)
        this.set('sorts', null)
        return
      }

      // this.addAttributes({ [attrName]: value.join(',') })
      this.set('sorts', value)
    },
    setContext() {
      const loop = this.getLoop()
      const parsedLoop = toJson(loop)
      const loopValue = Object.keys(parsedLoop)[0]
      const value = loopValue && loopValue !== 'undefined' ? loopValue : !parsedLoop && loop ? loop : null
      value && this.addAttributes({ 'data-context': getParentLoop(this, value) })
    },
    applyContext(value, context) {
      const hasMatchingContext = context !== 'root' && value.includes(context)
      if (!hasMatchingContext) return value
      if (context.includes('[')) return value.split(`${context}.`).pop()
      return value.split(`[${context}].`).pop()
    },
    toHTML(opts = {}) {
      const model = this
      const { type } = model.attributes

      // HTML TAG
      const customTag = opts.tag
      delete opts.tag
      const tag = customTag || model.get('tagName')
      const selfClosingTag = model.get('void')

      // ATTRIBUTES
      const attributes = this.getAttrToHTML()
      attributes['data-gjs-type'] = type

      const attributeStr = getAttrString(attributes)

      // INNER HTML
      let innerHTML = model.getInnerHTML()

      // ADD HANDLEBARS
      // ///////////////////

      // LOOP
      const loop = model.getLoop()
      const contextList = toJson(attributes['data-context'])
      const context = contextList ? uniqueCollection(contextList) : ['root']
      const lastContext = context[context.length - 1]

      let loopVar = ''
      let loopQuery = ''
      let loopIf = ''
      const options = []
      const sorts = model.getSorts()
      if (!isBlank(sorts)) options.push(`sort="${sorts}"`)
      if (!isBlank(loop) && !isJsonString(loop)) {
        loopQuery = this.applyContext(formatName(loop), lastContext)
        loopVar = loopQuery
      } else if (!isBlank(loop) && isJsonString(loop)) {
        const tree = JSON.parse(loop)
        const loopValue = tree ? Object.keys(tree)[0] : null
        if (loopValue && loopValue !== 'undefined' && tree[loopValue]['custom-condition']) {
          loopQuery = this.applyContext(loopValue, lastContext)
          loopVar = loopQuery
          loopIf = tree[loopValue]['custom-condition']
        } else if (loopValue && loopValue !== 'undefined' && tree[loopValue].logic) {
          loopQuery = `($filter ${this.applyContext(loopValue, lastContext)} '${JSON.stringify(
            tree[loopValue].logic,
          )}')`
          loopVar = this.applyContext(loopValue, lastContext)
        } else if (loopValue && loopValue !== 'undefined') {
          loopQuery = this.applyContext(loopValue, lastContext)
          loopVar = loopQuery
        }
      }

      loopQuery = loopQuery ? `${loopQuery}  ${options.join(' ')}` : ''

      // HTML
      let HTML = ''
      if (type === TYPES.row_2 || model.get('innerLoop')) {
        HTML = `<${tag} ${attributeStr} ${selfClosingTag && '/'}>
          ${loopQuery ? `{{#each ${loopQuery}}}` : ''}
            ${loopIf ? `{{#if (${loopIf}) }}` : ''}
              ${innerHTML}
            ${loopIf ? `{{/if}}` : ''}
          ${loopQuery ? `{{/each}}` : ''}
        ${selfClosingTag ? '' : `</${tag}>`}`
      } else {
        HTML = `${loopQuery ? `{{#each ${loopQuery}}}` : ''}
          ${loopIf ? `{{#if (${loopIf}) }}` : ''}
            <${tag} ${attributeStr} ${selfClosingTag && '/'}>
              ${innerHTML}
            ${selfClosingTag ? '' : `</${tag}>`}
          ${loopIf ? `{{/if}}` : ''}
        ${loopQuery ? `{{/each}}` : ''}`
      }

      // CONDITION
      const condition = model.getCondition() || {}
      if (typeof condition === 'object' && !isBlank(condition.operator)) {
        const { operand1, operand2, operator } = condition
        HTML = `{{#compare ${formatName(operand1)} "${operator}" ${operand2} }} ${HTML} {{/compare}} `
      } else if (!isBlank(condition) && isJsonString(condition)) {
        const tree = JSON.parse(condition)
        if (tree['custom-condition']) HTML = `{{#if (${tree['custom-condition']}) }} ${HTML} {{/if}} `
        else if (tree.logic) HTML = `{{#compare '${JSON.stringify(tree.logic)}' }} ${HTML} {{/compare}} `
      }

      if (loopVar) HTML = `{{#if ${loopVar} }} ${HTML} {{/if}} `

      return HTML.replace(new RegExp('&quot;', 'g'), '"')
        .replace(new RegExp('&#039;', 'g'), "'")
        .replace(new RegExp(`<font(.*?)>`, 'g'), '')
        .replace(new RegExp('</font>', 'g'), '')
    },
  }

  function isComponent(el) {}

  return dc.addType(type, { extend: 'default', model, isComponent })
}
