import { FaIcon } from '@components/icons'
import Connection from './Connection'
import Entity from './Entity'
import EntityConfigField from './EntityConfigField'

export { default as Connection } from './Connection'
export { default as Entity } from './Entity'
export { default as EntityConfigField } from './EntityConfigField'

/**
 * @typedef DataSource
 * @property {string} id
 * @property {string} name
 * @property {string} description
 * @property {string} label
 * @property {Object} icon
 * @property {string} icon.type
 * @property {string} icon.value
 * @property {boolean} disconnected
 * @property {object} primaryField
 * @property {Entity[]} entity
 * @property {Connection} connection
 */

export class DataSource {
  constructor(config = {}) {
    const { id, name, icon, description, connection, entity, expandedFields, schema, primaryField } = config
    this.id = id
    this.name = name
    this.icon = icon
    this.description = description

    if (connection) this.connection = connection
    if (entity) this.entity = entity
    if (expandedFields) this.expandedFields = expandedFields
    if (schema) this.schema = schema
    if (primaryField) this.primaryField = primaryField
  }

  // Returns whether the data source is the default data source
  get isDefault() {
    return this.id === 'default'
  }

  getTitle({ iconProps, ...rest } = {}) {
    return (
      <>
        {this.getIcon(iconProps)} {this.name}
      </>
    )
  }

  get connection() {
    return this._connection
  }

  set connection(connection) {
    this._connection = connection instanceof Connection ? connection : new Connection(connection, this)
  }

  get entity() {
    return this._entity
  }

  set entity(entity) {
    this._entity = entity instanceof Entity ? entity : new Entity(entity, this)
  }

  set entityConfigFields(fields) {
    this._entityConfigFields = fields.map((configField) =>
      configField instanceof EntityConfigField ? configField : new EntityConfigField(configField),
    )
  }

  get entityConfigFields() {
    return this._entityConfigFields
  }

  get schema() {
    return this._schema
  }

  set schema(schema) {
    this._schema = schema
  }

  get expandedFields() {
    return this._expandedFields
  }

  set expandedFields(fields) {
    this._expandedFields = fields
  }

  get primaryField() {
    return this._primaryField
  }

  set primaryField(field) {
    this._primaryField = field
  }

  connect() {
    this?.connection.connect?.()
  }

  disconnect() {
    this?.connection.disconnect?.()
  }

  reconnect() {
    this?.connection?.reconnect?.()
  }

  get connectionName() {
    return this.connection?.name
  }

  get provider() {
    return this?.connection?.provider || this?.id
  }

  get isConnected() {
    return this?.connection?.isConnected ?? false
  }

  get disconnected() {
    return this.connection?.disconnected
  }

  get entityName() {
    return this.configFields?.[this.configFields?.length - 1].value?.name
  }

  get entityFullName() {
    const configFieldValues = this.configFields?.map((field) => field?.value?.name).filter((name) => !!name)
    return configFieldValues?.join?.(' / ') ?? null
  }

  // Entity Config Fields
  addConfigField(configField) {
    configField = configField instanceof EntityConfigField ? configField : new EntityConfigField(configField)
    if (!this.configFields) this.configFields = []
    this.configFields.push(configField)
  }

  getConfigField(id) {
    return this.configFields?.find?.((field) => field.id === id)
  }

  getConfigFieldValue(id) {
    const field = this.getConfigField(id)
    return field?.value
  }

  getConfigFieldValues() {
    return this.configFields?.reduce((acc, cur) => {
      acc[cur.id] = cur.value
      return acc
    }, {})
  }

  clearConfigFieldValues() {
    this.configFields?.forEach?.((field) => {
      field.value = null
    })
  }

  // Returns the query object for the resource fields
  // used for fetching records, etc.
  getConfigFieldQuery() {
    return this.configFields?.reduce((acc, cur) => {
      acc[cur.key] = cur.value?.id || cur.value
      return acc
    }, {})
  }

  getJsonSchema(schema) {
    return schema
  }

  getExpandableFields(schema) {
    return schema
  }

  toObject() {
    const { id, name, entity, primaryField, expandedFields } = this
    const connection = this.connection?.toObject()
    const entityConfigFields = this.entityConfigFields?.map((ecf) => ecf.toObject())
    return { id, name, connection, entity: entity.value, expandedFields, primaryField, entityConfigFields }
  }
}

DataSource.prototype.getIcon = function ({ style = {}, ...props } = {}) {
  const { type, value, styles: iconStyles, ...propsFromIconDef } = this.icon || {}
  const styles = { ...iconStyles, ...style }
  const otherProps = { ...props, ...propsFromIconDef }

  switch (type) {
    case 'image':
      return (
        <img src={value} alt={this.name} style={{ maxWidth: '100%', maxHeight: '34px', ...styles }} {...otherProps} />
      )
    case 'icon':
      return <FaIcon icon={value} style={styles} {...otherProps} />
    default:
      return null
  }
}

export default DataSource
