import { TYPES } from '../../../plugins/componentTypes/consts'

const config = {
  name: 'Layout',
  open: true,
  buildProps: [
    'justify-content',
    'align-self',
    'flex-wrap',
    'overflow',
    'avoid-page-break',
    'vertical-align',
    'align-content-vertical',
  ],
  properties: [
    {
      name: 'Vertical Content Alignment',
      id: 'align-content-vertically',
      property: 'justify-content',
      type: 'radio',
      className: 'radio-vertical',
      full: false,
      default: 'flex-start',
      options: [
        { value: 'flex-start', className: 'fas fa-objects-align-top', title: 'Top', name: 'Top' },
        { value: 'center', className: 'fas fa-objects-align-center-vertical', title: 'Middle', name: 'Middle' },
        { value: 'flex-end', className: 'fas fa-objects-align-bottom', title: 'Bottom', name: 'Bottom' },
        { value: 'space-between', className: 'fas fa-bars', title: 'Between', name: 'Between' },
        { value: 'space-evenly', className: 'fas fa-distribute-spacing-vertical', title: 'Evenly', name: 'Evenly' },
      ],
      requires: { display: ['flex'], 'flex-direction': ['column'] },
    },

    {
      name: 'Horizontal Alignment All',
      id: 'align-content-horizontally',
      property: 'justify-content',
      type: 'radio',
      className: 'radio-vertical',
      full: false,
      default: 'flex-start',
      options: [
        { value: 'flex-start', className: 'fa fa-align-left', title: 'Left', name: 'Left' },
        { value: 'center', className: 'fa fa-align-center', title: 'Middle', name: 'Middle' },
        { value: 'flex-end', className: 'fa fa-align-right', title: 'Right', name: 'Right' },
      ],
      requires: { display: ['flex'], 'flex-direction': ['row'] },
    },
    {
      name: 'Vertical Alignment',
      id: 'align-content-vertical',
      property: 'justify-content',
      type: 'radio',
      className: 'radio-vertical',
      full: false,
      default: 'flex-start',
      options: [
        { value: 'flex-start', className: 'fas fa-objects-align-top', title: 'Left', name: 'Top' },
        { value: 'center', className: 'fas fa-objects-align-center', title: 'Middle', name: 'Middle' },
        { value: 'flex-end', className: 'fas fa-object-align-bottom', title: 'Right', name: 'Bottom' },
      ],
      requires: { display: ['flex'], 'flex-direction': ['row'] },
    },
    {
      name: 'Align Vertical',
      property: 'vertical-align',
      type: 'radio',
      full: false,
      default: 'top',
      options: [
        { value: 'top', className: 'fas fa-objects-align-top', title: 'Top', name: 'Top' },
        { value: 'middle', className: 'fas fa-objects-align-center-vertical', title: 'Middle', name: 'Middle' },
        { value: 'bottom', className: 'fas fa-objects-align-bottom', title: 'Bottom', name: 'Bottom' },
      ],
    },

    {
      name: 'Align Horizontal',
      id: 'align-horizontal',
      property: 'align-self',
      type: 'radio',
      options: [
        { value: 'initial', name: 'X' },
        { value: 'flex-start', className: 'fas fa-objects-align-left', title: 'Left', name: 'Left' },
        { value: 'center', className: 'fas fa-objects-align-center-horizontal', title: 'Center', name: 'Center' },
        { value: 'flex-end', className: 'fas fa-objects-align-right', title: 'Right', name: 'Right' },
      ],
      requiresParent: { display: ['flex'] },
    },

    {
      name: 'Align Horizontal',
      id: 'margin-align-horizontal',
      property: 'margin',
      type: 'radio',
      default: '0 auto 0 0',
      options: [
        { value: '0 auto 0 0', className: 'fas fa-objects-align-left', title: 'Left', name: 'Left' },
        {
          value: '0 auto 0 auto',
          className: 'fas fa-objects-align-center-horizontal',
          title: 'Center',
          name: 'Center',
        },
        { value: '0 0 0 auto', className: 'fas fa-objects-align-right', title: 'Right', name: 'Right' },
      ],
      requiresParent: { display: ['table-cell'] },
    },

    {
      name: 'Align Horizontal <i class="fa-solid fa-square-info"></i>',
      info: 'This sets the alignment on parent element.',
      id: 'parent-align-horizontal',
      property: 'parent-align',
      type: 'radio',
      default: 'left',
      options: [
        { value: 'left', className: 'fas fa-objects-align-left', title: 'Left', name: 'Left' },
        {
          value: 'center',
          className: 'fas fa-objects-align-center-horizontal',
          title: 'Center',
          name: 'Center',
        },
        { value: 'right', className: 'fas fa-objects-align-right', title: 'Right', name: 'Right' },
      ],
      requiresParent: { display: ['table-cell'] },
      requires: { display: ['inline-block'] },
      onChange({ value, property }) {
        if (!value) return
        const editor = property.em.getEditor()
        const target = editor.getSelected()
        // If no target or selectable inner text elements
        const targetType = target?.get('type')
        if (!targetType || targetType === TYPES.token || targetType === TYPES.link) return
         const parent = target.parent()
        if (!parent) return
        parent.addStyle({ 'text-align': value })
      },
    },

    {
      name: 'Align (Justify Self)',
      id: 'align-vertical',
      property: 'justify-self',
      type: 'radio',
      options: [
        { value: 'flex-start', className: 'fas fa-objects-align-left', title: 'Left', name: 'Left' },
        { value: 'center', className: 'fas fa-objects-align-center-horizontal', title: 'Center', name: 'Center' },
        { value: 'flex-end', className: 'fas fa-objects-align-right', title: 'Right', name: 'Right' },
      ],
      requiresParent: { display: ['flex'] },
    },

    {
      name: 'Clip Content  <i class="fa-solid fa-square-info"></i>',
      id: 'overflow',
      info: 'Any part of an element that falls outside the bounds of the frame will be hidden',
      property: 'overflow',
      type: 'radio',
      default: 'hidden',
      full: false,
      options: [
        { value: 'visible', name: 'No' },
        { value: 'hidden', name: 'Yes' },
      ],
    },

    {
      name: 'Break Across Pages  <i class="fa-solid fa-square-info"></i>',
      id: 'avoid-page-break',
      info: 'When set to &quot;Avoid&quot; this setting attempts to prevent splitting this element across multiple pages by pushing it down to the top of the next page.',
      property: 'page-break-inside',
      type: 'radio',
      default: 'auto',
      full: false,
      list: [
        { value: 'auto', name: 'Allow' },
        { value: 'avoid', name: 'Avoid' },
      ],
    },

    {
      name: 'Wrap Columns  <i class="fa-solid fa-square-info"></i>',
      id: 'flex-wrap',
      info: 'Allow columns to wrap',
      property: 'flex-wrap',
      type: 'radio',
      default: 'nowrap',
      options: [
        { value: 'nowrap', name: 'No' },
        { value: 'wrap', name: 'Yes' },
      ],
    },

    {
      name: 'Rotate',
      id: 'rotate-z',
      property: 'transform',
      functionName: 'rotateZ',
      type: 'radio',
      default: '0',
      options: [
        { value: '0', name: 'None' },
        { value: '90deg', name: '90deg' },
        { value: '180deg', name: '180deg' },
        { value: '270deg', name: '270deg' },
      ],
      //onChange({ to, from, value, property }) {
      //  const editor = property.em.getEditor()
      //  const target = editor.getSelected()
      //  if (!target && value === 0) return
      //  target.addStyle({ padding: value })
      //},
    },
  ],
}
export default config
